U.check_lbj_location_ids = function() {
	let lines = U.lbj_correction_data.split('\n')
	let true_llis = {}
	let true_lli_count = 0
	for (let line of lines) {
		line = $.trim(line)
		if (!line) continue
		++true_lli_count

		let arr = line.split('\t')
		true_llis[arr[0]] = {
			lbj_location_id: arr[0],
			county: arr[1],
			city: arr[2].toLowerCase(),
			zip: arr[3],
			name: arr[4].toLowerCase()
		}
	}
	console.log('processed true llis: ' + true_lli_count)

	let hash = {}
	let dups = {}
	let dup_count = 0
	for (let location of vapp.$store.state.locations) {
		let lli = location.lbj_location_id
		// just worry about ed locations for now
		if (location.location_type != 'ed') continue
		
		// don't worry about locations that don't have a lbj_location_id
		if (empty(lli)) continue

		location = {
			vh_location_id: location.vh_location_id,
			current_lbj_location_id: location.lbj_location_id,
			county: location.county,
			location: location.location,
			ed_priority: location.ed_priority,
			new_lbj_location_id: '',
		}

		if (hash[lli]) {
			if (!dups[lli]) {
				++dup_count
				dups[lli] = [hash[lli]]
			}
			dups[lli].push(location)
			++dup_count
		} else {
			hash[lli] = location
		}
	}
	console.log(sr('$1 total duplicates found', dup_count))

	let results = {
		found_county_match: [],
		two_dups_same_county: [],
		no_county_match: [],
		no_true_match: [],
	}
	let correct_locs = []
	let locs_to_update = []
	let unknown_locs = []
	for (let lli1 in dups) {
		let arr = dups[lli1]
		let true_lli_loc = true_llis[lli1]
		let county_match_count = 0
		if (empty(true_lli_loc)) {
			results.no_true_match.push(sr('$1: no true match', lli1))
		} else {
			for (let loc1 of arr) {
				// if counties match, this is (presumably) the right one
				if (loc1.county == true_lli_loc.county) {
					if (county_match_count > 0) {
						results.two_dups_same_county.push(sr('$1: two dups with the same county', lli1))
					}
					++county_match_count
				}
			}
		}
		// no county match: this never happened
		if (county_match_count == 0) results.no_county_match.push(sr('$1: DIDN’T FIND county match', lli1))
		if (county_match_count == 1) results.found_county_match.push(sr('$1: found county match', lli1))

		for (let loc2 of arr) {
			// if we found only one county match, we should be able to process all the dups
			if (county_match_count == 1) {
				// if counties match, it's correct
				if (loc2.county == true_lli_loc.county) {
					if (lli1 == '584831') console.log('found true county')
					loc2.new_lbj_location_id = lli1
					correct_locs.push(loc2)
				} else {
					// look for an item in true_llis that matches this loc2's name and city
					let lower_case_location = loc2.location.toLowerCase()
					if (lli1 == '584831') console.log(lower_case_location)
					let found_it = false
					for (let lli2 in true_llis) {
						let tl = true_llis[lli2]
						if (lower_case_location.indexOf(tl.name) > -1) {
							// console.log('found location: ' + tl.name)
							if (lower_case_location.indexOf(tl.city) > -1) {
								// console.log('   FOUND CITY!!: ' + tl.city)
								loc2.new_lbj_location_id = tl.lbj_location_id
								if (lli1 == '584831') console.log(sr('update $1 to $2', loc2.current_lbj_location_id, loc2.new_lbj_location_id))
								locs_to_update.push(loc2)

								// fix zip??
								loc2.location = loc2.location.replace(/\d\d\d\d\d$/, tl.zip)
								found_it = true
								break
							}
						}
					}
					if (!found_it) {
						// this doesn't happen
						console.log('   no match :( : ' + loc2.vh_location_id)
						loc2.new_lbj_location_id = '???'
						unknown_locs.push(loc2)
					}
				}
			} else {
				// else add all dups to unknown_locs
				// loc2.new_lbj_location_id = '???' + lli1
				unknown_locs.push(loc2)
			}
		}
	}

	for (let x in results) {
		console.log(sr('$1 ($2)', x, results[x].length))
		// console.log(sr('$1 ($2)', x, results[x].length), results[x].join('\n'))
	}

	console.log('correct_locs', correct_locs)
	console.log('locs_to_update', locs_to_update)
	console.log('unknown_locs', unknown_locs)

	// return
	let all_locs = unknown_locs.concat(['', 'CAN AUTO CORRECT'], locs_to_update, ['', 'CORRECT'], correct_locs)
	let report = ''
	for (let loc3 of all_locs) {
		if (typeof(loc3) == 'string') report += (loc3 + '\n')
		else report += sr('$1	$2	$3	$4	$5	$6\n', loc3.current_lbj_location_id, loc3.new_lbj_location_id, loc3.vh_location_id, loc3.county, loc3.ed_priority, loc3.location.replace(/\s/g, ' '))
	}

	// for (let lli3 in dups) {
	// 	let arr = dups[lli3]
	// 	for (let loc3 of arr) {
	// 		report += sr('$1 locations	$2	$3	$4	$5	$6	$7\n', arr.length, loc3.current_lbj_location_id, loc3.new_lbj_location_id, loc3.vh_location_id, loc3.county, loc3.ed_priority, loc3.location.replace(/\s/g, ' '))
	// 	}
	// }

	console.log(report)

	// update lines
	// priority (number) or vh_location_id], county, LBJ ID (number), location description/address/city/zip
	let update_lines = ''
	for (let loc4 of locs_to_update) {
		update_lines += sr('$1	$2	$3	$4\n', loc4.vh_location_id, loc4.county, loc4.new_lbj_location_id, loc4.location.replace(/\s/g, ' '))
	}
	console.log('update_lines')
	console.log(update_lines)

	// console.log(true_llis)
}

U.lbj_correction_data = `
588813	St. Lucie	Fort Pierce	34951	001 - Lakewood Park Village Hall
588819	St. Lucie	Fort Pierce	34947	006 - Renaissance Business PK (OBBC)
588786	St. Lucie	Fort Pierce	34981	008 - Midway Road Church of Christ
588822	St. Lucie	Fort Pierce	34949	009 - National Navy SEAL Museum
588802	St. Lucie	Fort Pierce	34982	010 - Havert L. Fenn Center
588837	St. Lucie	Pt St Lucie	34952	011 - MidFlorida Event Center (Civic)
588817	St. Lucie	Fort Pierce	34982	012 - Fort Pierce Masonic Lodge
588800	St. Lucie	Fort Pierce	34982	013 - Havert L Fenn Center
588795	St. Lucie	Fort Pierce	34947	014 - Miracle Prayer Temple
588820	St. Lucie	Jensen Beach	34957	015 - Holiday Out Recreation Hall
588798	St. Lucie	Fort Pierce	34949	016 - Westside Church
588783	St. Lucie	Fort Pierce	34947	017 - St. Paul A.M.E. Church
588804	St. Lucie	Fort Pierce	34950	018 - Lincoln Park Recreation Ctr
588779	St. Lucie	Fort Pierce	34982	019 - Havert L Fenn Center
588828	St. Lucie	Pt St Lucie	34952	020 - Spanish Lakes Riverfront
588776	St. Lucie	Pt St Lucie	34952	021 - Spanish Lakes Original
588825	St. Lucie	Pt St Lucie	34984	022 - PSL Council on Aging
588816	St. Lucie	Pt St Lucie	34983	023 - St. Andrew Lutheran Church
588826	St. Lucie	Fort Pierce	34947	024 - Miracle Prayer Temple
588811	St. Lucie	Fort Pierce	34982	025 - Days Inn Midtown
588803	St. Lucie	Pt St Lucie	34984	026 - Port St Lucie Community Ctr
588824	St. Lucie	Fort Pierce	34947	028 - Renaissance Business PK (OBBC)
588794	St. Lucie	Fort Pierce	34951	031 - Spanish Lakes Fairways
588789	St. Lucie	Pt St Lucie	34983	035 - F.U.M.C. Outreach Center
588821	St. Lucie	Pt St Lucie	34952	036 - MidFlorida Event Center (Civic)
588784	St. Lucie	Pt St Lucie	34953	037 - Treasure Coast High School
588814	St. Lucie	Pt St Lucie	34952	038 - Spanish Lakes Golf Village
588818	St. Lucie	Fort Pierce	34951	039 - Spanish Lakes Country Club
588787	St. Lucie	Fort Pierce	34982	040 - Church of God
588810	St. Lucie	Pt St Lucie	34983	041 - West St. Lucie Elks Lodge #2823
588780	St. Lucie	Pt St Lucie	34952	042 - First Congregational Church
588830	St. Lucie	Pt St Lucie	34986	043 -The Island Club at P.G.A. Vlge
588827	St. Lucie	Pt St Lucie	34984	045 - Port St Lucie Community Ctr
588797	St. Lucie	Pt St Lucie	34983	046 - Real Life Church
588799	St. Lucie	Pt St Lucie	34953	047 - Windmill Point I Clubhouse
588815	St. Lucie	Pt St Lucie	34953	049 - Oak Hammock K - 8 School
588777	St. Lucie	Pt St Lucie	34953	050 - Treasure Coast High School
588835	St. Lucie	Pt St Lucie	34953	051 - Disabled American Vets Hall
588831	St. Lucie	Fort Pierce	34951	052 - Spanish Lakes Country Club
588793	St. Lucie	Pt St Lucie	34952	053 - PSL Police Athletic League
588806	St. Lucie	Pt St Lucie	34983	056 - F.U.M.C. Outreach Center
588808	St. Lucie	Fort Pierce	34946	060 - St Lucie School
588832	St. Lucie	Palm City	34990	065 - Harbour Ridge Clubhouse
588840	St. Lucie	Pt St Lucie	34953	066 - Church of the Nazarene
588833	St. Lucie	Pt St Lucie	34986	067 - Grace Lutheran Church
588838	St. Lucie	Pt St Lucie	34983	068 - The Lighthouse Chapel
588782	St. Lucie	Pt St Lucie	34953	071 - Crosstown Fellowship
588829	St. Lucie	Pt St Lucie	34952	073 - Harbor Place
588792	St. Lucie	Pt St Lucie	34952	074 - Savanna Club
588841	St. Lucie	Pt St Lucie	34983	075 - St. Andrews Lutheran Church
588788	St. Lucie	Pt St Lucie	34983	082 - River Place on the St. Lucie
588785	St. Lucie	Pt St Lucie	34953	083 - Paula A. Lewis Library
588781	St. Lucie	Port St Lucie	34953	084 - Parks Edge, POA Inc.
588801	St. Lucie	Pt St Lucie	34986	085 - IRSC, Veterans Center of Excellence
588836	St. Lucie	Port St Lucie	34986	086 - Sunlight Community Church
588807	St. Lucie	Pt St Lucie	34987	089 - Tradition Town Hall
588790	St. Lucie	Pt St Lucie	34987	090 - Tradition Town Hall
588796	St. Lucie	Pt St Lucie	34987	091 - Allapattah Flats K - 8 School
588791	St. Lucie	Fort Pierce	34951	094-Spanish Lakes Fairways
588805	St. Lucie	Pt St Lucie	34986	095 - IRSC, Veterans Center of Excellence
585045	Dixie	Cross City	32628	10 - COUNTY BLDG.
586954	Martin	Stuart	34994	10th Street Recreation Center
587432	Miami-Dade	Miami	33172	111 BLDG--UOCAVA--FED ONLY
585063	Dixie	Steinhatchee	32359	11 - JENA COM. CENTER
585052	Dixie	Horseshoe Beach	32648	12 - HORSESHOE BCH CITY HALL
587882	Osceola	St. Cloud	34769	17TH STREET CIVIC CENTER
585039	Dixie		32680	1 - FIRST DIST.COM. BLDG
587574	Monroe	Marathon	33050	1st Baptist Church Marathon
588578	Polk	Bradley	33835	1st Baptist Church of Bradley
585236	Bay	Panama City	32404	1st Baptist Church of Callaway
588590	Polk	Winter Haven	33881	1st Baptist Church of Lucerne Park
586852	Marion	Summerfield	34491	1st Baptist Church of S'field, Family Life
587674	Orange	Winter Park	32789	1ST BAPTIST CHURCH OF WINTER PARK
588220	Palm Beach	Palm Beach Gardens	33410	1st Baptist Church PBG
586932	Marion	Salt Springs	32134	1st Baptist Church Salt Spr, Fell. Hall
585237	Bay	Panama City	32404	1st Baptist of Deerpoint Lake
585238	Bay	Mexico Beach	32456	1st Baptist of Mexico Beach
586847	Marion	Ocala	34471	1st Christian Church
587991	Palm Beach	West Palm Beach	33415	1st Church of the Nazarene
587626	Orange	Winter Park	32789	1ST COVENANT CHURCH
584943	Hardee	Wauchula	33873	1ST MISSIONARY BAPTIST CHURCH
588131	Palm Beach	North Palm Beach	33408	1st Unitarian Church NPB
587648	Orange	Orlando	32803	1ST UNITARIAN CHURCH OF ORLANDO
585114	Taylor	Perry	32347	1st United Methodist Ch
586972	Martin	Hobe Sound	33455	1st United Methodist Church
587652	Orange	Apopka	32703	1ST UNITED METHODIST CHURCH
584839	Dixie	Cross City	32628	2 - MASONIC LODGE
588471	Pinellas	St Petersburg	33713	30th Avenue Baptist Church
584932	Dixie	Old Town	32680	4 - SCHOOL BD MTG. FACILITY
585133	Lafayette	Branford	32008	4th District Community Center
588212	Palm Beach	Delray Beach	33483	505 Club
585230	Bay	Panama City Beach	32413	5 Bridges Church
584863	Dixie	Suwannee	32692	5 - SUWANNEE COM. CENTER
584859	Dixie	Cross City	32628	6 - 55A COM. BUILDING
586325	Hillsborough	Tampa	33619	78th Street Community Library
585134	Dixie	Cross City	32628	7 - WARD AVE.
585073	Dixie	Cross City	32628	9 - CITY HALL
588105	Palm Beach	Delray Beach	33446	Abbey Village Clubhouse
588039	Palm Beach	Boynton Beach	33472	Aberdeen East Clubhouse
586854	Marion	Ocala	34480	Abiding Hope Lutheran Church
588627	Polk	Winter Haven	33881	Abiding Savior Lutheran Church
588264	Palm Beach	Boca Raton	33487	Above and Beyond Community Church
585560	Broward	Margate	33063	Abundant Life Church
588033	Palm Beach	Loxahatchee	33470	Acreage Branch Library
588077	Palm Beach	Loxahatchee	33470	Acreage Pines Elementary
587084	Miami-Dade	Miami	33155	A. D. Barnes Park
588263	Palm Beach	Boca Raton	33486	Addison Mizner K-8 School
585295	Brevard	Melbourne	32940	Addison Village Club
585972	Duval	Atlantic Beach	32233	Adele Grage Cultural Center
585703	Clay	Orange Park	32073	ADVENT LUTHERAN CHURCH
586106	Flagler	Palm Coast	32164	AFRICAN AMERICAN CULTURAL SOCIETY
587457	Miami-Dade	Miami	33142	Agenoria S. Paschal/Olinda Elementary School
588738	St. Johns	St Augustine	32092	Agricultural Ctr
586312	Hillsborough	Plant City	33567	Ahava Baptist Church
587159	Miami-Dade	Homestead	33032	Air Base K-8 Center
584954	Lafayette	Mayo	32066	Airline Community Center
587575	Okaloosa	Crestview	32536	Airport Road Church of Christ
587122	Miami-Dade	Miami Gardens	33055	A. J. King Park
585172	Alachua	Newberry	32669	Alachua County Agr. & Equestrian Center
586762	Leon	Tallahassee	32304	Alarm International Ministries
588176	Palm Beach	West Palm Beach	33401	Alexander W Dreyfoos SOA High
584942	Jackson	Alford	32420	ALFORD COMM CENTER
587186	Miami-Dade	Miami	33131	Alfred I. DuPont Building
588347	Pasco	Zephyrhills	33542	Alice Hall Community Center
587985	Palm Beach	Palm Beach Gardens	33410	Allamanda Elementary
589082	Sumter	The Villages	32162	ALLAMANDA VLG REC CTR
587363	Miami-Dade	Miami	33142	Allapattah Branch Library
587177	Miami-Dade	Miami	33142	Allapattah Neighborhood Service Center
589084	Volusia	Daytona Beach	32114	Allen Chapel AME Church
587019	Miami-Dade	North Miami Beach	33162	Allen Park Community Center
588861	Santa Rosa	Jay	32565	Allentown VFD
586362	Hillsborough	Lutz	33558	All Saints Lutheran Church
587642	Orange	Orlando	32837	ALL SAINTS LUTHERAN CHURCH
585966	Duval	Jacksonville	32257	All Souls Anglican Church
589119	Volusia	New Smyrna Beach	32168	Alonzo Babe James Community Center
587029	Miami-Dade	Miami	33155	Alpha & Omega Church
589000	Seminole	Altamonte Springs	32701	Altamonte Christian School North Campus
588963	Seminole	Altamonte Springs	32701	Altamonte Springs SDA Church
586330	Hillsborough	Tampa	33614	ALTAR Church
584959	Calhoun	Altha	32421	Altha Old Gym
588708	Polk	Alturas	33820	Alturas Women's Club
586648	Lee	Alva	33920	Alva Community Center
588529	Pinellas	St Petersburg	33702	Americana Cove
584937	Nassau	Fernandina Beach	32034	American Beach Community Center
588073	Palm Beach	Lake Worth	33461	American Finnish Club
587900	Palm Beach	Lake Worth	33463	American German Club
585262	Brevard	Melbourne Village	32904	American Homesteading Foundation
588680	Polk	Fort Meade	33841	American Legion
586180	Highlands	Lake Placid	33852	AMERICAN LEGION BLDG
586911	Marion	Belleview	34420	American Legion Hall
584939	Franklin	Apalachicola	32320	American Legion Hall
585364	Broward	Pompano Beach	33060	American Legion Local Post #142
587567	Monroe	Marathon	33050	American Legion Marathon
586389	Hillsborough	Tampa	33604	American Legion Post 111
585662	Charlotte	Rotonda West	33947	American Legion Post #113
585981	Duval	Jacksonville Beach	32250	American Legion Post 129
586357	Hillsborough	Tampa	33626	American Legion Post 152
586780	Manatee	Bradenton	34209	American Legion Post #24
586255	Hillsborough	Plant City	33563	American Legion Post 26
586860	Marion	Dunnellon	34432	American Legion Post 58
587165	Miami-Dade	Coral Gables	33134	American Legion Post #98
587739	Orange	Orlando	32810	AMERICAN LEGION STATE HEADQUARTERS
588062	Palm Beach	Greenacres	33463	American Polish Club
587039	Miami-Dade	Hialeah	33015	American Senior High School
588747	St. Johns	St Augustine	32080	Anastasia Baptist Church
588762	St. Johns	St Augustine	32084	Anastasia Baptist S.R. 16 Campus
588184	Palm Beach	North Palm Beach	33408	Anchorage Activities Bldg
585030	Suwannee	Lake City	32024	ANCHOR BAPTIST CHURCH
587758	Orange	Maitland	32751	ANCHOR CHURCH OF MAITLAND
588213	Palm Beach	West Palm Beach	33411	Andros Isle POA Clubhouse
585598	Broward	Hollywood	33019	Anne Kolb Nature Center
588445	Pinellas	Largo	33774	Anona United Methodist Church
586873	Marion	Anthony	32617	Anthony UM Church, Educ. Bldg.
586316	Hillsborough	Apollo Beach	33572	Apollo Beach Campus of Bell Shoals Baptist Church
586326	Hillsborough	Apollo Beach	33572	Apollo Beach Recreation Center
587720	Orange	Apopka	32703	APOPKA COMMUNITY CENTER
586206	Hillsborough	Brandon	33510	Apostles Lutheran Church
586725	Leon	Tallahassee	32304	Aquilina Howell Conference Center
585153	Alachua	Archer	32618	Archer Community Center
587252	Miami-Dade	Miami	33147	Arcola Lakes Community Park
585887	Duval	Jacksonville	32222	Argyle Branch Library
585948	Duval	Jacksonville	32244	Argyle Church of Christ
585704	Clay	Orange Park	32073	ARGYLE ELEMENTARY
586260	Hillsborough	Brandon	33511	Arise Assembly of God at Brandon
587118	Miami-Dade	Sunny Isles Beach	33160	Arlen House Condo West
585846	Duval	Jacksonville	32211	Arlington Baptist Church
585858	Duval	Jacksonville	32211	Arlington Christian Church
585907	Duval	Jacksonville	32211	Arlington Congregational Church
586508	Lake	Leesburg	34748	Arlington Ridge-Fairfax Hall
587517	Miami-Dade	Miami	33144	Armando Badia Senior Center
584856	Washington	Chipley	32428	Armory
587155	Miami-Dade	Miami	33138	Arthur E. Teele Jr Community Center
587428	Miami-Dade	Miami	33186	Arvida Middle School
585724	Clay	Orange Park	32065	ASBURY UNITED METHODIST CH
587776	Orange	Maitland	32751	ASBURY UNITED METHODIST CHURCH
588207	Palm Beach	Boca Raton	33487	Ascension Catholic Church
586877	Marion	Ocala	34480	Ascension Lutheran Church
586132	Gadsden	Havana	32333	Assembly of God
585607	Broward	Lauderdale-by-the-sea	33062	Assumption Catholic Church
586346	Hillsborough	Tampa	33626	Aston Gardens At Tampa Bay
586511	Lake	Astor	32102	Astor Community Building
585131	Nassau	Fernandina Beach	32034	Atlantic Avenue Recreation Center
585905	Duval	Atlantic Beach	32233	Atlantic Beach City Hall
587928	Palm Beach	Delray Beach	33445	Atlantic High
585442	Broward	Margate	33063	Atlantic West Elementary School
588206	Palm Beach	Atlantis	33462	Atlantis City Hall
588584	Polk	Auburndale	33823	Auburndale Civic Center
587577	Okaloosa	Crestview	32536	Auburn Pentecostal Church
585079	Jefferson	Monticello	32344	Aucilla Central Baptist
587650	Orange	Orlando	32803	AUDUBON PARK COVENANT CHURCH
587833	Orange	Orlando	32828	AVALON CHURCH
587663	Orange	Orlando	32828	AVALON PARK VILLAGE COMMUNITY CENTER
587658	Orange	Orlando	32828	AVALON TECH
587241	Miami-Dade	Homestead	33034	Avant School of Excellence
587216	Miami-Dade	Aventura	33180	Aventura Community Recreation Center
587481	Miami-Dade	Aventura	33180	Aventura Government Center
586200	Highlands	Avon Park	33825	AVON PARK CITY HALL COUNCIL CHAMBERS
586176	Highlands	Avon Park	33825	AVON PARK COMMUNITY CENTER
586197	Highlands	Avon Park	33825	AVON PARK LAKES CLUBHOUSE
586361	Hillsborough	Tampa	33603	A.W. Windhorst Lodge 185 F. & A.M.
588519	Pinellas	St Petersburg	33710	Azalea Baptist Church
586037	Escambia	Pensacola	32514	AZALEA TRACE
585651	Charlotte	Babcock Ranch	33982	Babcock Ranch (Lee Health)
588662	Polk	Babson Park	33827	Babson Park Community Church
588845	Santa Rosa	Milton	32583	Bagdad-Bagdad Community Center
587605	Okaloosa	Baker	32531	Baker Area Recreation Bldg
585779	Collier	Naples	34102	Baker Park
588059	Palm Beach	West Palm Beach	33407	Bak Middle School of the Arts
585945	Duval	Jacksonville	32234	Baldwin Town Hall
587058	Miami-Dade	Bal Harbour	33154	Bal Harbour Village Hall
585959	Duval	Jacksonville	32207	Balis Community Center
588373	Pasco	Land O' Lakes	34638	Ballantrae Clubhouse
588007	Palm Beach	Palm Beach Gardens	33418	Ballen Isles Country Club
586243	Hillsborough	Wimauma	33598	Balm Civic Center
588616	Polk	Haines City	33844	Balmoral Event Center
588142	Palm Beach	Delray Beach	33445	Banyan Creek Elementary
587267	Miami-Dade	Miami	33155	Banyan Elementary School
587395	Miami-Dade	Miami Lakes	33018	Barbara Goleman Senior High School
588727	Putnam	Palatka	32177	Bardin Fire Dept
588419	Pinellas	Largo	33777	Bardmoor Community Center
585326	Brevard	Barefoot Bay	32976	Barefoot Bay Building A
587713	Orange	Orlando	32808	BARNETT PARK
586035	Escambia	Molino	32577	BARRINEAU PARK COMMUNITY CNTR
588664	Polk	Bartow	33830	Bartow Assembly of God
588675	Polk	Bartow	33830	Bartow Civic Center
585961	Duval	Jacksonville	32258	Bartram Springs Clubhouse
588767	St. Johns	St. Johns	32259	Bartram Trail Branch Library
585136	Okeechobee	Okeechobee	34972	BASINGER COMMUNITY CENTER
585104	Baker	Sanderson	32087	Baxter
586263	Hillsborough	Mango	33550	Bay Area Church Of Christ
587512	Miami-Dade	Bay Harbor Islands	33154	Bay Harbor Islands Town Hall
586547	Lake	Groveland	34736	Bay Lake Baptist Church
584972	Bradford	Starke	32091	Bayless Highway Baptist Church
586423	Hillsborough	Brandon	33510	Bay Life Church
585226	Bay	Panama City	32404	Bayou George Christian Church
588454	Pinellas	St. Petersburg	33708	Bay Pines Condo Recreation Center
588933	Sarasota	Nokomis	34275	BAY POINT CHURCH
586803	Manatee	Bradenton	34207	Bayshore Gardens Rec Center
586281	Hillsborough	Tampa	33629	Bayshore Presbyterian Church
586788	Manatee	Bradenton	34212	Bayside Community Church
586832	Manatee	Bradenton	34207	Bayside Community Church, Bayshore Campus
586643	Lee	Fort Myers Beach	33931	Bayside Estates Clubhouse
586807	Manatee	Parrish	34219	Bayside North River Campus
586058	Escambia	Pensacola	32503	BAYVIEW COMMUNITY CENTER
588456	Pinellas	St Petersburg	33705	Bay Vista Center
586268	Hillsborough	Tampa	33615	Bay West Club
588558	Pinellas	Palm Harbor	34683	Baywood Village
588159	Palm Beach	Boynton Beach	33426	BB Leisureville Clubhouse
589086	Volusia	Daytona Beach	32114	BCU Center for Civic Engagement
585986	Duval	Neptune Beach	32266	Beaches Regional Library
587606	Okaloosa	Ft Walton Bch	32547	Beachside Community Church
585291	Brevard	Melbourne	32934	Beacon Baptist Church
588193	Palm Beach	Jupiter	33458	Beacon Cove Intermediate School
588334	Pasco	Holiday	34691	Beacon Square Civic Club
588288	Pasco	Hudson	34667	Beacon Woods Civic Center
588299	Pasco	Hudson	34667	Beacon Woods East Clubhouse
586352	Hillsborough	Plant City	33567	Bealsville Community Center
587811	Orange	Orlando	32824	BEAR CREEK PARK
587711	Orange	Orlando	32801	BEARDALL SENIOR CENTER
588076	Palm Beach	West Palm Beach	33409	Bear Lakes Middle
586618	Lee	Lehigh Acres	33936	Beautiful Savior Lutheran Church
586821	Manatee	Sarasota	34243	Beautiful Savior Lutheran Church
585957	Duval	Jacksonville	32204	Beaver St Enterprise Center
587068	Miami-Dade	Miami	33184	Belen Jesuit Preparatory School
588670	Polk	Lakeland	33809	Believers' Fellowship Church
585110	Gilchrist	Bell	32619	Bell Community Center
588415	Pinellas	Belleair Beach	33786	Belleair Beach City Hall
588469	Pinellas	Belleair Bluffs	33770	Belleair Bluffs City Hall
588551	Pinellas	Belleair	33756	Belleair Town Hall
588250	Palm Beach	Belle Glade	33430	Belle Glade Library/Civic Ctr
587747	Orange	Orlando	32809	BELLE ISLE CITY HALL
586115	Flagler	Palm Coast	32137	BELLE TERRE ELEMENTARY SCHOOL
586227	Hillsborough	Brandon	33511	Bell Shoals Baptist Church
585074	Hamilton	Jennings	32053	BELLVILLE VOL FIRE DEPT
588200	Palm Beach	West Palm Beach	33405	Belvedere Elementary
588896	Sarasota	Sarasota	34233	BENEVA CHRISTIAN CHURCH
585891	Duval	Jacksonville Beach	32250	Bennie Furlong Senior Center
587227	Miami-Dade	Hialeah	33010	Benny Babcock Park
587903	Palm Beach	West Palm Beach	33411	Benoist Farms Elementary
587413	Miami-Dade	Hialeah	33016	Ben Sheppard Elementary School
587328	Miami-Dade	Miami	33175	Bent Tree Elementary School
588197	Palm Beach	Boynton Beach	33436	Bent Tree Villas East
586507	Lake	Okahumpka	34762	Berean Baptist Church
586726	Leon	Tallahassee	32310	Berean Christian Fellowship
585812	Collier	Naples	34104	Berkshire Lakes Clubhouse
587550	Monroe	Stock Is	33040	Bernstein Park Stock Is.
585210	Alachua	Gainesville	32606	Best Western Gateway Grand Hotel
585943	Duval	Jacksonville	32256	Best Western Premier Jacksonville
586441	Hillsborough	Tampa	33612	Bethanie French Seventh Day Adventist Church
586386	Hillsborough	Plant City	33565	Bethany Baptist Church
586789	Manatee	Myakka City	34251	Bethany Baptist Church
586742	Leon	Tallahassee	32310	Bethel AME Church
585057	DeSoto	Arcadia	34266	Bethel Assembly of God
588681	Polk	Lakeland	33810	Bethel Baptist Church
585292	Brevard	Cocoa	32922	Bethel Baptist Church
586815	Manatee	Bradenton	34205	Bethel Baptist Church
587687	Orange	Orlando	32839	BETHEL EGLISE HAITIENNE SDA
588262	Palm Beach	Delray Beach	33484	Bethel Evangelical Baptist
588511	Pinellas	Clearwater	33761	Bethel Lutheran Church
586686	Leon	Tallahassee	32304	Bethel Methodist Church
588492	Pinellas	St Petersburg	33711	Bethel Metropolitan Baptist Church
584927	Wakulla	Crawfordville	32327	BETHEL-MT. OLIVE PRIMITIVE BAPTIST
585129	Holmes	Bonifay	32425	Bethlehem
586634	Lee	Fort Myers	33905	Bethlehem Lutheran Church
586002	Duval	Jacksonville Beach	32250	Bethlehem Lutheran Church
588313	Pasco	Zephyrhills	33541	Betmar Acres Clubhouse #3
587322	Miami-Dade	Pinecrest	33156	Bet Shira Congregation
588716	Putnam	Crescent City	32112	Beulah Baptist Church
586256	Hillsborough	Tampa	33606	Beulah Baptist Institutional Church
586033	Escambia	Pensacola	32526	BEULAH CHURCH
587586	Okaloosa	Ft Walton Bch	32548	Beulah First Baptist Church
589158	Volusia	Daytona Beach	32114	Beville Road Church of Christ
585071	Glades	Okeechobee	34974	BHR Community Center
586820	Manatee	Bradenton	34208	Bible Baptist Church
587206	Miami-Dade	Miami	33147	Bible Baptist Church
586341	Hillsborough	Tampa	33624	Bible Based Fellowship Church
586258	Hillsborough	Tampa	33617	Bible Based Fellowship Church of Temple Terrace
586192	Highlands	Sebring	33872	BIBLE FELLOWSHIP CHURCH
584948	Taylor	Perry	32348	Big Bend Technical College
587564	Monroe	Big Coppitt	33040	Big Coppitt Fire Station
585761	Collier	Naples	34120	Big Corkscrew Island Regional Park
585102	Hendry	Clewiston	33440	Big Cypress Gymnasium
587545	Monroe	Big Pine Key	33043	Big Pine Community Park Center NEW 2016
587448	Miami-Dade	Coral Gables	33134	Biltmore Hotel
588952	Sarasota	Venice	34285	BIRD BAY VILLAGE CLUBHOUSE
586996	Miami-Dade	Miami	33175	Bird Lakes Park
587378	Miami-Dade	Miami Beach	33141	Biscayne Beach Elementary School
587037	Miami-Dade	Miami	33161	Biscayne Shores and Gardens Park
587635	Orange	Orlando	32820	BITHLO COMMUNITY CENTER
585743	Clay	Middleburg	32068	BLACK CREEK CHURCH OF CHRIST
585856	Duval	Jacksonville	32226	Black Hammock Island Community Center
587588	Okaloosa	Baker	32531	Blackman Community Center
586947	Martin	Stuart	34996	Blake Library
586205	Hillsborough	Tampa	33619	Blessed Sacrament Catholic Church
585525	Broward	Oakland Park	33334	Blessed Sacrament Catholic Church
586450	Hillsborough	Valrico	33596	Bloomingdale Golf Club
586239	Hillsborough	Valrico	33596	Bloomingdale Regional Library
586300	Hillsborough	Valrico	33596	Bloomingdale Youth Sports Association
585038	Taylor	Perry	32348	Blue Creek Bapt Church
585892	Duval	Jacksonville	32277	Blue Cypress Community Center
587475	Miami-Dade	Miami	33165	Blue Lakes Elementary School
588943	Sarasota	North Port	34288	BOBCAT TRAIL
587105	Miami-Dade	Miami Lakes	33016	Bob Graham Education Center
587578	Okaloosa	Shalimar	32579	Bob Hope Village Welcome Ctr
588211	Palm Beach	Delray Beach	33484	Boca Delray Clubhouse
588118	Palm Beach	Boca Raton	33431	Boca Delray Lodge #171
586590	Lee	Boca Grande	33921	Boca Grande Community Center
588029	Palm Beach	Boca Raton	33432	Boca Raton Community Center
587963	Palm Beach	Boca Raton	33432	Boca Raton Downtown Library
588121	Palm Beach	Boca Raton	33486	Boca Raton Middle
588153	Palm Beach	Boca Raton	33434	Boca West Country Club
588337	Pasco	Holiday	34691	Bonita Hall-Buena Vista Trailer Pk
586606	Lee	Bonita Springs	34135	Bonita Springs Fire Control District
586667	Lee	Bonita Springs	34135	Bonita Springs Lions Club
586642	Lee	Bonita Springs	34135	Bonita Springs YMCA
589015	Seminole	Sanford	32773	Boombah Sports Complex
588714	Putnam	Palatka	32177	Bostwick Community Center
585387	Broward	Hollywood	33024	Boulevard Heights Community Center
585328	Brevard	Melbourne	32935	Bowe Gardens Baptist Church
587468	Miami-Dade	Miami	33193	Bowman Ashe/ Doolin K-8 Academy
587218	Miami-Dade	Miami	33193	Bowman Ashe/Doolin K-8 Academy
585613	Broward	Lauderdale Lakes	33309	Boyd H. Anderson High School
585497	Broward	Oakland Park	33309	Boyd H. Anderson High School
588097	Palm Beach	Boynton Beach	33472	Boynton Beach Community Church
588066	Palm Beach	Boynton Beach	33426	Boynton Beach Fire Station #2
588248	Palm Beach	Boynton Beach	33426	Boynton Beach Fire Station #3
588231	Palm Beach	Boynton Beach	33426	Boynton Beach High
588178	Palm Beach	Boynton Beach	33435	Boynton Beach Senior Center
588038	Palm Beach	Boynton Beach	33426	Boynton Lakes North Clubhouse
586641	Lee	Fort Myers	33907	Boy Scout Conference Center
586226	Hillsborough	Tampa	33612	Boy Scouts of America, GTBAC
585635	Broward	Lauderhill	33313	Boys & Girls Club
586190	Highlands	Sebring	33870	BOYS & GIRLS CLUB
585426	Broward	Pompano Beach	33060	Boys & Girls Club of Broward County
585478	Broward	Davie	33024	Boys & Girls Club (Rick & Rita Case Club)
588286	Pasco	New Port Richey	34653	BPOE Elks #2284
586795	Manatee	Bradenton	34203	Braden River Library
586826	Manatee	Palmetto	34221	Bradenton Area Convention Center
586811	Manatee	Palmetto	34221	Bradenton Area Convention Center
586792	Manatee	Bradenton Beach	34217	Bradenton Beach Fire Hall
586777	Manatee	Bradenton	34205	Bradenton Dreamcenter
585893	Duval	Jacksonville	32208	Bradham Brooks Regional Library
586430	Hillsborough	Brandon	33511	Brandon Christian Church
586253	Hillsborough	Brandon	33510	Brandon Community Center
586212	Hillsborough	Brandon	33510	Brandon Masonic Lodge No 114
586322	Hillsborough	Brandon	33510	Brandon Seventh Day Adventist Church
585330	Brevard	Rockledge	32955	Brevard Baptist Association
588463	Pinellas	Safety Harbor	34695	Briar Creek MHP - Clubhouse 1
588424	Pinellas	Seminole	33776	BridgePoint Church Seminole
588303	Pasco	Wesley Chapel	33545	Bridgeway Church
585368	Broward	Pompano Beach	33064	Bright Horizons School
586179	Highlands	Okeechobee	34974	BRIGHTON BAPTIST CHURCH
587245	Miami-Dade	Hialeah	33013	Bright Park Recreation Center
585087	Hamilton	Jasper	32052	BRIGHT POND FELLOWSHIP HALL
587967	Palm Beach	Briny Breezes	33435	Briny Breezes Community Center
585107	Liberty	Bristol	32321	BRISTOL
586024	Escambia	Pensacola	32504	BRIT AHM MESSIANIC SYNAGOGUE
587865	Osceola	Kissimmee	34758	BROADMOOR CLUBHOUSE
585441	Broward	North Lauderdale	33068	Broadview Elementary School
587809	Orange	Orlando	32803	BROADWAY UNITED METHODIST CHURCH
588516	Pinellas	Pinellas Park	33781	Broderick Recreation Center
588018	Palm Beach	Boca Raton	33496	Broken Sound Club
584910	Levy	Bronson	32621	Bronson Dogan Cobb Bldg
584845	Bradford	Brooker	32622	Brooker City Hall
586145	Hernando	Brooksville	34613	BROOKRIDGE CLUBHOUSE MIDDLE RM
585934	Duval	Jacksonville	32206	Brown Eastside Branch Library
586038	Escambia	Pensacola	32505	BROWNSVILLE COMMUNITY CENTER
586278	Hillsborough	Plant City	33563	Bruton Memorial Library
585080	Nassau	Bryceville	32009	Bryceville Community Center
588704	Polk	Lake Wales	33853	B Street Community Center
586241	Hillsborough	Tampa	33613	Buchanan Baptist Church
586586	Lee	Fort Myers	33905	Buckingham Presbyterian Church
588173	Palm Beach	Wellington	33411	Buena Vida Clubhouse
588340	Pasco	Holiday	34690	Buena Vista Manor Civic Association
587523	Miami-Dade	Miami Gardens	33054	Bunche Park Elementary School
586109	Flagler	Bunnell	32110	BUNNELL CITY HALL
586036	Escambia	Pensacola	32503	BURGESS ROAD BAPTIST CHURCH
586008	Duval	Jacksonville	32257	Burnett Park Recreation Center
585660	Charlotte	Punta Gorda	33955	Burnt Store Presbyterian Church
589056	Sumter	Bushnell	33513	BUSHNELL COMMUNITY BUILDING
587886	Osceola	Kissimmee	34743	BVL LIBRARY
586060	Escambia	Century	32535	BYRNEVILLE COMMUNITY CENTER
586538	Lake	Clermont	34714	Cagan Crossings Comm. Library
586894	Marion	Ocala	34471	Cala Hills Clubhouse
585028	Nassau	Callahan	32011	Callahan Fairgrounds - Multi Purpose Building
585232	Bay	Panama City	32404	Callaway Arts & Conference Center
588155	Palm Beach	Palm Beach Gardens	33418	Caloosa POA Meeting Room
588096	Palm Beach	Boca Raton	33496	Calusa Elementary
587445	Miami-Dade	Miami	33186	Calusa Elementary School
587684	Orange	Winter Park	32789	CALVARY ASSEMBLY OF GOD
588600	Polk	Winter Haven	33884	Calvary Baptist Church
586500	Lake	Fruitland Park	34731	Calvary Baptist Church
587810	Orange	Orlando	32812	CALVARY BAPTIST CHURCH
587818	Orange	Winter Garden	34787	CALVARY BAPTIST CHURCH
586680	Leon	Tallahassee	32309	Calvary Chapel
589154	Volusia	Ormond Beach	32174	Calvary Christian Center
588195	Palm Beach	Jupiter	33478	Calvary Church of Jupiter
588416	Pinellas	Seminole	33772	Calvary Church Seminole
588461	Pinellas	Clearwater	33759	Calvary Church Student Ministry Center
586434	Hillsborough	Tampa	33634	Calvary Community Church
586289	Hillsborough	Ruskin	33570	Calvary Lutheran Church
586691	Leon	Tallahassee	32310	Calvary the Cornerstone M.B. Church
585747	Clay	Orange Park	32073	CALVARY UNITED METH. CHURCH
588138	Palm Beach	Royal Palm Beach	33411	Calypso Bay Waterpark
587908	Palm Beach	Delray Beach	33446	Camelot Village Clubhouse
589138	Volusia	Daytona Beach	32114	Campbell Middle School
585055	Washington	Vernon	32462	Campbell Park Community Center
584844	Jackson	Campbellton	32426	CAMPBELLTON COMM CENTER
588116	Palm Beach	Canal Point	33438	Canal Point Community Center
589070	Sumter	The Villages	32162	CANAL ST REC CTR
587863	Osceola	St. Cloud	34772	CANOE CREEK CHRISTIAN CHURCH
587851	Osceola	St. Cloud	34772	CANOE CREEK SCHOOL
586733	Leon	Tallahassee	32312	Canopy Roads Baptist Church
589016	Seminole	Oviedo	32765	Canterbury Conference Center
585290	Brevard	Cape Canaveral	32920	Cape Canaveral Community Center
586595	Lee	Cape Coral	33991	Cape Christian Fellowship
586647	Lee	Cape Coral	33914	Cape Coral Alliance Church-Fellowshp Hall
586601	Lee	Cape Coral	33914	Cape Coral Alliance Church-Sanctuary
586615	Lee	Cape Coral	33990	Cape Coral Kiwanis Club
586604	Lee	Cape Coral	33914	Cape Coral - Lee County Public Library
584976	Gulf	Cape San Blas	32456	Cape San Blas Fire Station
586739	Leon	Tallahassee	32308	Capital City Christian Church
589072	Sumter	The Villages	32163	CAPTIVA VILLAGE CTR
587209	Miami-Dade	Miami	33177	Caribbean K-8 Center
587072	Miami-Dade	Hialeah	33016	Carl F. Slade Park
584832	Hendry	Labelle	33935	Carlson Mem. United Church
588306	Pasco	Wesley Chapel	33544	Carmel Friendship Church
587082	Miami-Dade	Miami Gardens	33055	Carol City Elementary School
587372	Miami-Dade	Miami Gardens	33055	Carol City Middle School
588119	Palm Beach	Boynton Beach	33435	Carolyn Sims Center
588357	Pasco	Lutz	33559	Carpenter's Run Clubhouse
584920	Calhoun	Clarksville	32430	Carr-Clarksville VFD
587250	Miami-Dade	Miami	33142	Carrie P. Meek Art Center
587131	Miami-Dade	Miami	33167	Carrie P. Meek Elementary School
586251	Hillsborough	Tampa	33625	Carrollwood Baptist Church
586425	Hillsborough	Tampa	33618	Carrollwood Cultural Center
586364	Hillsborough	Tampa	33618	Carrollwood Recreation Center
586348	Hillsborough	Tampa	33625	Carrollwood Seventh Day Adventist
588603	Polk	Bartow	33830	Carver Center
587608	Okaloosa	Crestview	32536	Carver Hill School
588098	Palm Beach	Delray Beach	33445	Carver Middle
585625	Broward	West Park	33023	Carver Ranches Boys & Girls Club
584922	Washington	Caryville	32427	Caryville Town Hall
587953	Palm Beach	Riviera Beach	33418	Casa Rio Clubhouse
587899	Palm Beach	Boynton Beach	33437	Cascade Lakes Clubhouse
588966	Seminole	Casselberry	32707	Casselberry Recreation Center
586528	Lake	Eustis	32736	Cassia Community Club
585624	Broward	Lauderhill	33313	Castle Gardens Recreation Center
586204	Hillsborough		33610	C. Blythe Andrews, Jr. Public Library
585929	Duval	Jacksonville	32218	Cedar Bay Baptist Church
585885	Duval	Jacksonville	32205	Cedar Creek Baptist Church
585004	Levy	Cedar Key	32625	Cedar Key Community Center
586730	Leon	Tallahassee	32311	Celebrate New Life Church
586729	Leon	Tallahassee	32309	Celebration Baptist Church
586244	Hillsborough	Tampa	33613	Celebration Church
586900	Marion	Belleview	34420	Celebration Church
587877	Osceola	Kissimmee	34747	CELEBRATION HERITAGE HALL
588736	St. Johns	St Johns	32259	Celebration Lutheran Church
586120	Gadsden	Quincy	32351	Centenary United Methodist Church
585043	Gulf	Port St Joe	32456	Centennial Building
588083	Palm Beach	Boca Raton	33486	Center for Spiritual Living
589083	Sumter	Center Hill	33514	CENTER HILL COMMUNITY BUILDING
588993	Seminole	Oviedo	32765	Center Lake Park
588898	Sarasota	Venice	34293	CENTER OF HOPE NEIGHBORHOOD CHURCH
586457	Hillsborough	Valrico	33594	Centerpoint Church
586479	Indian River	Vero Beach	32966	Central Assembly of God
585249	Brevard	Melbourne	32901	Central Baptist Church
586843	Marion	Ocala	34470	Central Christian Church
587681	Orange	Orlando	32804	CENTRAL CHRISTIAN CHURCH
588496	Pinellas	Clearwater	33756	Central Christian Church of Clwr.
587188	Miami-Dade	Coral Gables	33134	Central Christian Church of Dade County
589006	Seminole	Altamonte Springs	32714	Central Church of Christ
586486	Lake	Eustis	32736	Central Florida Bible Camp
589012	Seminole	Sanford	32771	Central Florida Zoo & Botanical Gardens
586781	Manatee	Bradenton	34205	Central Library
585350	Broward	Plantation	33324	Central Park Elementary School
587803	Orange	Orlando	32821	CENTRAL PARKWAY BAPTIST CHURCH
585689	Citrus	Beverly Hills	34465	CENTRAL RIDGE COMMUNITY CENTER
585680	Citrus	Beverly Hills	34465	CENTRAL RIDGE LIBRARY
588552	Pinellas	Palm Harbor	34683	Centre of Palm Harbor
586207	Hillsborough	Brandon	33511	Centro Cristiano Hispano
587736	Orange	Orlando	32825	CENTRO CRISTIANO RESTAURACION
588631	Polk	Winter Haven	33880	Centro Guadalupe Catholic Mission
587748	Orange	Orlando	32807	CENTRO INTERNACIONAL DE LA FAMILIA
589155	Volusia	Deltona	32738	Centro Internacional de la Familia de Deltona
586089	Escambia	Century	32535	CENTURY TOWN HALL
588258	Palm Beach	West Palm Beach	33417	Century Village Clubhouse
585629	Broward	Pembroke Pines	33027	Century Village Clubhouse
588204	Palm Beach	Boca Raton	33434	Century Village West
588107	Palm Beach	West Palm Beach	33401	Chamber of Commerce
587042	Miami-Dade	South Miami	33143	Chamber South
589151	Volusia	Daytona Beach	32124	Champion Elementary School
586611	Lee	Fort Myers Beach	33931	Chapel By The Sea
585528	Broward	Pembroke Pines	33029	Chapel Trail Elementary School
586045	Escambia	Pensacola	32526	CHARITY CHAPEL CHURCH
585854	Duval	Jacksonville	32208	Charles Clark Community Center
587397	Miami-Dade	Miami	33055	Charles David Wyche Jr Elementary School
587173	Miami-Dade	Miami	33126	Charles R. Hadley Elementary School
585106	Gulf	Wewahitchka	32465	Charles Whitehead Public Library
585489	Broward	Margate	33063	Charley Katz Community Center
587161	Miami-Dade	Miami	33126	Charlie DeLucca Park (Kinloch Park)
585968	Duval	Jacksonville	32208	Charlie T. Joseph Senior Center
585658	Charlotte	Port Charlotte	33948	Charlotte County Fairground Assn
585638	Charlotte	Punta Gorda	33950	Charlotte Harbor Event & Conf Center
585349	Broward	Pompano Beach	33064	Charlotte J. Burrie Community (Civic) Center
586128	Gadsden	Chattahoochee	32324	Chattahoochee Recreation Center
584852	Madison	Madison	32340	Cherry Lake Methodist Church
588359	Pasco	New Port Richey	34655	Chester McKay VFW Hall #7987
585964	Duval	Jacksonville	32224	Chets Creek Church
586402	Hillsborough	Tampa	33605	Children's Board
588531	Pinellas	St Petersburg	33711	Childs Park Recreation & Fitness Center
585116	Franklin	Lanark Village	32323	Chillas Hall
587100	Miami-Dade	Miami	33184	Chinese Baptist Church
589130	Volusia	Deland	32720	Chisholm Community Center
589195	Walton	Freeport	32439	Choctaw Beach Community Center
587984	Palm Beach	Greenacres	33413	Cholee Lake Elementary
587990	Palm Beach	Boynton Beach	33472	Christa McAuliffe Middle
586474	Indian River	Vero Beach	32963	Christ By The Sea UMC
586069	Escambia	Pensacola	32501	CHRIST CHURCH PENSACOLA
585403	Broward	Fort Lauderdale	33308	Christ Church United Methodist
586476	Indian River	Vero Beach	32960	Christ Church Vero Beach
588691	Polk	Winter Haven	33884	Christ Community Church
588630	Polk	Lakeland	33815	Christ Community Church
585367	Broward	Pompano Beach	33060	Christ Community Church
586853	Marion	Ocala	34472	Christ Community Church
586585	Lee	Fort Myers	33966	Christ Community Church
587826	Orange	Orlando	32819	CHRIST COMMUNITY CHURCH
586365	Hillsborough	Brandon	33511	Christ Community Church of Brandon
587355	Miami-Dade	Palmetto Bay	33158	Christ Congregational Church
587092	Miami-Dade	Miami	33133	Christ Episcopal Church
588750	St. Johns	Ponte Vedra Beach	32082	Christ Episcopal Church
586958	Martin	Stuart	34997	Christ Fellowship Church
587895	Palm Beach	Boynton Beach	33426	Christ Fellowship Church BB
587958	Palm Beach	Palm Beach Gardens	33418	Christ Fellowship Church PBG
588041	Palm Beach	Royal Palm Beach	33411	Christ Fellowship Church RPB
588490	Pinellas	St Petersburg	33712	Christ Gospel Church
585684	Citrus	Homosassa	34448	CHRISTIAN CENTER CHURCH
586156	Hernando	Weeki Wachee	34613	CHRISTIAN CHURCH IN THE WILDWOOD
585956	Duval	Jacksonville	32222	Christian Family Fellowship
588865	Santa Rosa	Milton	32583	Christian Life Church
586809	Manatee	Bradenton	34212	Christian Retreat, ITC Bldg
587361	Miami-Dade	Miami	33196	Christina M. Eve Elementary School
587816	Orange	Christmas	32709	CHRISTMAS CIVIC CENTER
587579	Okaloosa	Niceville	32578	Christ Our Redeemer Catholic Church
586765	Leon	Tallahassee	32312	Christ Presbyterian Church
588547	Pinellas	Largo	33770	Christ Presbyterian Church
587754	Orange	Orlando	32810	CHRIST SANCTIFIED CHURCH
586889	Marion	Ocala	34476	Christ's Church of Marion County
586013	Duval	Jacksonville	32226	Christ's Church River City Campus
587407	Miami-Dade	Miami	33157	Christ the King Catholic Church
586229	Hillsborough	Tampa	33609	Christ the King Catholic Church
587683	Orange	Orlando	32807	CHRIST THE KING EPISCOPAL CHURCH
588466	Pinellas	Largo	33774	Christ the King Lutheran Church
587492	Miami-Dade	Pinecrest	33156	Christ The King Lutheran Church
585965	Duval	Jacksonville	32217	Christ the Messiah Church
586675	Lee	Lehigh Acres	33936	Christ United Methodist Church
585881	Duval	Neptune Beach	32266	Christ United Methodist Church
588269	Palm Beach	West Palm Beach	33409	Chuck Shaw Technical Education Center
586443	Hillsborough	Ruskin	33570	Chulavista Landings
588855	Santa Rosa	Jay	23565	Chumuckla Community Center
585960	Duval	Jacksonville	32244	Church at Argyle
588397	Pasco	Odessa	33556	Church at Odessa
586879	Marion	Ocala	34480	Church at the Springs
585275	Brevard	Melbourne	32940	Church at Viera
587732	Orange	Orlando	32804	CHURCH IN THE SON
586182	Highlands	Sebring	33870	CHURCH OF CHRIST
584949	Bradford	Starke	32091	Church of God by Faith
588343	Pasco	Land O Lakes	34638	Church of God Land O' Lakes
585974	Duval	Jacksonville	32254	Church of God - N Lane Ave
586779	Manatee	Palmetto	34221	Church of Hope
588608	Polk	Lakeland	33810	Church of Jesus Christ LDS
589030	Seminole	Lake Mary	32746	Church of Jesus Christ of L.D.S.
585322	Brevard	Palm Bay	32905	Church of Our Savior
585273	Brevard	Cocoa Beach	32931	Church of Our Saviour Social Hall
587176	Miami-Dade	Miami	33157	Church of The Ascension
586676	Lee	Fort Myers Beach	33931	Church Of The Ascension
587685	Orange	Orlando	32819	CHURCH OF THE ASCENSION
586673	Lee	Fort Myers	33912	Church Of The Cross
585070	Hendry	Labelle	33935	Church of the Good Shepherd
588890	Sarasota	Osprey	34229	CHURCH OF THE HOLY SPIRIT
588880	Sarasota	Sarasota	34243	CHURCH OF THE NATIVITY
585121	Jefferson	Monticello	32344	Church of The Nazarene
588917	Sarasota	Venice	34292	CHURCH OF THE NAZARENE
585670	Citrus	Hernando	34442	CHURCH OF THE NAZARENE
588892	Sarasota	Sarasota	34239	CHURCH OF THE PALMS
588640	Polk	Winter Haven	33884	Church of the Redeemer
588535	Pinellas	Tarpon Springs	34689	Church on the Bayou, Presbyterian
587804	Orange	Orlando	32804	CHURCH ON THE DRIVE
587587	Okaloosa	Fort Walton Beach	32548	Cinco Bayou Town Hall
586003	Duval	Jacksonville	32219	Cisco Garden Community Center
585127	Jackson	Marianna	32446	CITIZENS LODGE
586859	Marion	Citra	32113	Citra Community Center
585679	Citrus	Inverness	34452	CITRUS COUNTY AUDITORIUM
588198	Palm Beach	Boynton Beach	33436	Citrus Cove Elementary
589129	Volusia	Deland	32720	Citrus Grove Elementary School
587126	Miami-Dade	Miami	33125	Citrus Grove Middle School
585676	Citrus	Citrus Springs	34433	CITRUS SPG COMMUNITY CENTER
585693	Citrus	Citrus Springs	34434	CITRUS SPRINGS CHURCH OF GOD
588706	Polk	Lakeland	33801	Citrus Woods - Clubhouse Annex
589024	Seminole	Sanford	32771	City Church FL
586653	Lee	Cape Coral	33990	City First Church
584924	Washington	Ebro	32437	City Hall - Ebro
585040	Washington	Chipley	32428	City Hall - Wausau
585552	Broward	Fort Lauderdale	33308	City of Ft. Lauderdale Comm Center
587424	Miami-Dade	Miami Gardens	33056	City of Miami Gardens City Hall
587694	Orange	Orlando	32811	CITY OF ORLANDO WASTEWATER DIVISION
585446	Broward	Tamarac	33321	City of Tamarac Multi-Purpose Ctr.
585924	Duval	Jacksonville	32204	City Rescue Mission
585623	Broward	Pembroke Pines	33029	City Rev Church
589043	Seminole	Winter Park	32792	Ciudad de Fe
587714	Orange	Apopka	32703	CLARCONA HORSEMANS PARK
587236	Miami-Dade	Miami	33136	Claude Pepper Community Center II
587006	Miami-Dade	Miami	33186	Claude Pepper Elementary School
587392	Miami-Dade	Miami Shores	33138	C. Lawton McCall Community Center
585733	Clay	Middleburg	32068	CLAY CO. SALVATION ARMY
585701	Clay	Grn Cv Spgs	32043	CLAY CO. SHRINE CLUB
585715	Clay	Grn Cv Spgs	32043	CLAY COUNTY AG CENTER
585712	Clay	Middleburg	32068	CLAY COUNTY UTILITY AUTHORITY
585705	Clay	Jacksonville	32234	CLAY HILL BAPTIST CHURCH
585085	Suwannee	Live Oak	32060	CLAYLAND REC. CENTER
588256	Palm Beach	West Palm Beach	33415	Clayton Hutcheson Hall B
585338	Brevard	Cocoa	32922	Clearlake Education Center
588568	Pinellas	Clearwater	33767	Clearwater Beach Rec. Center
588533	Pinellas	Dunedin	34698	Clearwater Community Church
588525	Pinellas	Clearwater	33767	Clearwater Community Sailing Center
588548	Pinellas	Clearwater	33761	Clearwater Countryside Library
588538	Pinellas	Clearwater	33755	Clearwater Main Library
586540	Lake	Clermont	34711	Clermont Arts & Recreation Center
586496	Lake	Clermont	34711	Clermont Baptist Church
586563	Lake	Clermont	34711	Clermont City Center
585661	Charlotte	Punta Gorda	33982	Cleveland Baptist Church
588606	Polk	Lakeland	33803	Cleveland Heights Baptist Church
588579	Polk	Lakeland	33803	Cleveland Heights Golf Club
585648	Charlotte	Punta Gorda	33982	Cleveland United Methodist Church
588257	Palm Beach	Cloud Lake	33406	Cloud Lake Town Hall
588162	Palm Beach	Jupiter	33477	Club at Admirals Cove
588235	Palm Beach	West Palm Beach	33411	Club Baywinds
588312	Pasco	Land O' Lakes	34638	Club Connerton (Conference Room)
587254	Miami-Dade	Homestead	33033	Club Malibu Bay
589199	Walton	Defuniak Springs	32433	Cluster Springs Baptist Church
585895	Duval	Jacksonville	32225	Coastal Baptist Church
588859	Santa Rosa	Gulf Breeze	32563	Coastline Calvary Chapel-Grand Pointe
588847	Santa Rosa	Gulf Breeze	32563	Coastline Calvary Chapel-Oriole Beach
585329	Brevard	Cocoa Beach	32931	Cocoa Beach Community Church
585250	Brevard	Rockledge	32955	Cocoa/Rockledge Garden Club
584858	Jackson	Marianna	32448	CO COMM ADMN BLDG
589055	Sumter	The Villages	32162	COCONUT COVE REC CTR
588171	Palm Beach	Boca Raton	33498	Coconut Cove Waterpark
585402	Broward	Coconut Creek	33066	Coconut Creek Community Center
585429	Broward	Coconut Creek	33063	Coconut Creek Governmental Center
585574	Broward	Coconut Creek	33073	Coconut Creek Recreation Complex
585609	Broward	Miramar	33027	Coconut Palm Elementary School
587102	Miami-Dade	Homestead	33032	Coconut Palm K-8 Academy
587117	Miami-Dade	Coral Gables	33143	Coco Plum Woman's Club
588084	Palm Beach	Delray Beach	33484	Coco Wood Clubhouse
586082	Escambia	Pensacola	32504	COKESBURY UNITED METHODIST
588661	Polk	Lakeland	33805	Coleman-Bush Building
588453	Pinellas	St Petersburg	33701	Coliseum
584994	Suwannee	Live Oak	32064	COLISEUM
588599	Polk	Lakeland	33803	College Heights Methodist Church
585691	Citrus	Lecanto	34461	COLLEGE OF CENTRAL FLORIDA (CF)
585795	Collier	Naples	34120	Collier County University Extension
585462	Broward	Oakland Park	33334	Collins Community Center
587514	Miami-Dade	Miami	33157	Colonial Drive Park
588919	Sarasota	Sarasota	34241	COLONIAL OAKS BAPTIST CHURCH
587773	Orange	Orlando	32803	COLONIALTOWN NEIGHBORHOOD CENTER
589079	Sumter	The Villages	32162	COLONY COTTAGE REC CTR
588308	Pasco	New Port Richey	34653	Colony Cove Recreation Hall
585081	Columbia	Lake City	32024	COLUMBIA CITY POLLING LOCATION
586752	Leon	Tallahassee	32308	Comfort Inn & Suites
585208	Alachua	Gainesville	32608	Comfort Suites
587185	Miami-Dade	Aventura	33160	Commodore Plaza Tower II
586102	Flagler	Bunnell	32110	COMMUNITY BAPTIST CHURCH
588773	St. Johns	St Augustine	32086	Community Bible Church
586185	Highlands	Avon Park	33825	COMMUNITY BIBLE CHURCH
584849	Washington	Caryville	32427	Community Center- Five points
586893	Marion	Summerfield	34491	Community Harvest Baptist Church
585861	Duval	Jacksonville	32257	Community Hospice of NE FL
585335	Brevard	Palm Bay	32909	Community of Christ
586379	Hillsborough	Lutz	33559	Community of Christ Church
588260	Palm Beach	West Palm Beach	33415	Community Of Hope
588060	Palm Beach	Lake Worth	33467	Community of Hope Lake Worth
585346	Broward	Deerfield Beach	33441	Community Presbyterian Church
585902	Duval	Atlantic Beach	32233	Community Presbyterian Church
589142	Volusia	Daytona Beach	32118	Community United Methodist Church
588346	Pasco	San Antonio	33576	Community United Methodist Church
584991	Suwannee	Live Oak	32064	COM PRESBYTERIAN CHURCH
586400	Hillsborough	Tampa	33647	Compton Park Recreation Center
587156	Miami-Dade	Miami	33142	Comstock Elementary School
586333	Hillsborough	Tampa	33605	Concord Baptist Church
586127	Gadsden	Havana	32333	Concordia Baptist Church
586395	Hillsborough	Tampa	33613	Concordia Village
588307	Pasco	Land O' Lakes	34638	Concord Station CDD Clubhouse
585647	Charlotte	Punta Gorda	33950	Congregational United Church of Christ
588169	Palm Beach	Boynton Beach	33426	Congress Middle
588236	Palm Beach	West Palm Beach	33405	Conniston Middle
585376	Broward	Deerfield Beach	33442	Constitution Park
587144	Miami-Dade	Miami	33156	Continental Park
587834	Orange	Orlando	32812	CONWAY UNITED METHODIST CHURCH
588854	Santa Rosa	Jay	32565	Coon Hill-Linda Carden Community Center
585608	Broward	Cooper City	33328	Cooper City Community Center
585455	Broward	Cooper City	33028	Cooper CIty High School
585521	Broward	Cooper City	33026	Cooper City Pool & Tennis Center
586534	Lake	Clermont	34711	Cooper Memorial Library
586416	Hillsborough	Tampa	33612	Copeland Park
585342	Broward	Margate	33063	Coral Bay Clubhouse
585573	Broward	Miramar	33027	Coral Cove Elementary School
587403	Miami-Dade	Coral Gables	33134	Coral Gables Congregational Church
587189	Miami-Dade	Coral Gables	33146	Coral Gables Fire Station #2
587462	Miami-Dade	Coral Gables	33156	Coral Gables Fire Station #3
587023	Miami-Dade	Coral Gables	33146	Coral Gables Senior High School
587049	Miami-Dade	Coral Gables	33134	Coral Gables War Memorial Youth Center
587539	Miami-Dade	Miami	33145	Coral Gate Park Community Center
585348	Broward	Coral Springs	33065	Coral Glades High School
588091	Palm Beach	Boynton Beach	33437	Coral Lakes Clubhouse
585460	Broward	Coral Springs	33067	Coral Park Elementary School
587149	Miami-Dade	Pinecrest	33156	Coral Pine Park
587454	Miami-Dade	Miami	33157	Coral Reef Branch Library
588045	Palm Beach	Lake Worth	33467	Coral Reef Elementary
587421	Miami-Dade	Palmetto Bay	33157	Coral Reef Elementary School
585410	Broward	Coral Springs	33065	Coral Springs Center for the Arts
585620	Broward	Coral Springs	33065	Coral Springs Elementary School
585514	Broward	Coral Springs	33065	Coral Springs High School
587999	Palm Beach	Boca Raton	33428	Coral Sunset Elementary
587074	Miami-Dade	Miami	33145	Coral Way K-8 Center
586323	Hillsborough	Tampa	33614	Cordelia B Hunt Community Center
588655	Polk	Lakeland	33810	Cornerstone Baptist Church
586296	Hillsborough	Brandon	33510	Cornerstone Baptist Church
585225	Bay	Panama City Beach	32407	Cornerstone Baptist Church
585725	Clay	Middleburg	32068	CORNERSTONE BAPTIST CHURCH
588356	Pasco	New Port Richey	34652	Cornerstone Community Church
587884	Osceola	St. Cloud	34772	CORNERSTONE FAMILY CHURCH
587938	Palm Beach	West Palm Beach	33412	Cornerstone Fellowship
585769	Collier	Naples	34119	Cornerstone Methodist Church
586909	Marion	Ocala	34476	Cornerstone UP Church
589121	Volusia	New Smyna Beach	32169	Coronado Civic Center
589124	Volusia	New Smyrna Beach	32169	Coronado Elementary School
587051	Miami-Dade	Miami Gardens	33056	Cosmopolitan Baptist Church
584945	Jackson	Cottondale	32431	COTTONDALE COMM CENTER
584964	Hardee	Bowling Green	33834	COUNCIL CHAMBERS BOWLING GREEN
588274	Pasco	Dade City	33525	Country Aire Ests Clubhouse
588339	Pasco	Dade City	33525	Country Aire Manor
588323	Pasco	Hudson	34667	Country Club Civic Center
588889	Sarasota	Venice	34285	COUNTRY CLUB ESTATES
587259	Miami-Dade	Hialeah	33015	Country Club of Miami
586499	Lake	Mt. Dora	32757	Country Club of Mt Dora- Clubhouse
585546	Broward	Coral Springs	33076	Country Hill Elementary School
588124	Palm Beach	Delray Beach	33445	Country Manors Clubhouse
586414	Hillsborough	Tampa	33624	Country Place Park
588517	Pinellas	Clearwater	33761	Countryside Baptist Church
586899	Marion	Ocala	34476	Countryside Presbyterian Church
587290	Miami-Dade	Hialeah	33015	Country Village Park
588319	Pasco	Wesley Chapel	33543	Country Walk Community Clubhouse
587024	Miami-Dade	Miami	33186	Country Walk Park Recreation Center
586937	Marion	Ocala	34470	County Ag Ctr, Auditorium
586078	Escambia	Pensacola	32505	COUNTY CENTRAL OFFICE COMPLEX
584898	Hardee	Wauchula	33873	COUNTY COMMISSION CHAMBERS
585111	Gilchrist	Trenton	32693	County Library
586962	Martin	Tequesta	33469	County Line Civic Center
587669	Orange	Orlando	32820	COUNTY LINE MOOSE LODGE 2427
588514	Pinellas	Clearwater	33760	Cove Cay - Village Four
585816	Collier	Naples	34108	Covenant Church of Naples PCA
587593	Okaloosa	Fort Walton Beach	32547	Covenant Community Church
586949	Martin	Stuart	34994	Covenant Fellowship Church
586454	Hillsborough	Tampa	33629	Covenant Life Church Tampa
588915	Sarasota	Sarasota	34238	COVENANT LIFE PRESBYTERIAN CHURCH
585163	Alachua	Gainesville	32606	Covenant Presbyterian Church
589005	Seminole	Oviedo	32766	Covenant Presbyterian Church
587961	Palm Beach	Lake Worth	33467	Covered Bridge Clubhouse
589168	Volusia	Port Orange	32128	Crane Lakes Golf & Country Club, Clubhouse
584921	Wakulla	Crawfordville	32327	CRAWFORDVILLE - LIVESTOCK PAVILION
588755	St. Johns	St. Johns	32259	Creekside Christian Church
589161	Volusia	Port Orange	32128	Creekside Middle School
587921	Palm Beach	West Palm Beach	33415	Cresthaven Ashley Aud
587894	Palm Beach	West Palm Beach	33415	Cresthaven Barkley Aud
588170	Palm Beach	West Palm Beach	33415	Cresthaven Emory Aud
588388	Pasco	Holiday	34690	Crest Ridge Gdns Community Club
585681	Citrus	Crystal River	34428	C.R. METHODIST CHURCH
588632	Polk	Lake Wales	33859	Crooked Lake Community Center
585325	Brevard	Rockledge	32955	Cross Bridge Church
588075	Palm Beach	Boca Raton	33428	Cross Bridge Church
587983	Palm Beach	Palm Beach Gardens	33410	Cross Community Church
589014	Seminole	Oviedo	32765	CrossLife Church
586596	Lee	Cape Coral	33991	Crosspoint Christian Church
587601	Okaloosa	Niceville	32578	Crosspoint Church, Bluewater
585713	Clay	Fleming Island	32003	CROSS+ROAD LUTHERAN CH
589109	Volusia	New Smyrna Beach	32168	Crossroads Christian Church
586521	Lake	Lady Lake	32159	Crossroads Community Church
584876	Hamilton	Jasper	32052	CROSS ROADS VOL FIRE DEPT
585911	Duval	Jacksonville	32246	Crossroad United Methodist Church
588764	St. Johns	Ponte Vedra	32081	Crosswater Hall at Nocatee
586723	Leon	Tallahassee	32305	Crossway Baptist Church
587691	Orange	Orlando	32835	CROWN OF GLORY LUTHERAN CHURCH
584936	Gilchrist	Bell	32619	Cruse Center
588495	Pinellas	Crystal Beach	34681	Crystal Beach Civic Center
585566	Broward	Deerfield Beach	33064	Crystal Lake Golf Villas Clubhouse
587981	Palm Beach	Boynton Beach	33437	Crystal Lakes Elementary
588488	Pinellas	Pinellas Park	33781	Crystal Lakes Manor
585690	Citrus	Lecanto	34461	CRYSTAL OAKS CLUBHOUSE
585669	Citrus	Crystal River	34428	CRYSTAL RIVER CITY HALL
588328	Pasco	Zephyrhills	33540	Crystal Springs Community Hall
585160	Alachua	Alachua	32615	CSI Academy of Florida
587203	Miami-Dade	Homestead	33033	Ctr for Int'l Education-A Cambridge Assoc. School
585877	Duval	Jacksonville	32207	Cuba Hunter Community Center
586314	Hillsborough	Tampa	33615	Cuban Civic Club
587061	Miami-Dade	Miami	33136	Culmer Neighborhood Service Center
585493	Broward	Hallandale Beach	33009	Cultural Community Center
586967	Martin	Palm City	34990	Cummings Library
588528	Pinellas	Palm Harbor	34683	Curlew United Methodist Church
586993	Miami-Dade	Miami	33125	Curtis Park Community House
587125	Miami-Dade	Cutler Bay	33189	Cutler Bay Senior High School
587033	Miami-Dade	Cutler Bay	33189	Cutler Bay Worship Center
587387	Miami-Dade	Cutler Bay	33157	Cutler Cay Homeowners Association
584883	Baker	Glen St. Mary	32040	Cuyler
586015	Duval	Jacksonville	32205	CWA AFL-CIO Local #3106
585414	Broward	Dania Beach	33004	C.W. Thomas Community Center Park
585390	Broward	Pompano Beach	33069	Cypress Bend Protective
589094	Volusia	Port Orange	32128	Cypress Creek Elementary School
587094	Miami-Dade	Miami	33165	Cypress Elementary School
585554	Broward	Pompano Beach	33060	Cypress Elementary School
587862	Osceola	Kissimmee	34743	CYPRESS ELEMENTARY SCHOOL
588501	Pinellas	Oldsmar	34677	Cypress Forest Recreation Center
587670	Orange	Orlando	32839	CYPRESS GROVE PARK
585433	Broward	Coral Springs	33071	Cypress Hall at Cypress Park
589179	Volusia	Port Orange	32128	Cypress Head HOA
588053	Palm Beach	West Palm Beach	33417	Cypress Lakes HOA
587835	Orange	Orlando	32820	CYPRESS LAKES HOA CLUBHOUSE
588550	Pinellas	Clearwater	33759	Cypress Meadows Community Church
585022	Jackson	Cypress	32432	CYPRESS PARK
588649	Polk	Winter Haven	33884	Cypress Ridge Presbyterian
585817	Collier	Naples	34120	Cypress Ward LDS Church
585228	Bay	Panama City	32401	Daffin Park Clubhouse
587964	Palm Beach	Boca Raton	33498	Daggerwing Nature Center
585925	Duval	Jacksonville	32209	Dallas Graham Branch Library
586019	Escambia	Pensacola	32534	DAMASCUS ROAD MISSIONARY BAPTIST
588174	Palm Beach	Riviera Beach	33404	Dan Calloway Complex
586661	Lee	Fort Myers	33912	Daniels Road Baptist Church
587316	Miami-Dade	Miami	33193	Dante B. Fascell Elementary School
588369	Pasco	Dade City	33525	Darby Community Church
589182	Walton	Westville	32464	Darlington Baptist Church
586359	Hillsborough	Tampa	33607	David Barksdale Senior Center
587014	Miami-Dade	Miami	33155	David Fairchild Elementary School
585377	Broward	Hollywood	33021	David Park Community Center
585309	Brevard	Satellite Beach	32937	David R. Schechter Community Center
585596	Broward	Davie	33314	Davie Elementary School
585341	Broward	Davie	33314	Davie Police Athletic League
585148	Lafayette	Mayo	32066	Day Community Center
585154	Alachua	Gainesville	32608	Days Inn Hotel
587777	Orange	Apopka	32712	DAYSPRING COMMUNITY CHURCH
585438	Broward	Fort Lauderdale	33315	Dayspring International Church of the Nazarene
587050	Miami-Dade	Miami	33147	Dayspring Missionary Baptist Church
589112	Volusia	Daytona Beach	32114	Daytona Beach Regional Library - City Island
589152	Volusia	Daytona Beach Shores	32118	Daytona Beach Shores Community Center
585865	Duval	Jacksonville	32216	Dean Road Church of Christ
589137	Volusia	Debary	32713	DeBary Elementary School
584867	Columbia	Lake City	32055	DEEP CREEK COMMUNITY CENTER
585640	Charlotte	Port Charlotte	33983	Deep Creek Elementary School
585837	Duval	Jacksonville	32256	Deercreek Country Club
585589	Broward	Deerfield Beach	33441	Deerfield Beach Housing Authority
585363	Broward	Deerfield Beach	33441	Deerfield Beach Percy White Library
586166	Hernando	Brooksville	34604	DEERFIELD CLUBHOUSE
586722	Leon	Tallahassee	32312	Deer Lake United Methodist
585916	Duval	Jacksonville	32256	Deermeadows Baptist Church
587389	Miami-Dade	Miami	33186	Deerwood Bonita Lakes Park
585999	Duval	Jacksonville	32256	Deerwood Country Club
589186	Walton	Defuniak Springs	32433	DeFuniak Springs Community Center
589176	Volusia	Deland	32724	DeLand High School
589166	Volusia	Deland	32724	DeLand Middle School
587905	Palm Beach	Boca Raton	33433	Del Prado Elementary
587942	Palm Beach	Delray Beach	33444	Delray Beach Community Center
587913	Palm Beach	Delray Beach	33484	Delray Beach First Baptist
587901	Palm Beach	Delray Beach	33445	Delray Beach Golf Club
588209	Palm Beach	Delray Beach	33444	Delray Beach Library
588150	Palm Beach	Delray Beach	33444	Delray Beach Swim and Tennis
587940	Palm Beach	Boynton Beach	33436	Delray Dunes
588069	Palm Beach	Delray Beach	33484	Delray Villas
589143	Volusia	Deltona	32725	Deltona Christian Church
589140	Volusia	Deltona	32725	Deltona Middle School
589162	Volusia	Deltona	32725	Deltona Regional Library
589101	Volusia	Deltona	32725	Deltona United Church of Christ
586703	Leon	Tallahassee	32311	Dept. of Agriculture Complex
586376	Hillsborough	Riverview	33578	Desoto Masonic Lodge #105
585824	Duval	Jacksonville	32211	Destination Church Assembly of God
587619	Okaloosa	Destin	32541	Destin City Hall Annex
587616	Okaloosa	Destin	32541	Destin Community Center
587582	Okaloosa	Destin	32541	Destin United Methodist Church
587398	Miami-Dade	Miami	33186	Devon Aire K-8 Center
587504	Miami-Dade	Miami	33186	Devon Aire Park
587955	Palm Beach	Greenacres	33463	Diamond View Elementary
589128	Volusia	Orange City	32763	Dickinson Memorial Library Hall
585617	Broward	Fort Lauderdale	33311	Dillard Elementary School
587340	Miami-Dade	Doral	33166	Dios Con Nosotros
586608	Lee	Cape Coral	33909	Diplomat Wesleyan Church
588626	Polk	Lakeland	33801	Discover Church
587880	Osceola	Kissimmee	34758	DISCOVERY INTERMEDIATE SCHOOL
587911	Palm Beach	Lake Worth	33467	Discovery Key Elementary
587828	Orange	Orlando	32828	DISCOVERY MIDDLE SCHOOL
585463	Broward	Lighthouse Point	33064	Dixon Ahl Recreation Center
585461	Broward	Miramar	33027	Dolphin Bay Elementary School
588667	Polk	Polk City	33868	Donald Bronson Community Center
584979	Gilchrist	Trenton	32693	Donnie Center
588567	Pinellas	Clearwater	33761	Doral Village
587592	Okaloosa	Crestview	32536	Dorcas Baptist Church
587101	Miami-Dade	Miami	33176	Dorothy M. Wallace C.O.P.E. Center
586687	Leon	Tallahassee	32317	Dorothy Spence Community Center
587696	Orange	Orlando	32807	DOVER SHORES BAPTIST CHURCH
587628	Orange	Orlando	32812	DOVER SHORES COMMUNITY CENTER
584931	Glades	Moore Haven	33471	Doyle Conner Bldg
585161	Alachua	Gainesville	32614	Doyle Conner Building
586639	Lee	Fort Myers	33916	Dr. Carrie D. Robinson Center
587069	Miami-Dade	Cutler Bay	33189	Dr. Edward L. Whigham Elementary School
587233	Miami-Dade	Miami	33196	Dr. Gilbert L. Porter Elementary School
585615	Broward	Hollywood	33024	Driftwood Recreation Center
587697	Orange	Orlando	32805	DR JAMES R SMITH NEIGHBORHOOD CENTER
585311	Brevard	Cocoa	32922	Dr. Joe Lee Smith Community Center
587510	Miami-Dade	Miami	33185	Dr. Manuel C. Barreiro Elementary School
586975	Miami-Dade	Miami	33179	Dr. Michael M. Krop Senior High School
587347	Miami-Dade	Miami	33142	Dr. M. L. King Jr Memorial Park
587212	Miami-Dade	Opa Locka	33054	Dr. Robert B. Ingram Elementary School
585583	Broward	Fort Lauderdale	33309	DRV PNK Stadium
586804	Manatee	Duette	34219	Duette Fire & Rescue District
588613	Polk	Dundee	33838	Dundee Community Center
588509	Pinellas	Dunedin	34698	Dunedin Assembly of God Church
588467	Pinellas	Dunedin	34698	Dunedin Community Center
588720	Putnam	Palatka	32177	Dunham Woods Baptist Church
586906	Marion	Dunnellon	34431	Dunnellon City Hall
588721	Putnam	San Mateo	32187	Dunns Creek Baptist Church
585844	Duval	Jacksonville	32218	Dunns Creek Baptist Church
588473	Pinellas	St Petersburg	33705	Dwight H. Jones Neighborhood Center
585721	Clay	Fleming Island	32003	EAGLE HARBOR SOCCER CLUB
588637	Polk	Eagle Lake	33839	Eagle Lake Church of Christ
585772	Collier	Naples	34113	Eagle Lakes Community Park
587624	Orange	Orlando	32837	EAGLE PARK COMMUNITY ASSOCIATION BUILDING
585518	Broward	Weston	33326	Eagle Point Elementary School
587927	Palm Beach	Boca Raton	33498	Eagles Landing Middle
585017	Holmes	Bonifay	32425	East Bonifay
586225	Hillsborough	Tampa	33610	East Chelsea Baptist Church
588998	Seminole	Oviedo	32765	East Coast Believers Church
586657	Lee	Lehigh Acres	33971	East County Regional Library
585659	Charlotte	Punta Gorda	33982	East Elementary School
587802	Orange	Orlando	32826	EAST LAKE ELEMENTARY SCHOOL
586426	Hillsborough	Tampa	33610	Eastlake Park Civic Club
588441	Pinellas	Palm Harbor	34685	East Lake United Methodist Church
588542	Pinellas	Oldsmar	34677	East Lake Woodlands Country Club
587764	Orange	Orlando	32825	EASTLAND BAPTIST CHURCH
588864	Santa Rosa	Milton	32583	East Milton Community Gym
585301	Brevard	Indialantic	32903	Eastminster Presbyterian Church
589046	Seminole	Altamonte Springs	32701	Eastmonte Civic Center
585778	Collier	Naples	34112	East Naples Community Park
587651	Orange	Orlando	32826	EAST ORANGE COMMUNITY CENTER
586076	Escambia	Pensacola	32503	EAST PENSACOLA HEIGHTS COMM CTR
585866	Duval	Jacksonville	32225	East Pointe Baptist Church
588004	Palm Beach	Palm Beach Gardens	33418	Eastpointe Country Club
585124	Jackson	Marianna	32448	EASTSIDE BAPTIST CHURCH
586261	Hillsborough	Thonotosassa	33592	East Thonotosassa Baptist Church
587671	Orange	Eatonville	32751	EATONVILLE TOWN HALL
585296	Brevard	Melbourne	32935	Eau Gallie Civic Center
585312	Brevard	Melbourne	32935	Eau Gallie First Baptist Church
585122	Jefferson	Lamont	32336	Ebenezer Baptist Church
587356	Miami-Dade	Miami	33134	Ebenezer Freewill Baptist Church
587343	Miami-Dade	Miami	33155	Ebenezer Spanish SDA Church
587111	Miami-Dade	Miami	33142	Ebenezer United Methodist Church
586080	Escambia	Pensacola	32505	EBONWOOD COMMUNITY CENTER
587181	Miami-Dade	Biscayne Park	33161	Ed Burke Recreation Center
585321	Brevard	Melbourne	32901	Eddie Lee Taylor, Sr. Community Center
586153	Hernando	Brooksville	34601	EDEN BAPTIST CHURCH
587430	Miami-Dade	Miami	33150	Eden Eglise Adventist DU 7th Jour
587017	Miami-Dade	North Miami Beach	33160	Eden Isles Condominium
588718	Putnam	Palatka	32177	Edgar Johnson Senior Center
589134	Volusia	Edgewater	32132	Edgewater City Hall
587657	Orange	Orlando	32809	EDGEWOOD CITY HALL
587489	Miami-Dade	Miami	33150	Edison Courts Community Center
587113	Miami-Dade	Miami	33127	Edison Towers
587456	Miami-Dade	Miami	33150	Edison West Little River Community Resource Center
585873	Duval	Jacksonville	32254	Edith Brown Ford Community Center
586337	Hillsborough	Tampa	33626	Ed Radice Sports Complex Baseball Bldg
587458	Miami-Dade	Palmetto Bay	33157	Edward & Arlene Feller Community Room
585302	Brevard	Palm Bay	32909	EFSC Palm Bay - Community Dining Rm
587580	Okaloosa	Eglin Afb	32542	Eglin West Gate Chapel
588036	Palm Beach	West Palm Beach	33417	Egret Lake Elementary
589053	Sumter	The Villages	32163	EISENHOWER REC CTR
587915	Palm Beach	Wellington	33414	Elbridge Gale Elementary
587320	Miami-Dade	Miami	33144	El Camino Church
587196	Miami-Dade	Miami	33175	Elim Missionary Assembly
586956	Martin	Indiantown	34956	Elisabeth Lahti Library
586140	Gadsden	Chattahoochee	32324	Elizabeth A.M.E. Church
587538	Miami-Dade	Miami	33133	Elizabeth Virrick Park
586004	Duval	Jacksonville	32244	Elks Club #2605
587554	Monroe	Tavernier	33070	Elks Club, Tavernier
587615	Okaloosa	Fort Walton Beach	32548	Elks Lodge
587560	Monroe	Key West	33040	Elks Lodge
588756	St. Johns	St Augustine	32080	Elks Lodge
588086	Palm Beach	West Palm Beach	33413	Elks Lodge #1352
586808	Manatee	Bradenton	34209	Elks Lodge #1511
586404	Hillsborough	Tampa	33611	Elks Lodge 708
585218	Alachua	Gainesville	32605	Elks Lodge #990
586021	Escambia	Pensacola	32506	ELKS LODGE BPOE #2108
587888	Osceola	Kissimmee	34744	ELKS LODGE KISSIMMEE
586973	Martin	Stuart	34996	Elliott Museum
587446	Miami-Dade	El Portal	33138	El Portal Village Hall
589067	Sumter	The Villages	32162	EL SANTIAGO REC CTR
588367	Pasco	Spring Hill	34610	Elsie Logan Memorial Pk Civic Center
586320	Hillsborough	Tampa	33619	Emanuel P Johnson Recreation Center
585553	Broward	Cooper City	33026	Embassy Lakes Clubhouse
588995	Seminole	Altamonte Springs	32701	Embassy Suites by Hilton Orlando North
586490	Lake	Montverde	34756	Embrace Church
587994	Palm Beach	Wellington	33414	Emerald Cove Middle
588587	Polk	Davenport	33837	Emerald Lakes MHP Clubhouse
587210	Miami-Dade	Miami	33155	Emerson Elementary School
585490	Broward	Pompano Beach	33060	Emma Lou Olson Community Center
588276	Pasco	Port Richey	34668	Emmanuel Baptist Church
587612	Okaloosa	Crestview	32539	Emmanuel Baptist Church
587904	Palm Beach	Delray Beach	33484	Emmanuel Catholic Church
588426	Pinellas	Palm Harbor	34683	Emmanuel Community Church
587636	Orange	Orlando	32803	EMMANUEL EPISCOPAL CHURCH
588944	Sarasota	Venice	34285	EMMANUEL LUTHERAN CHURCH
585253	Brevard	Melbourne	32935	Emmanuel United Methodist Church
586785	Manatee	Bradenton	34210	Emmanuel United Methodist Church
585949	Duval	Jacksonville	32209	Emmett Reed Center
587706	Orange	Orlando	32807	ENGELWOOD NEIGHBORHOOD CENTER
585894	Duval	Jacksonville	32207	Englewood Baptist Church
588924	Sarasota	Englewood	34223	ENGLEWOOD SPORTS COMPLEX
585526	Broward	Lauderhill	33319	Environ Cultural Center
587699	Orange	Orlando	32806	EOLA MASONIC LODGE 207
588432	Pinellas	Clearwater	33760	EpiCenter at St. Petersburg College
586668	Lee	Cape Coral	33904	Epiphany Episcopal Church
586732	Leon	Tallahassee	32312	Epiphany Lutheran Church
588974	Seminole	Oviedo	32765	Epiphany Lutheran Church - Oviedo
588420	Pinellas	St Petersburg	33702	Epiphany Ukrainian Catholic Church
585888	Duval	Jacksonville	32223	Episcopal Church of Our Savior
587450	Miami-Dade	Hialeah	33018	Ernest R. Graham K-8 Academy
586083	Escambia	Cantonment	32533	ESCAMBIA CO EXTENSION OFFICE
586041	Escambia	Pensacola	32503	ESCAMBIA EDUCATION ASSOC
585468	Broward	Dania Beach	33312	Estates of Ft Lauderdale Clubhouse
586610	Lee	Estero	33928	Estero Community Church
586631	Lee	Estero	33928	Estero Recreation Center
586587	Lee	Estero	33928	Estero United Methodist Church
587415	Miami-Dade	Miami	33157	Ethel F. Beckford/Richmond Elementary School
589183	Walton	Ponce De Leon	32455	Eucheeanna Old School Community Ctr
586118	Gadsden	Midway	32343	Eugene Lamb Recreation Center
587153	Miami-Dade	Doral	33178	Eugenia B. Thomas K-8 Center
587251	Miami-Dade	Miami	33177	Eureka Park
587426	Miami-Dade	Miami	33177	Eureka Villas Park
586505	Lake	Eustis	32726	Eustis Community Building
586532	Lake	Eustis	32726	Eustis Service Center
585847	Duval	Jacksonville	32205	Evangel Temple Assembly of God
585766	Collier	Everglades City	34139	Everglades City Hall
585578	Broward	Weston	33331	Everglades Elementary School
587103	Miami-Dade	Miami	33155	Everglades K-8 Center
589074	Sumter	The Villages	32163	EVERGLADES REC CTR / YELLOWSTONE
589065	Sumter	The Villages	32163	EVERGLADES REC CTR / YOSEMITE
586963	Martin	Indiantown	34956	Evergreen Baptist Church
588052	Palm Beach	Palm Beach Gardens	33410	Evergrene Clubhouse
587440	Miami-Dade	Miami	33172	E.W.F. Stirrup Elementary School
588570	Polk	Lakeland	33805	Ewing Memorial 7th Day Adventist Church
589068	Sumter	The Villages	32163	EZELL REC CTR
585171	Alachua	Gainesville	32609	Fairbanks Baptist Church
586890	Marion	Reddick	32686	Fairfield Baptist Church, Fell. Hall
585156	Alachua	Gainesville	32608	Fairfield Inn and Suites Gainesville I-75
586301	Hillsborough	Tampa	33610	Fair Oaks Recreation Center
587797	Orange	Orlando	32810	FAIRVIEW SHORES LIBRARY
585879	Duval	Jacksonville	32208	Fairway Oaks
587767	Orange	Orlando	32826	FAIRWAYS COUNTRY CLUB
588344	Pasco	New Port Richey	34655	Fairway Springs Clubhouse
588418	Pinellas	St Petersburg	33714	Faith Assembly
589191	Walton	Miramar Beach	32550	Faith Assembly Church
587722	Orange	Orlando	32825	FAITH ASSEMBLY OF GOD
587623	Orange	Orlando	32806	FAITH ASSEMBLY OF GOD, MICHIGAN STREET CAMPUS
586638	Lee	North Fort Myers	33917	Faith Assembly Of God - North Campus
588965	Seminole	Casselberry	32707	Faith Assembly of God Red Bug Lake Campus
588690	Polk	Winter Haven	33880	Faith Baptist Church
586092	Escambia	Pensacola	32504	FAITH BAPTIST CHURCH
588327	Pasco	New Port Richey	34654	Faith Baptist Church- NPR
585320	Brevard	Palm Bay	32907	Faith Baptist Church of Palm Bay
588381	Pasco	Zephyrhills	33542	Faith Baptist Church- ZH
585871	Duval	Jacksonville	32225	Faith Chapel Free Will Baptist Church
586716	Leon	Tallahassee	32301	Faith Christian Family Center
588934	Sarasota	Sarasota	34232	FAITH CHURCH
587484	Miami-Dade	Miami	33150	Faith Community Baptist Church
588766	St. Johns	St. Johns	32259	Faith Community Church
586154	Hernando	Brooksville	34601	FAITH EVANGELICAL PRESBYTERIAN CHURCH
587801	Orange	Winter Garden	34787	FAITH FAMILY COMMUNITY CHURCH
586418	Hillsborough	Tampa	33625	Faith Family Worship Center
587968	Palm Beach	Boynton Beach	33472	Faith Farm Tabernacle
585288	Brevard	Melbourne	32940	Faith Fellowship Church
587193	Miami-Dade	Miami	33196	Faith International Fellowship
589098	Volusia	Deland	32724	Faith Life International Church
586655	Lee	Lehigh Acres	33936	Faith Lutheran Church
586798	Manatee	Parrish	34219	Faith Lutheran Church
585798	Collier	Naples	34103	Faith Lutheran Church
588983	Seminole	Oviedo	32765	Faith Outreach Church
588046	Palm Beach	Palm Springs	33461	Faith Presbyterian
585164	Alachua	Gainesville	32606	Faith Presbyterian Church
586651	Lee	Cape Coral	33904	Faith Presbyterian Church
586731	Leon	Tallahassee	32303	Faith Presbyterian Church
588383	Pasco	Hudson	34669	Faith United Methodist Church
588080	Palm Beach	Boynton Beach	33437	Faith United Methodist Church
587799	Orange	Orlando	32825	FAITH UNITED METHODIST CHURCH
586625	Lee	Fort Myers	33908	Faith United Methodist Church - Family LIfe Ctr.
585264	Brevard	Rockledge	32955	Faith Viera Lutheran Church
588692	Polk	Lakeland	33805	Faith Wesleyan Church
587723	Orange	Orlando	32836	FAMILY CHURCH
586773	Manatee	Palmetto	34221	Family of God United Methodist Church
586294	Hillsborough	Temple Terrace	33617	Family Recreation Complex
584987	DeSoto	Arcadia	34266	Family Service Center Annex
586754	Leon	Tallahassee	32303	Family Worship and Praise Center
588593	Polk	Lakeland	33801	Family Worship Center
586141	Gadsden	Quincy	32352	FAMU Research Center
586737	Leon	Tallahassee	32307	FAMU Student Union
584846	Levy	Fanning Springs	32693	Fanning Springs City Hall
587893	Palm Beach	Boca Raton	33431	FAU Arena Lobby
588175	Palm Beach	Jupiter	33458	FAU Jupiter Campus
588480	Pinellas	Clearwater	33762	Feather Sound Country Club
585058	Hendry	Felda	33930	Felda Community Center
587300	Miami-Dade	Miami	33196	Felix Varela Senior High School
588592	Polk	Lakeland	33813	Fellowship Baptist Church
586216	Hillsborough	Thonotosassa	33592	Fellowship Baptist Church
586735	Leon	Tallahassee	32303	Fellowship Baptist Church
586871	Marion	Ocala	34482	Fellowship Baptist Church, Fell. Hall
587752	Orange	Orlando	32810	FELLOWSHIP MISSIONARY BAPTIST CHURCH
588903	Sarasota	Sarasota	34237	FELLOWSHIP OF BELIEVERS
586721	Leon	Tallahassee	32309	Fellowship Presbyterian Church
586458	Indian River	Fellsmere	32948	Fellsmere Community Bible Church
589060	Sumter	The Villages	32163	FENNEY REC CTR
586555	Lake	Clermont	34715	Ferndale Baptist Church-Fellowship Hall
588638	Polk	Haines City	33844	FFA Leadership Center
588873	Santa Rosa	Jay	32565	Fidelis-Linda Carden Community Center
587380	Miami-Dade	Miami Beach	33139	Fienberg Fisher K-8 Center
588446	Pinellas	St Petersburg	33711	Fifth Avenue Church of Christ
586886	Marion	Ocala	34482	Fire College, Bldg. B
587065	Miami-Dade	Doral	33122	Fire Fighters Memorial Building
585568	Broward	Fort Lauderdale	33308	Fire Station #35
587866	Osceola	Kissimmee	34746	FIRE STATION 42 - POINCIANA BLVD
587883	Osceola	St Cloud	34771	FIRE STATION 52 - PINE GROVE
587869	Osceola	St. Cloud	34772	FIRE STATION 53 - DEER RUN
587887	Osceola	St. Cloud	34771	FIRE STATION 54 - HARMONY
585853	Duval	Jacksonville	32225	Fire Station #58
587878	Osceola	Kissimmee	34747	FIRE STATION 71 - W192
587849	Osceola	Davenport	33896	FIRE STATION 73- REUNION
585031	Washington	Caryville	32428	Fire Station - Hinson Crossroads
588572	Polk	Lakeland	33803	First Alliance Church
587666	Orange	Orlando	32803	FIRST ALLIANCE CHURCH
589174	Volusia	Deland	32724	First Assembly of DeLand
586245	Hillsborough	Plant City	33566	First Assembly of God
585741	Clay	Keystone Hts	32656	FIRST BAPTIST CH OF KH
585252	Brevard	Melbourne	32935	First Baptist Ch of South Brevard
587570	Monroe	Big Pine Key	33043	First Baptist Ch (Summerland Votes)
584831	Washington	Chipley	32428	First Baptist Church
588544	Pinellas	Tarpon Springs	34689	First Baptist Church
585209	Alachua	Gainesville	32606	First Baptist Church
587590	Okaloosa	Mary Esther	32569	First Baptist Church
588653	Polk	Frostproof	33843	First Baptist Church
589173	Volusia	Oak Hill	32759	First Baptist Church
588921	Sarasota	Sarasota	34236	FIRST BAPTIST CHURCH
587556	Monroe	Big Pine Key	33043	First Baptist Church Big Pine Key
586524	Lake	Clermont	34711	First Baptist Church Clermont
588389	Pasco	Dade City	33525	First Baptist Church Dade City
586557	Lake	Mt. Dora	32757	First Baptist Church-Family Ministry Center
586915	Marion	Ocala	34471	First Baptist Church, Foyer
585686	Citrus	Inverness	34452	FIRST BAPTIST CHURCH INVERNESS
588365	Pasco	Dade City	33523	First Baptist Church Lacoochee
588694	Polk	Bartow	33830	First Baptist Church-Ministry Center
586506	Lake	Altoona	32702	First Baptist Church of Altoona
585698	Citrus	Beverly Hills	34465	FIRST BAPTIST CHURCH OF BEVERLY HILLS
586046	Escambia	Century	32535	FIRST BAPTIST CHURCH OF BRATT
585688	Citrus	Crystal River	34428	FIRST BAPTIST CHURCH OF C.R.
586233	Hillsborough	Dover	33527	First Baptist Church of Dover
589023	Seminole	Geneva	32732	First Baptist Church of Geneva
586408	Hillsborough	Gibsonton	33534	First Baptist Church Of Gibsonton
586799	Manatee	Palmetto	34221	First Baptist Church of Gillette
587083	Miami-Dade	Miami	33169	First Baptist Church of Greater Miami
585398	Broward	Hollywood	33020	First Baptist Church of Hollywood
587617	Okaloosa	Holt	32564	First Baptist Church of Holt
585307	Brevard	Indialantic	32903	First Baptist Church of Indialantic
585975	Duval	Jacksonville	32202	First Baptist Church of Jacksonville
585979	Duval	Ponte Vedra	32081	First Baptist Church of Jax at Nocatee
588657	Polk	Bartow	33830	First Baptist Church of Lake Garfield
586193	Highlands	Sebring	33875	FIRST BAPTIST CHURCH OF LAKE JOSEPHINE
587583	Okaloosa	Laurel Hill	32567	First Baptist Church of Laurel Hill
585257	Brevard	Malabar	32950	First Baptist Church of Malabar
585710	Clay	Middleburg	32068	FIRST BAPTIST CHURCH OF MIDDLEBURG
589184	Walton	Defuniak Springs	32433	First Baptist Church of Mossy Head
584860	DeSoto	Nocatee	34268	First Baptist Church of Nocatee
586277	Hillsborough	Riverview	33578	First Baptist Church of Riverview
586445	Hillsborough	Tampa	33606	First Baptist Church of Tampa
585188	Alachua	Waldo	32694	First Baptist Church of Waldo
586044	Escambia	Pensacola	32507	FIRST BAPTIST CHURCH OF WARRINGTON
585378	Broward	Lauderdale Lakes	33313	First Baptist Church Piney Grove
584990	DeSoto	Arcadia	34266	First Baptist Chur Christ. Life Ctr
584996	DeSoto	Arcadia	34266	First Baptist Chur Christ. Life Ctr
585025	Okeechobee	Okeechobee	34974	FIRST BAPTIST CHURCH ROC
588487	Pinellas	St Petersburg	33702	First Baptist Church/St. Petersburg
588984	Seminole	Longwood	32779	First Baptist Church Sweetwater
587987	Palm Beach	Tequesta	33469	First Baptist Church Teq
588210	Palm Beach	Wellington	33414	First Baptist Church Wel
588624	Polk	Lakeland	33815	First Baptist Institutional Church
586516	Lake	Leesburg	34748	First Baptist Leesburg-South Campus
588911	Sarasota	Sarasota	34237	FIRST BRETHREN CHURCH
586267	Hillsborough	Dover	33527	First Call Church
588168	Palm Beach	West Palm Beach	33409	First Christian Church
586646	Lee	Cape Coral	33904	First Christian Church - C.C.
588559	Pinellas	Clearwater	33765	First Christian Church of Clwr.
585882	Duval	Neptune Beach	32266	First Christian Church of the Beaches
588491	Pinellas	St Petersburg	33702	First Church of Christ, Scientist
588695	Polk	Winter Haven	33881	First Church of God
586329	Hillsborough	Plant City	33563	First Church of God of Plant City
586272	Hillsborough	Tampa	33612	First Church of God of Tampa
587318	Miami-Dade	North Miami	33161	First Church of North Miami
588485	Pinellas	Clearwater	33764	First Church of the Nazarene
586693	Leon	Tallahassee	32308	First Church of the Nazarene
588619	Polk	Winter Haven	33880	First Church of the Nazarene
587891	Osceola	Kissimmee	34744	FIRST CHURCH OF THE NAZARENE
585794	Collier	Naples	34119	First Congregational Church of Naples
586592	Lee	Cape Coral	33991	First Congregational Community Church
585800	Collier	Naples	34114	First Haitian Baptist Church
586221	Hillsborough	Tampa	33614	First Hispanic Presbyterian Church
585211	Alachua	Gainesville	32603	First Lutheran Church
586349	Hillsborough	Seffner	33584	First Missionary Baptist Church of Seffner
587589	Okaloosa	Niceville	32578	First Presbyterian Church
585610	Broward	Fort Lauderdale	33301	First Presbyterian Church
587312	Miami-Dade	Miami	33131	First Presbyterian Church
588476	Pinellas	Dunedin	34698	First Presbyterian Church
585748	Clay	Grn Cv Spgs	32043	FIRST PRESBYTERIAN CHURCH
588884	Sarasota	Sarasota	34237	FIRST PRESBYTERIAN CHURCH
586247	Hillsborough	Thonotosassa	33592	First Thonotosassa Missionary Baptist Church
585938	Duval	Jacksonville	32218	First Timothy Baptist Church
585576	Broward	Hollywood	33021	First United Church of Christ
588576	Polk	Lake Wales	33853	First United Methodist Church
585199	Alachua	High Springs	32643	First United Methodist Church
589049	Sumter	Bushnell	33513	FIRST UNITED METHODIST CHURCH
586398	Hillsborough	Lutz	33548	First United Methodist Church of Lutz
586554	Lake	Tavares	32778	First United Methodist Church-UMAC Bldg
584850	Hardee	Wauchula	33873	FIRST UNITED METHODIST CHURCH WAUC
586383	Hillsborough	Seffner	33584	First United Methodist of Seffner
587200	Miami-Dade	Coral Gables	33134	First UTD Methodist Church of Coral Gables
587047	Miami-Dade	Miami	33144	Flagami Elementary School
586112	Flagler	Flagler Beach	32136	FLAGLER BEACH CITY COMMISSION CHAMBER ROOM
586110	Flagler	Flagler Beach	32136	FLAGLER BEACH UNITED METHODIST CHURCH
586103	Flagler	Palm Coast	32137	FLAGLER COMMUNITY EDUCATION
586098	Flagler	Bunnell	32110	FLAGLER COUNTY ASSOCIATION OF REALTORS
586094	Flagler	Palm Coast	32137	FLAGLER COUNTY PUBLIC LIBRARY
586096	Flagler	Palm Coast	32164	FLAGLER TECHNICAL COLLEGE
585601	Broward	Sunrise	33323	Flamingo Park
585569	Broward	Pembroke Pines	33028	Flanagan High School
585910	Duval	Jacksonville	32244	Fleet Reserve
585716	Clay	Fleming Island	32003	FLEMING ISLAND SPLASH PARK
585673	Citrus	Floral City	34436	FLORAL CITY LIONS CLUB
585683	Citrus	Floral City	34436	FLORAL CITY UNITED METHODIST
585580	Broward	Fort Lauderdale	33334	Floranada Elementary School
589131	Volusia	Debary	32713	Florence K Little Town Hall
588610	Polk	Davenport	33897	Florida Camp Inn
587225	Miami-Dade	Homestead	33034	Florida City City Hall
587264	Miami-Dade	Florida City	33034	Florida City Elementary School
587237	Miami-Dade	Homestead	33034	Florida City/Homestead Neighborhood Service Center
589035	Seminole	Altamonte Springs	32714	Florida Conference of Seventh-day Adventists
587655	Orange	Orlando	32817	FLORIDA CONFERENCE UNITED CHURCH OF CHRIST
588058	Palm Beach	Lake Worth	33467	Florida Gardens Baptist Church
586917	Marion	Dunnellon	34432	Florida Highlands Baptist Church
587192	Miami-Dade	Miami Gardens	33054	Florida Memorial University
585181	Alachua	Gainesville	32611	Florida Museum of Natural History
589111	Volusia	Edgewater	32141	Florida Shores Clubhouse
585789	Collier	Naples	34114	Florida Sports Park
587220	Miami-Dade	Miami	33172	Fontainebleau Milton Rental Apartments
588565	Pinellas	Pinellas Park	33782	Forbes Recreation Center
586903	Marion	Ocala	34474	Fore Ranch Prairie Club
586845	Marion	Silver Springs	34488	Forest Assembly Of God
586896	Marion	Fort Mccoy	32134	Forest Baptist Church
584893	Taylor	Perry	32348	Forest Capital Hall
586857	Marion	Ocklawaha	32179	Forest Community Center
585166	Alachua	Alachua	32615	Forest Grove Baptist Church
587951	Palm Beach	West Palm Beach	33415	Forest Hill Elementary
586283	Hillsborough	Tampa	33612	Forest Hills Community Center
586419	Hillsborough	Tampa	33612	Forest Hills Presbyterian Church
589104	Volusia	Deltona	32725	Forest Lake Elementary
588992	Seminole	Apopka	32703	Forest Lake SDA Church
588064	Palm Beach	Boynton Beach	33435	Forest Park Elementary
585562	Broward	Tamarac	33321	Forsythe Colony Sect.16 Clubhouse
587808	Orange	Orlando	32807	FORSYTH WOODS ELEMENTARY SCHOOL
586713	Leon	Tallahassee	32310	Fort Braden Community Center
586000	Duval	Jacksonville	32277	Fort Caroline Christian Church
585971	Duval	Jacksonville	32277	Fort Caroline Presbyterian Church
584968	Hardee	Bowling Green	33834	FORT GREEN BAPTIST CHURCH
585547	Broward	Fort Lauderdale	33301	Fort Lauderdale City Hall
585571	Broward	Fort Lauderdale	33311	Fort Lauderdale Fire Rescue
586874	Marion	Fort Mccoy	32134	Fort McCoy Public Library
588595	Polk	Fort Meade	33841	Fort Meade Community Center
586577	Lee	Fort Myers	33919	Fort Myers Christian Church
586660	Lee	Fort Myers	33919	Fort Myers Congregational U.C.C.
589118	Volusia	Deltona	32738	Fort Smith Blvd Baptist Church
584970	Columbia	Fort White	32038	FORT WHITE COMMUNITY CENTER
585586	Broward	Hallandale Beach	33009	Foster Park
587573	Monroe	Islamorada	33036	Founders Park Community Center
585235	Bay	Fountain	32438	Fountain Fire Station
586664	Lee	Estero	33928	Fountain Lakes
588588	Polk	Davenport	33837	Four Corners Baptist Church
586701	Leon	Tallahassee	32309	Four Oaks Community Church
586388	Hillsborough	Temple Terrace	33617	Fowler Avenue Baptist Church
586177	Highlands	Sebring	33870	FRANCIS II MHP CLUBHOUSE
585224	Bay	Panama City Beach	32413	Frank Brown Park Community Center
585538	Broward	Fort Lauderdale	33311	Franklin Park Community Center
585263	Brevard	Palm Bay	32908	Franklin T. De Groodt Public Library
585231	Bay	Panama City	32405	Frank Nelson Building
588481	Pinellas	St Petersburg	33705	Frank Pierce Recreation Center
587678	Orange	Orlando	32822	FRATERNAL ORDER OF EAGLES
585728	Clay	Middleburg	32068	FRATERNAL ORDER OF POLICE #104
589175	Volusia	New Smyrna Beach	32168	Fraternal Order of Police Lodge #40
586318	Hillsborough	Tampa	33602	Fred B Karl County Center
585428	Broward	Hollywood	33020	Fred Lippman Multipurpose Center
585742	Clay	Keystone Hts	32656	FREEDOM BAPTIST CHURCH
585831	Duval	Jacksonville	32223	Freedom Christian Fellowship
586460	Indian River	Vero Beach	32968	Freedom Church
586767	Leon	Tallahassee	32308	Freedom Church 1st Assembly of God
585182	Alachua	Gainesville	32606	Freedom Community Center at Kanapaha Park
585764	Collier	Naples	34105	Freedom Park
586368	Hillsborough	Sun City Center	33573	Freedom Plaza
584915	Okeechobee	Okeechobee	34974	FREEDOM RANCH
588049	Palm Beach	Boynton Beach	33436	Freedom Shores Elementary
587336	Miami-Dade	Opa Locka	33054	Freeman Collins & Raymond Britton Senior Facility
589201	Walton	Freeport	32439	Freeport City Hall
587923	Palm Beach	Palm Beach Gardens	33410	Frenchmans Creek Country Club
587745	Orange	Orlando	32818	FRESH OIL MINISTRIES CHURCH OF GOD
586064	Escambia	Pensacola	32501	FRICKER COMMUNITY CENTER
586751	Leon	Tallahassee	32303	Friendship AME Church
589197	Walton	Defuniak Springs	32433	Friendship Baptist Church
588866	Santa Rosa	Milton	32570	Friendship Baptist Church
588951	Sarasota	North Port	34287	FRIENDSHIP CLUB OF WARM MINERAL SPRINGS
589093	Volusia	Edgewater	32141	Friendship Community Church
587962	Palm Beach	Loxahatchee	33470	Frontier Elementary
585305	Brevard	Melbourne	32901	Front Street Civic Center
585520	Broward	Dania Beach	33004	Frost Park, City of Dania Beach
586550	Lake	Fruitland Park	34731	Fruitland Park Community Center
587132	Miami-Dade	Homestead	33031	Fruit & Spice Park
585048	Nassau	Yulee	32097	FSCJ - Building 30 Nassau Room
585839	Duval	Jacksonville	32256	FSCJ - Deerwood Center
586745	Leon	Tallahssee	32306	FSU Student Services Building
585100	Okeechobee	Okeechobee	34972	FT DRUM COMMUNITY CHURCH
585594	Broward	Fort Lauderdale	33315	Ft. Lauderdale 7th Day Adventist Church
588643	Polk	Fort Meade	33841	Ft Meade First Church of God
585095	DeSoto	Ft. Ogden	34266	Ft. Ogden Community Church
587602	Okaloosa	Fort Walton Beach	32548	Ft Walton Bch Recreation Center
587431	Miami-Dade	North Miami Beach	33162	Fulford United Methodist Church
588763	St. Johns	St Augustine	32084	Fullerwood Training Ctr
585720	Clay	Keystone Heights	32656	GADARA BAPTIST CHURCH
587898	Palm Beach	West Palm Beach	33401	Gaines Park Community Center
585169	Alachua	Gainesville	32605	Gainesville Baptist Church
585175	Alachua	Gainesville	32608	Gainesville Shrine Club
589092	Volusia	Deltona	32725	Galaxy Middle School
588510	Pinellas	St Petersburg	33711	Galilee Missionary Baptist Church
586370	Hillsborough	Tampa	33616	Gandy Civic Association
588582	Polk	Lakeland	33801	Gapway Baptist Church
586007	Duval	Jacksonville	32218	Garden City United Methodist Church
588230	Palm Beach	Palm Beach Gardens	33403	Garden Lodge 366 F&AM
587977	Palm Beach	Palm Beach Gardens	33410	Gardens Branch Library
588099	Palm Beach	Palm Beach Gardens	33410	Gardens Mall Community Room
588338	Pasco	New Port Richey	34652	Gardens of Beacon Square III Clubhouse
588068	Palm Beach	Palm Beach Gardens	33418	Gardens Presbyterian
586438	Hillsborough	Gibsonton	33534	Gardenville Recreation Center
589200	Walton	Westville	32464	Gaskin First Baptist Church
586160	Hernando	Spring Hill	34606	GATEWAY BIBLE CHURCH
585206	Alachua	Gainesville	32653	Gateway Christian Center
587705	Orange	Winter Park	32789	GATEWAY CHURCH
584944	Hardee	Bowling Green	33834	GATEWAY CHURCH
587041	Miami-Dade	Homestead	33033	Gate Way Church of Christ
587133	Miami-Dade	Homestead	33035	Gateway Environmental K-8 Learning Center
587434	Miami-Dade	Sunny Isles Beach	33160	Gateway Park
586624	Lee	Fort Myers	33913	Gateway Trinity Lutheran Church
587551	Monroe	Key West	33040	Gato Building Conference Room 1st Floor
587097	Miami-Dade	Miami	33183	General Conference Seventh-day Adventist Church
588290	Pasco	Trinity	34655	Generations Christian Church
586744	Leon	Tallahassee	32304	Generations Church
586195	Highlands	Lake Placid	33852	GENESIS CENTER
586689	Leon	Tallahassee	32303	Genesis Church
584908	Hamilton	White Springs	32096	GENOA VOL FIRE DEPT
585487	Broward	Fort Lauderdale	33304	George English Park Community Ctr.
589095	Volusia	Deland	32724	George Marks Elementary School
588922	Sarasota	North Port	34286	GEORGE MULLEN ACTIVITY CENTER
588560	Pinellas	Pinellas Park	33781	German American Club
587168	Miami-Dade	Miami	33175	German American Social Club
587533	Miami-Dade	Miami	33162	Gertrude K. Edelman/Sabal Palm Elementary School
587482	Miami-Dade	Miami	33185	G. Holmes Braddock Senior High School
587400	Miami-Dade	South Miami	33143	Gibson Bethel Community Center
588644	Polk	Lakeland	33809	Gibsonia Baptist Church
586478	Indian River	Vero Beach	32967	Gifford Community Center
588404	Pinellas	St. Petersburg	33713	Gladden Park Recreation Center
587920	Palm Beach	Belle Glade	33430	Glades Central High
585774	Collier	Naples	34112	Glades Community Center
588163	Palm Beach	Boca Raton	33434	Glades Road Branch Library
587771	Orange	Ocoee	34761	GLAD TIDINGS CHURCH
587565	Monroe	Key West	33040	Glad Tiding Tabernacle
587371	Miami-Dade	Miami	33186	Glendale Baptist Church
585896	Duval	Jacksonville	32216	Glendale Community Church
588112	Palm Beach	Delray Beach	33446	Gleneagles Maintenance Facility
585863	Duval	Jacksonville	32224	Glen Kernan Golf and Country Club
587759	Orange	Orlando	32814	GLENRIDGE MIDDLE SCHOOL
585113	Baker	Glen St. Mary	32040	Glen St. Mary
585229	Bay	Panama City	32401	Glenwood Community Center
589172	Volusia	Deland	32720	Glenwood Presbyterian Church
587066	Miami-Dade	Miami	33055	Global Church
587297	Miami-Dade	Miami	33176	Gloria Floyd Elementary School
585333	Brevard	Palm Bay	32909	Glory Bound Church of God
586203	Hillsborough	Wimauma	33598	God's Missionary Church
585818	Collier	Naples	34117	Golden Gate Assembly of God
585777	Collier	Naples	34116	Golden Gate Community Center
587532	Miami-Dade	Miami Gardens	33054	Golden Glades Elementary School
587917	Palm Beach	Westlake	33411	Golden Grove Elementary
588182	Palm Beach	West Palm Beach	33411	Golden Lakes Village Phase A Clubhouse
587822	Orange	Winter Park	32792	GOLDENROD CIVIC CLUB
586790	Manatee	Bradenton	34203	Golf Lakes MHP, Rec Hall
588654	Polk	Lake Wales	33898	Golfview Baptist Church
588012	Palm Beach	Boynton Beach	33426	Golfview Harbour Clubhouse
586039	Escambia	Cantonment	32533	GONZALEZ BAPTIST CHURCH
588575	Polk	Bartow	33830	Good Hope Missionary Baptist Church
585805	Collier	Goodland	34140	Goodland Community Center
587008	Miami-Dade	Hialeah	33012	Goodlet Park
588765	St. Johns	St Augustine	32086	Good News Church
589194	Walton	Santa Rosa Beach	32459	Good News United Methodist Church
586699	Leon	Tallahassee	32311	Good Samaritan UMC
586714	Leon	Tallahssee	32309	Good Shepherd Catholic Church
588137	Palm Beach	Boca Raton	33433	Good Shepherd Lutheran
585696	Citrus	Hernando	34442	GOOD SHEPHERD LUTHERAN
586310	Hillsborough	Tampa	33609	Good Shepherd Lutheran Church
587742	Orange	Orlando	32809	GOODWILL INDUSTRIES
586583	Lee	Bonita Springs	34135	Gospel Baptist Church
587815	Orange	Apopka	32712	GOSPEL CENTERED CHURCH
586743	Leon	Tallahassee	32311	Gospel Light Church
587337	Miami-Dade	Miami	33170	Goulds Church of Christ
587055	Miami-Dade	Miami	33170	Goulds Park
587325	Miami-Dade	Miami	33156	Grace A Metro Life Church
586605	Lee	Cape Coral	33991	Grace Baptist Church
585501	Broward	Southwest Ranches	33332	Grace Baptist Church
585872	Duval	Jacksonville	32206	Grace Baptist Church
585841	Duval	Jacksonville	32205	Grace Church of Avondale
588711	Putnam	Palatka	32177	Grace Community Baptist Church
588088	Palm Beach	Boca Raton	33486	Grace Community Church
588950	Sarasota	Sarasota	34241	GRACE COMMUNITY CHURCH
586297	Hillsborough	Lithia	33547	Grace Community United Methodist Church
586435	Hillsborough	Tampa	33647	Grace Episcopal Church
587924	Palm Beach	West Palm Beach	33407	Grace Episcopal Church
586219	Hillsborough	Lutz	33558	Grace Family Church
586213	Hillsborough	Tampa	33637	Grace Family Church Temple Terrace
586306	Hillsborough	Tampa	33634	Grace Family Church - Waters
587914	Palm Beach	Jupiter	33477	Grace Immanuel Bible Church
586367	Hillsborough	Tampa	33618	Grace Lutheran Church
586748	Leon	Tallahassee	32308	Grace Lutheran Church
586051	Escambia	Pensacola	32504	GRACE LUTHERAN CHURCH
585565	Broward	Oakland Park	33334	Gracepoint Church
586420	Hillsborough	Plant City	33566	GracePoint Church
587661	Orange	Apopka	32712	GRACE POINTE CHURCH
586848	Marion	Ocala	34480	Grace Presbyterian Church
586147	Hernando	Spring Hill	34608	GRACE PRESBYTERIAN CHURCH
585077	Bradford	Lawtey	32058	Grace United Methodist Church
585158	Alachua	Gainesville	32607	Grace United Methodist Church
585115	Jackson	Graceville	32440	GRACEVILLE CIVIC CENTER
586548	Lake	Leesburg	34788	GraceWay Church
586116	Flagler	Palm Coast	32137	GRAND HAVEN VILLAGE CENTER
588732	Putnam	Interlachen	32148	Grandin Lake Shores Community Center
586541	Lake	Grand Island	32735	Grand Island Baptist Church
584963	Jackson	Grand Ridge	32442	GRAND RIDGE COMM CTR
588372	Pasco	New Port Richey	34653	Grand Valley Community Clubhouse
585327	Brevard	Grant	32949	Grant Community Center
586360	Hillsborough	Tampa	33619	Grant Park
587187	Miami-Dade	Miami	33125	Grapeland Park
586761	Leon	Tallahassee	32303	Gray Memorial United Methodist Church
585176	Alachua	Gainesville	32641	Greater Bethel A.M.E. Church
586377	Hillsborough	Tampa	33602	Greater Bethel Baptist Church
588074	Palm Beach	Boca Raton	33433	Greater BR Swim & Racquet Ctr
585967	Duval	Jacksonville	32208	Greater Harvest Christian Fellowship
587376	Miami-Dade	Miami	33167	Greater Miami Church of God
585803	Collier	Naples	34119	Greater Naples Fire Admin HQ
585802	Collier	Naples	34117	Greater Naples Fire Station # 71
585283	Brevard	Palm Bay	32907	Greater Palm Bay Senior Center
588457	Pinellas	Largo	33778	Greater Ridgecrest Branch YMCA
587941	Palm Beach	Greenacres	33463	Greenacres Community Center
588246	Palm Beach	Boynton Beach	33437	Green Cay Nature Center
585730	Clay	Grn Cv Spgs	32043	GREEN COVE SPGS CITY HALL
585723	Clay	Green Cove Springs	32043	GREEN COVE SPRINGS CHURCH OF CHRIST
587071	Miami-Dade	Miami	33175	Greenglade Elementary School
585467	Broward	Tamarac	33321	Green Haven Section 12
585142	Washington	Chipley	32428	Greenhead Fire Department
588589	Polk	Polk City	33868	Green Pond Baptist Church
586121	Gadsden	Greensboro	32330	Greensboro Town Hall
586122	Gadsden	Quincy	32352	Green Shade AME Church
587935	Palm Beach	Boynton Beach	33436	Greentree Villas
584983	Madison	Greenville	32331	Greenville American Legion
585096	Jackson	Greenwood	32443	GREENWOOD TOWN HALL
588678	Polk	Haines City	33844	Grenelefe Resort
586136	Gadsden	Gretna	32332	Gretna Town Hall
587396	Miami-Dade	North Miami Beach	33162	Greynolds Park Elementary School
585595	Broward	Cooper City	33330	Griffin Elementary School
587195	Miami-Dade	North Miami	33161	Griffing Senior Citizens Center
585109	Holmes	Bonifay	32425	Gritney
585775	Collier	Naples	34110	Grow Church
586829	Manatee	Bradenton	34209	G T Bray Park
587751	Orange	Orlando	32827	GUIDEWELL INNOVATION CENTER
588850	Santa Rosa	Gulf Breeze	32561	Gulf Breeze Community Center
586632	Lee	Cape Coral	33990	Gulf Coast Baptist Church
588502	Pinellas	Largo	33774	Gulf Coast Church
586576	Lee	Fort Myers	33966	Gulf Coast Church Of Christ
584877	Levy	Gulf Hammock	32639	Gulf Hammock Church of God
588317	Pasco	New Port Richey	34652	Gulf Harbors Civic Assn
588526	Pinellas	Gulfport	33707	Gulfport City Hall
588459	Pinellas	Gulfport	33707	Gulfport Neighborhood Center
588409	Pinellas	Gulfport	33707	Gulfport Recreation Center
587291	Miami-Dade	Cutler Bay	33189	Gulfstream Elementary School
588017	Palm Beach	Gulf Stream	33483	Gulf Stream Town Hall
585240	Bay	Panama City Beach	32413	Gulfview United Methodist Church
588561	Pinellas	Oldsmar	34677	Gull Aire Village Clubhouse
587868	Osceola	Kissimmee	34759	GYM AND AQUATIC CENTER
585415	Broward	Wilton Manors	33305	Hagen Park Community Center
587929	Palm Beach	Delray Beach	33446	Hagen Ranch Road Library
588081	Palm Beach	Boynton Beach	33437	Hagen Road Elementary
585165	Alachua	Gainesville	32608	Haile Meeting Hall
587439	Miami-Dade	North Miami	33161	Haitian Evangelical Baptist Church
589097	Volusia	Ormond Beach	32174	Halifax Plantation Clubhouse
588002	Palm Beach	Boca Raton	33433	Hammock Pointe Elementary
587303	Miami-Dade	Miami	33196	Hammocks Middle School
585878	Duval	Jacksonville	32256	Hampton Glen at Deerwood
585991	Duval	Jacksonville Beach	32250	Hampton Inn
584892	Okeechobee	Okeechobee	34972	HAMPTON INN
588677	Polk	Lake Wales	33859	Hampton Inn Lake Wales
589038	Seminole	Altamonte Springs	32714	Hampton Inn & Suites
588846	Santa Rosa	Navarre	32566	Harbor at Holley Assembly of God (Harbor Cafe)
585281	Brevard	Melbourne	32934	Harbor City Christian Church Hall
588907	Sarasota	North Port	34287	HARBOR COVE
585633	Broward	Fort Lauderdale	33316	Harbordale Elementary School
588452	Pinellas	Palm Harbor	34683	Harbor Hall
586568	Lake	Lady Lake	32159	Harbor Hills Clubhouse
585666	Charlotte	Port Charlotte	33980	Harold Ave Regional Park
587800	Orange	Orlando	32803	HARRY P LEU GARDENS
585331	Brevard	Mims	32754	Harry T. & Harriette V. Moore Cultural Complex
588134	Palm Beach	Jupiter	33458	Harvest Community Church
587645	Orange	Winter Garden	34787	HARVEST-KOREAN PRESBYTERIAN CHURCH OF ORLANDO
585941	Duval	Jacksonville	32210	Harvest Ministries
585851	Duval	Jacksonville	32211	Harvest Time Christian Fellowship
586814	Manatee	Lakewood Ranch	34202	Harvest United Methodist Church
588761	St. Johns	Hastings	32145	Hastings Branch Library
585139	Suwannee	Branford	32008	HATCH PARK COM. CENTER
585189	Alachua	Alachua	32615	Hathcock Community Center
586124	Gadsden	Havana	32333	Havana Public Library
588113	Palm Beach	Haverhill	33417	Haverhill Town Hall
585416	Broward	Davie	33331	Hawkes Bluff Elementary School
586512	Lake	Leesburg	34748	Hawthorne-Clubhouse
585205	Alachua	Hawthorne	32640	Hawthorne Community Center
587842	Osceola	St. Cloud	34771	HEARTCRY CHAPEL
587675	Orange	Orlando	32804	HEART OF FLORIDA UNITED WAY
588628	Polk	Lakeland	33803	Heatherwood Village
586866	Marion	Ocala	34473	Hebron Evangelical Church
584903	Bradford	Starke	32091	Heilbronn Springs Baptist Church
585915	Duval	Jacksonville	32207	Hendricks Avenue Baptist Church
586983	Miami-Dade	Miami	33147	Henry E.S. Reeves K-8 Center Primary Academy
587505	Miami-Dade	Miami	33167	Henry Reeves Elementary School
587088	Miami-Dade	Miami	33177	Herbert A. Ammons Middle School
585510	Broward	Lauderhill	33313	Herbert Sadkin Community Center
585557	Broward	Pompano Beach	33069	Herb Skolnick Community Center
588581	Polk	Lakeland	33811	Heritage Baptist Church
588734	St. Johns	St. Augustine	32086	Heritage Baptist Church
585786	Collier	Naples	34120	Heritage Bay Government Services Center
588156	Palm Beach	Greenacres	33463	Heritage Elementary
586552	Lake	Clermont	34711	Heritage Hills-Clubhouse
586413	Hillsborough	Tampa	33647	Heritage Isles Golf & Country Club
588349	Pasco	New Port Richey	34655	Heritage Lake Clubhouse
588749	St. Johns	St Augustine	32092	Heritage Landing Amenity Ctr
588364	Pasco	Land O' Lakes	34639	Heritage Park Building A
588320	Pasco	Land O'lakes	34639	Heritage Park Building B
588326	Pasco	Hudson	34667	Heritage Pines Country Club
588331	Pasco	Trinity	34655	Heritage Springs Clubhouse
588442	Pinellas	Clearwater	33761	Heritage United Methodist Church
588403	Pinellas	Largo	33774	Heritage Village
586172	Hernando	Hernando Beach	34607	HERNANDO BEACH MARINE GROUP
586146	Hernando	Brooksville	34613	HERNANDO COUNTY SHRINE CLUB
586165	Hernando	Brooksville	34613	HERNANDO COUNTY UTILITIES DEPT
585677	Citrus	Hernando	34442	HERNANDO UNITED METHODIST CH
586599	Lee	North Fort Myers	33917	Herons Glen Golf And Country Club
587141	Miami-Dade	Hialeah	33013	Hialeah Fire Station #2
587383	Miami-Dade	Hialeah	33014	Hialeah Fire Station #5
587013	Miami-Dade	Hialeah	33010	Hialeah Fire Station #6
587174	Miami-Dade	Hialeah	33016	Hialeah Fire Station #7
587496	Miami-Dade	Hialeah Gardens	33016	Hialeah Gardens City Hall
587373	Miami-Dade	Hialeah Gardens	33018	Hialeah Gardens Middle School
587214	Miami-Dade	Hialeah	33014	Hialeah-Miami Lakes Senior High School
587352	Miami-Dade	Hialeah	33013	Hialeah Middle School
585711	Clay	Fleming Island	32003	HIBERNIA BAPTIST CHURCH
585901	Duval	Jacksonville	32225	Hidden Hills Country Club
588215	Palm Beach	Lake Worth	33463	Hidden Oaks Elementary
586382	Hillsborough	Seffner	33584	Higginbotham Park
588508	Pinellas	Palm Harbor	34684	Highland Lakes Club
586561	Lake	Leesburg	34748	Highland Lakes-Clubhouse
587326	Miami-Dade	Miami	33180	Highland Oaks Middle School
588666	Polk	Lakeland	33813	Highland Park Church
585836	Duval	Jacksonville	32218	Highlands Baptist Church
588705	Polk	Lakeland	33813	Highlands Church of Christ
585874	Duval	Jacksonville	32218	Highlands Regional Library
586189	Highlands	Avon Park	33825	HIGHLANDS SHRINE CLUB
584941	Gulf	Port St. Joe	32456	Highland View Fire Station
587004	Miami-Dade	North Miami Beach	33181	Highland Village Community Center
584873	Okeechobee	Okeechobee	34972	HIGHPOINT/ABIDING HOPE WORSHIP CENTER
586150	Hernando	Brooksville	34613	HIGH POINT COMM. CLUB HOUSE
588421	Pinellas	Clearwater	33760	High Point Neighborhood Family Center
588092	Palm Beach	Delray Beach	33484	High Point Section II
585155	Alachua	High Springs	32643	High Springs Civic Center
585988	Duval	Jacksonville	32221	Hillcrest at Crystal Springs
585921	Duval	Jacksonville	32244	Hillcrest Baptist
586075	Escambia	Pensacola	32514	HILLCREST BAPTIST CHURCH
585395	Broward	Hollywood	33021	Hillcrest Recreation Building #2
586451	Hillsborough	Tampa	33617	Hillel Jewish Student Center
584969	Nassau	Hilliard	32046	Hilliard Community Center
585399	Broward	Hillsboro Beach	33062	Hillsboro Beach Town Hall
585549	Broward	Deerfield Beach	33441	Hillsboro Community Center
586164	Hernando	Brooksville	34602	HILLSIDE COMMUNITY BAPTIST CHURCH
589004	Seminole	Altamonte Springs	32701	Hilton Altamonte Springs
588639	Polk	Lakeland	33811	Hilton Garden Inn
588973	Seminole	Casselberry	32707	Hindu Society of Central Florida
587130	Miami-Dade	Miami	33135	Hispanic Branch Library
584992	Franklin	Apalachicola	32320	Historic Holy Family
585770	Collier	Naples	34113	Hitching Post Recreation Hall
588227	Palm Beach	Royal Palm Beach	33411	HL Johnson Elementary
586968	Martin	Hobe Sound	33455	Hobe Sound Library
586959	Martin	Jensen Beach	34957	Hoke Library
588289	Pasco	Holiday	34690	Holiday Free Methodist Church
585914	Duval	Jacksonville	32216	Holiday Hill Baptist Church
586006	Duval	Jacksonville	32258	Holiday Inn Bartram Park S
585855	Duval	Jacksonville	32258	Holiday Inn Bartram Park South
587780	Orange	Orlando	32826	HOLIDAY INN ORLANDO EAST
588304	Pasco	Holiday	34691	Holiday Lake Ests Civic Center
588909	Sarasota	North Port	34287	HOLIDAY PARK
585597	Broward	Fort Lauderdale	33304	Holiday Park Social Center
585500	Broward	Pembroke Pines	33025	Hollybrook Golf & Tennis Club
585543	Broward	Hollywood	33019	Hollywood Beach Community Center
585604	Broward	Hollywood	33021	Hollywood Gardens Condominium 1
585417	Broward	Hollywood	33021	Hollywood Hills Elementary School
585472	Broward	Hollywood	33021	Hollywood Hills High School
585466	Broward	Hollywood	33020	Hollywood Performing Arts Center
587844	Osceola	St. Cloud	34773	HOLOPAW COMMUNITY CENTER
586467	Indian River	Vero Beach	32963	Holy Cross Catholic Church Parish Hall
588618	Polk	Winter Haven	33884	Holy Cross Episcopal Church
586081	Escambia	Pensacola	32514	HOLY CROSS EPISCOPAL CHURCH
588987	Seminole	Lake Mary	32746	Holy Cross Lutheran Church
586158	Hernando	Spring Hill	34606	HOLY CROSS LUTHERAN CHURCH
585003	Franklin	Apalachicola	32320	Holy Family Center
586313	Hillsborough	Valrico	33594	Holy Innocents Episcopal Church
587591	Okaloosa	Niceville	32578	Holy Name of Jesus Catholic Church
588140	Palm Beach	Palm Springs	33461	Holy Redeemer Church
586029	Escambia	Pensacola	32507	HOLY SPIRIT CATHOLIC CHURCH
588228	Palm Beach	Juno Beach	33408	Holy Spirit Lutheran
585649	Charlotte	Port Charlotte	33980	Holy Trinity Greek Orthodox Church
588297	Pasco	Lutz	33558	Holy Trinity Lutheran Church
585655	Charlotte	Punta Gorda	33950	Homeowners of Alligator Park
587332	Miami-Dade	Homestead	33032	Homestead Air Reserve Park
587093	Miami-Dade	Homestead	33030	Homestead Community Center (William F. Dickinson)
587516	Miami-Dade	Homestead	33033	Homestead Mennonite Church
587243	Miami-Dade	Homestead	33034	Homestead Senior High School
585672	Citrus	Homosassa	34448	HOMOSASSA METHODIST CHURCH
585010	Gulf	Wewahitchka	32465	Honeyville Community Center
587647	Orange	Apopka	32703	HOPE BAPTIST CHURCH
586266	Hillsborough	Tampa	33625	Hope Christian School
586527	Lake	Groveland	34736	Hope International Church
588725	Putnam	Satsuma	32189	Hope Lutheran Church
586373	Hillsborough	Plant City	33563	Hope Lutheran Church
586923	Marion	Summerfield	34491	Hope Lutheran Church
589081	Sumter	The Villages	32159	HOPE LUTHERAN CHURCH
587827	Orange	Orlando	32817	HOPE LUTHERAN CHURCH
588522	Pinellas	Clearwater	33764	Hope Presbyterian Church
588574	Polk	Winter Haven	33884	Hope Presbyterian Church
585261	Brevard	Rockledge	32955	Hope United Church of Christ
586619	Lee	Cape Coral	33991	Hope United Methodist Church
586137	Gadsden	Havana	32333	Hopewell Church (China Hill)
589180	Volusia	Lake Helen	32744	Hopkins Hall
587011	Miami-Dade	El Portal	33150	Horace Mann Middle School
587643	Orange	Winter Garden	34787	HORIZON WEST COMMUNITY CHURCH
585556	Broward	Fort Lauderdale	33312	Hortt Park
585033	Liberty	Hosford	32334	HOSFORD
588904	Sarasota	Venice	34285	HOTEL VENEZIA
586334	Hillsborough	Tampa	33619	House of Prayer Eternal Ministries
584870	Union	Lake Butler	32054	HOUSING AUTHORITY IN LB
585097	Union	Lake Butler	32054	HOUSING AUTHORITY IN LB
585119	Gulf	Wewahitchka	32465	Howard Creek Fire Station
587379	Miami-Dade	Miami	33183	Howard D. McMillan Middle School
587242	Miami-Dade	Miami	33156	Howard Drive Elementary School
587956	Palm Beach	West Palm Beach	33401	Howard Park Community Center
586961	Martin	Hobe Sound	33455	HS Bible Church, Fine Arts Bldg
587522	Miami-Dade	Miami	33168	Hubert O. Sibley K-8 Academy
587258	Miami-Dade	Miami	33125	H U D
588305	Pasco	Hudson	34667	Hudson Library Community Room
587785	Orange	Orlando	32837	HUNTERS CREEK TOWN HALL
586302	Hillsborough	Tampa	33647	Hunter's Green Country Club
588254	Palm Beach	Boynton Beach	33436	Hunter's Run Country Club
588008	Palm Beach	Riviera Beach	33404	Hurst Chapel AME Church
588139	Palm Beach	Hypoluxo	33462	Hypoluxo Town Hall
586898	Marion	Reddick	32686	IBEW Local 222
587980	Palm Beach	West Palm Beach	33412	Ibis POA Admin Building
587031	Miami-Dade	Miami Gardens	33055	Iglesia Adventista De Carol City
586991	Miami-Dade	Miami	33130	Iglesia Adventista del Septimo Dia
587344	Miami-Dade	Miami	33165	Iglesia Adventista del Septimo Dia Westchester
587704	Orange	Orlando	32807	IGLESIA BAUTISTA EL CAMINO
587543	Miami-Dade	Miami	33170	Iglesia Bautista Manantial De Vida
587280	Miami-Dade	Miami	33157	Iglesia Bautista McCall
587485	Miami-Dade	Miami	33126	Iglesia Bautista Misionera Renovacion
587070	Miami-Dade	Miami	33145	Iglesia Bautista Resurreccion
587534	Miami-Dade	Homestead	33033	Iglesia Centro de Fe
584919	DeSoto	Arcadia	34266	Iglesia Cristana Patco De Fe Church
587350	Miami-Dade	Miami	33155	Iglesia Cristiana Comunidad de Esperanza
587018	Miami-Dade	Homestead	33032	Iglesia Cristiana El Buen Samaritano
585272	Brevard	Malabar	32950	Iglesia Cristiana Elohim Church
587630	Orange	Orlando	32810	IGLESIA CRISTIANA PENTECOSTAL DE ORLANDO
587979	Palm Beach	West Palm Beach	33415	Iglesia Cristo Mi Redentor
587362	Miami-Dade	Hialeah	33014	Iglesia Cristo Vive - Hermanos en Cristo
586265	Hillsborough	Tampa	33612	Iglesia De Cristo Misionera
587321	Miami-Dade	Miami	33165	Iglesia El Redentor - Asamblea de Dios
587814	Orange	Orlando	32812	IGLESIA METODISTA UNIDA CRISTO
587515	Miami-Dade	Miami	33177	Iglesia Metodista Unida Peace
586640	Lee	Fort Myers	33905	Iglesia Peniel
585937	Duval	Jacksonville	32246	Iglesia Pentecostal Presencia De Dios
587631	Orange	Orlando	32822	IGLESIA TABERNACULO DE LA FE
585196	Alachua	Gainesville	32601	Ignite Life Center
585280	Brevard	Indian Harbour Beach	32937	I H B City Hall Council Chambers
588199	Palm Beach	Boynton Beach	33426	Imagine Schools - Chancellor
586816	Manatee	Bradenton	34210	IMG Academy - Golf Club
587329	Miami-Dade	Hialeah	33012	Immaculate Conception Catholic Church
588869	Santa Rosa	Pace	32571	Immanuel Baptist Church
586969	Martin	Palm City	34990	Immanuel Lutheran Church
587544	Monroe	Tavernier	33070	Immanuel Lutheran Church Tavernie
585797	Collier	Immokalee	34142	Immokalee Community Park
586810	Manatee	Palmetto	34221	Imperial Lakes Clubhouse
587357	Miami-Dade	Miami	33182	Imperial Lakes Club House
585345	Broward	Fort Lauderdale	33308	Imperial Point Branch Library
586535	Lake	Tavares	32778	Imperial Terrace-Clubhouse East
588065	Palm Beach	Jupiter	33458	Independence Middle
586978	Miami-Dade	Miami Beach	33141	Indian Creek Fire Station #4
589071	Sumter	Bushnell	33513	INDIAN HILL BAPTIST CHURCH
584847	Glades	Moore Haven	33471	Indian Hills Fire Dept.
588633	Polk	Indian Lake Est	33855	Indian Lake Estates Clubhouse
587949	Palm Beach	Lake Worth	33463	Indian Pines Elementary
585276	Brevard	Melbourne	32940	Indian River Colony Club
589107	Volusia	Edgewater	32141	Indian River Elementary School
586480	Indian River	Indian River Shores	32963	Indian River Shores - Comm Center
588541	Pinellas	Indian Rocks Beach	33785	Indian Rocks Beach City Hall
588447	Pinellas	Indian Shores	33785	Indian Shores Town Hall
586759	Leon	Tallahassee	32309	Indian Springs Baptist Church
588221	Palm Beach	West Palm Beach	33412	Indian Trail Improvement Dist
587613	Okaloosa	Crestview	32536	INDYNE
584880	Levy	Inglis	34449	Inglis Community Center
587874	Osceola	Intercession City	33848	INTERCESSION CITY CIVIC CENTER
586475	Indian River	Vero Beach	32962	Intergenerational Recreation Center
588728	Putnam	Interlachen	32148	Interlachen Community Center
586070	Escambia	Cantonment	32533	INTERNATIONAL PAPER
585485	Broward	Fort Lauderdale	33311	Int'l Longshoremen's Local #1526
588252	Palm Beach	Boynton Beach	33435	Intracoastal Park Clubhouse
585694	Citrus	Inverness	34450	INVERNESS CITY HALL
587918	Palm Beach	West Palm Beach	33412	Ironhorse Country Club
585217	Alachua	Gainesville	32609	Ironwood Golf Course
586354	Hillsborough	Tampa	33610	Ironworkers Local No 397
587401	Miami-Dade	Miami	33177	Isaac A. Withers Enrichment Center
587547	Monroe	Islamorada	33036	Islamorada Branch Library
588423	Pinellas	Tierra Verde	33715	Island Chapel
585202	Alachua	Island Grove	32654	Island Grove Baptist Church
586378	Hillsborough	Tampa	33606	Islands Campus of South Tampa Fellowship Church
585808	Collier	Naples	34119	Island Walk Town Center
587744	Orange	Orlando	32805	ISLE OF CATALINA BEACH HOUSE
585834	Duval	Jacksonville	32224	Isle of Faith United Methodist Church
587465	Miami-Dade	Cutler Bay	33190	Isles at Bayshore Clubhouse
585813	Collier	Naples	34113	Isles of Capri Community Center
585645	Charlotte	Punta Gorda	33950	Isles Yacht Club
587644	Orange	Ocoee	34761	ISON SENIOR & VETERAN'S CENTER
585838	Duval	Jacksonville	32223	Italian American Club
587467	Miami-Dade	Miami	33127	iTech @ Thomas A. Edison Educational Center
584946	Wakulla	Crawfordville	32327	IVAN - COUNTY POLLING HOUSE
585636	Broward	Davie	33325	Ivanhoe Fire Station Community Room
586976	Miami-Dade	Miami	33186	Jack D. Gordon Elementary School Main Campus
586711	Leon	Tallahassee	32301	Jack L. McLean Center
587005	Miami-Dade	Miami	33128	Jack Orr Senior Center
586392	Hillsborough	Tampa	33610	Jackson Heights NFL YET Center
586719	Leon	Tallahassee	32301	Jackson Lodge
585904	Duval	Jacksonville Beach	32250	Jacksonville Beach City Hall
585835	Duval	Jacksonville	32217	Jacksonville Community Church
585992	Duval	Jacksonville	32224	Jacksonville Golf and Country Club
586736	Leon	Tallahassee	32310	Jacob Chapel Baptist Church
586700	Leon	Tallahassee	32305	Jake Gaither Community Center
588042	Palm Beach	Boynton Beach	33436	Jamaica Bay Clubhouse
586627	Lee	Fort Myers	33908	Jamaica Bay Veranda Clubhouse
587003	Miami-Dade	Hialeah	33010	James H. Bright Elementary School
585027	Nassau	Yulee	32097	James S. Page Governmental Complex
587281	Miami-Dade	Hialeah	33012	James S. Walker Community Center
587494	Miami-Dade	Miami	33185	Jane S. Roberts K-8 Center
584960	Hendry	Clewiston	33440	Janet B. Taylor Auditorium
586355	Hillsborough	Tampa	33611	Jan K Platt Regional Library
585523	Broward	Pompano Beach	33069	Jan Moran Collier City Library
585505	Broward	Lauderdale-by-the-sea	33308	Jarvis Hall Commission Room
584895	Hamilton	Jasper	32052	JASPER CIVIC CENTER
585149	Hamilton	Jasper	32052	JASPER COURTHOUSE
588852	Santa Rosa	Jay	32565	Jay-Linda Carden Community Center
588361	Pasco	Holiday	34691	J. Ben Harrill Recreation Center
588179	Palm Beach	Boca Raton	33431	JC Mitchell Elementary
588214	Palm Beach	West Palm Beach	33411	Jeaga Middle
588975	Seminole	Casselberry	32707	Jean Rhein Central Branch Library
587231	Miami-Dade	Miami	33142	Jefferson Reaves Sr. Park
585147	Hamilton	Jennings	32053	JENNINGS CITY HALL
584899	Taylor	Perry	32348	Jerkins Bldg
586152	Hernando	Brooksville	34601	JEROME BROWN COMMUNITY CENTER
588037	Palm Beach	Jupiter	33458	Jerry Thomas Elementary
587128	Miami-Dade	Miami	33150	Jesse J. McCrary Jr. Elementary School
588106	Palm Beach	Riviera Beach	33404	J F Kennedy Middle
586293	Hillsborough	Lutz	33558	J F Swartsel Masonic Lodge 251
587725	Orange	Ocoee	34761	JIM BEECH RECREATION CENTER
586303	Hillsborough	Tampa	33618	Jimmie B. Keel Regional Library
585384	Broward	Plantation	33317	Jim Ward Community Center
586288	Hillsborough	Tampa	33604	J L Young Apartments
585355	Broward	Deerfield Beach	33441	JM Family Teen Center
588906	Sarasota	North Port	34287	JOCKEY CLUB
586242	Hillsborough	Tampa	33611	Joe Abrahams Fitness and Wellness Center
587221	Miami-Dade	Miami	33175	Joe Hall Elementary School
587302	Miami-Dade	Hialeah	33015	Joella C. Good Elementary School
587388	Miami-Dade	Miami	33185	John A. Ferguson Senior High School
585135	Hendry	Clewiston	33440	John Boy Auditorium
587518	Miami-Dade	Homestead	33030	John D. Campbell Agricultural Center
587262	Miami-Dade	Hialeah	33012	John F. Kennedy Library
587256	Miami-Dade	North Miami Beach	33162	John F. Kennedy Middle School
587477	Miami-Dade	Hialeah	33012	John G. DuPuis Elementary School
587686	Orange	Apopka	32703	JOHN H BRIDGES COMMUNITY CENTER
584962	Suwannee	Live Oak	32064	JOHN H. HALE PARK
587731	Orange	Orlando	32805	JOHN H JACKSON COMMUNITY CENTER
587162	Miami-Dade	Doral	33178	John I. Smith K-8 Center - Main Campus
585449	Broward	Pompano Beach	33060	John Knox Village
589164	Volusia	Orange City	32763	John Knox Village Barker Center
585995	Duval	Jacksonville	32209	Johnnie W. Walker Community Center
586998	Miami-Dade	Hialeah	33010	Johnny L. Cotson Sr Park
584918	Taylor	Perry	32347	Johnson Strip Rd Voting
586717	Leon	Tallahassee	32301	John Wesley United Methodist Church
585018	Suwannee	Dowling Park	32060	JO KENNON PUBLIC LIBRARY
587503	Miami-Dade	Miami	33127	Jordan Grove Baptist Church
587163	Miami-Dade	Miami	33196	Jorge Mas Canosa Middle School
587228	Miami-Dade	Miami	33174	Jorge Mas Canosa Youth Center
587289	Miami-Dade	Miami	33127	Jose De Diego Middle School
587169	Miami-Dade	Hialeah	33016	Jose Marti MAST 6-12 Academy
587099	Miami-Dade	Miami	33130	Jose Marti Park Gymnasium
585913	Duval	Jacksonville	32208	Joseph Lee Community Center
586286	Hillsborough	Odessa	33556	Journey A Christian Church
588126	Palm Beach	Jupiter	33478	Journey Church of Jupiter
588122	Palm Beach	Lake Worth	33467	Journeys End
586924	Marion	Ocala	34476	Joy Evangelical Lutheran
587641	Orange	Orlando	32806	JOY METROPOLITAN COMMUNITY CHURCH
585411	Broward	Coral Springs	33071	J. P. Taravella High School
587110	Miami-Dade	Miami	33142	Juan P. Duarte Park
585973	Duval	Jacksonville	32256	Julington Baptist Church
588757	St. Johns	St. Johns	32259	Julington Creek Annex
588744	St. Johns	St. Johns	32259	Julington Creek Plantation Rec Ctr
588152	Palm Beach	Juno Beach	33408	Juno Beach Town Center
588148	Palm Beach	Jupiter	33458	Jupiter Branch Library
588158	Palm Beach	Jupiter	33458	Jupiter Community Center
588078	Palm Beach	Jupiter	33478	Jupiter Farms Elementary
587996	Palm Beach	Jupiter	33478	Jupiter Farms Park Community Pavilion
588085	Palm Beach	Jupiter	33458	Jupiter High
588180	Palm Beach	Jupiter Inlet Colony	33469	Jupiter Inlet Colony Admin Bldg
586957	Martin	Hobe Sound	33455	Jupiter Island Town Hall
588249	Palm Beach	Jupiter	33458	Jupiter Middle
585212	Alachua	Gainesville	32611	J. Wayne Reitz Union
585190	Alachua	Gainesville	32608	Kanapaha Presbyterian Church
586637	Lee	Fort Myers	33967	Karl J Drews Community Center
586235	Hillsborough	Tampa	33606	Kate Jackson Community Center
588635	Polk	Lakeland	33810	Kathleen Baptist Church
585405	Broward	Hollywood	33023	Kay Gaither Community Center
585118	Taylor	Perry	32348	Kelly Grade Voting House
588641	Polk	Lakeland	33803	Kelly Recreation Complex
587175	Miami-Dade	Miami	33142	Kelsey L. Pharr Elementary School
587890	Osceola	Kenansville	34739	KENANSVILLE COMMUNITY CENTER
587377	Miami-Dade	Miami	33176	Kendale Elementary School
587341	Miami-Dade	Miami	33196	Kendale Lakes Branch Library
587541	Miami-Dade	Miami	33183	Kendale Lakes Elementary School
587234	Miami-Dade	Miami	33176	Kendall Branch Library
587470	Miami-Dade	Miami	33186	Kendall Breeze HOA
587273	Miami-Dade	Miami	33176	Kendall Church of God
587148	Miami-Dade	Miami	33196	Kendall Square K-8 Center
587022	Miami-Dade	Pinecrest	33156	Kendall United Methodist Church
587191	Miami-Dade	Miami	33183	Kendall Village Center
588414	Pinellas	Kenneth City	33709	Kenneth City Community Hall
587235	Miami-Dade	Miami	33125	Kensington Park Elementary School
587129	Miami-Dade	Miami	33156	Kenwood K-8 Center
585830	Duval	Jacksonville	32224	Kernan Blvd Baptist Church
587035	Miami-Dade	Key Biscayne	33149	Key Biscayne Community Center
587572	Monroe	Key Colony Bch	33051	Key Colony Beach City Hall
587566	Monroe	Key Largo	33037	Key Largo Civic Club
587548	Monroe	Key West	33040	Keys Energy Utility Board Room
586375	Hillsborough	Odessa	33556	Keystone Bible Church
585717	Clay	Keystone Hts	32656	KEYSTONE HTS CITY HALL
587412	Miami-Dade	North Miami	33181	Keystone Park Community Center
586305	Hillsborough	Odessa	33556	Keystone Recreation Center
585084	Bradford	Keystone Heights	32656	Keystone United Methodist Church
586351	Hillsborough	Lithia	33547	Keysville Assembly Of God
586248	Hillsborough	Lithia	33547	Keysville Recreation Center
587563	Monroe	Key West	33040	Key West High School Auditorium
587552	Monroe	Key West	33040	Key West Public Works Depart. (former KW Citizen)
587119	Miami-Dade	Miami	33176	Killian Pines UTD Methodist Church
584904	Calhoun	Kinard	32449	Kinard Public Library
587847	Osceola	Kissimmee	34744	KINDRED HERITAGE PARK
587073	Miami-Dade	Miami	33157	Kingdom Covenant Church
586812	Manatee	Bradenton	34205	Kingdom Life Christian Church
587762	Orange	Maitland	32751	KING OF KINGS LUTHERAN CHURCH
586276	Hillsborough	Brandon	33511	Kings Avenue Baptist Church
587059	Miami-Dade	Miami	33143	Kings Creek Village Recreation Center
588918	Sarasota	Nokomis	34275	KINGS GATE CLUB INC
588479	Pinellas	Clearwater	33755	Kings Highway Recreation Center
585729	Clay	Starke	32091	KINGSLEY LAKE CIVIC CENTER
585381	Broward	Tamarac	33321	Kings Point Clubhouse
586262	Hillsborough	Sun City Center	33573	Kings Point Clubhouse
587972	Palm Beach	Delray Beach	33446	Kings Point Clubhouse
587919	Palm Beach	Delray Beach	33484	Kings Point Flanders
586495	Lake	Clermont	34711	Kings Ridge Clubhouse
587542	Miami-Dade	Miami	33126	Kinloch Park Middle School
585950	Duval	Jacksonville	32244	Kirkwood Presbyterian Church
587843	Osceola	Kissimmee	34741	KISSIMMEE CIVIC CENTER
587871	Osceola	Kissimmee	34741	KISSIMMEE FIRE STATION 14
588669	Polk	Davenport	33837	Kissimmee South Resort
585254	Brevard	Merritt Island	32952	Kiwanis Island Park Gym
586211	Hillsborough	Plant City	33565	Knights Baptist Church
586580	Lee	Lehigh Acres	33936	Knights Of Columbus
585675	Citrus	Lecanto	34461	KNIGHTS OF COLUMBUS
587859	Osceola	Kissimmee	34744	KNIGHTS OF COLUMBUS
588927	Sarasota	Sarasota	34232	KNIGHTS OF COLUMBUS
588360	Pasco	San Antonio	33576	Knights of Columbus Hall San Antonio
587366	Miami-Dade	Miami	33133	Knights of Columbus Miami Council 1726
585091	Okeechobee	Okeechobee	34974	KOA
588671	Polk	Auburndale	33823	K-Ville Community Center
585639	Charlotte	Rotonda West	33947	L.A. Ainger Middle School
585051	Hendry	Labelle	33935	LaBelle Civic Center
588937	Sarasota	North Port	34287	LA CASA MOBILE HOME PARK
587166	Miami-Dade	Miami	33126	La Catedral Del Pueblo
585200	Alachua	Lacrosse	32658	LaCrosse Town Hall
587931	Palm Beach	Lake Worth	33467	Lacuna HOA Clubhouse
586519	Lake	Lady Lake	32159	Lady Lake Community Building
587167	Miami-Dade	Hialeah	33012	La Esperanza Senior Housing Project
586740	Leon	Tallahassee	32308	Lafayette Presbyterian Church
586565	Lake	Eustis	32736	La Gracia Baptist Church
586491	Lake	Lady Lake	32159	La Hacienda Center (Carmen Miranda Rm.)
588660	Polk	Lake Alfred	33850	Lake Alfred City Hall
588659	Polk	Auburndale	33823	Lake Ariana Clubhouse
585738	Clay	Grn Cv Spgs	32043	LAKE ASBURY COMMUNITY CNTR
588571	Polk	Lake Wales	33859	Lake Ashton Clubhouse
586410	Hillsborough	Lutz	33558	Lake Carlton Arms Apartments
587975	Palm Beach	Lake Clarke Shores	33406	Lake Clarke Shores Town Hall
586497	Lake	Tavares	32778	Lake County Ag. Center
588605	Polk	Lakeland	33805	Lake Crago Recreation Complex
586208	Hillsborough	Lutz	33559	Lake Forest HOA Inc Clubhouse
586572	Lake	Tavares	32778	Lake Frances Estates-Clubhouse
586522	Lake	Leesburg	34788	Lake Griffin Harbor-Clubhouse
588676	Polk	Lake Hamilton	33851	Lake Hamilton Woman's Club
588345	Pasco	Lutz	33549	Lake Heron Clubhouse
586695	Leon	Tallahasse	32303	Lake Jackson Community Center
586607	Lee	Cape Coral	33991	Lake Kennedy Senior Center
588625	Polk	Lakeland	33805	Lakeland 7th Day Adventist Church
588629	Polk	Lakeland	33813	Lakeland Elks Lodge #1291
586327	Hillsborough	Tampa	33618	Lake Magdalene United Methodist Church
588990	Seminole	Lake Mary	32746	Lake Mary City Hall
589007	Seminole	Lake Mary	32746	Lake Mary Community Center
588968	Seminole	Lake Mary	32746	Lake Mary Events Center
588604	Polk	Lakeland	33801	Lake Mirror Center
584978	Liberty	Bristol	32321	LAKE MYSTIC
587770	Orange	Orlando	32832	LAKE NONA HIGH SCHOOL
587525	Miami-Dade	Miami	33185	Lake of the Meadows Recreation Center
588979	Seminole	Fern Park	32730	Lake of the Woods HOA
589069	Sumter	Lake Panasoffkee	33538	LAKE PANASOFFKEE REC CTR
587499	Miami-Dade	Miami	33179	Lake Park Condominium
587992	Palm Beach	Lake Park	33403	Lake Park Town Hall
584965	Glades	Moore Haven	33471	Lakeport Community Center
586513	Lake	Leesburg	34748	Lake Port Square-Town Hall
588203	Palm Beach	Boca Raton	33433	Lakes at Boca Rio Clubhouse
586551	Lake	Leesburg	34788	Lakes at Leesburg-Clubhouse
588429	Pinellas	Seminole	33772	Lake Seminole Presbyterian Church
588645	Polk	Lakeshore	33854	Lakeshore Club Villas
588136	Palm Beach	Lake Park	33403	Lake Shore Park Pavilion
585128	Taylor	Perry	32348	Lakeside Bapt Church
585734	Clay	Orange Park	32073	LAKESIDE CHURCH OF CHRIST
588902	Sarasota	North Port	34289	LAKESIDE PLANTATION
587973	Palm Beach	West Palm Beach	33405	Lakeside Presbyterian Church
588259	Palm Beach	Lake Worth Beach	33461	Lakeside UM Church
588047	Palm Beach	Boca Raton	33433	Lakes Of Boca Barwood
588247	Palm Beach	Delray Beach	33484	Lakes of Delray Clubhouse
586484	Lake	Mt. Dora	32757	Lakes of Mt. Dora
588145	Palm Beach	Lake Worth	33467	Lakes of Sherbrooke
587104	Miami-Dade	Miami	33055	Lake Stevens Middle School
586925	Marion	Dunnellon	34432	Lake Tropicana Civic Assoc. Clubhse.
588095	Palm Beach	Delray Beach	33444	Lakeview Baptist Church
586074	Escambia	Pensacola	32505	LAKEVIEW BAPTIST CHURCH
588434	Pinellas	Tarpon Springs	34688	Lakeview Community Church
587064	Miami-Dade	Miami	33167	Lakeview Elementary School
588412	Pinellas	St Petersburg	33705	Lakeview Presbyterian Church
586570	Lake	Altoona	32702	Lakeview Terrace Wellness Center
588507	Pinellas	St. Petersburg	33705	Lake Vista Recreation Center
588598	Polk	Lake Wales	33853	Lake Wales Administration Building
588615	Polk	Lake Wales	33853	Lake Wales Masonic Lodge
586837	Marion	Ocklawaha	32179	Lake Weir Chamber of Commerce
586929	Marion	Weirsdale	32195	Lake Weir Kiwanis Club Bldg.
587682	Orange	Winter Garden	34787	LAKE WHITNEY ELEMENTARY SCHOOL
588316	Pasco	Hudson	34669	Lakewood Church of God
586828	Manatee	Lakewood Ranch	34202	Lakewood Ranch Town Hall
588483	Pinellas	St Petersburg	33705	Lakewood United Methodist Church
586466	Indian River	Vero Beach	32966	Lakewood Village Club House
588054	Palm Beach	Delray Beach	33484	Lake Worth Drainage District
587974	Palm Beach	Lake Worth Beach	33460	Lake Worth Spanish SDA Church
587345	Miami-Dade	Miami	33185	Lamar Louise Curry Middle School
585009	Jefferson	Lamont	32336	Lamont Baptist Church
588393	Pasco	Lutz	33549	Land O' Lakes Bible Chapel
588370	Pasco	Land O' Lakes	34639	Land O' Lakes Recreation Complex
586948	Martin	Jensen Beach	34957	Langford Park
588048	Palm Beach	Lantana	33462	Lantana Recreation Center
587997	Palm Beach	Lake Worth	33462	Lantana Road Branch Library
588225	Palm Beach	Lantana	33462	Lantana Town Hall
588549	Pinellas	St Petersburg	33710	LAO Conference of Churches
585241	Bay	Panama City Beach	32408	LaQuinta Inn
588431	Pinellas	Largo	33771	Largo Community Center
585357	Broward	Lauderhill	33311	Larkdale Elementary School
588005	Palm Beach	Delray Beach	33484	Las Verdes Clubhouse
585427	Broward	Lauderdale Lakes	33311	Lauderdale Lakes Educational and Cultural Center
585359	Broward	Lauderdale Lakes	33309	Lauderdale Lakes Middle School
585527	Broward	Tamarac	33319	Lauderdale Lakes Moose Lodge #2267
585530	Broward	Lauderdale Lakes	33319	Lauderdale Lakes Multi-Purp. Center
585445	Broward	Fort Lauderdale	33311	Lauderdale Manors Community Center
585456	Broward	Plantation	33322	Lauderdale West Recreation Facility
585454	Broward	Lauderhill	33313	Lauderhill City Hall
585575	Broward	Lauderhill	33313	Lauderhill Paul Turner Elementary School
589058	Sumter	The Villages	32162	LAUREL MANOR REC CTR
588908	Sarasota	Nokomis	34275	LAUREL PARK COMMUNITY CENTER
586715	Leon	Tallahassee	32304	Lawrence-Gregory Community Center
587408	Miami-Dade	Hialeah	33015	Lawton Chiles Middle School
588955	Sarasota	North Port	34287	LAZY RIVER VILLAGE
584998	Union	Lake Butler	32054	LB COMM CNTR
587954	Palm Beach	Greenacres	33463	LC Swain Middle
588477	Pinellas	St Petersburg	33714	Lealman and Asian Neighborhood Family Center
585372	Broward	Deerfield Beach	33442	Le Club Century Village
584885	Madison	Lee	32059	Lee City Hall
586613	Lee	North Fort Myers	33917	Lee Civic Center Davidson House
586575	Lee	Bonita Springs	34134	Lee County Elections Branch Office
586602	Lee	Cape Coral	33990	Lee County Elections Cape Branch Office
586656	Lee	Fort Myers	33907	Lee County Elections Center
586560	Lake	Leesburg	34748	Leesburg Masonic Lodge Bldg. #58
586539	Lake	Leesburg	34748	Leesburg Recreation Center
586549	Lake	Leesburg	34748	Leesburg Venetian Center
587277	Miami-Dade	Miami	33176	Leewood K-8 Center
586827	Manatee	Bradenton	34207	Legacy Baptist Church
588682	Polk	Auburndale	33823	Legacy Community Church
586059	Escambia	Pensacola	32503	LEGACY HOUSE
587632	Orange	Orlando	32825	LEGACY MIDDLE SCHOOL
586485	Lake	Leesburg	34748	Legacy of Leesburg-Clock Tower Hall
585177	Alachua	Alachua	32615	Legacy Park Multipurpose Center
585821	Duval	Jacksonville	32208	Legends Community Center
586544	Lake	Clermont	34711	Legends Community Clubhouse
586043	Escambia	Pensacola	32502	LEGION FIELD RESOURCE CENTER
587172	Miami-Dade	Miami	33138	Legion Memorial Park
586582	Lee	Lehigh Acres	33936	Lehigh Acres Mun Svcs Imp District
588071	Palm Beach	Boynton Beach	33426	Leisureville #1 Clubhouse
588114	Palm Beach	Boynton Beach	33426	Leisureville #3 Clubhouse
585457	Broward	Pompano Beach	33064	Leisureville Clubhouse #1
588707	Polk	Auburndale	33823	Lena Vista United Methodist Church
586753	Leon	Tallahassee	32308	Leon County Public Works Department
586011	Duval	Jacksonville	32220	Leroy D. Clemons Senior Center
585014	Levy	Bronson	32621	Levy County Ag Extension Office
588253	Palm Beach	Belle Glade	33430	Lewis Evans Resource Center
588298	Pasco	Wesley Chapel	33544	Lexington Oaks Community Center
586498	Lake	Clermont	34711	Liberty Baptist Church
586030	Escambia	Pensacola	32506	LIBERTY CHURCH
585373	Broward	Margate	33063	Liberty Elementary School
588187	Palm Beach	Greenacres	33413	Liberty Park Elementary
587268	Miami-Dade	Miami	33147	Liberty Square Community Center
585815	Collier	Naples	34109	Library Headquarters
587695	Orange	Windermere	34786	LIFEBRIDGE CHURCH
588368	Pasco	Wesley Chapel	33544	Life Church
589116	Volusia	Deltona	32738	Life Fellowship Church of the Nazarene
589031	Seminole	Longwood	32750	LifePoint Christian Church
587597	Okaloosa	Crestview	32536	Lifepoint Church
589167	Volusia	Deland	32720	LifePoint Community Church
588885	Sarasota	Venice	34285	LIFT CHURCH
585140	Nassau	Fernandina Beach	32034	Lighthouse Bible Baptist Church
586683	Leon	Tallahassee	32309	Lighthouse Childrens Home
585285	Brevard	Merritt Island	32952	Lighthouse Christian Church
588895	Sarasota	Sarasota	34234	LIGHT OF THE WORLD INTERNATIONAL CHURCH
586908	Marion	Ocala	34475	Lillian Bryant Center
588244	Palm Beach	Jupiter	33458	Limestone Creek Elementary
586448	Hillsborough	Brandon	33510	Limona Village Chapel United Methodist Church
586764	Leon	Tallahassee	32310	Lincoln Neighborhood Center
588586	Polk	Eagle Lake	33839	Linda Weldon Activity Center
588201	Palm Beach	Riviera Beach	33404	Lindsey Davis Senior Comm Ctr
587858	Osceola	Kissimmee	34744	LIONS CLUB
588460	Pinellas	Gulfport	33711	Lions Club of Gulfport Florida
587459	Miami-Dade	Miami	33135	Little Havana Housing Project #1
587581	Okaloosa	Crestview	32536	Live Oak Baptist Church
584881	Suwannee	Live Oak	32064	LIVE OAK CHRISTIAN CHURCH
585089	Suwannee	Live Oak	32060	LIVE OAK CHURCH OF GOD
589026	Seminole	Oviedo	32765	Live Oak Community Center
586123	Gadsden	Quincy	32351	Livestock Pavilion
586215	Hillsborough	Tampa	33617	Living Faith Assembly of God
589136	Volusia	Daytona Beach	32117	Living Faith World Ministries
588710	Putnam	Florahome	32140	Living Hope Family Worship Center
587860	Osceola	Kissimmee	34746	LIVING WATER FELLOWSHIP
586862	Marion	Ocala	34475	Living Waters Worship Center
586863	Marion	Dunnellon	34432	Living Water Wesleyan Church
588332	Pasco	New Port Richey	34653	Living Word Church
584882	Jefferson	Lloyd	32337	Lloyd Baptist Church
585614	Broward	Oakland Park	33309	Lloyd Estates Elementary School
584957	Jefferson	Monticello	32344	Lloyd Woman's Club
587819	Orange	Orlando	32810	LOCKHART BAPTIST CHURCH
588050	Palm Beach	Juno Beach	33408	Loggerhead Marinelife Center
588216	Palm Beach	Boca Raton	33428	Loggers Run Middle
586319	Hillsborough	Plant City	33567	Lone Oak Baptist Church
586787	Manatee	Longboat Key	34228	Longboat Island Chapel
585840	Duval	Jacksonville	32206	Long Branch Senior Center
588398	Pasco	Lutz	33558	Long Lake Reserve CDD Amenity Center
589032	Seminole	Longwood	32750	Longwood Community Building
588925	Sarasota	Sarasota	34243	LONGWOOD RECREATION CENTER
587279	Miami-Dade	Miami	33142	Lorah Park Elementary School
586291	Hillsborough	Tampa	33607	Loretta Ingraham Community Center
586201	Highlands	Lorida	33857	LORIDA COMMUNITY BLDG
588194	Palm Beach	Loxahatchee	33470	Loxahatchee Groves Elementary
588192	Palm Beach	Jupiter	33477	Loxahatchee River Center
587969	Palm Beach	Lake Worth	33467	Lucerne Pointe Clubhouse
585150	Columbia	Lulu	32061	LULU COMMUNITY CENTER
584981	Suwannee	Live Oak	32060	LURAVILLE COM. CENTER
588402	Pinellas	St Petersburg	33703	Lutheran Church of the Cross
588970	Seminole	Sanford	32773	Lutheran Church of the Redeemer
588520	Pinellas	Palm Harbor	34685	Lutheran Church of the Resurrection
588981	Seminole	Oviedo	32765	Lutheran Haven Fellowship Hall
585473	Broward	Hollywood	33021	Luther Memorial Church
586249	Hillsborough	Lutz	33548	Lutz Civic Center
586833	Manatee	Lakewood Ranch	34202	LWR-Sarasota Elks Lodge #2855
585219	Bay	Panama City Beach	32407	Lyndell Conference Center
585221	Bay	Lynn Haven	32444	Lynn Haven Senior Center
585234	Bay	Panama City	32405	Lynn Haven United Methodist Church
584861	Baker	Macclenny	32063	Macedonia
585152	Jefferson	Monticello	32344	Macedonia Baptist Church
586093	Escambia	Pensacola	32503	MACEDONIA BAPTIST CHURCH
584977	Bradford	Lawtey	32058	Macedonia Freewill Bapt Church
585783	Collier	Marco Island	34145	Mackle Park
585646	Charlotte	Englewood	34224	Mac V. Horton West County Annex
587971	Palm Beach	Lantana	33462	Maddock Park
588534	Pinellas	Madeira Beach	33708	Madeira Beach Municipal Building
587479	Miami-Dade	Miami	33179	Madie Ives K-8 Preparatory Academy
585023	Madison	Madison	32340	Madison County Ag. Extension Office
584874	Madison	Madison	32340	Madison County Annex Board Room
588034	Palm Beach	Royal Palm Beach	33411	Madison Green Country Club
587171	Miami-Dade	Miami	33147	Madison Middle School
584884	Bradford	Starke	32091	Madison Street Baptist Church
585495	Broward	Tamarac	33319	Mainlands 1 & 2 Clubhouse
588554	Pinellas	Pinellas Park	33782	Mainlands of Tamarac - Unit #4
588014	Palm Beach	West Palm Beach	33406	Main Library
589019	Seminole	Altamonte Springs	32714	Maison & Jardin Event Center
585227	Bay	Panama City Beach	32407	Majestic Beach Resort (Majestic II Conf. Room)
587841	Orange	Orlando	32811	MAJESTIC LIFE MINISTRIES
587230	Miami-Dade	Miami	33142	Malcolm Ross Senior Center
584905	Jackson	Malone	32445	MALONE JOY CLUB
588009	Palm Beach	Manalapan	33462	Manalapan Town Hall
588266	Palm Beach	Lake Worth	33467	Manatee Elementary
586784	Manatee	Bradenton	34205	Manatee River Garden Center
585927	Duval	Jacksonville	32257	Mandarin Branch Library
585819	Duval	Jacksonville	32258	Mandarin Church of Christ
586005	Duval	Jacksonville	32257	Mandarin Moose Lodge #42
585989	Duval	Jacksonville	32223	Mandarin Presbyterian Church
585883	Duval	Jacksonville	32257	Mandarin Senior Center
588055	Palm Beach	Mangonia Park	33407	Mangonia Park Town Hall
586336	Hillsborough	Seffner	33584	Mango Recreation Center
586421	Hillsborough	Tampa	33611	Manhattan Avenue Church of Christ
586938	Marion	Ocala	34473	Maranatha Baptist Church
588961	Seminole	Apopka	32703	Maranatha SDA Church
586196	Highlands	Sebring	33870	MARANATHA VILLAGE
587562	Monroe	Marathon	33050	Marathon City Hall
585788	Collier	Marco Island	34145	Marco Lutheran Church
585801	Collier	Marco Island	34145	Marco Presbyterian Church
586048	Escambia	Pensacola	32505	MARCUS POINTE BAPTIST CHURCH
585537	Broward	Margate	33063	Margate Middle School
586504	Lake	Howey-in-the-hills	34737	Marianne Beck Library - Comm. Rm.
586087	Escambia	Pensacola	32505	MARIE K. YOUNG WEDGEWOOD COMM CNTR
588015	Palm Beach	Boca Raton	33431	Marine Safety Headquarters
586878	Marion	Ocala	34476	Marion Landing Lifestyle Ctr.
586895	Marion	Ocala	34473	Marion Oaks Assembly of God
586867	Marion	Ocala	34473	Marion Oaks Comm Ctr, Aud.
586943	Marion	Ocala	34473	Marion Oaks Comm Ctr., Bldg. B
586236	Hillsborough	Tampa	33606	Marjorie Park Marina
587114	Miami-Dade	North Miami Beach	33162	Marjorie/William McDonald Center
587154	Miami-Dade	Miami	33184	Marjory Stoneman Douglas Elementary School
589042	Seminole	Lake Mary	32746	Markham Woods Presbyterian Church
589033	Seminole	Longwood	32779	Markham Woods SDA Church
587788	Orange	Orlando	32803	MARKS STREET SENIOR RECREATION COMPLEX
587540	Miami-Dade	Palmetto Bay	33157	Marlin Moore Post #133
588108	Palm Beach	Jupiter	33458	Martinique Clubhouse
585519	Broward	Hollywood	33020	Martin Luther King Jr. Community Center
586525	Lake	Mt. Dora	32757	Martin Luther King Memorial Bldg.
587549	Monroe	Key West	33040	Martin Luther King Pool
587327	Miami-Dade	Miami Lakes	33016	Mary Collins Community Center
587852	Osceola	Kissimmee	34744	MARYDIA COMMUNITY CENTER
585917	Duval	Jacksonville	32210	Mary Lena Gibbs Community Center
588265	Palm Beach	Riviera Beach	33404	Mary McLeod Bethune Elementary
585850	Duval	Jacksonville	32206	Mary Singleton Senior Citizen Center
586685	Leon	Tallahassee	32303	Marzuq Shrine Center
586515	Lake	Mascotte	34753	Mascotte Community Building
585046	Columbia	Lake City	32025	MASON CITY COMMUNITY CENTER
588385	Pasco	New Port Richey	34653	Masonic Lodge F.& A.M. #146
588698	Polk	Lakeland	33803	Mas Verde
586114	Flagler	Palm Coast	32137	MATANZAS HIGH SCHOOL
586254	Hillsborough	Tampa	33626	Maureen B. Gauzza Public Library
585807	Collier	Naples	34117	Max A Hasse Jr Community Park
585337	Brevard	West Melbourne	32904	Max K. Rodes Park Comm Center
585875	Duval	Maxville	32234	Maxville Community Center
586032	Escambia	Pensacola	32505	MAYFAIR COMMUNITY CENTER
585793	Collier	Naples	34112	Mayflower Congregation UCC
585105	Lafayette	Mayo	32066	Mayo Community Center - District 2
584926	Lafayette	Mayo	32066	Mayo Community Center - District 5
585920	Duval	Atlantic Beach	32233	Mayport Community Center
585120	Suwannee	Mcalpin	32062	MCALPIN COM. CENTER
585439	Broward	Hollywood	33024	McArthur High School
588025	Palm Beach	Palm Beach Gardens	33418	MCC Of the Palm Beaches
588888	Sarasota	Sarasota	34236	MCCOWN TOWERS
585581	Broward	Davie	33317	McFatter Technical School
585939	Duval	Jacksonville	32244	McGirts Creek Regional Park
586588	Lee	Fort Myers	33966	McGregor Baptist Church
589089	Volusia	De Leon Springs	32130	McInnis Elementary School
586883	Marion	Mcintosh	32664	McIntosh Civic Center
586875	Marion	Mcintosh	32664	McIntosh United Methodist Church
585319	Brevard	Rockledge	32955	McLarty Recreation Hall
588872	Santa Rosa	Milton	32570	McLellan- Blackwater Baptist Church
587080	Miami-Dade	Miami	33183	McMillan Park
585451	Broward	Pompano Beach	33069	McNair Community Center
585502	Broward	Hollywood	33020	McNicol Middle School
585214	Alachua	Gainesville	32602	McPherson Recreation Center
585151	DeSoto	Arcadia	34266	McSwain Building
585558	Broward	West Park	33023	McTyre Park Community Center
587052	Miami-Dade	Miami	33175	MDC Herbert S. Saffir Permit & Inspection Ctr
585361	Broward	Fort Lauderdale	33317	Meadowbrook Elementary School
587299	Miami-Dade	Hialeah	33012	Meadowlane Elementary School
585641	Charlotte	Port Charlotte	33948	Meadow Park Elementary School
588325	Pasco	Wesley Chapel	33543	Meadow Pointe Clubhouse
588310	Pasco	Wesley Chapel	33543	Meadow Pointe II Clubhouse
588387	Pasco	Wesley Chapel	33543	Meadow Pointe III Clubhouse
588382	Pasco	Wesley Chapel	33543	Meadow Pointe IV Clubhouse
588957	Sarasota	Sarasota	34235	MEADOWS COMMUNITY CENTER
585591	Broward	Deerfield Beach	33442	Meadows of Crystal Lake Clubhouse
587727	Orange	Orlando	32824	MEADOW WOODS RECREATION CENTER
585144	Wakulla	Crawfordville	32327	MEDART - PUBLIC LIBRARY
587010	Miami-Dade	Medley	33166	Medley Town Hall
588620	Polk	Lakeland	33811	Medulla Community Center
587298	Miami-Dade	Miami	33142	Melrose Elementary School
585344	Broward	Fort Lauderdale	33312	Melrose Park Community Center
585168	Alachua	Melrose	32666	Melrose United Methodist Church
585482	Broward	Lauderdale Lakes	33319	Merrell United Methodist Church
585222	Bay	Panama City	32405	Messiah Lutheran Church
586617	Lee	Cape Coral	33909	Messiah Lutheran Church
588967	Seminole	Casselberry	32707	Metro Life Church
587710	Orange	Orlando	32835	METROWEST GOLF CLUB
587391	Miami-Dade	Miami	33185	Miami Arts Studio 6-12 @ Zelda Glazer
587283	Miami-Dade	Miami Beach	33139	Miami Beach Botanical Garden
587257	Miami-Dade	Miami Beach	33139	Miami Beach City Hall
587338	Miami-Dade	Miami Beach	33140	Miami Beach Fire Station #3
587026	Miami-Dade	Miami Beach	33139	Miami Beach Police Athletic League
587135	Miami-Dade	Miami Beach	33139	Miami Beach Police Department
587420	Miami-Dade	Miami Beach	33139	Miami Beach Regional Library
587386	Miami-Dade	Miami Beach	33139	Miami Beach Senior High School
587502	Miami-Dade	Miami Gardens	33056	Miami Carol City Senior High
587528	Miami-Dade	Miami	33172	Miami Christian School
587170	Miami-Dade	Miami Beach	33139	Miami City Ballet
587145	Miami-Dade	Miami	33133	Miami City Hall
587339	Miami-Dade	Homestead	33030	Miami Dade College - Homestead Campus
587015	Miami-Dade	Miami	33176	Miami Dade College - Kendall Campus
587358	Miami-Dade	Miami	33135	Miami-Dade County Auditorium
587296	Miami-Dade	Homestead	33035	Miami-Dade County East Homestead Fire Station #65
587276	Miami-Dade	Miami	33165	Miami-Dade County Fair & Expo Center
586982	Miami-Dade	Opa Locka	33055	Miami-Dade County Fire Rescue Honey Hill Station
587199	Miami-Dade	North Miami	33168	Miami-Dade County Fire Station #19
587278	Miami-Dade	North Miami	33161	Miami-Dade County Fire Station #20
587438	Miami-Dade	Miami	33196	Miami-Dade County Fire Station #36
587180	Miami-Dade	Miami	33175	Miami-Dade County Fire Station #37
587346	Miami-Dade	Hialeah	33015	Miami-Dade County Fire Station #44
587443	Miami-Dade	Miami	33193	Miami-Dade County Fire Station #56
586981	Miami-Dade	Miami	33194	Miami-Dade County Fire Station #61
587107	Miami-Dade	Doral	33178	Miami-Dade County Fire Station #69
587524	Miami-Dade	Miami	33133	Miami-Dade Water & Sewer Authority
587469	Miami-Dade	Miami	33173	Miami Elk's Lodge #948
587249	Miami-Dade	Miami	33125	Miami Fire Fighter Benevolent Association
586980	Miami-Dade	Miami	33136	Miami Fire Station #2
587330	Miami-Dade	Miami	33135	Miami Fire Station #7
587488	Miami-Dade	Miami	33187	Miami First Church of the Nazarene
587348	Miami-Dade	Miami Gardens	33055	Miami Gardens Elementary School
587301	Miami-Dade	Miami	33177	Miami Heights Elementary School
587483	Miami-Dade	Miami Lakes	33014	Miami Lakes Branch Library
587139	Miami-Dade	Miami Lakes	33014	Miami Lakes K-8 Center
587229	Miami-Dade	Miami Lakes	33014	Miami Lakes Middle School
587160	Miami-Dade	Miami Lakes	33014	Miami Lakes United Methodist Church
587309	Miami-Dade	Miami Gardens	33169	Miami Norland Senior High School
587140	Miami-Dade	Pinecrest	33156	Miami Palmetto Senior High Community School
587044	Miami-Dade	Miami	33125	Miami Police Benevolent Association
587473	Miami-Dade	Miami Shores	33138	Miami Shores Christian Church
586990	Miami-Dade	Miami Shores	33138	Miami Shores Elementary School
587124	Miami-Dade	Miami	33157	Miami Southridge Senior High School
587506	Miami-Dade	Miami Springs	33166	Miami Springs Community Center
587197	Miami-Dade	Miami Springs	33166	Miami Springs Golf & Country Club
587425	Miami-Dade	Miami Springs	33166	Miami Springs Middle School
587244	Miami-Dade	Miami	33183	Miami Sunset Sr. High School
586984	Miami-Dade	North Miami	33168	Miami Union Academy
587384	Miami-Dade	Miami	33186	Miami Vineyard Community Church
585203	Alachua	Micanopy	32667	Micanopy Town Hall
586766	Leon	Tallahassee	32309	Miccosukee Community Center
586987	Miami-Dade	Miami	33183	Miccosukee Golf & Country Club
589145	Volusia	Ormond Beach	32176	Michael Crotty Bicentennial Park
585652	Charlotte	Port Charlotte	33952	Mid-County Regional Library
588237	Palm Beach	Lake Worth	33461	Mid County Senior Center
585739	Clay	Middleburg	32068	MIDDLEBURG CIVIC CENTER
586492	Lake	Leesburg	34788	Mid Florida Lakes-Clubhouse
587772	Orange	Orlando	32809	MID FLORIDA TECH
585926	Duval	Jacksonville	32221	Midnight Cry Ministries
589105	Volusia	Daytona Beach	32114	Midtown Cultural & Education Center
588842	Santa Rosa	Gulf Breeze	32563	Midway Baptist Church
586806	Manatee	Sarasota	34243	Midway Church of Christ
589036	Seminole	Sanford	32771	Midway Community Center
586569	Lake	Lisbon	34788	Midway Manor Office Bldg.
587390	Miami-Dade	Miami	33174	Mildred & Claude Pepper Senior Center
587466	Miami-Dade	Miami	33142	Mildred & Claude Pepper Towers
585408	Broward	Tamarac	33321	Millennium Collegiate Academy 6-12
585159	Alachua	Gainesville	32606	Millhopper Branch Library
587611	Okaloosa	Baker	32531	Milligan Assembly of God Church
588324	Pasco	New Port Richey	34653	Millpond Clubhouse
588844	Santa Rosa	Milton	32570	Milton Community Center
585308	Brevard	Mims	32754	Mims Scottsmoor Public Library
587429	Miami-Dade	Miami	33196	Ministerio Cristiano Exodo 13:21
587359	Miami-Dade	Hialeah	33014	Ministerio Unidos En Amor
586526	Lake	Minneola	34715	Minneola City Hall
585029	Levy	Williston	32696	Miracle Vision Tabernacle Outreach
585436	Broward	Miramar	33027	Miramar City Hall
585393	Broward	Miramar	33023	Miramar Elementary School
585420	Broward	Miramar	33025	Miramar High School
585588	Broward	Miramar	33023	Miramar United Methodist Church
587959	Palm Beach	Palm Beach Gardens	33418	Mirasol Sales and Info Center
585015	Franklin	Alligator Point	32346	Mission by the Sea
586350	Hillsborough	Temple Terrace	33617	Mission Hill Church
586428	Hillsborough	Tampa	33612	Mission Hill Church Lake Carroll Campus
586709	Leon	Tallahassee	32304	Mission San Luis
584888	Nassau	Fernandina Beach	32034	MLK / Elm Street Recreation Center
587840	Orange	Orlando	32810	MOKANNA MASONIC LODGE 329
585550	Broward	Coconut Creek	33073	Monarch High School
584993	Jefferson	Monticello	32344	Monticello Opera House
585062	Hendry	Clewiston	33440	Montura Clubhouse
585864	Duval	Jacksonville	32225	Monument Pointe Fellowship
587414	Miami-Dade	Miami	33127	Moore Park
585790	Collier	Naples	34103	Moorings Presbyterian Church
587553	Monroe	Key West	33040	Moose Club
586746	Leon	Tallahassee	32303	Moose Lodge
585258	Brevard	Merritt Island	32953	Moose Lodge #2073
588958	Sarasota	North Port	34291	MORGAN FAMILY COMMUNITY CENTER
587442	Miami-Dade	Doral	33178	Morgan Levy Park
586712	Leon	Tallahassee	32317	Morningside Baptist Church
587096	Miami-Dade	Miami	33138	Morningside K-8 Academy
587307	Miami-Dade	Miami	33137	Morningside Park
587002	Miami-Dade	Miami	33170	Morning Star Baptist Church
586469	Indian River	Vero Beach	32962	Morning Star Church
584911	Levy	Morriston	32668	Morriston Baptist Church
587986	Palm Beach	Palm Beach	33480	Morton & Barbara Mandel Center
587238	Miami-Dade	North Miami	33168	Mount Olives Church of God
587409	Miami-Dade	Miami	33170	Mount Pleasant Baptist Church
588577	Polk	Lakeland	33810	Mount Tabor Baptist Church
586801	Manatee	Bradenton	34210	Mount Vernon Rec Hall
587240	Miami-Dade	Miami Gardens	33054	Mount Zion African Methodist Episcopal Church
586997	Miami-Dade	Miami	33147	Mount Zion Apostolic Temple
586556	Lake	The Villiages	32163	Moyer, Villages Recreation Center
585108	Taylor	Perry	32347	Mt. Carmel Baptist Church
585204	Alachua	Gainesville	32641	Mt. Carmel Baptist Church
587194	Miami-Dade	Miami	33147	Mt Carmel Missionary Baptist Church
584906	Suwannee	Live Oak	32060	MT. GILEAD BAPTIST CHURCH
584925	Jefferson	Lamont	32336	Mt. Morilla Baptist Church
586061	Escambia	Pensacola	32501	MT OLIVE MISSIONARY BAPTIST
586131	Gadsden	Mt. Pleasant	32352	Mt Pleasant Fire Dept.
587649	Orange	Orlando	32811	MT PLEASANT MISSIONARY BAPTIST CHURCH
586834	Manatee	Palmetto	34221	Mt Raymond Baptist Church
587740	Orange	Orlando	32811	MT SINAI MISSIONARY BAPTIST CHURCH
588537	Pinellas	St Petersburg	33705	Mt. Zion AME Church
586918	Marion	The Villages	32162	Mulberry Center, Liberty & Old Glory Rm
588591	Polk	Mulberry	33860	Mulberry Civic Center
585561	Broward	Coral Springs	33065	Mullins Park-Sartory Senior Center
588685	Polk	Winter Haven	33881	Multiply Christian Church
585545	Broward	North Lauderdale	33068	Municipal Complex N Laud City Hall
588856	Santa Rosa	Milton	32570	Munson-Blackwater Baptist Church
585650	Charlotte	Port Charlotte	33948	Murdock Middle School
585829	Duval	Jacksonville	32205	Murray Hill Baptist Church
587558	Monroe	Key Largo	33037	Murray Nelson Goverment Center
589196	Walton	Ponce De Leon	32455	Muscogee Nation Museum
585044	Glades	Labelle	33935	Muse Community Center
586813	Manatee	Myakka City	34251	Myakka City United Methodist Church
585664	Charlotte	Port Charlotte	33981	Myakka River Elementary School
586056	Escambia	Pensacola	32506	MYRTLE GROVE BAPTIST CHURCH
586022	Escambia	Pensacola	32506	MYRTLE GROVE BAPTIST CHURCH
587449	Miami-Dade	Miami Gardens	33056	Myrtle Grove Presbyterian Church
586066	Escambia	Pensacola	32506	MYRTLE GROVE UNITED METHODIST
585796	Collier	Naples	34120	Naples Church
585768	Collier	Naples	34105	Naples Church of Christ
587530	Miami-Dade	Naranja	33032	Naranja Branch Library
587260	Miami-Dade	Homestead	33032	Naranja Community Resource Center
587207	Miami-Dade	Homestead	33032	Naranja Park Recreation Center
587872	Osceola	St. Cloud	34771	NARCOOSSEE COMMUNITY CENTER
587845	Osceola	St. Cloud	34771	NARCOOSSEE MIDDLE SCHOOL
587134	Miami-Dade	Opa Locka	33054	Nathan B. Young Elementary School
588775	St. Johns	St. Augustine	32084	National Guard Armory
586119	Gadsden	Quincy	32351	National Guard Armory
585697	Citrus	Crystal River	34429	NATIONAL GUARD ARMORY
586142	Hernando	Weeki Wachee	34613	NATIVITY LUTHERAN CHURCH
587417	Miami-Dade	North Miami	33181	Natural Bridge Elementary School
588709	Polk	Lake Wales	33898	Nature's Edge
587367	Miami-Dade	Miami Beach	33140	Nautilus Middle School
588857	Santa Rosa	Navarre	32566	Navarre Beach-Navarre Community Gym
588851	Santa Rosa	Navarre	32566	Navarre Community Gym
588843	Santa Rosa	Navarre	32566	Navarre United Methodist Church
584952	Calhoun	Blountstown	32424	Neal Civic Center
589017	Seminole	Longwood	32779	Neighborhood Alliance Church
587620	Okaloosa	Crestview	32536	New Beginnings Church
588697	Polk	Lakeland	33801	New Beginnings Church of the Nazarene
588378	Pasco	Spring Hill	34610	New Beginnings Family Ministries
585194	Alachua	Newberry	32669	Newberry Municipal Building
584868	Jefferson	Monticello	32344	New Bethel Ame Church
586138	Gadsden	Quincy	32351	New Bethel A.M.E.Church
587738	Orange	Orlando	32811	NEW BETHEL AME CHURCH
588273	Palm Beach	Boynton Beach	33437	New Church
587715	Orange	Orlando	32808	NEW CHURCH OF FAITH
587823	Orange	Orlando	32805	NEW COVENANT BAPTIST CHURCH
587795	Orange	Orlando	32809	NEW COVENANT BIBLE FELLOWSHIP
588976	Seminole	Winter Springs	32708	New Covenant Church
589192	Walton	Defuniak Springs	32433	New Harmony Community Center
587892	Palm Beach	Jupiter	33458	Newhaven Clubhouse
585000	Holmes	Westville	32464	New Hope
587604	Okaloosa	Valparaiso	32580	New Hope Baptist Church
588499	Pinellas	St Petersburg	33712	New Hope Baptist Church
586830	Manatee	Parrish	34219	New Hope Baptist Church
589100	Volusia	Deltona	32738	New Hope Baptist Church
584830	Hardee	Wauchula	33873	NEW HOPE BAPTIST CHURCH
586950	Martin	Palm City	34990	New Hope Fellowship Church
585759	Collier	Naples	34104	New Hope Ministries
588379	Pasco	Hudson	34667	New Hope Missionary Baptist Church
586234	Hillsborough	Dover	33527	New Hope United Methodist Church Dover
587079	Miami-Dade	Miami	33179	New Horizons Condominium Association
588223	Palm Beach	Wellington	33414	New Horizons Elementary
586630	Lee	Lehigh Acres	33973	New Life Assembly Of God
587774	Orange	Orlando	32818	NEW LIFE CHURCH
588948	Sarasota	Venice	34293	NEW LIFE CHURCH
587825	Orange	Orlando	32826	NEW LIFE CHURCH OF GOD
585868	Duval	Jacksonville	32218	New Life Community Church
586842	Marion	Ocala	34482	New Life Family Church
589189	Walton	Defuniak Springs	32433	New Life Holiness Church
588893	Sarasota	Sarasota	34240	NEW LIFE LUTHERAN CHURCH
586510	Lake	Minneola	34715	New Life Presbyterian Church
586371	Hillsborough	Tampa	33605	New Mt Zion Baptist Church
588302	Pasco	New Port Richey	34653	New Port Richey Recreation Center
585564	Broward	Miramar	33025	New Renaissance Middle School
588296	Pasco	Wesley Chapel	33543	New River Branch Library
585592	Broward	Sunrise	33326	New River Civic Center
585412	Broward	Fort Lauderdale	33312	New River Middle School
585293	Brevard	Melbourne	32934	New Shiloh Christian Center
589088	Volusia	New Smyrna Beach	32168	New Smyrna Beach Garden Club
589125	Volusia	New Smyrna Beach	32168	New Smyrna Beach Regional Library
586257	Hillsborough	Lutz	33559	New Tampa Baptist Church
586422	Hillsborough	Tampa	33647	New Tampa Community Park Gym and Dance
586324	Hillsborough	Tampa	33647	New Tampa Regional Library
588443	Pinellas	Largo	33771	New Testament Baptist Church
584857	Okeechobee	Okeechobee	34972	NEW TESTAMENT BAPTIST CHURCH
588353	Pasco	Zephyrhills	33541	New Walk Church
587423	Miami-Dade	Miami	33147	NFL Yet Center/Gwen Cherry Park
584988	Gilchrist	Branford	32008	N Gilchrist Fire Station
585508	Broward	Sunrise	33322	Nob Hill Soccer Club
587640	Orange	Apopka	32712	NOCIA
588877	Sarasota	Nokomis	34275	NOKOMIS BEACH PLAZA
588941	Sarasota	Nokomis	34275	NOKOMIS COMMUNITY CENTER
585059	Holmes	Bonifay	32425	Noma
585503	Broward	Pompano Beach	33064	Norcrest Elementary School
587138	Miami-Dade	Miami Gardens	33169	Norland United Methodist Church
586999	Miami-Dade	Miami	33196	Norma Butler Bossard Elementary School
587360	Miami-Dade	Miami Beach	33141	Normandy Park & Pool
587476	Miami-Dade	Miami Beach	33141	Normandy Shores Golf Club
587213	Miami-Dade	Hialeah	33015	Norman & Jean Reach Park
585339	Broward	Oakland Park	33334	North Andrews Gardens Elementary School
587248	Miami-Dade	Miami Beach	33140	North Beach Elementary School
587902	Palm Beach	West Palm Beach	33407	Northboro Elementary
588497	Pinellas	St Petersburg	33702	North Branch Library
585303	Brevard	Titusville	32780	North Brevard Senior Center
585184	Alachua	Gainesville	32606	North Central Baptist Church
587478	Miami-Dade	Miami	33147	North Central Branch Library
585192	Alachua	Gainesville	32605	North Central Florida YMCA
586162	Hernando	Spring Hill	34608	NORTHCLIFFE CHURCH (HALL)
585776	Collier	Naples	34109	North Collier Fire Admin HQ
585782	Collier	Naples	34109	North Collier Regional Park
587410	Miami-Dade	Miami Gardens	33056	North County K-8 Center
586473	Indian River	Sebastain	32958	North County Public Library
587511	Miami-Dade	Miami Gardens	33054	North Dade Middle School
587509	Miami-Dade	Miami Gardens	33056	North Dade Regional Library
586220	Hillsborough	Tampa	33624	Northdale Regional Park & Recreation Center
587293	Miami-Dade	Aventura	33180	Northeast Dade Aventura Branch Library
586600	Lee	North Fort Myers	33917	North Fort Myers Church Of The Nazarene
586598	Lee	North Fort Myers	33903	North Fort Myers Public Library
586578	Lee	North Fort Myers	33903	North Fort Myers Recreation Center
585185	Alachua	Gainesville	32606	North Gainesville Baptist Church
587190	Miami-Dade	Miami	33055	North Glade Elementary School
588428	Pinellas	Clearwater	33755	North Greenwood Recreation & Aquatic Complex
585942	Duval	Jacksonville	32208	North Jacksonville Church of God
585532	Broward	North Lauderdale	33068	North Lauderdale Elementary
584842	Baker	Macclenny	32063	North Macclenny
587178	Miami-Dade	Miami	33162	North Miami Beach Church Nazarene
587261	Miami-Dade	Miami	33162	North Miami Beach Senior High School
587333	Miami-Dade	North Miami	33168	North Miami Church of the Nazarene
587427	Miami-Dade	North Miami	33161	North Miami Elementary School
587263	Miami-Dade	North Miami	33161	North Miami Public Library
587232	Miami-Dade	North Miami	33168	North Miami Seventh Day Adventist Church
585799	Collier	Naples	34119	North Naples Baptist Church
587728	Orange	Orlando	32818	NORTH ORLANDO 7TH DAY ADVENTIST CHURCH
587460	Miami-Dade	Hialeah	33015	North Palm Baptist Church
588878	Sarasota	North Port	34287	NORTH PORT COMMUNITY UNITED CHURCH OF CHRIST
586776	Manatee	Parrish	34219	North River Church
588913	Sarasota	Sarasota	34234	NORTH SARASOTA PUBLIC LIBRARY
587219	Miami-Dade	Miami Beach	33141	North Shore Branch Library
587146	Miami-Dade	Miami Beach	33141	North Shore Park Youth Center
586707	Leon	Tallahassee	32312	Northside Community Center
588440	Pinellas	St Petersburg	33704	Northside Lodge #283, F & AM
586274	Hillsborough	Tampa	33610	Northside Missionary Baptist Church
587394	Miami-Dade	Miami	33182	North Trail Park
585157	Alachua	Gainesville	32606	Northwest Baptist Church
588978	Seminole	Lake Mary	32746	Northwest Branch Library
587757	Orange	Orlando	32808	NORTHWEST COMMUNITY CENTER
587775	Orange	Apopka	32712	NORTHWEST RECREATION COMPLEX ADMIN OFFICE
585375	Broward	Coral Springs	33065	Northwest Regional Library
586626	Lee	Cape Coral	33993	Northwest Regional Library
586210	Hillsborough	Tampa	33615	Northwest Tampa Church Of Christ
588358	Pasco	Wesley Chapel	33544	Northwood Community Center
588405	Pinellas	Clearwater	33761	Northwood Presbyterian Church
586756	Leon	Tallahassee	32303	Northwoods Baptist Church
587998	Palm Beach	North Palm Beach	33408	NPB Community Center
588268	Palm Beach	North Palm Beach	33408	NPB Council Chambers
588115	Palm Beach	North Palm Beach	33408	NPB Country Club
588472	Pinellas	North Redington Beach	33708	N Redington Beach Town Hall
587607	Okaloosa	Ft Walton Bch	32547	NWFSC, Bldg 41 Rm 126
586868	Marion	Ocala	34470	Oakcrest Baptist Church, Gym
585195	Alachua	Newberry	32669	Oak Dale Baptist Church
587471	Miami-Dade	Miami	33162	Oak Grove Park
586380	Hillsborough	Tampa	33614	Oak Grove United Methodist Church
584950	DeSoto	Arcadia	34266	Oak Hill Baptist Church
585977	Duval	Jacksonville	32210	Oak Hill Church of the Nazarene
589163	Volusia	Oak Hill	32759	Oak Hill City Hall
588406	Pinellas	Seminole	33776	Oakhurst United Methodist Church
585443	Broward	Margate	33068	Oakland Hills Social Center
588651	Polk	Haines City	33844	Oakland Neighborhood Center
585450	Broward	Oakland Park	33334	Oakland Park Library
587622	Orange	Oakland	34760	OAKLAND PRESBYTERIAN CHURCH
585735	Clay	Orange Park	32065	OAKLEAF BAPTIST CHURCH
585732	Clay	Orange Park	32065	OAKLEAF PLT ATHLETIC CTR
585386	Broward	Hollywood	33020	Oakridge Elementary School
586914	Marion	Ocala	34481	Oak Run Orchid Clubhouse
586942	Marion	Ocala	34476	Oak Run Palm Grove Clubhouse
586381	Hillsborough	Tampa	33604	Oaks At Riverview Community Center
587679	Orange	Orlando	32824	OAKSHIRE ELEMENTARY SCHOOL
588281	Pasco	Land O'lakes	34638	Oakstead Clubhouse
584836	Okeechobee	Okeechobee	34974	OAKVIEW BAPTIST CHURCH
588916	Sarasota	Sarasota	34237	OAKWOOD MANOR
588072	Palm Beach	Loxahatchee	33470	Oasis Church
586774	Manatee	Bradenton	34211	Oasis Church
587493	Miami-Dade	Homestead	33033	Oasis Club House
587922	Palm Beach	Lake Worth	33461	Oasis De Amor Baptist Church
585785	Collier	Naples	34102	Oasis Naples Church
586936	Marion	Summerfield	34491	O.B.H.H.A. Community Center
584917	Suwannee	O'brien	32071	O'BRIEN BAPTIST CHURCH
586865	Marion	Silver Springs	34488	Ocala Boat Club
586933	Marion	Ocala	34470	Ocala Christian Church
586856	Marion	Ocala	34470	Ocala East Villas Clubhouse
586884	Marion	Ocala	34470	Ocala Golf Club
586881	Marion	Ocala	34480	Ocala Shrine Club
586674	Lee	Estero	33928	Ocean Church
588205	Palm Beach	Ocean Ridge	33435	Ocean Ridge Town Hall
585996	Duval	Atlantic Beach	32233	Oceanside Church of Christ
588183	Palm Beach	Juno Beach	33408	Oceanview Methodist
585928	Duval	Jacksonville	32218	Oceanway Community Center
585047	Wakulla	Panacea	32346	OCHLOCKONEE BAY - VOL. FIRE DEPT
587821	Orange	Ocoee	34761	OCOEE LAKESHORE CENTER
588377	Pasco	Odessa	33556	Odessa Community Center
587455	Miami-Dade	Miami	33180	Ojus Elementary School
587596	Okaloosa	Shalimar	32579	Okaloosa County Administration Bldg
584953	Okeechobee	Okeechobee	34972	OKEECHOBEE CIVIC CENTER
585458	Broward	Davie	33314	Old Davie School
586181	Highlands	Sebring	33876	OLD DESOTO CITY HALL
585498	Broward	Fort Lauderdale	33311	Old Dillard Museum
585075	Jefferson	Monticello	32344	Old J.C.H.S. Auditorium
586099	Flagler	Flagler Beach	32136	OLD KINGS ELEMENTARY SCHOOL
588920	Sarasota	Sarasota	34240	OLD MIAKKA UNITED METHODIST CHURCH
588151	Palm Beach	North Palm Beach	33408	Old Port Cove
588401	Pinellas	Oldsmar	34677	Oldsmar Library
586040	Escambia	Pensacola	32514	OLIVE BAPTIST CHURCH
587437	Miami-Dade	Miami	33196	Oliver Hoover Elementary School
586901	Marion	Ocala	34476	Olivet Baptist Church
585425	Broward	Dania Beach	33004	Olsen Middle School
585067	Baker	Olustee	32072	Olustee
587054	Miami-Dade	Miami	33165	Olympia Heights Elementary School
588129	Palm Beach	Boca Raton	33434	Olympic Heights High
584853	Hardee	Ona	33865	ONA BAPTIST CHURCH
587349	Miami-Dade	Miami	33143	One City Church
586786	Manatee	Bradenton	34203	Oneco United Methodist Church
588451	Pinellas	Clearwater	33763	On Top of the World East
588566	Pinellas	Clearwater	33763	On Top of the World West
587404	Miami-Dade	Opa Locka	33054	Opa-Locka UTD Methodist Church
587202	Miami-Dade	Miami	33155	Open Bible Temple
586734	Leon	Tallahassee	32301	Optimist Park
587274	Miami-Dade	Hialeah	33012	O'Quinn Park
584838	Liberty	Bristol	32321	ORANGE
586571	Lake	Eustis	32726	Orange Avenue Church of Christ
585469	Broward	Hollywood	33021	Orange Brook Elementary School
589087	Volusia	Orange City	32763	Orange City United Methodist Church
587817	Orange	Apopka	32712	ORANGE COUNTY APOPKA SERVICE CENTER
587829	Orange	Orlando	32819	ORANGE COUNTY LIBRARY SOUTHWEST
587838	Orange	Winter Garden	34787	ORANGE COUNTY NATIONAL GOLF
587781	Orange	Orlando	32839	ORANGE COUNTY PUBLIC WORKS
587721	Orange	Orlando	32836	ORANGE COUNTY SPORTSMENS ASSOCIATION
585709	Clay	Fleming Island	32003	ORANGE CV 7TH DAY ADVENTIST
585126	Washington	Chipley	32428	Orange Hill Fire Station
585707	Clay	Orange Park	32073	ORANGE PARK CHRISTIAN CHURCH
585708	Clay	Orange Park	30273	ORANGE PARK LIBRARY
585726	Clay	Orange Park	32073	ORANGE PARK TOWN HALL
586366	Hillsborough	Tampa	33637	Orange River Estates HOA Clubhouse
586944	Marion	Fort Mccoy	32134	Orange Springs Comm. Bldg.
587779	Orange	Winter Park	32789	ORANGE TECHNICAL COLLEGE -WINTER PARK CAMPUS
587737	Orange	Orlando	32819	ORANGE TREE RECREATION CENTER
588181	Palm Beach	Delray Beach	33445	Orchard View Elementary
585474	Broward	Lauderdale Lakes	33309	Oriole Elementary School
585572	Broward	Margate	33063	Oriole Gardens Phase 1 Clubhouse
588962	Seminole	Altamonte Springs	32714	Orlando Filipino SDA Church
587718	Orange	Orlando	32810	ORLANDO KOREAN PRESBYTERIAN CHURCH
587836	Orange	Orlando	32835	ORLO VISTA CHAMBER OF COMMERCE
589113	Volusia	Ormond Beach	32176	Ormond Beach Presbyterian Church
589123	Volusia	Ormond Beach	32174	Ormond Beach Regional Library
589150	Volusia	Ormond Beach	32174	Ormond Beach Senior Center
589146	Volusia	Ormond Beach	32174	Ormond Lakes Clubhouse
586012	Duval	Jacksonville	32210	Ortega United Methodist Church
584974	Glades	Moore Haven	33471	Ortona Community Center
588024	Palm Beach	Lake Worth Beach	33460	Osborne Community Center
588056	Palm Beach	North Palm Beach	33408	Osborne Park Activities Bldg
585090	Gilchrist	Trenton	32693	Osceola Center
587879	Osceola	St. Cloud	34769	OSCEOLA COUNTY ROAD & BRIDGE
587876	Osceola	Kissimmee	34741	OSCEOLA COUNTY WELCOME CENTER
588157	Palm Beach	Loxahatchee	33470	Osceola Creek Middle
587850	Osceola	Kissimmee	34744	OSCEOLA HERITAGE PARK
587654	Orange	Orlando	32837	OSPREY PARK COMMUNITY CENTER
585447	Broward	Fort Lauderdale	33311	Osswald Park
584889	Gilchrist	High Springs	32643	Osteen Center
589160	Volusia	Osteen	32764	Osteen Civic Center
586846	Marion	Ocala	34481	OTOW Arbor Conference Ctr., Ste. F&G
586897	Marion	Ocala	34481	OTOW Arbor Conference Ctr., Suite E
586880	Marion	Ocala	34481	OTOW Master the Possibilities, ClassRm. 1
586905	Marion	Ocala	34481	OTOW Master the Possibilities, ClassRm. 2
585678	Citrus	Beverly Hills	34465	OUR LADY OF GRACE CHURCH
586629	Lee	Fort Myers	33967	Our Lady Of Light
587374	Miami-Dade	Miami	33186	Our Lady of Lourdes Catholic Church
586793	Manatee	Sarasota	34243	Our Lady Queen of Martyrs Rec Hall
588028	Palm Beach	Delray Beach	33446	Our Lady Queen of Peace
588771	St. Johns	Ponte Vedra Beach	32082	Our Lady Star of the Sea Church
585953	Duval	Jacksonville	32218	Our Redeemer Lutheran Church
588132	Palm Beach	Lake Worth Beach	33460	Our Savior Lutheran Church
588601	Polk	Lakeland	33809	Our Savior Lutheran Church
588935	Sarasota	Nokomis	34275	OUR SAVIOR LUTHERAN CHURCH
588703	Polk	Lakeland	33809	Outreach Baptist
585702	Clay	Orange Park	32073	OUTREACH OF AMERICA
586397	Hillsborough	Valrico	33596	Overflow Church
585013	Gulf	Port St Joe	32456	Overstreet Fire Station
589034	Seminole	Oviedo	32765	Oviedo East Branch Library
588988	Seminole	Oviedo	32765	Oviedo Gym & Aquatic Facility
588959	Seminole	Oviedo	32765	Oviedo Mall - Community Room
589003	Seminole	Oviedo	32765	Oviedo Presbyterian Church
585049	DeSoto	Arcadia	34266	Owens Community Center/School
589052	Sumter	Oxford	34484	OXFORD COMMUNITY BUILDING
585935	Duval	Jacksonville	32246	Pablo Creek Regional Library
588874	Santa Rosa	Pace	32571	Pace Community Center (Ball Park)
588094	Palm Beach	Pahokee	33476	Pahokee High
588191	Palm Beach	Pahokee	33476	Pahokee Recreation Center
586501	Lake	Paisley	32767	Paisley Community Building
588726	Putnam	Palatka	32177	Palatka Woman's Club
585727	Clay	Orange Park	32073	P.A.L Bldg @ Walter Odum Park
585069	Union	Lake Butler	32054	PALESTINE
586385	Hillsborough	Tampa	33629	Palma Ceia United Methodist Church
586817	Manatee	Sarasota	34243	Palm Aire Country Club
585268	Brevard	Palm Bay	32907	Palm Bay City Hall Council Chambers
585313	Brevard	Palm Bay	32909	Palm Bay Fire Station #4
585310	Brevard	Palm Bay	32905	Palm Bay (Tony Rosa) Community Center
587976	Palm Beach	Wellington	33411	Palm Beach Central High
588141	Palm Beach	Palm Beach Gardens	33410	Palm Beach Gardens Elementary
588010	Palm Beach	Palm Beach Gardens	33410	Palm Beach Gardens High
588079	Palm Beach	West Palm Beach	33407	Palm Beach Lakes High
587945	Palm Beach	Palm Beach Shores	33404	Palm Beach Shores Town Hall
586861	Marion	Ocala	34481	Palm Cay Oasis Clubhouse
586974	Martin	Palm City	34990	Palm City Community Center
586951	Martin	Palm City	34990	Palm City Presbyterian Church
586105	Flagler	Palm Coast	32164	PALM COAST BIBLE CHURCH
586097	Flagler	Palm Coast	32137	PALM COAST COMMUNITY CENTER
587490	Miami-Dade	Miami	33150	Palm Court Apartments
585353	Broward	Pembroke Pines	33025	Palm Cove Elementary School
585125	Glades	Palmdale	33944	Palmdale Community Center
586706	Leon	Tallahassee	32304	Palmer Munroe Teen Center
587521	Miami-Dade	Palmetto Bay	33157	Palmetto Bay Park
587098	Miami-Dade	Pinecrest	33156	Palmetto Elementary School
587087	Miami-Dade	Palmetto Bay	33157	Palmetto Golf Course
587369	Miami-Dade	Pinecrest	33156	Palmetto Middle School
586772	Manatee	Palmetto	34221	Palmetto Point Civic Association
586802	Manatee	Palmetto	34221	Palmetto Youth Center
587948	Palm Beach	Delray Beach	33484	Palm Greens Clubhouse
588430	Pinellas	Palm Harbor	34683	Palm Harbor Library
588512	Pinellas	Largo	33778	Palm Hill Country Club
588399	Pinellas	St Petersburg	33710	Palm Lake Christian Church
587106	Miami-Dade	Hialeah	33014	Palm Lakes Elementary School
585304	Brevard	Palm Shores	32940	Palm Shores Town Hall
587385	Miami-Dade	Hialeah	33013	Palm Springs Elementary School
588255	Palm Beach	West Palm Beach	33406	Palm Springs Middle
586985	Miami-Dade	Hialeah	33012	Palm Springs Middle School
587451	Miami-Dade	Hialeah	33015	Palm Springs North Elementary School
588120	Palm Beach	Palm Springs	33461	Palm Springs Village Hall
588082	Palm Beach	Loxahatchee	33470	Palms West Presbyterian
588733	St. Johns	Ponte Vedra Beach	32082	Palm Valley Baptist Church
589045	Seminole	Oviedo	32765	Palm Valley Club House
585007	Okeechobee	Okeechobee	34974	PALM VILLAGE RANCH CLUBHOUSE
585243	Bay	Panama City	32405	Palo Alto Church of Christ
585146	Wakulla	Panacea	32346	PANACEA - WOMENS CLUB
587965	Palm Beach	Lake Worth	33467	Panther Run Elementary
585400	Broward	Margate	33063	Paradise Gardens Section 4 CH
586529	Lake	Lady Lake	32159	Paradise Recreation Hall (Judy Garland Rm.)
585524	Broward	Davie	33325	Paradise Village Clubhouse
586581	Lee	Fort Myers	33967	Park Alliance Church
585248	Bay	Panama City	32404	Parker Community Center
588465	Pinellas	Pinellas Park	33781	Park Place Wesleyan Church
585582	Broward	Coral Springs	33076	Parkridge Church
585611	Broward	Deerfield Beach	33064	Park Ridge Elementary School
585440	Broward	Coral Springs	33067	Park Springs Elementary School
586697	Leon	Tallahassee	32301	Parks & Recreation Dept.
585179	Alachua	Gainesville	32609	Parkview Baptist Church
586107	Flagler	Palm Coast	32137	PARKVIEW BAPTIST CHURCH
588658	Polk	Haines City	33844	Parkview Christian Center - Hall
587487	Miami-Dade	Miami Gardens	33056	Parkview Elementary School
587932	Palm Beach	Lake Worth	33467	Park Vista High
586399	Hillsborough	Plant City	33567	Parkway Baptist Church
586670	Lee	Fort Myers	33912	Parkway Baptist Church
587057	Miami-Dade	Miami	33169	Parkway Elementary School
585765	Collier	Naples	34116	Parkway Life Church
586823	Manatee	Parrish	34219	Parrish United Methodist Church
588436	Pinellas	St Petersburg	33707	Pasadena Community Church
585444	Broward	Pembroke Pines	33024	Pasadena Lakes Elementary School
588354	Pasco	Dade City	33525	Pasco County Fair Assoc - Clayton Hall
588315	Pasco	Dade City	33523	Pasco-Hernando State College
588186	Palm Beach	Boca Raton	33431	Patch Reef Park Community Center
585365	Broward	Dania Beach	33312	Patrick J Meli Aquatic Complex
587436	Miami-Dade	Hialeah	33012	Patterson Pavillion
587081	Miami-Dade	Miami	33127	Paul Laurence Dunbar K-8 Center
587021	Miami-Dade	Miami	33182	Paul W. Bell Middle School
589190	Walton	Paxton	32538	Paxton Agriculture Complex
588040	Palm Beach	Palm Beach	33480	PB Fire Station #3
588035	Palm Beach	Palm Beach Gardens	33410	PBG City Hall Council Room
587995	Palm Beach	Palm Beach Gardens	33410	PBG Community Center
588057	Palm Beach	Palm Beach Gardens	33410	PBG Moose Lodge 2010
588093	Palm Beach	Loxahatchee Groves	33470	PBSC Loxahatchee Groves Campus
586358	Hillsborough	Tampa	33615	Peace Baptist Church of Town and Country
586645	Lee	Fort Myers	33908	Peace Lutheran Church
586966	Martin	Stuart	34997	Peace Presbyterian Church
585657	Charlotte	Port Charlotte	33952	Peace River Elementary School
587791	Orange	Orlando	32837	PEACE UNITED METHODIST CHURCH
585958	Duval	Jacksonville	32218	Pecan Park Baptist Church
585763	Collier	Naples	34108	Pelican Bay Community Center
587078	Miami-Dade	Miami	33138	Pelican Harbor Marina
585787	Collier	Naples	34109	Pelican Marsh Community Center
585394	Broward	Pembroke Pines	33026	Pembroke Lakes Country Club #19
585533	Broward	Pembroke Pines	33026	Pembroke Lakes Elementary School
585484	Broward	Pembroke Park	33023	Pembroke Park Center Commission Room
585570	Broward	Pembroke Pines	33025	Pembroke Pines City Hall
585362	Broward	Pembroke Pines	33023	Pembroke Pines Elementary School
585494	Broward	Pembroke Pines	33029	Pembroke Pines YMCA Family Center
585486	Broward	Miramar	33023	Pembroke Road Baptist Church
586531	Lake	Leesburg	34748	Pennbrooke Fairways-Grand Hall
585740	Clay	Penney Farms	32079	PENNEY FARMS TOWN HALL
586018	Escambia	Pensacola	32526	PENSACOLA BAPTIST TEMPLE
586077	Escambia	Pensacola Beach	32561	PENSACOLA BEACH COMM CHURCH
586063	Escambia	Pensacola	32503	PENSACOLA CHRISTIAN COLLEGE
586023	Escambia	Pensacola	32502	PENSACOLA CITY HALL
586016	Escambia	Pensacola	32514	PENSACOLA LODGE #42 F&AM
586025	Escambia	Pensacola	32507	PENSACOLA STATE COLLEGE - WARRINGTON
586042	Escambia	Pensacola	32526	PENS INTERSTATE FAIRGROUNDS
586034	Escambia	Pensacola	32507	PERDIDO BAY BAPTIST CHURCH
586068	Escambia	Pensacola	32507	PERDIDO BAY UNITED METHODIST
584999	Taylor	Perry	32347	Perry Shrine Bldg
588117	Palm Beach	Palm Beach Gardens	33403	Pew Leadership Center
587310	Miami-Dade	Homestead	33030	Phichol Williams Community Center
588309	Pasco	Odessa	33556	Phi Delta Kappa
588104	Palm Beach	Riviera Beach	33404	Phil Foster Park Marine Center
586328	Hillsborough	Tampa	33626	Philippine Art and Cultural Foundation
585174	Alachua	Gainesville	32611	Phillips Center for the Performing Arts
587253	Miami-Dade	Miami	33136	Phillis Wheatley Elementary School
587453	Miami-Dade	Miami	33138	Phyllis R. Miller Elementary School
588127	Palm Beach	Loxahatchee	33470	Pierce Hammock Elementary
589171	Volusia	Pierson	32180	Pierson Community Center
589132	Volusia	Daytona Beach	32119	Piggotte Community Center
589075	Sumter	The Villages	32162	PIMLICO REC CTR
587625	Orange	Orlando	32809	PINE CASTLE CHURCH OF CHRIST
587806	Orange	Orlando	32809	PINE CASTLE MASONIC LODGE 368
587323	Miami-Dade	Pinecrest	33156	Pinecrest Branch Library
586073	Escambia	Pensacola	32526	PINE FOREST UNITED METHODIST
584833	Levy	Trenton	32693	Pine Grove Baptist Church
584935	Suwannee	Live Oak	32060	PINEGROVE METHODIST CHURCH
587702	Orange	Orlando	32818	PINE HILLS COMMUNITY CENTER
585475	Broward	Davie	33328	Pine Island Multipurpose Center
585584	Broward	Davie	33324	Pine Island Ridge Country Club
586636	Lee	Bokeelia	33922	Pine Island United Methodist Church
588245	Palm Beach	West Palm Beach	33415	Pine Jog Environmental Center
587433	Miami-Dade	Cutler Bay	33189	Pinelands Presbyterian Church
584901	DeSoto	Arcadia	34266	Pine Level United Methodist Church
588562	Pinellas	St Petersburg	33712	Pinellas Community Church
588523	Pinellas	Largo	33774	Pinellas County Parks and Conservation
588557	Pinellas	Pinellas Park	33781	Pinellas Park Performing Arts Center
584862	Holmes	Westville	32464	Pine Log
585695	Citrus	Beverly Hills	34465	PINE RIDGE COMMUNITY BUILDING
589178	Volusia	Deltona	32738	Pine Ridge High School
588031	Palm Beach	Greenacres	33415	Pine Ridge North II
587937	Palm Beach	Greenacres	33463	Pine Ridge South IV Clubhouse
585619	Broward	Pembroke Pines	33026	Pines Lakes Elementary School
588101	Palm Beach	Boca Raton	33428	Pines Of Boca Barwood
585603	Broward	Pembroke Pines	33025	Pines Place
586088	Escambia	Pensacola	32526	PINE SUMMIT BAPTIST CHURCH
588863	Santa Rosa	Milton	32570	Pine Terrace Baptist Church
588867	Santa Rosa	Milton	32570	Pine Terrace Baptist Church
585366	Broward	Parkland	33076	Pine Trails Park Amphitheater
584837	Madison	Pinetta	32350	Pinetta Vol. Fire Dept.
588563	Pinellas	St Petersburg	33702	Pinewood Co-op, Inc.
585606	Broward	North Lauderdale	33068	Pinewood Elementary School
585706	Clay	Middleburg	32068	PINEWOOD PRESBYTERIAN CH
586062	Escambia	Cantonment	32533	PINEWOODS PRESBYTERIAN CHURCH
586755	Leon	Tallahassee	32311	Piney Z Lodge
585141	Hendry	Clewiston	33440	Pioneer Fire Station
585621	Broward	Sunrise	33351	Piper High School
586031	Escambia	Pensacola	32534	PLAINVIEW BAPTIST CHURCH
585513	Broward	Plantation	33324	Plantation Central Park Rec. Bldg.
586518	Lake	Leesburg	34748	Plantation-Hermitage Hall
585517	Broward	Plantation	33313	Plantation High School
586270	Hillsborough	Tampa	33624	Plantation Homeowners Clubhouse
585509	Broward	Plantation	33317	Plantation Park Elementary School
585531	Broward	Plantation	33317	Plantation Presbyterian Church
586514	Lake	Leesburg	34748	Plantation The Manor Clubhouse
586794	Manatee	Bradenton	34207	Plantation Village West, Rec Hall
586424	Hillsborough	Plant City	33566	Plant City Community Church of the Nazarene
588130	Palm Beach	Boynton Beach	33437	Platina Clubhouse
586633	Lee	Punta Gorda	33955	Platinum Point Yacht Club
587269	Miami-Dade	Aventura	33160	Plaza Del Prado Condominium
587966	Palm Beach	West Palm Beach	33407	Pleasant City Community Center
586280	Hillsborough	Plant City	33567	Pleasant Grove Assembly of God
586026	Escambia	Pensacola	32507	PLEASANT GROVE BAPTIST CHURCH
587854	Osceola	Kissimmee	34741	PLEASANT HILL ELEMENTARY SCHOOL
588556	Pinellas	Palm Harbor	34683	Pleasant Valley Baptist Church
588090	Palm Beach	Delray Beach	33444	Plumosa SOA Elementary
587091	Miami-Dade	Miami	33133	Plymouth Congregational Church
587870	Osceola	Kissimmee	34746	POINCIANA CHRISTIAN CHURCH
588597	Polk	Poinciana	34759	Poinciana Community Center
587875	Osceola	Kissimmee	34758	POINCIANA LIBRARY
587411	Miami-Dade	Aventura	33160	Point East Condominium
585832	Duval	Jacksonville	32225	Police Athletic League
585912	Duval	Jacksonville	32206	Police Athletic League Eastside
585993	Duval	Jacksonville	32209	Police Athletic League of Jacksonville, Inc
588408	Pinellas	Clearwater	33755	Polish Center
588647	Polk	Davenport	33896	Polk County Sheriffs Northeast District Office
588580	Polk	Winter Haven	33880	Polk County Utilities Division
588612	Polk	Bartow	33830	Polk Street Community Center
585201	Alachua	Gainesville	32601	Pollworker Standby
588650	Polk	Davenport	33897	Polo Park
588700	Polk	Davenport	33897	Polo Park East
588712	Putnam	Pomona Park	32181	Pomona Park Community Center
585632	Broward	Pompano Beach	33062	Pompano Bch Municipal Golf Course
585370	Broward	Pompano Beach	33064	Pompano Lakes Woodsetter Clubhouse
588123	Palm Beach	Delray Beach	33444	Pompey Park Recreation Center
584871	Holmes	Ponce De Leon	32455	Ponce de Leon
587306	Miami-Dade	Coral Gables	33146	Ponce De Leon Middle School
589099	Volusia	Ponce Inlet	32127	Ponce Inlet Community Center
588143	Palm Beach	Boynton Beach	33472	Ponte Vecchio Clubhouse
588742	St. Johns	Ponte Vedra Beach	32082	Ponte Vedra Branch Library
588743	St. Johns	Ponte Vedra Beach	32082	Ponte Vedra Concert Hall
585653	Charlotte	Port Charlotte	33952	Port Charlotte Beach Park
585656	Charlotte	Port Charlotte	33952	Port Charlotte Middle School
589198	Walton	Freeport	32439	Portland Community Center
589120	Volusia	Port Orange	32127	Port Orange Adult Center
589090	Volusia	Port Orange	32127	Port Orange Christian Church
589133	Volusia	Port Orange	32129	Port Orange Presbyterian Church
589170	Volusia	Port Orange	32129	Port Orange Regional Library
588322	Pasco	Port Richey	34668	Port Richey City Hall
586964	Martin	Stuart	34997	Port Salerno Church of God
585034	Gulf	Port St Joe	32456	Port St Joe Fire Station
585298	Brevard	Cocoa	32927	Port St. John Community Center
586298	Hillsborough	Tampa	33616	Port Tampa Community Center
588482	Pinellas	St. Petersburg	33709	Portuguese American Suncoast Assoc.
585985	Duval	Jacksonville	32205	Potters House International Ministries
585011	Bradford	Hampton	32044	Praise Christian Assembly
588486	Pinellas	St Petersburg	33711	Prayer Tower Church of God in Christ
585099	Hardee	Wauchula	33873	PRECO Building
586705	Leon	Tallahassee	32312	Premier Health & Fitness Center
585279	Brevard	Melbourne	32901	Presbyterian Ch of Good Shepherd
586396	Hillsborough	Brandon	33511	Presbyterian Church of Bloomingdale
587832	Orange	Orlando	32819	PRESBYTERIAN CHURCH OF THE LAKES
587571	Monroe	Marathon	33050	Presbyterian Kirk of the Keys Marathon
588731	Putnam	Palatka	32177	Price Martin Community Center
587677	Orange	Orlando	32822	PRIMERA IGLESIA BAUTISTA
587036	Miami-Dade	Miami	33165	Primera Iglesia Bautista Coral Park
585324	Brevard	Melbourne	32940	Prince of Peace Anglican Church
586175	Hernando	Spring Hill	34609	PRISTINE PLACE
588011	Palm Beach	Lantana	33462	Proclaim Church
586285	Hillsborough	Tampa	33619	Progress Village Civic Center
587319	Miami-Dade	Miami	33186	Promenade Condominium Club House #2
585994	Duval	Jacksonville	32221	Promise Land Baptist Church
589108	Volusia	Ormond Beach	32174	Providence Church
586151	Hernando	Spring Hill	34608	PROVIDENCE CHURCH
584947	Union	Lake Butler	32054	PROVIDENCE COMMUNITY CNTR
587034	Miami-Dade	Miami	33133	Providence Road/Coral Baptist Church
586409	Hillsborough	Riverview	33578	Providence West Community Center
585642	Charlotte	Punta Gorda	33950	Punta Gorda Isles Civic Association
588715	Putnam	East Palatka	32131	Putnam County Ag Center
588109	Palm Beach	Boynton Beach	33436	Quail Ridge Business Center
585692	Citrus	Hernando	34442	QUAIL RUN COMMUNITY BUILDING
585755	Collier	Naples	34105	Quail Run Golf Club
585700	Citrus	Hernando	34442	QUALITY INN @ CITRUS HILLS
588439	Pinellas	Tarpon Springs	34689	Quality Inn & Suites
585869	Duval	Jacksonville	32225	Queen's Harbor Yacht & Country Club
587698	Orange	Ocoee	34761	QUEST CHURCH
585382	Broward	Deerfield Beach	33442	Quiet Waters Elementary School
586134	Gadsden	Quincy	32351	Quincy Recreation Center
586050	Escambia	Cantonment	32533	QUINTETTE COMMUNITY CENTER
586271	Hillsborough	Tampa	33605	Ragan Park
584929	Union	Raiford	32083	RAIFORD COMMUNITY CNTR
586945	Marion	Dunnellon	34431	Rainbow Lakes Estates, Comm. Ctr.
586927	Marion	Dunnellon	34431	Rainbow Springs Village Church
585963	Duval	Jacksonville	32216	Ramallah American Club of Jacksonville
587164	Miami-Dade	Miami	33183	Ramblewood Condominium
585579	Broward	Coral Springs	33071	Ramblewood Elementary School
588411	Pinellas	Largo	33771	Ranchero Village Co-op, Inc.
587659	Orange	Orlando	32832	RANDAL PARK CLUBHOUSE
585479	Broward	Pembroke Park	33023	Raymond Ogelsby Preserve Rental
586053	Escambia	Mcdavid	32568	RAY'S CHAPEL BAPTIST CHURCH
588023	Palm Beach	Riviera Beach	33404	RB Prep Achievement Academy
586461	Indian River	Vero Beach	32967	Realtors Association of IRC
586876	Marion	Reddick	32686	Reddick Community Center
586805	Manatee	Bradenton	34209	Redeemer Lutheran Church
587724	Orange	Winter Park	32792	REDEEMER LUTHERAN CHURCH
586456	Hillsborough	Riverview	33569	Redeemer Presbyterian Church
588699	Polk	Winter Haven	33880	Redemption Baptist Church
585930	Duval	Jacksonville	32210	Redemption Church
585671	Citrus	Inverness	34452	REDEMPTION POINT AG
588478	Pinellas	Redington Beach	33708	Redington Beach Town Hall
588458	Pinellas	Redington Shores	33708	Redington Shores Town Hall
585682	Citrus	Crystal River	34428	RED LEVEL BAPTIST CHURCH
588868	Santa Rosa	Milton	32570	Red Rock-Blackwater Baptist Church
587864	Osceola	Kissimmee	34758	REEDY CREEK ELEMENTARY SCHOOL
587668	Orange	Orlando	32806	REFORMATION LUTHERAN CHURCH
588384	Pasco	Port Richey	34668	Regency Park Civic Club
588380	Pasco	New Port Richey	34654	Regency Park Library
585962	Duval	Jacksonville	32225	Regency Square Regional Library
586919	Marion	Ocala	34470	Reilly Arts Center
587807	Orange	Orlando	32829	RENAISSANCE SENIOR CENTER
587208	Miami-Dade	Miami	33145	Rene Janero Recreation Center
587315	Miami-Dade	Miami	33126	Residential Plaza
585982	Duval	Jacksonville	32216	Restoration Church
586232	Hillsborough	Tampa	33614	Restore Family Church
586331	Hillsborough	Riverview	33578	Resurrection Catholic Church
585870	Duval	Jacksonville	32225	Resurrection Episcopal Church
588622	Polk	Winter Haven	33884	Resurrection Lutheran Church
586308	Hillsborough	Tampa	33603	Revealing Truth Ministries
588679	Polk	Lakeland	33812	Revive Church
587116	Miami-Dade	Hialeah	33010	Revive Iglesia Adventista del Septimo Dia
585628	Broward	Davie	33325	Rexmere Village Wharf Clubhouse
585143	Columbia	Lake City	32055	RICHARDSON COMMUNITY CENTER
587375	Miami-Dade	Miami	33176	Richmond Heights Middle School
585563	Broward	Davie	33331	Rick Case Honda Dealership
586170	Hernando	Ridge Manor	33523	RIDGE MANOR COMMUNITY CENTER
588673	Polk	Davenport	33897	Ridge Mobile Home Park Clubhouse
587717	Orange	Orlando	32828	RISEN SAVIOR LUTHERAN CHURCH
585780	Collier	Naples	34112	Rivera Golf Estates Clubhouse
589064	Sumter	The Villages	32163	RIVERBEND REC CTR
588003	Palm Beach	Greenacres	33413	River Bridge Activities Center
585714	Clay	Fleming Island	32003	RIVER CHRISTIAN CHURCH
589091	Volusia	Debary	32713	River City Church
585946	Duval	Jacksonville	32244	River City Community Church
586335	Hillsborough	Riverview	33569	Rivercrest Clubhouse
585418	Broward	Parkland	33067	Riverglades Elementary School
586214	Hillsborough	Valrico	33596	River Hills Country Club
588770	St. Johns	St. Augustine	32084	River House
588754	St. Johns	St. Johns	32259	RiverHouse at RiverTown
585480	Broward	Fort Lauderdale	33312	Riverland Park
586782	Manatee	Bradenton	34208	River Life Church
586449	Hillsborough	Lutz	33549	River Of Life Church
585791	Collier	Naples	34102	River Park Community Center
585955	Duval	Jacksonville	32211	River Reach Baptist Church
585005	Nassau	Hilliard	32046	River Road Baptist Church
589039	Seminole	Oviedo	32765	River Run Church
587075	Miami-Dade	Miami	33176	Riverside Baptist Church
586665	Lee	Fort Myers	33912	Riverside Baptist Church
585317	Brevard	Indialantic	32903	Riverside Baptist Church
587712	Orange	Orlando	32810	RIVERSIDE BAPTIST CHURCH
586623	Lee	Fort Myers	33916	Riverside Community Center
587435	Miami-Dade	Miami	33130	Riverside Elementary School
588177	Palm Beach	Jupiter	33469	Riverside Improvement
588969	Seminole	Oviedo	32765	Riverside Park
585392	Broward	Fort Lauderdale	33312	Riverside Park
585820	Duval	Jacksonville	32204	Riverside Park United Methodist Church
585286	Brevard	Cocoa Beach	32931	Riverside Presbyterian Church Hall
587603	Okaloosa	Fort Walton Beach	32547	Rivers of Living Waters Church
586796	Manatee	Bradenton	34212	River Strand Golf and Country Club
585857	Duval	Jacksonville	32224	Rivertown Church
586282	Hillsborough	Riverview	33569	Riverview Civic Center
588748	St. Johns	St Augustine	32086	Riverview Club
586209	Hillsborough	Riverview	33578	Riverview Memorial VFW Post 8108
585842	Duval	Jacksonville	32208	Riverview Senior Center
588165	Palm Beach	West Palm Beach	33411	Riverwalk Clubhouse
588271	Palm Beach	Riviera Beach	33404	Riviera Beach Marina Event Ctr
587246	Miami-Dade	Miami	33143	Riviera Presbyterian Church
588564	Pinellas	St Petersburg	33702	Riviera United Methodist Church
585544	Broward	Davie	33330	Robbins Lodge Community Hall
587853	Osceola	Kissimmee	34743	ROBERT GUEVARA COMMUNITY CENTER
587136	Miami-Dade	Miami	33144	Robert King High Community House
588926	Sarasota	Sarasota	34234	ROBERT L TAYLOR COMMUNITY CENTER
586965	Martin	Stuart	34997	Robert Morgade Library
587030	Miami-Dade	Miami	33177	Robert Morgan Educational Center
587201	Miami-Dade	Miami Gardens	33056	Robert Renick Educational Center
587120	Miami-Dade	Miami	33157	Robert Russa Moton Elementary School
587198	Miami-Dade	Miami Gardens	33169	Robert Sharp Towers #1
588474	Pinellas	St Petersburg	33703	Roberts Recreation Center
585042	Liberty	Bristol	32321	ROCK BLUFF
587763	Orange	Orlando	32805	ROCK LAKE COMMUNITY CENTER
585318	Brevard	Rockledge	32955	Rockledge City Hall
587474	Miami-Dade	Miami	33165	Rockway Middle School
587464	Miami-Dade	Miami	33165	Rockway Park Recreation Bldg
586825	Manatee	Ellenton	34222	Rocky Bluff Library
584989	Suwannee	Live Oak	32060	ROCKY SINK BAPTIST CHURCH
586835	Manatee	Bradenton	34208	Rogers Community United Meth Ch
589077	Sumter	The Villages	32163	ROHAN REC CTR
588006	Palm Beach	Boynton Beach	33435	Rolling Green Elementary
586839	Marion	Ocala	34472	Rolling Greens West, Clubhouse
587520	Miami-Dade	Miami	33179	Ro-Mont South Rec Hall
587127	Miami-Dade	Doral	33178	Ronald W. Reagan/Doral Senior High School
587012	Miami-Dade	Miami Beach	33141	Ronald W. Shane Center
587150	Miami-Dade	Miami	33176	Ron Ehmann Recreation Center
588063	Palm Beach	West Palm Beach	33401	Roosevelt Elementary
585406	Broward	Pembroke Pines	33029	Rose G. Price Park
589187	Walton	Inlet Beach	32461	Rosemary Beach Town Hall
587627	Orange	Orlando	32808	ROSEMONT COMMUNITY CENTER
587820	Orange	Orlando	32836	ROSEN JEWISH COMMUNITY CENTER
586800	Manatee	Anna Maria	34216	Roser Memorial Community Church
588493	Pinellas	Clearwater	33756	Ross Norton Recreation Center
586589	Lee	Cape Coral	33914	Rotary Park Environmental Center
586994	Miami-Dade	Miami	33174	Roxcy O'Neal Bolton Women's History Gallery
587399	Miami-Dade	Miami	33174	Royal Ballrooms Banquet Hall
589076	Sumter	Wildwood	34785	ROYAL COMMUNITY BLDG
587284	Miami-Dade	Miami Gardens	33055	Royal Country Community - Sun Homes
586509	Lake	Tavares	32778	Royal Harbor-Social Hall
586537	Lake	Leesburg	34748	Royal Highlands-Recreation Center
587662	Orange	Orlando	32822	ROYAL MANOR VILLAS CLUBHOUSE
588026	Palm Beach	Royal Palm Beach	33411	Royal Palm Beach High
586462	Indian River	Vero Beach	32962	Royal Palm Clubhouse Vista Royale
587137	Miami-Dade	Miami	33165	Royal Palm Elementary School
585541	Broward	Lauderhill	33313	Royal Palm Elementary School
585511	Broward	Oakland Park	33309	Royal Park Condo Clubhouse
586307	Hillsborough	Tampa	33612	Roy Haynes Park
588241	Palm Beach	Royal Palm Beach	33411	RPB Branch Library
587946	Palm Beach	Royal Palm Beach	33411	RPB Cultural Center
588067	Palm Beach	Royal Palm Beach	33411	RPB Recreation Center
588684	Polk	Lakeland	33815	RP Funding Center
587447	Miami-Dade	Miami	33172	Ruben Dario Middle School
587266	Miami-Dade	Miami	33174	Ruben Dario Park
586407	Hillsborough	Ruskin	33570	Ruskin Recreation Center
585722	Clay	Grn Cv Spgs	32043	RUSSELL BAPTIST CHURCH
587046	Miami-Dade	Hialeah	33016	Russell Bucky Dent Park
586591	Lee	Fort Myers	33905	Russell Park Community Center
587043	Miami-Dade	Hialeah	33016	Ruth A. Tinsman Housing Authority
587076	Miami-Dade	Miami	33173	Ruth Owens Kruse Educational Center
586100	Flagler	Palm Coast	32164	RYMFIRE ELEMENTARY SCHOOL
586930	Marion	Belleview	34420	Sacred Fire Church
588350	Pasco	Dade City	33525	Sacred Heart Catholic Church
589078	Sumter	The Villages	32162	SADDLEBROOK REC CTR
586934	Marion	Ocala	34474	Saddle Oak Club Clubhouse
586238	Hillsborough	Plant City	33563	Sadye Gibbs Martin Community Center
588985	Seminole	Sanford	32771	Safeharbor Christian Church
588539	Pinellas	Safety Harbor	34695	Safety Harbor Public Library
585908	Duval	Jacksonville	32211	Saint Andrews Episcopal Church.
585990	Duval	Jacksonville	32210	Saint Catherine's Episcopal Church
587495	Miami-Dade	Miami	33147	Saint James AME Church
585940	Duval	Jacksonville	32207	Saint Mark's Lutheran Church - ELCA
585419	Broward	Weston	33326	Saint Paul Lutheran Church
587342	Miami-Dade	Miami	33165	Saint Timothy Catholic Church
584930	Taylor	Salem	32356	Salem Baptist Church
587646	Orange	Orlando	32819	SALEM LUTHERAN CHURCH
586750	Leon	Tallahassee	32312	Salvation Army
588553	Pinellas	Clearwater	33765	Salvation Army
588239	Palm Beach	West Palm Beach	33409	Salvation Army Activity Hall
588284	Pasco	Port Richey	34668	Salvation Army Fellowship Hall
586791	Manatee	Bradenton	34208	Samoset Baptist Church
588583	Polk	Winter Haven	33881	Samson Masonic Lodge #142
585123	Baker	Sanderson	32087	Sanderson
585448	Broward	Pompano Beach	33060	Sanders Park Elementary School
588218	Palm Beach	Palm Beach Gardens	33412	Sandhill Crane Golf Club
585220	Bay	Southport	32409	Sand Hills Fire Station
587989	Palm Beach	Boca Raton	33498	Sandpiper Shores Elementary
585270	Brevard	Titusville	32796	Sandrift Community Center
588977	Seminole	Sanford	32771	Sanford Civic Center
589040	Seminole	Sanford	32771	Sanford Memorial Stadium
586594	Lee	Sanibel	33957	Sanibel Community Church
586609	Lee	Sanibel	33957	Sanibel Community House
585969	Duval	Jacksonville	32217	San Jose Church of Christ
589028	Seminole	Longwood	32750	Sanlando United Methodist Church
585256	Brevard	Titusville	32780	San Mateo Mobile Village
588759	St. Johns	St Augustine	32084	San Sebastian Catholic Church
588128	Palm Beach	Lantana	33462	Santaluces High
588875	Santa Rosa	Milton	32583	Santa Rosa County Auditorium
588899	Sarasota	Sarasota	34241	SARASOTA BAPTIST CHURCH
588929	Sarasota	Sarasota	34232	SARASOTA COMMUNITY CHURCH
589061	Sumter	The Villages	32162	SAVANNAH CTR / ATLANTA
588938	Sarasota	Englewood	34223	SAVING GRACE BIBLE CHURCH
585616	Broward	Davie	33324	Scarborough Homeowners Association
586614	Lee	Fort Myers	33905	Schandler Hall Community Park, Suarez Hall
585903	Duval	Jacksonville	32209	Schell-Sweet Resource Wellness Center
589096	Volusia	Daytona Beach	32118	Schnebly Recreation Center
586470	Indian River	Vero Beach	32967	School District of Indian River County
586536	Lake	Leesburg	34788	Scottish Highlands Clubhouse
588663	Polk	Lakeland	33813	Scott Lake Baptist Church
587419	Miami-Dade	Miami Gardens	33169	Scott Lake Elementary School
585297	Brevard	Satellite Beach	32937	Scotty Culp Municipal Complex
585101	Calhoun	Blountstown	32424	Scout House
589059	Sumter	The Villages	32162	SEABREEZE REC CTR
585476	Broward	Miramar	33025	Sea Castle Elementary School
587909	Palm Beach	Delray Beach	33444	Seacrest Presbyterian
588279	Pasco	Hudson	34667	Sea Pines Civic Association
588362	Pasco	Hudson	34667	Sea Ranch Civic Association
585515	Broward	Sea Ranch Lakes	33308	Sea Ranch Lakes Beach Club
588144	Palm Beach	Boca Raton	33496	Seasons HOA Clubhouse
586468	Indian River	Sebastian	32958	Sebastian Christian Church
586463	Indian River	Sebastian	32958	Sebastian Church of God
586481	Indian River	Sebastian	32958	Sebastian Community Center
586459	Indian River	Sebastian	32958	Sebastian Elks Lodge No. 2714
586178	Highlands	Sebring	33870	SEBRING ELKS LODGE
586191	Highlands	Sebring	33870	SEBRING FALLS CLUBHOUSE
586199	Highlands	Sebring	33870	SEBRING HILLS CLUBHOUSE
586187	Highlands	Sebring	33870	SEBRING MASONIC LODGE
586183	Highlands	Sebring	33870	SEBRING RECREATION CLUB
586135	Gadsden	Quincy	32352	Second Elizabeth Church
587519	Miami-Dade	Hialeah	33012	Seduction Banquet Hall
586720	Leon	Tallahassee	32303	Seminole Baptist Church
589041	Seminole	Sanford	32771	Seminole Community Church
588498	Pinellas	Seminole	33772	Seminole Community Library at SPC
589018	Seminole	Sanford	32773	Seminole County Co-op Extension
589029	Seminole	Sanford	32773	Seminole County Health Dept
587020	Miami-Dade	Miami	33144	Seminole Elementary School
586342	Hillsborough	Tampa	33604	Seminole Heights Garden Center
586429	Hillsborough	Tampa	33603	Seminole Heights Library
588449	Pinellas	Seminole	33772	Seminole Recreation Center
588166	Palm Beach	Westlake	33470	Seminole Ridge High
587978	Palm Beach	West Palm Beach	33417	Seminole Trails Elementary
588546	Pinellas	Seminole	33772	Seminole United Methodist Church
588348	Pasco	Port Richey	34668	Senate Manor Mobile Park
585065	Bradford	Starke	32091	Senior Center
585002	Franklin	Carrabelle	32322	Senior Citizens Center
586174	Hernando	Spring Hill	34606	SENIOR CITIZENS CLUB
585187	Alachua	Gainesville	32653	Senior Recreation Center
588746	St. Johns	Ponte Vedra Beach	32082	Serenata Beach Club
586545	Lake	Clermont	34714	Serenoa Clubhouse
588335	Pasco	Wesley Chapel	33544	Seven Oaks Clubhouse
588392	Pasco	New Port Richey	34655	Seven Springs Country Club
586558	Lake	Mt. Dora	32757	Seventh Day Adventist Church
588489	Pinellas	Clearwater	33756	Seventh Day Adventist Church
586662	Lee	Cape Coral	33914	Seventh-Day Adventist Church
586946	Martin	Stuart	34996	Sewall's Point Town Hall
585167	Alachua	Gainesville	32601	SFC Blount Center
587215	Miami-Dade	Miami	33176	Sgt. Joseph Delancy Park
585246	Bay	Panama City	32405	Shaddai Shrine Center
584986	Wakulla	Crawfordville	32327	SHADEVILLE-WAK EDUCATIONAL CENTER
587513	Miami-Dade	Miami	33127	Shadowlawn Elementary School
584897	Taylor	Greenville	32331	Shady Gr Missnry Bapt Ch
584891	Jackson	Grand Ridge	32442	SHADY GROVE METHODIST CH
588291	Pasco	Zephyrhills	33540	Shady Oaks MHP Civic Club
586892	Marion	Ocala	34476	Shady Road Villas Clubhouse
587311	Miami-Dade	North Miami	33161	Shalom Community Church
588768	St. Johns	St. Augustine	32092	Shearwater Amenities Ctr
585056	Wakulla	Crawfordville	32327	SHELL POINT-APALACHEE BAY FIRE DEPT
585138	Calhoun	Altha	32421	Shelton Park Library
587151	Miami-Dade	Miami	33145	Shenandoah Branch Library
587032	Miami-Dade	Miami	33135	Shenandoah Elementary School
586108	Flagler	Palm Coast	32164	SHEPHERD OF THE COAST LUTHERAN CHURCH
585767	Collier	Naples	34113	Shepherd of the Glades Church
588336	Pasco	Dade City	33525	Shepherd of the Hills Lutheran Church
585898	Duval	Jacksonville	32256	Shepherd of the Woods Lutheran Church
588602	Polk	Lakeland	33811	Shepherds Community United Methodist
585245	Bay	Panama City Beach	32408	Sheraton Bay Point Resort (Spanish Moss)
586887	Marion	Ocala	34475	Sheriff's NW District Office, Comm. Rm.
586844	Marion	Ocala	34480	Sheriff's South Dist Office
585719	Clay	Grn Cv Spgs	32043	SHILOH BAPTIST CHURCH
587769	Orange	Orlando	32805	SHILOH BAPTIST CHURCH
586129	Gadsden	Havana	32333	Shiloh Church
586855	Marion	Micanopy	32667	Shiloh U.M. Church, Fell. Hall
585848	Duval	Jacksonville	32222	Shindler Dr Baptist Church
586869	Marion	Ocala	34472	Shores Community Center
586935	Marion	Ocala	34472	Shores Presbyterian Church, Gym
589169	Volusia	Holly Hill	32117	Sica Hall
587501	Miami-Dade	Miami Gardens	33169	Sierra Norwood Calvary Baptist Church
588945	Sarasota	Sarasota	34242	SIESTA KEY CHAPEL
587115	Miami-Dade	Miami	33133	Silver Bluff Elementary School
586523	Lake	Leesburg	34788	Silver Lake Community Church
585567	Broward	Miramar	33029	Silver Lakes Elementary School
585423	Broward	North Lauderdale	33068	Silver Lakes Middle School
587062	Miami-Dade	Homestead	33031	Silver Palm United Methodist Church
587778	Orange	Orlando	32818	SILVER STAR CHRISTIAN CHURCH
585453	Broward	Pembroke Pines	33331	Silver Trail Middle School
585886	Duval	Jacksonville	32209	Simonds-Johnson Community Center
587536	Miami-Dade	Miami	33129	Simpson Park Recreation Building
585093	Madison	Greenville	32331	Sirmans Vol. Fire Dept.
586014	Duval	Jacksonville	32226	Sisters Creek Marina
585012	Wakulla	Sopchoppy	32358	SMITH CREEK - VOL FIRE DEPT
586708	Leon	Tallahassee	32310	Smith-Williams Service Center
587211	Miami-Dade	Miami	33173	Snapper Creek Elementary School
587000	Miami-Dade	Miami	33173	Snapper Creek Townhouse Recreation Center
584958	Jackson	Sneads	32460	SNEADS CITY HALL
587660	Orange	Orlando	32812	SOCIEDAD CUBANA DE ORLANDO
588848	Santa Rosa	Milton	32570	Solid Rock Baptist Church
588665	Polk	Poinciana	34759	Solivita
588739	St. Johns	St Augustine	32084	Solomon Calhoun Community Ctr
585585	Broward	Wilton Manors	33311	Somerset Academy Wilton Manors
587639	Orange	Orlando	32821	SOMERSET VILLAGE CLUBHOUSE
586628	Lee	Fort Myers	33905	Sonshine Christian Academy
584875	Wakulla	Sopchoppy	32358	SOPCHOPPY Old High School
586559	Lake	Sorrento	32776	Sorrento Church of God
586562	Lake	Sorrento	32776	Sorrento Grace City Church
586453	Hillsborough	Riverview	33578	South Bay Church
587947	Palm Beach	South Bay	33493	South Bay Commission Chambers
585314	Brevard	Melbourne Beach	32951	South Beach Community Center
585064	DeSoto	Arcadia	34266	South County Annex
588125	Palm Beach	Delray Beach	33446	South County Civic Center
585643	Charlotte	Punta Gorda	33950	South County Regional Park
588413	Pinellas	St. Petersburg	33709	South Cross Bayou, Water Reclamation
587441	Miami-Dade	Homestead	33030	South Dade Baptist Church
587461	Miami-Dade	Homestead	33033	South Dade Park
587308	Miami-Dade	Cutler Bay	33189	South Dade Regional Library
589157	Volusia	South Daytona	32119	South Daytona Christian Church
588740	St. Johns	St Augustine	32086	Southeast Branch Library
584896	Gilchrist	Trenton	32693	Southeast Center
587729	Orange	Orlando	32822	SOUTH EAST LIBRARY
587109	Miami-Dade	Hialeah	33010	South East Park
585936	Duval	Jacksonville	32256	Southeast Regional Library
588222	Palm Beach	West Palm Beach	33411	South Florida Fairgrounds
586228	Hillsborough	Ruskin	33570	South Hillsborough Elks Lodge #2672
586574	Lake	Groveland	34736	South Lake Christian Church
584984	Baker	Macclenny	32063	South Macclenny
586818	Manatee	Bradenton	34207	South Manatee Library
585827	Duval	Jacksonville	32223	South Mandarin Regional Library
587255	Miami-Dade	South Miami	33143	South Miami American Legion #31
587351	Miami-Dade	Miami	33177	South Miami Heights Elementary School
586979	Miami-Dade	Miami	33155	South Miami Senior High School
585737	Clay	Middleburg	32050	SOUTH MIDDLEBURG BAPT CH
588111	Palm Beach	West Palm Beach	33405	South Olive Community Center
587730	Orange	Orlando	32809	SOUTH ORLANDO YMCA
587944	Palm Beach	South Palm Beach	33480	South Palm Beach Town Hall
588435	Pinellas	South Pasadena	33707	South Pasadena City Hall
585383	Broward	Plantation	33317	South Plantation High School
586010	Duval	Jacksonville	32216	Southpoint Baptist Church
586851	Marion	Ocala	34471	South Point Church
587027	Miami-Dade	Miami Beach	33139	South Pointe Elementary School
588713	Putnam	Crescent City	32112	South Putnam Government Center
585599	Broward	Pembroke Pines	33024	South Regional Library
585760	Collier	Naples	34113	South Regional Library
587060	Miami-Dade	Miami Beach	33139	South Shore Community Center
586391	Hillsborough	Riverview	33579	South Shore United Methodist Church
588585	Polk	Lakeland	33813	Southside Baptist Church
588652	Polk	Winter Haven	33880	Southside Baptist Church
588939	Sarasota	Sarasota	34239	SOUTHSIDE BAPTIST CHURCH
584909	Columbia	Lake City	32024	SOUTHSIDE REC COMPLEX-COACH BLDG
586566	Lake	Umatilla	32784	Southside Umatilla Community Center
585909	Duval	Jacksonville	32207	Southside United Methodist Church
588272	Palm Beach	Boynton Beach	33437	South Tech Academy
588905	Sarasota	Venice	34293	SOUTH VENICE BAPTIST CHURCH
589144	Volusia	Deland	32720	Southwestern Middle School
588722	Putnam	Palatka	32177	Southwest Fire Dept
587152	Miami-Dade	Miami	33165	Southwest Miami Senior High School
585551	Broward	Southwest Ranches	33330	Southwest Ranches Town Hall
585559	Broward	Pembroke Pines	33331	Southwest Regional Library
585170	Alachua	Gainesville	32643	Southwest United Methodist Church
587906	Palm Beach	Boca Raton	33434	Southwinds Golf Course
586718	Leon	Tallahassee	32305	Southwood Baptist Church
586702	Leon	Tallahassee	32311	Southwood Community Center
587689	Orange	Orlando	32809	SOUTHWOOD COMMUNITY CENTER
587497	Miami-Dade	Palmetto Bay	33157	Southwood Middle School
585306	Brevard	Cocoa	32927	Space Coast Jr/Sr High School
588185	Palm Beach	Boca Raton	33496	Spanish River High
588224	Palm Beach	Boca Raton	33431	Spanish River Library
588374	Pasco	Zephyrhills	33541	Spanish Trails East Clubhouse
587224	Miami-Dade	Hialeah	33012	Sparks Park
586941	Marion	Sparr	32113	Sparr United Methodist Church
587934	Palm Beach	Lake Worth	33461	Special Olympics of PBC
589117	Volusia	Deltona	32725	Spirit Elementary School
588686	Polk	Winter Haven	33880	Spirit Lake Community Church
587637	Orange	Orlando	32817	SPIRIT OF JOY LUTHERAN CHURCH
585947	Duval	Jacksonville	32226	Spirit of Life Lutheran Church
586698	Leon	Tallahassee	32304	Springfield Community Center
586284	Hillsborough	Plant City	33566	Springhead Civic Center
585178	Alachua	Gainesville	32641	Springhill Baptist Church
584841	Nassau	Fernandina Beach	32034	Springhill Baptist Church
586363	Hillsborough	Tampa	33604	Springhill Park Community Center
586159	Hernando	Spring Hill	34609	SPRING HILL UNITED CHURCH OF CHRIST - HALL
586186	Highlands	Sebring	33876	SPRING LAKE COMMUNITY CENTER
586168	Hernando	Brooksville	34601	SPRING LAKE UNITED METHODIST CHURCH
588964	Seminole	Sanford	32773	Spring Meadows SDA Church
585674	Citrus	Citrus Springs	34434	SPRINGS PRESBYTERIAN CHURCH
587217	Miami-Dade	Miami Springs	33166	Springview Elementary School
586904	Marion	Summerfield	34491	Spruce Creek CC, BallRoom
589115	Volusia	Port Orange	32127	Spruce Creek High School
586913	Marion	Dunnellon	34432	Spruce Creek Preserve, Comm. Ctr.
586840	Marion	Summerfield	34491	Spruce Creek South, Clubhouse
585654	Charlotte	Punta Gorda	33955	S Punta Gorda Heights Civic Assn
589008	Seminole	Heathrow	32746	SSC - Heathrow Campus
588366	Pasco	Land O' Lakes	34639	Stagecoach Clubhouse
585756	Collier	Naples	34120	St. Agnes Parish Center
586401	Hillsborough	Tampa	33611	S. Tampa Fellowship Ballast Point Campus
588752	St. Johns	St Augustine	32080	St. Anastasia Catholic Church
586027	Escambia	Pensacola	32505	ST ANDREW ORTHODOX CHURCH
588232	Palm Beach	Lake Worth Beach	33460	St Andrews Episcopal
587275	Miami-Dade	Palmetto Bay	33158	St. Andrews Episcopal Church
586161	Hernando	Spring Hill	34606	ST ANDREWS EPISCOPAL CHURCH
587726	Orange	Winter Park	32792	ST ANDREWS METHODIST CHURCH
586230	Hillsborough	Sun City Center	33573	St. Andrews Presbyterian Church
588928	Sarasota	Sarasota	34238	ST ANDREW UNITED CHURCH
585757	Collier	Naples	34102	St. Ann Catholic Church Rooney Hall
586223	Hillsborough	Ruskin	33570	St Anne Catholic Church
588293	Pasco	San Antonio	33576	St. Anthony of Padua Catholic Church
587364	Miami-Dade	Miami	33161	Stanton Memorial Baptist Church
585006	Bradford	Starke	32091	Starke Golf & Country Club
588530	Pinellas	Seminole	33777	Starkey Road Baptist Church
588202	Palm Beach	Lantana	33462	Starlight Cove Elementary
587672	Orange	Orlando	32822	STARLIGHT RANCH CLUBHOUSE 1
588886	Sarasota	Sarasota	34236	ST ARMANDS KEY LUTHERAN CHURCH
588190	Palm Beach	West Palm Beach	33415	State Only
588769	St. Johns	St Augustine	32080	St. Augustine Beach City Hall
587324	Miami-Dade	Coral Gables	33146	St. Augustine Catholic Church
587393	Miami-Dade	Hialeah	33018	St. Barbara Catholic Church
586769	Manatee	Sarasota	34243	St Barbara Greek Orthodox Church
585984	Duval	Jacksonville	32246	St. Barnabas Anglican Church
585612	Broward	Sunrise	33322	St Bernard Catholic Church
586824	Manatee	Holmes Beach	34217	St Bernard Catholic Church
588901	Sarasota	Sarasota	34242	ST BONIFACE EPISCOPAL CHURCH
586269	Hillsborough	Temple Terrace	33617	St Catherine's Episcopal Church
586603	Lee	Fort Myers	33919	St. Cecilia Parish Center
586250	Hillsborough	Tampa	33603	St Chad's Episcopal Church
587916	Palm Beach	West Palm Beach	33417	St Christophers Episcopal Chr
587867	Osceola	St. Cloud	34769	ST CLOUD BRANCH LIBRARY
587885	Osceola	St. Cloud	34769	ST CLOUD COMMUNITY HOUSE
585534	Broward	Pompano Beach	33062	St. Coleman Church Holy Family Center
586593	Lee	Fort Myers	33908	St. Columbkille Catholic Church
585336	Brevard	Cocoa Beach	32931	St. David's by the Sea Episcopal Church
587982	Palm Beach	Palm Beach	33480	St Edwards Church
585050	Taylor	Steinhatchee	32359	Steinhatchee Comm Cntr
588270	Palm Beach	West Palm Beach	33405	STEM Bldg at Cox Science Center
585483	Broward	Fort Lauderdale	33312	Stephen Foster Elementary School
589080	Sumter	The Villages	32162	STERLING HEIGHTS REC CTR
586340	Hillsborough	Thonotosassa	33592	Sterling Heights Recreation Center
588751	St. Johns	Ponte Vedra	32081	St. Francis in the Field
586311	Hillsborough	Seffner	33584	St Francis of Assisi Catholic Church
586907	Marion	Ocala	34480	St George Anglican Cathedral
585481	Broward	Lauderhill	33311	St. George Community Center
588375	Pasco	New Port Richey	34654	St. George Greek Orthodox Church
584907	Franklin	St George Island	32328	St. George Island Methodist Church
585452	Broward	Plantation	33324	St. Gregory Catholic Church
588226	Palm Beach	Boca Raton	33432	St Gregory Episcopal Church
586597	Lee	Fort Myers	33901	St. Hilary's Episcopal Church
585542	Broward	Davie	33312	Stirling Road Library
586133	Gadsden	Havana	32333	St. James Baptist Church
586224	Hillsborough	Tampa	33602	St James House of Prayer Episcopal Church
586020	Escambia	Pensacola	32514	ST JAMES MISSIONARY BAPTIST CHURCH
586157	Hernando	Spring Hill	34609	ST JOAN OF ARC CHURCH (PARISH HALL)
584995	Gulf	Port St Joe	32456	St Joe Beach Fire Station
586079	Escambia	Pensacola	32503	ST JOHN DIVINE BAPTIST CHURCH
588196	Palm Beach	Lake Park	33403	St John Lutheran Church
588737	St. Johns	St Augustine	32092	St. Johns Convention Ctr
585750	Collier	Naples	34103	St. John's Episcopal Church
588543	Pinellas	Clearwater	33764	St. John's Episcopal Church
587848	Osceola	Kissimmee	34741	ST. JOHN'S EPISCOPAL CHURCH
588772	St. Johns	St Augustine	32092	St. Johns Golf and Country Club
589126	Volusia	Deland	32720	St Johns Lodge #37 F&AM
587406	Miami-Dade	Miami Beach	33140	St. Johns on the Lake Methodist Church
586113	Flagler	Bunnell	32110	ST JOHNS PARK COMMUNITY CENTER
585826	Duval	Jacksonville	32210	St. Johns Presbyterian Church
588717	Putnam	Palatka	32177	St Johns River State College
586928	Marion	Ocala	34482	St Johns UM Church, Conley Hall
585752	Collier	Naples	34108	St. John the Evangelist
586126	Gadsden	Quincy	32351	St. Joseph Missionary Bap. Church
586412	Hillsborough	Tampa	33634	St. Joseph Vietnamese Catholic Church
587599	Okaloosa	Niceville	32578	St Jude's Episcopal Church
585998	Duval	Jacksonville	32258	St. Justin Martyr
588422	Pinellas	Seminole	33778	St. Justin, Martyr Roman Catholic Church
586635	Lee	Cape Coral	33993	St. Katharine Drexel Parish Hall
585315	Brevard	Melbourne	32940	St Katherine Greek Orthodox Church
586650	Lee	Bonita Springs	34134	St. Leo The Great Parish Life Center
588311	Pasco	St Leo	33574	St. Leo Town Hall
588251	Palm Beach	Highland Beach	33487	St Lucy Catholic Church
588545	Pinellas	Palm Harbor	34684	St. Luke Catholic Church
587856	Osceola	Kissimmee	34744	ST. LUKE MISSIONARY BAPTIST CHURCH
588425	Pinellas	St Petersburg	33713	St. Luke's United Methodist Church
587690	Orange	Orlando	32819	ST LUKES UNITED METHODIST CHURCH
587028	Miami-Dade	Miami	33186	St. Luke the Physician Episcopal Church
586071	Escambia	Pensacola	32514	ST LUKE UNITED METHODIST
584848	Jefferson	Monticello	32344	St Margaret's Catholic Church
587664	Orange	Orlando	32805	ST MARK AME CHURCH
584869	Wakulla	St. Marks	32355	ST MARKS - CITY HALL
586440	Hillsborough	Tampa	33625	St Marks Episcopal Church
586411	Hillsborough	Tampa	33647	St Mark the Evangelist Catholic Church
587157	Miami-Dade	Homestead	33033	St. Martin De Porres Catholic Church
587045	Miami-Dade	Sunny Isles Beach	33160	St. Mary Magdalen Catholic Church
586836	Manatee	Lakewood Ranch	34202	St Mary Magdalene Episcopal Church
588261	Palm Beach	West Palm Beach	33406	St Marys Orthodox Church
588689	Polk	Haines City	33844	St. Mary's Primitive Baptist Church
585822	Duval	Jacksonville	32209	St. Matthew Baptist Church
586986	Miami-Dade	Miami	33150	St. Matthew Freewill Baptist Church
586139	Gadsden	Quincy	32352	St. Matthew Primitive Bapt. Church
587634	Orange	Orlando	32817	ST MATTHEWS EPISCOPAL CHURCH
588410	Pinellas	Clearwater	33763	St. Michael Archangel Catholic Ch
587703	Orange	Orlando	32804	ST MICHAELS EPISCOPAL CHURCH
586085	Escambia	Cantonment	32533	ST MONICA'S EPISCOPAL CHURCH
588949	Sarasota	North Port	34287	ST NATHANIELS EPISCOPAL CHURCH
588455	Pinellas	Tarpon Springs	34689	St. Nicholas Cathedral
585867	Duval	Jacksonville	32207	St. Nicholas Park Christian Church
586921	Marion	Ocala	34481	Stone Creek Reunion Center
586939	Marion	Summerfield	34491	Stonecrest, Community Center
587793	Orange	Orlando	32828	STONE LAKES ELEMENTARY SCHOOL
588883	Sarasota	Venice	34292	STONEYBROOK CLUB HOUSE
585931	Duval	Jacksonville	32219	St. Paul AME Church
586384	Hillsborough	Tampa	33618	St Paul Catholic Church
587038	Miami-Dade	Miami	33142	St. Paul Institutional AME Church
588882	Sarasota	Sarasota	34239	ST PAUL LUTHERAN CHURCH
585923	Duval	Jacksonville	32207	St. Pauls Episcopal Church
588527	Pinellas	Clearwater	33755	St. Paul's Lutheran Church
586841	Marion	Ocala	34471	St. Paul's UM Church, Fell. Hall
585389	Broward	Lighthouse Point	33064	St. Paul The Apostle Church
588417	Pinellas	Largo	33770	St. Paul United Methodist Church
588468	Pinellas	St. Pete Beach	33706	St. Pete Beach Community Center
587897	Palm Beach	Jupiter	33458	St Peter Catholic Church
587610	Okaloosa	Mary Esther	32569	St. Peter Catholic Church
588427	Pinellas	St Petersburg	33705	St. Pete Religious Society/Friends
588515	Pinellas	St Petersburg	33713	St. Petersburg Community Church
588407	Pinellas	St. Petersburg	33704	St. Petersburg Women's Club
585978	Duval	Jacksonville	32210	St. Peter's Episcopal Church
588154	Palm Beach	Wellington	33414	St Peters Methodist Church
586694	Leon	Tallahassee	32308	St. Peters Prim. Bap. Church
586503	Lake	Mt. Dora	32757	St. Philip Lutheran Church
585424	Broward	Fort Lauderdale	33305	St. Pius X Catholic Church
585396	Broward	Fort Lauderdale	33312	Stranahan High School
586658	Lee	Lehigh Acres	33971	St. Raphael's Catholic Church
586315	Hillsborough	Valrico	33594	Strawberry Ridge
588300	Pasco	Wesley Chapel	33544	St. Reweis Coptic Orthodox Church
587182	Miami-Dade	Palmetto Bay	33157	St. Richard Parish Center
588960	Seminole	Winter Park	32792	St. Richard's Episcopal Church
585464	Broward	Fort Lauderdale	33316	St. Sebastian Catholic Church
587498	Miami-Dade	Miami	33165	St. Simons Episcopal Church
588470	Pinellas	St Petersburg	33710	St. Stefanos Greek Orthodox Church
585459	Broward	Pompano Beach	33062	St. Stephen Lutheran Church
588989	Seminole	Longwood	32779	St. Stephen Lutheran Church
587653	Orange	Orlando	32825	ST STEPHEN PRESBYTERIAN CHURCH
588860	Santa Rosa	Gulf Breeze	32563	St. Sylvester's Catholic Church
588871	Santa Rosa	Gulf Breeze	32563	St. Sylvester's Catholic Church
588536	Pinellas	Tarpon Springs	34689	St. Timothy Lutheran Church
585277	Brevard	Melbourne	32935	St. Timothy Lutheran Church Friendship Hall
588329	Pasco	Holiday	34690	St. Vincent DePaul Catholic Church
588437	Pinellas	St Petersburg	33710	St. Vincent's Episcopal Church
586677	Leon	Tallahassee	32303	Sue H. McCollum Community Center
587568	Monroe	Sugarloaf	33044	Sugarloaf Baptist Church (turn at traffic light)
589106	Volusia	New Smyrna Beach	32168	Sugar Mill Country Club
588243	Palm Beach	Boca Raton	33486	Sugar Sand Park Community Center
586542	Lake	Mt. Dora	32757	Sullivan Ranch Clubhouse
584985	Liberty	Bristol	32321	SUMATRA
586727	Leon	Tallahassee	32312	Summerbrooke Golf Club
586218	Hillsborough	Riverview	33579	Summerfield Crossing Community Center
586885	Marion	Ocala	34473	SummerGlen Clubhouse
585814	Collier	Naples	34110	Summit Church
586659	Lee	Fort Myers	33913	Summit Church - Fort Myers
586612	Lee	Fort Myers	33913	Summit Church - Gateway
586494	Lake	Clermont	34711	Summit Greens-Clubhouse
586444	Hillsborough	Sun City Center	33573	Sun City Center Community Hall
586447	Hillsborough	Sun City Center	33573	Sun City Center Florida Room
588396	Pasco	Land O' Lakes	34638	Suncoast Lakes HOA Clubhouse
586989	Miami-Dade	Pinecrest	33156	Suniland Park
588555	Pinellas	St Petersburg	33704	Sunken Gardens
587063	Miami-Dade	North Miami	33167	Sunkist Grove Community Center
586517	Lake	Grand Island	32735	Sun Lake Estates-Clubhouse
588687	Polk	Davenport	33896	Sunlake Terrace Estates
585470	Broward	Fort Lauderdale	33311	Sunland Park Academy
587925	Palm Beach	Lake Worth Beach	33460	Sunlight Community Church
587265	Miami-Dade	Miami	33134	Sunlight Miami
588693	Polk	Lakeland	33811	Sun 'n Fun Expo Campus
586198	Highlands	Sebring	33872	SUN 'N LAKE COMMUNITY CENTER
586184	Highlands	Lake Placid	33852	SUN 'N LAKES SOUTH CLUBHOUSE
586912	Marion	Umatilla	32784	Sunnyhill Restoration Area, Blue House
584872	Washington	Chipley	32428	Sunny Hills Community Ctr/Library
587123	Miami-Dade	Sunny Isles Beach	33160	Sunny Isles Beach Government Center
588642	Polk	Frostproof	33843	Sun Ray United Methodist Church
585587	Broward	Sunrise	33323	Sunrise Athletic Complex
585422	Broward	Sunrise	33351	Sunrise Civic Center Grand Ballroom
585358	Broward	Sunrise	33322	Sunrise Lakes Phase 2 Auditorium
585437	Broward	Sunrise	33322	Sunrise Lakes Phase 3 Sat. 4
585371	Broward	Sunrise	33322	Sunrise Lakes Phase 4 Main Clubhouse
585477	Broward	Fort Lauderdale	33304	Sunrise Middle School
588102	Palm Beach	Boca Raton	33498	Sunrise Park Elementary
587402	Miami-Dade	Hialeah	33015	Sunrise Presbyterian Church
586940	Marion	Summerfield	34491	Sunset Harbor Baptist Church
585631	Broward	Miramar	33029	Sunset Lakes Community Center
588242	Palm Beach	Boynton Beach	33472	Sunset Palms Elementary
587095	Miami-Dade	Miami	33173	Sunset Park Elementary School
585488	Broward	Miramar	33023	Sunshine Elementary School
586279	Hillsborough	Sun City Center	33573	Sun Towers
585282	Brevard	Melbourne	32940	Suntree United Methodist Church
584866	Columbia	Lake City	32055	SUPERVISOR OF ELECTIONS
585233	Bay	Panama City	32401	Supervisor of Elections Office
586760	Leon	Tallahassee	32301	Supervisor of Elections Office
585269	Brevard	Titusville	32780	Supervisor of Elections Office
585810	Collier	Naples	34104	Supervisor of Elections Office
585388	Broward	Fort Lauderdale	33301	Supervisor Of Elections Office 102
584913	Jefferson	Monticello	32344	Supt. of Schools Board Room
587535	Miami-Dade	Miami	33174	Sweetwater Elementary School
586850	Marion	Ocala	34482	Sweetwater Oaks Clubhouse
588741	St. Johns	St. Johns	32259	Switzerland Community Church
586125	Gadsden	Quincy	32351	Sycamore Methodist Church
588521	Pinellas	Clearwater	33759	Sylvan Abbey United Methodist Ch
587067	Miami-Dade	Miami	33155	Sylvania Heights Elementary School
587638	Orange	Orlando	32824	TAFT COMMUNITY CENTER
586710	Leon	Tallahassee	32301	Tallahassee Elks Lodge
588275	Pasco	New Port Richey	34654	Tall Pines Community Clubhouse
586888	Marion	Silver Springs	34488	Tall Timber Mobile Home Park
585421	Broward	Fort Lauderdale	33309	Tamarac Lakes Assoc. South Clubhouse
587416	Miami-Dade	Miami	33144	Tamiami Baptist Church
588295	Pasco	San Antonio	33576	Tampa Bay Golf & Country Club
586405	Hillsborough	Tampa	33618	Tampa Covenant Church
586339	Hillsborough	Tampa	33629	Tampa Garden Club
586432	Hillsborough	Tampa	33615	Tampa Korean Seventh Day Adventist
586299	Hillsborough	Tampa	33635	Tampa Shores Baptist Church
587796	Orange	Orlando	32819	TANGELO BAPTIST CHURCH
587760	Orange	Winter Garden	34787	TANNER HALL
588513	Pinellas	Tarpon Springs	34689	Tarpon Springs Community Center
588462	Pinellas	Tarpon Springs	34689	Tarpon Springs Heritage Museum
588505	Pinellas	Tarpon Springs	34689	Tarpon Springs Manor Apartments
586488	Lake	Tavares	32778	Tavares Civic Center
587861	Osceola	Kissimmee	34746	TAX COLLECTOR (CAMPBELL CITY)
585032	Baker	Sanderson	32087	Taylor
586953	Martin	Stuart	34994	TC3 Church
585897	Duval	Jacksonville	32254	Teamsters 512
585278	Brevard	Palm Bay	32907	Ted Whitlock Community Center
585037	Liberty	Hosford	32334	TELOGIA
585889	Duval	Jacksonville	32220	Temple Baptist Church
586169	Hernando	Spring Hill	34609	TEMPLE BETH DAVID JEWISH CENTER
585626	Broward	Cooper City	33330	Temple Beth Emet
587952	Palm Beach	Boynton Beach	33435	Temple Beth Kodesh
586671	Lee	Cape Coral	33990	Temple Beth Shalom
587053	Miami-Dade	Miami Beach	33140	Temple Beth Sholom
586321	Hillsborough	Tampa	33604	Temple Crest Community Center
588999	Seminole	Winter Springs	32708	Temple Israel
587222	Miami-Dade	Miami	33132	Temple Israel of Greater Miami
588897	Sarasota	Sarasota	34231	TEMPLE SINAI
586343	Hillsborough	Temple Terrace	33617	Temple Terrace Presbyterian Church
586237	Hillsborough	Tampa	33617	Temple Terrace United Methodist Church
585637	Broward	Coral Springs	33065	Tennis Center of Coral Springs
588019	Palm Beach	Tequesta	33469	Tequesta Council Chambers
587988	Palm Beach	Tequesta	33469	Tequesta Recreation Center
585413	Broward	Weston	33326	Tequesta Trace Middle School
586831	Manatee	Terra Ceia	34221	Terra Ceia VIA Club
588400	Pinellas	St Petersburg	33709	Terrace Park of Five Towns - Magnolia
588912	Sarasota	Nokomis	34275	TERRA COVE CONDOMINIUM
587507	Miami-Dade	Miami	33173	Terra Environmental Research Institute
585843	Duval	Jacksonville	32277	Terry Parker Baptist Church
586394	Hillsborough	Lithia	33547	Thatcher Civic Center
586455	Hillsborough	Riverview	33578	The Alley at SouthShore
588110	Palm Beach	West Palm Beach	33406	The Ark Church
587910	Palm Beach	Boynton Beach	33437	The Cascades Clubhouse
589114	Volusia	Ormond Beach	32176	The Casements
587621	Orange	Orlando	32835	THE CENTER CHURCH
585951	Duval	Jacksonville	32211	The Central Church of the Nazarene
588729	Putnam	Welaka	32193	The Church
589044	Seminole	Winter Springs	32708	The Church at 434
588991	Seminole	Sanford	32771	The Church at Heathrow
588314	Pasco	Land O' Lakes	34639	the church @ Myrtle Lake
587576	Okaloosa	Ft Walton Bch	32548	The Church of Christ
585997	Duval	Jacksonville	32256	The Church of Eleven22
587463	Miami-Dade	Miami	33127	The Church of God of Prophecy Miami #1
585811	Collier	Ave Maria	34142	The Club at Maple Ridge
585744	Clay	Orange Park	32073	THE CLUB CONTINENTAL
588745	St. Johns	St. Augustine	32095	The Clubhouse at Beacon Lake
585784	Collier	Naples	34114	The Club & Spa at Fiddler's Creek
587561	Monroe	Key Largo	33037	The College of the Florida Keys
588395	Pasco	Shady Hills	34610	The Concourse Event Center
587368	Miami-Dade	Coral Gables	33134	The Country Club of Coral Gables
586222	Hillsborough	Tampa	33619	The Crossing Church - Tampa
587287	Miami-Dade	Miami	33186	The Crossings Country Club
586747	Leon	Tallahassee	32312	The Episcopal Church of the Advent
585193	Alachua	Gainesville	32607	The Family Church
586431	Hillsborough	Tampa	33602	The Florida Aquarium
588450	Pinellas	St Petersburg	33703	The Gathering Church
587707	Orange	Orlando	32822	THE GATHERING PLACE
586952	Martin	Stuart	34997	The Grace Place
585271	Brevard	Titusville	32780	The Grove Church (Chapel)
588030	Palm Beach	Boynton Beach	33437	The Grove Clubhouse
588342	Pasco	Land O' Lakes	34637	The Groves CDD Clubhouse
586864	Marion	Ocala	34475	The Hampton Center, Room 102
588607	Polk	Auburndale	33823	The Hamptons
587056	Miami-Dade	Miami	33147	The Highway Church of Apostle's Faith
588500	Pinellas	Largo	33771	The Historic Largo Feed Store
584923	DeSoto	Arcadia	34266	The House of God Church
588233	Palm Beach	Jupiter	33458	The Island Clubhouse
588208	Palm Beach	Wellington	33449	The Isles at Wellington
586970	Martin	Stuart	34997	The Kane Center
587741	Orange	Orlando	32808	THE KINGDOM CHURCH
586493	Lake	Mt. Dora	32757	The Lodge @ Waterman Village
586489	Lake	Clermont	34714	The Lord's House
585016	Jefferson	Monticello	32344	The Masonic Lodge
587618	Okaloosa	Ft Walton Bch	32547	The Meridian at Westwood
587491	Miami-Dade	Miami	33015	The Moors Club Center
587239	Miami-Dade	Miami	33150	Thena C. Crowder Early Learning Center
588758	St. Johns	St Augustine	32095	The Palencia Club
586415	Hillsborough	Lithia	33547	The Palmetto Club at FishHawk Ranch
587792	Orange	Windermere	34786	THE PINES AT WINDERMERE
588061	Palm Beach	Boca Raton	33496	The Polo Club of Boca Raton
586783	Manatee	Bradenton	34203	The Preserve at Tara
585401	Broward	Hollywood	33024	The Quest Center School
585024	Bradford	Starke	32091	Theressa Community Center
586749	Leon	Tallahassee	32309	The Retreat at Bradleys Pond
585906	Duval	Jacksonville	32225	The River at Jacksonville Church
584879	Nassau	Yulee	32097	The River of Praise Worship Center
588672	Polk	Winter Haven	33881	The Rock of Winter Haven
585809	Collier	Naples	34104	The Salvation Army
587598	Okaloosa	Ft Walton Bch	32548	The Salvation Army Chapel
586240	Hillsborough	Tampa	33609	The Sons of Italy Hall Lodge 1251
585259	Brevard	Melbourne	32940	The Springs of Suntree
588330	Pasco	Odessa	33556	The Starkey Ranch (Cunningham Hall)
585197	Alachua	Gainesville	32601	The Thomas Center
586001	Duval	Jacksonville	32208	The Universal Church
587305	Miami-Dade	Miami	33135	The Universal Church of The Kingdom of God
587855	Osceola	Kissimmee	34741	THE VINE CHURCH
588623	Polk	Lakeland	33811	The Vineyard
588020	Palm Beach	Boca Raton	33486	The Volen Center
585849	Duval	Jacksonville	32224	The Windsor of San Pablo
586533	Lake	Groveland	34736	The Woodlands at Church Lake-Clubhouse
585845	Duval	Jacksonville	32246	The Woods Community Center
587143	Miami-Dade	Miami	33168	Thomas Jefferson Middle School
588503	Pinellas	St Petersburg	33712	Thomas Jet Jackson Recreation Center
586741	Leon	Tallahassee	32308	Thomasville Road Baptist Church
586356	Hillsborough	Thonotosassa	33592	Thonotosassa Branch Library
585745	Clay	Orange Park	32065	THRASHER-HORNE CONF CNTR
585535	Broward	Fort Lauderdale	33311	Thurgood Marshall Elementary School
588858	Santa Rosa	Gulf Breeze	32563	Tiger Point Community Center
587629	Orange	Orlando	32828	TIMBER CREEK HIGH SCHOOL
588294	Pasco	New Port Richey	34655	Timber Greens Clubhouse
588351	Pasco	Zephyrhills	33543	Timber Lake Estates Clubhouse
588283	Pasco	Port Richey	34668	Timber Oaks - The Oaks Club
586143	Hernando	Spring Hill	34606	TIMBER PINES PERF. ARTS CTR
586870	Marion	Dunnellon	34432	Timber Ridge Comm. Church of Ocala
585289	Brevard	Titusville	32780	Titusville Public Library
588688	Polk	Lakeland	33809	Together Church
587846	Osceola	Kissimmee	34744	TOHOPEKALIGA HIGH SCHOOL
588634	Polk	Davenport	33837	Tom Fellows Community Center
584914	Levy	Chiefland	32626	Tommy Usher Center
589110	Volusia	Ormond Beach	32174	Tomoka Christian Church
589127	Volusia	Ormond Beach	32174	Tomoka United Methodist Church
585699	Citrus	Inverness	34450	TOO FAR @ POINT O WOODS
587285	Miami-Dade	Miami	33137	Toussaint Louverture Elementary School
585207	Alachua	Gainesville	32608	Tower Road Branch Library
585404	Broward	Hallandale Beach	33009	Towers of Ocean View - Recreation Bldg
585506	Broward	Weston	33326	Town Center Club
586684	Leon	Tallahassee	32301	Towne East Baptist Church
585360	Broward	Pembroke Pines	33028	Towngate Park
585300	Brevard	Melbourne Beach	32951	Town Hall Community Center
588900	Sarasota	Longboat Key	34228	TOWN HALL OF LONGBOAT KEY
586264	Hillsborough	Tampa	33634	Town N Country Park
586246	Hillsborough	Tampa	33615	Town N Country Regional Library
586502	Lake	Astatula	34705	Town of Astatula Community Center
586567	Lake	Astatula	34705	Town of Astatula Community Center
587317	Miami-Dade	Surfside	33154	Town of Surfside
588051	Palm Beach	Greenacres	33463	Tradewinds Middle
586797	Manatee	Bradenton	34207	Trailer Estates Auditorium
588278	Pasco	Dade City	33523	Travelers Rest Citrus Hall
585267	Brevard	Cocoa	32926	Travis Recreation Center
588475	Pinellas	Treasure Island	33706	Treasure Island Community Center
587223	Miami-Dade	North Bay Village	33141	Treasure Island Elementary School
585618	Broward	Davie	33328	Tree Tops Park (Oak Ridge Auditorium)
584940	Gilchrist	Trenton	32693	Trenton Center
588371	Pasco	Dade City	33523	Trilby Lodge No.141 F & AM
586487	Lake	Groveland	34736	Trilogy-Magnolia House
585665	Charlotte	Englewood	34224	Tringali Park
588668	Polk	Lakeland	33811	Trinity Baptist Church
586231	Hillsborough	Sun City Center	33573	Trinity Baptist Church
587787	Orange	Apopka	32703	TRINITY BAPTIST CHURCH
587001	Miami-Dade	Miami	33132	Trinity Cathedral Hall
589149	Volusia	Deltona	32725	Trinity Christian Academy, Gym
588636	Polk	Lakeland	33813	Trinity Christian Center
588341	Pasco	Trinity	34655	Trinity College of Florida
588719	Putnam	Melrose	32666	Trinity Episcopal Church
586838	Marion	Ocala	34479	Trinity Lutheran Church
585352	Broward	Fort Lauderdale	33315	Trinity Lutheran Church
585987	Duval	Jacksonville	32205	Trinity Lutheran Church - ELCA
588363	Pasco	New Port Richey	34655	Trinity Presbyterian Church
588532	Pinellas	Clearwater	33765	Trinity Presbyterian Church
588936	Sarasota	Venice	34293	TRINITY PRESBYTERIAN CHURCH
586055	Escambia	Pensacola	32503	TRINITY PRESBYTERIAN CHURCH
585186	Alachua	Gainesville	32653	Trinity United Methodist Church
589135	Volusia	Seville	32190	Trinity United Methodist Church
587600	Okaloosa	Fort Walton Beach	32547	Trinity United Methodist Church
585026	DeSoto	Arcadia	34266	Trinity United Methodist Church
588596	Polk	Lakeland	33815	Trinity United Methodist Church
588930	Sarasota	Sarasota	34231	TRINITY UNITED METHODIST CHURCH
585274	Brevard	Satellite Beach	32937	Trinity Wellsprings Church
588891	Sarasota	Sarasota	34234	TRI PAR RECREATION HALL
588394	Pasco	Zephyrhills	33541	Tropical Acres Clubhouse
587313	Miami-Dade	Miami	33165	Tropical Estates Recreation Center
585284	Brevard	Melbourne	32901	Tropical Haven Auditorium
588376	Pasco	Zephyrhills	33541	Tropical Mobile Home Park Clubhouse
587086	Miami-Dade	Miami	33155	Tropical Park Community Center
588735	St. Johns	St Augustine	32092	Trout Creek Community Ctr
589050	Sumter	The Villages	32162	TRUMAN REC CTR
586681	Leon	Tallahassee	32304	Truth Gatherers Dream Center
587500	Miami-Dade	Miami	33175	Turningpoint Baptist Church
588994	Seminole	Winter Springs	32708	Tuscawilla Country Club
589021	Seminole	Casselberry	32707	Tuskawilla United Methodist Church
587271	Miami-Dade	Miami	33147	Twin Lakes Apartments
587370	Miami-Dade	Hialeah	33012	Twin Lakes Elementary School
588876	Sarasota	Sarasota	34241	TWIN LAKES PARK
585540	Broward	Hollywood	33021	T. Y. Park
587709	Orange	Orlando	32816	UCF - THE GARDEN ROOM AT LIVE OAK EVENT CENTER
585260	Brevard	Cocoa	32926	UF/IFAS Brevard County Extension Services
587531	Miami-Dade	North Miami Beach	33162	Uleta Community Center
587957	Palm Beach	West Palm Beach	33409	U M Church of Palm Beaches
586117	Gadsden	Quincy	32351	Union Chapel AME Church
587933	Palm Beach	West Palm Beach	33415	Union Congregational Church
586217	Hillsborough	Tampa	33612	Unisen Senior Living
586692	Leon	Tallahassee	32312	Unitarian Universalist Church
586663	Lee	Fort Myers	33912	Unitarian Universalist Church
586287	Hillsborough	Tampa	33637	Unitarian Universalist Church
587226	Miami-Dade	Miami	33143	Unitarian Universalist Congregation
588032	Palm Beach	Boca Raton	33434	Unitarian Universalist Fellowship
585216	Alachua	Gainesville	32605	Unitarian Universalist Fellowship
586477	Indian River	Vero Beach	32960	Unitarian Universalist Fellowship Hall
589177	Volusia	Daytona Beach	32117	United Brethren in Christ Church
586682	Leon	Tallahassee	32308	United Church of Tallahassee
587782	Orange	Orlando	32825	UNITED CHURCH OF THE NAZARENE
588701	Polk	Lakeland	33803	United Methodist Temple
584966	Taylor	Perry	32348	United Steel Workers Bdg
587077	Miami-Dade	Miami	33187	Unity Center of Miami
586465	Indian River	Vero Beach	32960	Unity Church of Vero Beach
588702	Polk	Lakeland	33813	Unity In The Grove Church
585933	Duval	Jacksonville	32208	Unity Missionary Baptist Church
588540	Pinellas	St Petersburg	33710	Unity of St. Petersburg
587335	Miami-Dade	Miami Gardens	33056	Universal Truth Center
586446	Hillsborough	Tampa	33613	University Area Community Center
586295	Hillsborough	Tampa	33612	University Baptist Church
585198	Alachua	Gainesville	32605	University City Church of Christ
589153	Volusia	Orange City	32763	University High School
587048	Miami-Dade	Miami	33184	University Lakes Recreation Hall
585954	Duval	Jacksonville	32277	University Park Branch Library
586819	Manatee	University Park	34201	University Park Country Club
587719	Orange	Orlando	32817	UNIVERSITY PRESBYTERIAN CHURCH
587761	Orange	Orlando	32817	UNIVERSITY UNITARIAN UNIVERSALIST FELLOWSHIP
587007	Miami-Dade	Miami	33177	Upper Room Assembly of God
586353	Hillsborough	Tampa	33620	USF Marshall Center
585833	Duval	Atlantic Beach	32233	USO Center of Jacksonville
585351	Broward	Weston	33326	Vacation Village Social Center
588219	Palm Beach	Delray Beach	33446	Valencia Falls Clubhouse
588087	Palm Beach	Boynton Beach	33437	Valencia Lakes HOA
588147	Palm Beach	Boynton Beach	33437	Valencia Pointe Clubhouse
587943	Palm Beach	Lake Worth	33467	Valencia Shores Social Club
589185	Walton	Ponce De Leon	32455	Valley View Community Center
585792	Collier	Naples	34110	Vanderbilt Presbyterian Church
585491	Broward	Tamarac	33321	Vanguard Village #15 Clubhouse
586704	Leon	Tallahassee	32309	Vantage Church
587970	Palm Beach	Boynton Beach	33472	Venetian Isles Clubhouse
588947	Sarasota	Sarasota	34238	VENETIAN PARK ESTATES
588932	Sarasota	North Venice	34275	VENETIAN RIVER CLUB
588894	Sarasota	Venice	34285	VENICE COMMUNITY CENTER
588954	Sarasota	Venice	34293	VENICE GARDENS CIVIC CENTER
588914	Sarasota	Venice	34285	VENICE ISLE MOBILE ESTATES
586188	Highlands	Venus	33960	VENUS CLUBHOUSE
587926	Palm Beach	Boca Raton	33433	Verde K-8 School
585112	Washington	Vernon	32462	Vernon City Hall- Council Meeting Room
585397	Broward	Miramar	33023	Vernon E. Hargray Youth Enrichment Center
585771	Collier	Naples	34114	Verona Walk Town Center
585773	Collier	Naples	34109	Veterans Community Park
585334	Brevard	Merritt Island	32952	Veterans Memorial Center
585266	Brevard	West Melbourne	32904	Veterans Memorial Complex
588301	Pasco	Hudson	34669	Veterans Memorial Park Gym
586167	Hernando	Spring Hill	34609	VETERANS OF FOREIGN WARS
587205	Miami-Dade	Hialeah	33018	Veterans Park
585602	Broward	Lauderhill	33351	Veterans Park
586649	Lee	Lehigh Acres	33936	Veterans Park Recreation Center
588234	Palm Beach	Delray Beach	33483	Veterans Park Recreation Ctr
588390	Pasco	Dade City	33525	VFW Post 4283
587813	Orange	Orlando	32822	VFW POST 4287
588321	Pasco	Hudson	34669	VFW Post 4412
585762	Collier	Naples	34119	VFW Post # 7721
585718	Clay	Middleburg	32068	VFW POST # 8255
586104	Flagler	Palm Coast	32137	VFW POST #8696
586065	Escambia	Pensacola	32504	VICKREY RESOURCE CENTER
587831	Orange	Orlando	32837	VICTORIA CHURCH
587314	Miami-Dade	Hialeah	33012	Victor Wilde Community Center
588621	Polk	Lakeland	33810	Victory Assembly of God
586345	Hillsborough	Tampa	33611	Victory Baptist Church
584955	Bradford	Hampton	32044	Victory Baptist Church
585255	Brevard	Cocoa	32927	Victory Baptist Church
586403	Hillsborough	Valrico	33594	Victory Baptist Church of Brandon
586009	Duval	Jacksonville	32226	Victory Celebration Church
587939	Palm Beach	Boca Raton	33431	Victory Church
585287	Brevard	Melbourne	32940	Viera Regional Community Center
588100	Palm Beach	Delray Beach	33446	Villa Borghese Clubhouse
588027	Palm Beach	Delray Beach	33444	Village Academy
588753	St. Johns	St. Augustine	32092	Village Church
588646	Polk	Winter Haven	33880	Village Church of God
585539	Broward	Sunrise	33313	Village Civic Center
587537	Miami-Dade	Miami	33175	Village Green Elementary School
588267	Palm Beach	Village Of Golf	33436	Village of Golf Admin Bldg
586437	Hillsborough	Tampa	33618	Village Presbyterian Church
588043	Palm Beach	Boynton Beach	33435	Village Royale on the Green
587016	Miami-Dade	Homestead	33035	Villages of Homestead Audubon Club House
585781	Collier	Naples	34109	Village Walk Community Center
588149	Palm Beach	Wellington	33414	Village Walk HOA Clubhouse
588021	Palm Beach	Wellington	33414	Villa Olympia Clubhouse
586622	Lee	Cape Coral	33904	Vineyard Community Church
585806	Collier	Naples	34119	Vineyards Community Park
587292	Miami-Dade	Miami	33180	Virginia A. Boone Highland Oaks Elementary School
587142	Miami-Dade	Virginia Gardens	33166	Virginia Gardens Town Hall
585374	Broward	Fort Lauderdale	33301	Virginia S. Young Elementary School
589188	Walton	Santa Rosa Beach	32459	Vision Church at Christian International
587025	Miami-Dade	Miami	33179	Visitation Catholic Church
586338	Hillsborough	Tampa	33602	Vista 400
587734	Orange	Orlando	32829	VISTA COMMUNITY CHURCH
587633	Orange	Orlando	32829	VISTA LAKES CLUBHOUSE
587272	Miami-Dade	Hialeah	33012	Vivian Villas
587121	Miami-Dade	Miami	33129	Vizcaya Village Garage - Historic Garage
585098	Franklin	Eastpoint	32328	Volunteer Fire Department
585605	Broward	Plantation	33323	Volunteer Park Community Center
589122	Volusia	Pierson	32180	Volusia Lodge #77 F&AM
584835	Jefferson	Monticello	32344	Wacissa Volunteer Fire Dept
587786	Orange	Orlando	32806	WADEVIEW NEIGHBORHOOD CENTER
586111	Flagler	Palm Coast	32164	WADSWORTH ELEMENTARY SCHOOL
586644	Lee	Fort Myers	33908	Wa-ke Hatchee Recreation Center
586778	Manatee	Bradenton	34208	Wakeland Support Center
585078	Wakulla	Crawfordville	32327	WAKULLA-First Baptist Chu WAK-STAT
585884	Duval	Jacksonville	32209	Wallace Small Senior Citizen Center
585492	Broward	Pembroke Pines	33024	Walnut Creek Park
586067	Escambia	Walnut Hill	32568	WALNUT HILL COMMUNITY CENTER
585299	Brevard	Cocoa	32927	Walter Butler Community Center
585369	Broward	Pembroke Pines	33028	Walter C. Young Middle School
584900	Nassau	Callahan	32011	Walter Junior Boatright County Building
588444	Pinellas	St Petersburg	33710	Walter P. Fuller Recreation Center
585343	Broward	Fort Lauderdale	33311	Warfield Park Recreation Center
588506	Pinellas	St. Pete Beach	33706	Warren Webster Community Center
586047	Escambia	Pensacola	32507	WARRINGTON CHURCH
586028	Escambia	Pensacola	32507	WARRINGTON PRESBYTERIAN
584956	Glades	Moore Haven	33471	Washington Park Comm. ctr.
585430	Broward	Hollywood	33023	Washington Park Community Center
587282	Miami-Dade	North Miami Beach	33162	Washington Park Community Center
585499	Broward	Sunrise	33313	Waterbridge Project Rec. Center
587733	Orange	Winter Garden	34787	WATER CONSERV II DISTRIBUTION CENTER
588887	Sarasota	Venice	34292	WATERFORD SPORTS CLUB
586770	Manatee	Bradenton	34212	Waterlefe Golf Club
586520	Lake	Lady Lake	32159	Water Oak-Main Clubhouse
586672	Lee	Cape Coral	33991	Waters Edge Church
588146	Palm Beach	Boca Raton	33428	Waters Edge Elementary
586273	Hillsborough	Apollo Beach	33572	Waterset Clubhouse
588972	Seminole	Longwood	32750	WaterStone Church
587108	Miami-Dade	Homestead	33033	Waterstone Grand
585088	Columbia	Lake City	32055	WATERTOWN POLLING LOCATION
585432	Broward	Pompano Beach	33069	Water & Wastewater Services
587304	Miami-Dade	Coral Gables	33146	Watsco Center at University of Miami
588133	Palm Beach	Palm Beach Gardens	33418	Watson B Duncan Middle
584973	Hardee	Wauchula	33873	WAUCHULA CITY HALL
584967	Hardee	Wauchula	33873	Wauchula Civic Center
585035	Jefferson	Monticello	32344	Waukeenah Church(Methodist)
589085	Volusia	Deland	32724	Wayne G. Sanborn Activities Center Building
588617	Polk	Lake Wales	33898	Wayside Baptist Church
586910	Marion	Ocala	34474	Webber Center, Bldg. 71
585980	Duval	Jacksonville	32210	Webb Wesconnett Regional Library
589047	Sumter	Webster	33597	WEBSTER COMMUNITY BUILDING
587753	Orange	Orlando	32833	WEDGEFIELD GOLF CLUB
589011	Seminole	Longwood	32779	Wekiva Presbyterian Church
587839	Orange	Apopka	32712	WEKIWA SPRINGS BAPTIST CHURCH
584997	Jackson	Grand Ridge	32442	WELCOME ASSEMBLY OF GOD CH
586553	Lake	Leesburg	34748	Welcome Home Christian Church
585036	Suwannee	Wellborn	32094	WELLBORN MASONIC LODGE
585507	Broward	Sunrise	33351	Welleby Elementary School
588013	Palm Beach	Wellington	33414	Wellington Branch Library
586149	Hernando	Spring Hill	34609	WELLINGTON CLUBHOUSE
588160	Palm Beach	Wellington	33414	Wellington Community Center
588188	Palm Beach	Wellington	33414	Wellington Elementary
588022	Palm Beach	Wellington	33414	Wellington High
587896	Palm Beach	Wellington	33414	Wellington Landings Middle
588164	Palm Beach	Wellington	33414	Wellington Village Park
588238	Palm Beach	Riviera Beach	33404	Wells Rec Community Center
586259	Hillsborough	Tampa	33603	Wellswood Civic Center
585970	Duval	Jacksonville	32244	Wesconnett Assembly of God
585828	Duval	Jacksonville	32210	Wesconnett Church of Christ
589147	Volusia	Deltona	32738	Wes Crile Park - Gym
588282	Pasco	Wesley Chapel	33545	Wesley Chapel District Park
587529	Miami-Dade	Miami	33175	Wesley Matthews Elementary School
586292	Hillsborough	Tampa	33615	Wesley Memorial United Methodist Church
587040	Miami-Dade	Miami	33135	Wesley United Methodist Church
588016	Palm Beach	Boca Raton	33498	West Boca Branch Library
588167	Palm Beach	Boca Raton	33498	West Boca Raton High
584928	Holmes	Bonifay	32425	West Bonifay
588103	Palm Beach	Boynton Beach	33437	West Boynton Branch Library
588172	Palm Beach	Lake Worth	33463	West Boynton Park and Rec
586775	Manatee	Bradenton	34209	West Bradenton Baptist Church
589025	Seminole	Longwood	32779	West Branch Library
586452	Hillsborough	Tampa	33626	Westchase Swim and Tennis Center
587486	Miami-Dade	Miami	33165	Westchester Regional Library
585668	Citrus	Homosassa	34446	WEST CITRUS ELKS LODGE
587286	Miami-Dade	Miami	33144	West End Park Community House
585354	Broward	Davie	33325	Western High School
588217	Palm Beach	Westlake	33411	Western Pines Middle
587907	Palm Beach	West Palm Beach	33409	Westgate Community Center
585600	Broward	Parkland	33076	West Glades Middle School
587422	Miami-Dade	Hialeah Gardens	33018	West Hialeah Gardens Elementary School
586995	Miami-Dade	Homestead	33030	West Homestead Elementary School
588161	Palm Beach	Jupiter	33458	West Jupiter Recreation Center
587405	Miami-Dade	Miami	33196	West Kendall Regional Library
587365	Miami-Dade	Hialeah Gardens	33018	Westland Gardens Park
587381	Miami-Dade	Miami	33147	West Little River Park
585316	Brevard	Melbourne	32904	West Melbourne Public Library
587247	Miami-Dade	Miami	33155	West Miami Middle School
587085	Miami-Dade	West Miami	33155	West Miami Recreation Center
589139	Volusia	Daytona Beach	32118	Westminster by the Sea Presbyterian Church
586738	Leon	Tallahassee	32301	Westminster Gardens
586690	Leon	Tallahassee	32308	Westminster Oaks
585213	Alachua	Gainesville	32605	Westminster Presbyterian Church
588986	Seminole	Altamonte Springs	32714	Westmonte Recreation Center
587794	Orange	Ocoee	34761	WEST OAKS MALL STARS VOLUNTEER CENTER
585529	Broward	Weston	33332	Weston Branch Library
585347	Broward	Weston	33327	Weston Community Center
588000	Palm Beach	Delray Beach	33445	West Park Baptist Church
585634	Broward	Sunrise	33351	Westpine Middle School
585876	Duval	Jacksonville	32221	West Regional Library
586436	Hillsborough	Tampa	33609	West Shore Baptist Church
585173	Alachua	Gainesville	32606	Westside Baptist Church
588996	Seminole	Sanford	32771	Westside Community Center
585132	Columbia	Lake City	32024	WESTSIDE COMMUNITY CENTER
585983	Duval	Jacksonville	32210	Westside Family Worship Center
585215	Alachua	Gainesville	32605	Westside Recreation Center
584886	Calhoun	Blountstown	32424	Westside VFD
586347	Hillsborough	Tampa	33607	West Tampa Branch Library
588980	Seminole	Sanford	32771	Westview Baptist Church
587334	Miami-Dade	Miami	33167	Westview Baptist Church
585627	Broward	Pembroke Pines	33024	Westview Lakes Clubhouse
588001	Palm Beach	West Palm Beach	33401	Westward Elementary
585754	Collier	Naples	34114	West Wind Estates Comm Bldg
586977	Miami-Dade	Miami	33193	Westwind Lakes Park
588240	Palm Beach	Palm Beach Gardens	33418	Westwood Gardens HOA
587354	Miami-Dade	Miami	33165	Westwood Park
586130	Gadsden	Quincy	32351	Wetumpka Fire Dept.
585630	Broward	Fort Lauderdale	33315	Whiddon Rogers Education Center
587789	Orange	Winter Garden	34787	WHISPERING OAK ELEMENTARY SCHOOL
588089	Palm Beach	Boca Raton	33496	Whispering Pines Elementary
587183	Miami-Dade	Cutler Bay	33157	Whispering Pines Elementary School
588229	Palm Beach	Boca Raton	33496	Whisper Walk Section C
585001	Gulf	White City	32465	White City Fire Station
585852	Duval	Jacksonville	32220	Whitehouse Baptist Church
584971	Hamilton	White Springs	32096	WHITE SPRINGS LIBRARY
585294	Brevard	Melbourne	32935	Wickham Park Community Center
585890	Duval	Jacksonville	32206	Wilbur Fernander Scottish Rite Masonic Cathedral
587444	Miami-Dade	Miami	33196	Wild Lime Park
589048	Sumter	Wildwood	34785	WILDWOOD COMM BLDG
586728	Leon	Tallahassee	32312	Wildwood Presbyterian Church
586992	Miami-Dade	Miami	33176	William Lehman Elementary School
588277	Pasco	Wesley Chapel	33543	Williamsburg Tanglewood Club
587295	Miami-Dade	Aventura	33160	Williams Island Club House
587936	Palm Beach	Palm Beach Gardens	33418	William T Dwyer High
585577	Broward	Lauderdale Lakes	33311	Willie L. Webb Sr. Park
588433	Pinellas	St Petersburg	33702	Willis S. Johns Recreation Center
585086	Levy	Williston	32696	Williston City Hall
585041	Levy	Williston	32696	Williston Highlands
588391	Pasco	Lutz	33558	Willow Bend Community Church
589002	Seminole	Winter Springs	32708	Willow Creek Church
588573	Polk	Mulberry	33860	Willow Oak Baptist Church
585082	Levy	Chiefland	32626	Willow Sink
586427	Hillsborough	Wimauma	33598	Wimauma Community Church of God
587743	Orange	Windermere	34786	WINDERMERE TOWN HALL
587805	Orange	Orlando	32819	WINDHOVER CONDO ASSOCIATION
585180	Alachua	Gainesville	32641	Windsor Baptist Church
586084	Escambia	Pensacola	32526	WINDY HILL BAPTIST CHURCH
585919	Duval	Jacksonville	32246	Windy Hill Community Center
584887	Columbia	Lake City	32055	WINFIELD COMMUNITY CENTER
587665	Orange	Orlando	32819	WINGATE BY WYNDHAM
587667	Orange	Orlando	32821	WIN-GATE VILLAGE CLUB
586872	Marion	Ocala	34472	Wings of Faith, Life Center
586579	Lee	Fort Myers	33919	Winkler Road Baptist Church
588656	Polk	Lakeland	33803	Winston Baptist Church
587480	Miami-Dade	Miami	33183	Winston Park Club House
585465	Broward	Coconut Creek	33073	Winston Park Elementary School
587158	Miami-Dade	Sunny Isles Beach	33160	Winston Towers Assoc - Bldg 100
588135	Palm Beach	Lake Worth	33463	Winston Trails Swim Center
588674	Polk	Winter Haven	33880	Winter Haven Senior Adult Center
587692	Orange	Winter Park	32789	WINTER PARK 9TH GRADE CENTER
587756	Orange	Winter Park	32792	WINTER PARK CHRISTIAN CHURCH
589013	Seminole	Winter Park	32792	Winter Park Elks Lodge No. 1830
587830	Orange	Winter Park	32792	WINTER PARK HIGH SCHOOL
587746	Orange	Winter Park	32792	WINTER PARK PRESBYTERIAN CHURCH
589022	Seminole	Winter Springs	32708	Winter Springs City Hall
589020	Seminole	Winter Springs	32708	Winter Springs Senior Center
586387	Hillsborough	Riverview	33578	Winthrop Barn Theatre
588318	Pasco	Wesley Chapel	33543	Wiregrass Ranch Sports Campus
588971	Seminole	Casselberry	32707	Wirz Park
587472	Miami-Dade	North Miami	33161	W.J. Bryan Elementary School
586971	Martin	Stuart	34994	Women's Club of Stuart
588611	Polk	Lakeland	33815	Woodall's M. H. P.
588870	Santa Rosa	Pace	32571	Woodbine Church
587701	Orange	Orlando	32828	WOODBURY PRESBYTERIAN CHURCH
588189	Palm Beach	Boca Raton	33496	Woodfield Country Club HOA
587960	Palm Beach	Lake Worth	33467	Woodlands Middle
587595	Okaloosa	Crestview	32536	Woodlawn Baptist Church
588724	Putnam	Hawthorne	32640	Woodlawn Baptist Church
585825	Duval	Jacksonville	32209	Woodlawn Presbyterian Church
588484	Pinellas	St Petersburg	33704	Woodlawn Presbyterian Church
588956	Sarasota	Venice	34293	WOODMERE PARK
586332	Hillsborough	Temple Terrace	33617	Woodmont Clubhouse
587837	Orange	Orlando	32818	WOODRIDGE APARTMENTS
586696	Leon	Tallahassee	32311	Woodrun Baptist Church
586858	Marion	Ocklawaha	32179	Woods & Lakes Club House
588448	Pinellas	Clearwater	33759	Wood Valley Boys and Girls Club
586688	Leon	Tallahassee	32305	Woodville Community Center
585251	Brevard	Merritt Island	32953	Woody Simpson Park
587824	Orange	Apopka	32712	WORD OF LIFE CHURCH
586654	Lee	Lehigh Acres	33936	World Wide Miracle Ministries
587912	Palm Beach	West Palm Beach	33401	WPB City Fire Station #5
587950	Palm Beach	West Palm Beach	33401	WPB City Hall Flagler Gallery
587584	Okaloosa	Fort Walton Beach	32547	Wright Baptist Church
587147	Miami-Dade	Miami	33175	W.R. Thomas Middle School
584951	Union	Lake Butler	32054	WS COMM CNTR
587768	Orange	Orlando	32832	WYCLIFFE DISCOVERY CENTER
588070	Palm Beach	Lake Worth	33449	Wycliffe Golf and Country Club
587708	Orange	Orlando	32824	WYNDHAM LAKES CLUBHOUSE
587735	Orange	Orlando	32824	WYNDHAM LAKES ELEMENTARY SCHOOL
585590	Broward	Coconut Creek	33066	Wynmoor Comm. Council West Wing
587353	Miami-Dade	Miami	33127	Wynwood Community Resource Center
587857	Osceola	Kissimmee	34741	YMCA
586148	Hernando	Spring Hill	34609	YMCA OF THE SUNCOAST
585244	Bay	Youngstown	32466	Youngstown Fire Station
585060	Hendry	Clewiston	33440	Youth Center
585066	Nassau	Yulee	32097	Yulee Sports Complex
587700	Orange	Zellwood	32798	ZELLWOOD UNITED METHODIST CHURCH
588292	Pasco	Zephyrhills	33542	Zephyrhills Elks Lodge #2731
588386	Pasco	Zephyrhills	33541	Zephyr Shores Estates POA Clubhouse
585932	Duval	Jacksonville	32209	Zion Hope Missionary Baptist Church
586666	Lee	Fort Myers	33919	Zion Lutheran Church
586891	Marion	Ocala	34475	Zion U.M. Church
584864	Hardee	Zolfo Springs	33890	ZOLFO SPRINGS CIVIC CENTER
`