<!-- Part of the SPARKL educational activity system, Copyright 2019 by Pepper Williams -->
<template><v-dialog v-model="dialog_open" :max-width="dialog_max_width" persistent scrollable>
	<v-card class="k-resource-editor-outer">

		<v-card-title style="border-bottom:1px solid #999"><b style="font-weight:900" v-html="header_text"></b></v-card-title>

		<v-card-text class="pt-3" style="background-color:#eee">
			<div v-if="is_new_resource">
				<div style="border-bottom:1px solid #999;" class="pb-4 mb-4">
					<div class="text-center mb-2 grey--text text--darken-3" style="font-size:16px;" :style="selected_resource_pool=='new'?'opacity:0.9':''">
						<div v-if="selected_resource_pool!='new'"><b><nobr>Choose an existing resource:</nobr></b></div>
						<v-btn v-if="selected_resource_pool=='new'" small color="primary" @click="selected_resource_pool='existing'">Choose an existing resource</v-btn>
					</div>
					<div class="d-flex align-center" v-if="selected_resource_pool!='new'">
						<v-autocomplete v-model="selected_resource" prepend-inner-icon="fas fa-search" :items="resource_selector_items" :filter="resource_selector_filter" :search-input.sync="autocomplete_search" label="Find a resource to use…" :no-data-text="selected_resource_no_data_text" outlined hide-details dense background-color="#fff" @change="existing_resource_selected"></v-autocomplete>
						<v-btn small color="secondary" class="ml-2" @click="preview_selected_resource" :disabled="!selected_resource">Preview<v-icon small class="ml-1">fas fa-up-right-from-square</v-icon></v-btn>
					</div>
				</div>
				<div v-if="selected_resource_pool!='new'" class="mt-6 text-center grey--text text--darken-3" style="font-size:16px" :style="(selected_resource_pool!='new'&&selected_resource_pool!='')?'opacity:0.9':''"><b class="mr-2">OR</b> <v-btn small color="primary" @click="selected_resource_pool='new'"><v-icon small class="mr-2">fas fa-plus</v-icon>Add a new resource</v-btn></div>
			</div>

			<div v-if="!is_new_resource||selected_resource_pool=='new'" class="mt-4">
				<div class="d-flex align-center mb-2">
					<b v-if="is_new_resource" class="grey--text text--darken-3 mr-2">New Resource:</b>
					<v-select v-model="edited_resource.type" :items="resource_types" label="Type" tabindex="1" outlined hide-details dense background-color="#fff" style="max-width:150px" @change="resource_type_selected"></v-select>
					<v-text-field v-show="resource_type!='none'&&resource_type!='html'" hide-details outlined clearable :label="url_field_label" v-model="edited_resource.url" background-color="#fff" dense tabindex="2" class="ml-2" autocomplete="new-password"></v-text-field>
					<v-btn v-show="resource_type=='upload'" @click="upload_file" class="ml-2" fab small color="light-blue" dark><v-icon>fas fa-file-upload</v-icon></v-btn>
				</div>

				<v-textarea v-show="resource_type!='none'" class="my-4" hide-details outlined label="Resource title" v-model="edited_resource.title" background-color="#fff" auto-grow dense rows="1" tabindex="3"></v-textarea>

				<div class="my-4">
					<froala v-show="resource_type=='html'" :config="editor_config()" v-model="resource_html" />
				</div>
			</div>
		</v-card-text>
		<v-card-actions class="pa-3" style="border-top:1px solid #999">
			<v-btn small class="mr-2" color="secondary" @click="$emit('edit_resource_cancel')" tabindex="6"><v-icon small class="mr-2">fas fa-times</v-icon>Cancel</v-btn>
			<v-spacer/>
			<v-btn v-if="selected_resource||resource_type!='none'" small color="primary" @click="save_resource" tabindex="5"><v-icon small class="mr-2">fas fa-check</v-icon>{{save_btn_text}}</v-btn>
		</v-card-actions>
	</v-card>
</v-dialog></template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
	components: {  },
	props: {
		original_resource: { required: true },	// if this is type string, we're creating a new resource
	},
	data() { return {
		dialog_open: true,
		edited_resource: null,
		resource_types: [
			{ value: 'website', text: 'Website' },
			{ value: 'video', text: 'Video' },
			{ value: 'upload', text: 'Uploaded File (pdf)' },
			{ value: 'html', text: 'Text (entered here)' },
		],
		resource_html: '',

		uploaded_file: null,
		uploaded_file_name: '',

		selected_resource_pool: 'existing',
		selected_resource: null,
		selected_resource_no_data_text: '',

		autocomplete_loading: false,
		autocomplete_search: '',
		autocomplete_items: [],

		editor_component_id: U.new_uuid(),
	}},
	computed: {
		...mapState(['site_resources']),
		dialog_max_width() {
			if (!this.edited_resource) return 640
			if (this.edited_resource.type == 'html') return 820
			return 640
		},
		is_new_resource() { return typeof(this.original_resource) == 'string' },
		resource_type() { return this.edited_resource.type },
		header_text() { return this.is_new_resource ? 'Add Resource' : 'Edit Resource' },
		save_btn_text() { return this.is_new_resource ? 'Add Resource' : 'Save Resource' },
		url_field_label() {
			if (this.resource_type == 'upload') return 'Resource File Identifier'
			return 'Resource URL'
		},
		resource_selector_items() {
			let arr = []
			if (this.selected_resource_pool == 'existing') {
				for (let r of this.site_resources) {
					let item = {value: r, text: sr('$1 ($2)', r.title, r.type_label())}
					item.text_lc = item.text.toLowerCase()
					arr.push(item)
				}

			} else if (this.selected_resource_pool == 'all') {
				// results of live search -- not currently used
				return this.autocomplete_items
			}

			// easy natural sort algorithm that actually seems to work!
			// https://fuzzytolerance.info/blog/2019/07/19/The-better-way-to-do-natural-sort-in-JavaScript/
			arr.sort((a, b) => a.text.localeCompare(b.text, navigator.languages[0] || navigator.language, {numeric: true, ignorePunctuation: true}))

			return arr
		},
		autocomplete_search_lc() {
			let s = this.autocomplete_search.replace(/[^\w-]/g, '')
			return s.toLowerCase()
		},
	},
	watch: {
		// not currently used...
		autocomplete_search(val) {
			return

			// if user pastes in a link, shrink to the resource_id
			if (this.autocomplete_search && this.autocomplete_search.search(/\/reource\/(.*)/) > -1) {
				this.autocomplete_search = RegExp.$1
			}

			// we only do autocomplete if selected_resource_pool == 'all'
			if (!this.selected_resource_pool == 'all') return

			// items have already been requested
			if (this.autocomplete_loading) return

			// don't search until we have X characters; then if the value drops back below X characters, clear autocomplete_items so we'll search again when we get back to X
			if (!val || val.length < 4) {
				this.selected_resource_no_data_text = val ? 'Type at least 4 characters to search' : 'Type to search'
				this.autocomplete_items = []
				return
			} else if (this.autocomplete_items.length > 0 && val.length > 4) {
				// if we don't have any items, the search service may have overloaded, so keep trying if the user keeps typing
				return
			}
			this.selected_resource_no_data_text = 'Searching…'

			// if we get to here, search for items
			this.autocomplete_loading = true
			let payload = {
				user_id: vapp.user_info.user_id,
				return_abbrev: false,
				stem: val,
			}
			U.ajax('resource_stem_search', payload, result=>{
				if (result.entries.length == 0) {
					if (result.overflow) this.selected_resource_no_data_text = 'Type more characters to narrow search results'
					else this.selected_resource_no_data_text = 'No resources found'
				} else {
					this.selected_resource_no_data_text = 'xxx'	// this shouldn't ever be seen
				}

				// parse results, which should be in result.entries
				let arr = []
				for (let entry of result.entries) {
					let r = new Site_Resource(entry)
					let title = sr('$1 ($2)', r.title, r.type_label())

					// avoid duplicates
					if (!arr.find(x=>x.text == title)) {
						arr.push({value: r, text: title, text_lc: title.toLowerCase()})
					}
				}
				this.autocomplete_items = arr
				this.autocomplete_loading = false
			})
		},
	},
	created() {
		// create edited_resource, based on original_resource (if we received one)
		if (!this.is_new_resource) {
			this.edited_resource = new Site_Resource(this.original_resource)

			// TODO: ???
			// retrieve resource_html if we should already have it
			if (this.edited_resource.type == 'html' && !empty(this.edited_resource.url)) {
				let html_url = '/user-files/' + this.edited_resource.url
				$.ajax({
					url: html_url,
					success: (html)=>{
						this.resource_html = html
					},
					cache: false,
				})
			}

		// else it's a new resource
		} else {
			this.edited_resource = new Site_Resource({resource_id:'new'})
		}

		this.$store.commit('set', ['editor_components', this.editor_component_id, this])
	},
	mounted() {
	},
	methods: {
		editor_config(text) {
			return U.get_froala_config({
				placeholderText: 'Enter text here',
				zIndex: 2501,	// this is needed to show the image editor properly, because it's in a dialog
			})
		},

		resource_selector_filter(item, queryText, itemText) {
			// console.log(item, this.autocomplete_search)
			// https://henryconnects.henry.k12.ga.us/reslink/RESOURCE_6_SCI_GA19ED_ESE_G03U00L00_001_EN
			return item.text_lc.indexOf(this.autocomplete_search_lc) > -1 || (this.autocomplete_search && this.autocomplete_search.indexOf(item.value.resource_id) > -1)
		},

		upload_file() {
			// prompt for file to import
			this.$prompt({
				text: '<p>Select the file you’d like to upload:</p>',
				promptType: 'file',
				acceptText: 'Choose File',
				cancelText: 'Cancel'
			}).then(file => {
				// if (file.name.search(/.*(\.\w+)$/) == -1) {
				// only allowing for pdf here
				if (file.name.search(/.*(\.pdf)$/) == -1) {
					this.$alert('Please save your uploaded file as a .pdf document.')
					return
				}

				this.uploaded_file = file
				this.uploaded_file_name = file.name.replace(/.*(\.\w+)$/, U.new_uuid() + '$1')

				// set url to this.uploaded_file_name
				this.edited_resource.url = this.uploaded_file_name

				// if title is empty, set to the actual file name
				if (empty(this.edited_resource.title)) {
					this.edited_resource.title = file.name
				}

			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		set_teacher_facing_default(new_default) {
			U.local_storage_set('district_portal_default_teacher_facing', new_default)
		},

		resource_type_selected() {
			if (this.resource_type == 'upload') {
				this.upload_file()
			}
		},

		preview_selected_resource() {
			vapp.open_resource_link(this.selected_resource)
		},

		existing_resource_selected() {
			console.log('existing_resource_selected', this.selected_resource)
		},

		save_resource() {
			// if an existing resource was selected, all we have to do is emit the edit_resource_saved event
			if (this.selected_resource) {
				this.$emit('edit_resource_saved', this.selected_resource)
				return
			}

			let uploaded_file = null

			// if type is upload, we must have a resource guid in the url field
			if (this.edited_resource.type == 'upload') {
				if (!U.is_uuid(this.edited_resource.url.replace(/\.\w+$/, ''))) {
					this.$alert('For an Uploaded File resource, you must either choose a file to upload, or enter a previously-uploaded Resource File Identifier.')
					return
				}
				// if the user specified a file to be uploaded, include it in the resource save operation
				// (the user can alternatively specify an already-existing filename -- though this is risky and will probably be undocumented)
				if (this.edited_resource.url == this.uploaded_file_name) {
					uploaded_file = this.uploaded_file
				}

			} else if (this.edited_resource.type == 'html') {
				if (empty(this.resource_html)) {
					this.$alert('Please enter the text you’d like to save for this resource.')
					return
				}
				uploaded_file = this.resource_html
				// generate a filename for the html, if not already specified
				if (!U.is_uuid(this.edited_resource.url.replace(/\.\w+$/, ''))) {
					this.edited_resource.url = U.new_uuid() + '.html'
				}

			} else {
				if (empty(this.edited_resource.url)) {
					this.$alert('You must specify the URL for the resource.')
					return
				}
			}

			if (empty(this.edited_resource.title)) {
				this.$alert('Please enter a brief title or description for the resource.')
				return
			}

			this.$store.dispatch('save_resource', {resource: this.edited_resource, uploaded_file: uploaded_file}).then((updated_resource)=>{
				// once resource is saved, emit edit_resource_saved, sending on the updated_resource data sent back from the service
				this.$emit('edit_resource_saved', updated_resource)
			}).catch(error=>{
				console.log(error)
				this.$alert(sr('A problem occurred when attempting to save the resource. Message from server:<div class="mt-2">$1</div>', error))
			})
		},
	}
}
</script>

<style lang="scss">
.k-resource-editor-outer {
	background-color:#fbe4d4;

	.v-input--checkbox .v-label {
		color:#222;
	}
}

.k-inline-checkbox-holder {
	background-color:#fff;
	padding:4px 6px;
	border:1px solid #9E9E9E;
	border-radius:4px;
}

// .k-resource-link, .k-resource-link-placeholder {
.k-resource-link-placeholder {
	background-color:$v-brown;
	color:#fff;
	padding:2px 8px;
	border-radius:5px!important;
	margin:0 4px!important;
	display:inline-block;
	height:20px;
	font-size:16px;
	cursor:pointer;
}

.k-resource-link {
	background-color:$v-brown;
	color:#fff;
	padding:2px 8px;
	border-radius:5px!important;
	margin:1px 4px!important;
	display:inline-block;
	line-height:23px;
	font-size:16px;
	cursor:pointer;
}
</style>
