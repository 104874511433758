import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		// this imports the version number from package.json, so we can show the version number in the ui with $state.store.app_version; also need something in vue.config.js (PACKAGE_VERSION)
		// run the following to update the third number; use 'minor' to update the second number and 'major' to update the first number
		// npm --no-git-tag-version version patch; npm run build; npm run serve
		app_version: process.env.PACKAGE_VERSION || '0.0.0',

		initialized: false,
		google_client_id: '',
		vh_org_id: '',
		user_info: {},

		users: [],
		locations: [],
		shifts: [],
		got_shifts: false,
		dialpad_users: [],
		site_data: {},
		page_data: {},
		site_resources: [],
		site_faq: [],
		site_faq_hash: {},

		my_shifts: [],
		current_shifts_file_content: '',
		editor_components: {},	// used for implementing the "add resource" button in the froala editor

		time_zone_correction: 0,

		site_version: 0,

		today_date: '',
		this_year: '',

		times: ['06:00', '06:30', '07:00', '07:30', '08:00', '08:30', '09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00', '19:30', '20:00', '20:30', '21:00', '21:30', '22:00' ],

		// Froala wildcard key -- received 11/12/2021
		froala_key: 'dKA5cC2D2E2G2D2C5zPAENHMi1JPQRFZBTBa1WWEPYDbA2B6C4D4F4B2A2C3H2B1==',

		// "local_storage settings": set defaults here; lst_initialize is called on initialization; call lst_set to set new values, possibly in computed:
		// foo: {
		// 	get() { return this.$store.state.lst.foo },
		// 	set(val) { this.$store.commit('lst_set', ['foo', val]) }
		// },
		// @update:foo="(val)=>foo=val"
		lst: {
			default_county: '',
			shift_counties: [],
			froala_image_size: '500',
			froala_paste_mode: 'normal',
			batch_user_import_fields: ['email', 'name_last', 'name_first'],
		},
		lst_prefix: 'flvh_local_storage_setting_',
	},
	getters: {
		// exercises:(state) => { return state.exercises },
		is_system_admin:(state) => {
			if (!state.user_info.is_system_admin) return false
			return state.user_info.is_system_admin()
		},
		is_county_admin:(state) => {
			if (!state.user_info.is_county_admin) return false
			return state.user_info.is_county_admin()
		},
		is_system_or_county_admin:(state) => {
			if (!state.user_info.is_system_or_county_admin) return false
			return state.user_info.is_system_or_county_admin()
		},
		user_hash:(state) => {
			console.log('calculate user_hash')
			let hash = {}
			for (let user of state.users) {
				hash[user.email] = user
			}
			return hash
		},
		all_dates:(state) => {
			// set all_dates, counting back from election day
			let year = date.format(new Date(), 'YYYY')
			let ade = date.parse(year + '-' + state.site_data.election_date, 'YYYY-MM-DD')
			let arr = [
				{ value: state.site_data.election_date, text: sr('ELECTION DAY! ($1)', date.format(ade, 'ddd MMM D')) },
			]
			for (let i = state.site_data.n_shift_dates; i > 0; --i) {
				let ad = new Date(ade.getTime() - (i * 24 * 60 * 60 * 1000))
				let val = date.format(ad, 'MM-DD')
				let text = date.format(ad, 'ddd MMM D')
				arr.push({ value: val, text: text })
			}
			return arr
		},
		all_dates_hotline:(state) => {
			// set all_dates_hotline, counting back from election day
			let year = date.format(new Date(), 'YYYY')
			let ade = date.parse(year + '-' + state.site_data.election_date, 'YYYY-MM-DD')
			let arr = [
				{ value: state.site_data.election_date, text: sr('ELECTION DAY! ($1)', date.format(ade, 'ddd MMM D')) },
			]
			for (let i = state.site_data.n_shift_dates_hotline; i > 0; --i) {
				let ad = new Date(ade.getTime() - (i * 24 * 60 * 60 * 1000))
				let val = date.format(ad, 'MM-DD')
				let text = date.format(ad, 'ddd MMM D')
				arr.push({ value: val, text: text })
			}
			return arr
		},
		available_dates:(state, getters) => {
			// filter all_dates to exclude ones earlier than today
			let arr = []
			for (let d of getters.all_dates) {
				if (d.value >= state.today_date) arr.push(d)
			}
			return arr
		},
		available_dates_hotline:(state, getters) => {
			// filter all_dates to exclude ones earlier than today
			let arr = []
			for (let d of getters.all_dates_hotline) {
				if (d.value >= state.today_date) arr.push(d)
			}
			return arr
		},
		org_state:(state) => {
			return state.site_data.vh_org_id.substr(0, 2).toUpperCase()
		},
	},
	mutations: {
		set(state, payload) {
			// this.$store.commit('set', ['key', val])
			// update state property 'key' to value 'val'
			if (payload.length == 2) {
				state[payload[0]] = payload[1]
				return
			}

			var o = payload[0]
			var key = payload[1]
			var val = payload[2]

			// this.$store.commit('set', ['obj', 'key', val])
			// update property 'key' of 'o' to value 'val'
			if (typeof(o) == 'string') {
				if (state[o][key] == undefined) Vue.set(state[o], key, val)
				else state[o][key] = val
				return
			}

			// this.$store.commit('set', [obj, 'key', true])
			// this.$store.commit('set', [obj, ['level_1_key', 'level_2_key'], true])
			// this.$store.commit('set', [obj, 'PUSH', 1])	// push 1 onto obj, which must be an array in this case
			// this.$store.commit('set', [obj, 'SPLICE', 2])	// splice value with index 2 from obj, which must be an array in this case
			// update property of obj, **WHICH MUST BE PART OF STATE!**
			if (typeof(key) == 'string') {
				if (key == 'PUSH') {
					o.push(val)
				} else if (key == 'UNSHIFT') {
					o.unshift(val)
				} else if (key == 'SPLICE') {
					// if we got a fourth value in payload, add that value into the array; otherwise just take the val-th item out
					if (!empty(payload[3])) {
						o.splice(val, 1, payload[3])
					} else {
						o.splice(val, 1)
					}
				} else if (o[key] == undefined) {
					Vue.set(o, key, val)
				} else {
					o[key] = val
				}
			} else {
				for (var i = 0; i < key.length-1; ++i) {
					o = o[key[i]]
					if (empty(o)) {
						console.log('ERROR IN STORE.SET', key, val)
						return
					}
				}
				if (o[key[i]] == undefined) Vue.set(o, key[i], val)
				else o[key[i]] = val
			}

			// samples:
			// this.$store.commit('set', [this.exercise, ['temp', 'editing'], true])
			// this.$store.commit('set', [this.qstatus, 'started', true])
		},

		push(state, payload) {
			// this.$store.commit('push', ['key', val])
			// push value 'val' onto property 'key', which must be an array
			if (payload.length == 2 && typeof(payload[0]) == 'string') {
				state[payload[0]].push(payload[1])
				return
			}

			// this.$store.commit('push', [obj, val])
			// push value onto array property of obj, **WHICH MUST BE PART OF STATE!**
			var o = payload[0]
			var val = payload[1]
			o.push(val)
		},

		// fns to initialize and set local_storage settings
		lst_initialize(state) {
			for (let key in state.lst) {
				let val = U.local_storage_get(state.lst_prefix + key)
				if (!empty(val)) {
					state.lst[key] = val
				}
			}
		},

		// this.$store.commit('lst_set', ['mc_mode', 'bubbles'])
		lst_set(state, payload) {
			let key, val
			if (typeof(payload) == 'string') {
				// if a single string value is sent in, we just save in local_storage; presumably the changed value will have been already saved via set
				U.local_storage_set(state.lst_prefix + payload, state.lst[payload])
			}

			if (Array.isArray(payload)) {
				key = payload[0]
				val = payload[1]
			} else {
				key = payload.key
				val = payload.val
			}

			// save in state
			state.lst[key] = val

			// now save in local_storage
			U.local_storage_set(state.lst_prefix + key, val)
		},

		lst_clear(state, key) {
			U.local_storage_clear(state.lst_prefix + key)
		},

	},
	actions: {
		initialize_app({state, commit, dispatch}, payload) {
			if (empty(payload)) payload = {}

			// initialize local_storage settings
			commit('lst_initialize')

			// if results are coming from the server they'll be in GMT, so subtract time accordingly (I hate time zones!!!)
			// getTimezoneOffset is in minutes
			state.time_zone_correction = new Date().getTimezoneOffset() * 60 * 1000

			return new Promise((resolve, reject)=>{
				// BYPASS INITIALIZATION
				// if (false) {
				// 	commit('set', ['user_info', {
				// 		// user_id: 1,
				// 		name_first: 'Pepper',
				// 		name_last: 'Williams',
				// 		email: 'pwdem27@gmail.com',
				// 	}])
				// 	resolve('main')
				// 	return
				// }

				U.loading_start()
				U.ajax('flvh/initialize_app', payload, result=>{
					U.loading_stop()

					if (result.status.indexOf('Error') > -1) {
						vapp.$alert(result.status).then(x=>{
							dispatch('sign_out')
						})
						reject()
						return
					}

					// result should always include at least minimal site data (e.g. the site title and tech_help_email)
					commit('set', ['site_data', new Site_Data(result.site_data)])
					commit('set', ['vh_org_id', result.site_data.vh_org_id])
					commit('set', ['site_version', result.site_data.site_version])
					commit('set', ['site_version_message', result.site_data.site_version_message])

					if (result.status == 'unknown_user') {
						// if the google login succeeded but we didn't find the email, offer to try to associate it with another email
						vapp.$prompt({
							text: sr('We don’t recognize that email (<span>$1</span>) in our system. If you used a different email when you signed up to volunteer, please enter it below and click “TRY THIS EMAIL”. Contact <a href="mailto:$2">$2</a> if you need further assistance.', result.email, state.site_data.tech_help_email),
							acceptText: 'Try This Email',
						}).then(new_email => {
							if (!empty(new_email)) {
								payload.new_email = new_email
								vapp.initialize_app(payload)
							}
						}).catch(n=>{console.log(n)}).finally(f=>{});

						resolve('login')
						return

					} else if (result.status != 'ok') {
						vapp.$alert('Error in site initialization!')
						reject()
						return
					}
					commit('set', ['initialized', true])
					console.log('Initialized!', result)

					// result should always include the google_client_id
					commit('set', ['google_client_id', result.google_client_id])

					// if we didn't receive user_info, the user is not already logged in...
					if (empty(result.user_info)) {
						commit('set', ['login_error', result.login_error])
						resolve('login')
						return
					}

					commit('set', ['user_info', new User(result.user_info)])

					// set today_date; by default set dynamically, but can be hard coded by config value
					// TODO FOR SUNIL: add frozen_today_date to site_data form so that an admin can set/change this
					if (result.site_data.frozen_today_date) state.today_date = result.site_data.frozen_today_date
					else state.today_date = date.format(new Date(), 'MM-DD')
					
					// set this_year
					state.this_year = (new Date()).getFullYear()

					// if we got user_info we will get additional site data

					let page_data = (empty(result.site_data.page_data) || $.isArray(result.site_data.page_data)) ? {} : result.site_data.page_data
					
					// use tab_order for page_data array if we received it; otherwise use default order
					let tab_order = result.site_data.tab_order
					if (empty(tab_order) || tab_order.length == 0) {
						tab_order = ['home', 'shifts', 'resources', 'voterfaq', 'techfaq', 'custom1', 'custom2', 'custom3', 'custom4', 'custom5', 'custom6', 'custom7', 'custom8']
					}
					let o = {}
					for (let key of tab_order) {
						o[key] = new Page_Data(page_data[key], key)
					}
					commit('set', ['page_data', o])

					let resources = (empty(result.site_data.resources)) ? [] : result.site_data.resources
					for (let resource_data of resources) {
						commit('set', [state.site_resources, 'PUSH', new Site_Resource(resource_data)])
					}

					state.site_faq = []
					for (let item of result.site_data.site_faq) {
						if (item.visible || state.user_info.is_system_admin()) {
							state.site_faq.push(new FAQ_Item(item))
						}
					}

					// build site_faq_hash
					let build_site_faq_hash = (node) => {
						state.site_faq_hash[node.faq_id] = node
						for (let child_node of node.children) build_site_faq_hash(child_node)
					}
					state.site_faq_hash = {}
					for (let node of state.site_faq) build_site_faq_hash(node)

					////////////////////////////////////////

					resolve('main')
				});
			})
		},

		ping({state, commit, dispatch}) {
			return new Promise((resolve, reject)=>{
				console.log('ping')
				U.ajax('flvh/ping', {vh_user_id: state.user_info.vh_user_id}, result=>{
					if (result.status != 'ok') {
						vapp.$alert(sr('Your session has expired. Click “OK” to sign back in. If you can’t make it back into the site, please contact <a href="mailto:$1">$1</a>.', state.site_data.tech_help_email)).then(()=>{
							document.location.reload()
						})
						reject()
						return
					}
					commit('set', ['my_shifts', result.my_shifts])

					if (state.site_version != 0 && state.site_version != result.site_version) {
						let msg = 'A new update to the website has been posted. Please refresh your browser to get the update.'
						if (result.site_version_message != '') {
							msg = sr('A new update to the website has been posted:<ul class="my-2">$1</ul>Please refresh your browser to get the update.', result.site_version_message)
						}
						vapp.$confirm({
						    title: 'New Updates!',
						    text: msg,
						    acceptText: 'Refresh Now',
						    // cancelText: 'I sink',
							dialogMaxWidth: 600
						}).then(y => {
							document.location.reload()
						}).catch(n=>{console.log(n)}).finally(f=>{})
					}
					state.site_version = result.site_version

					// set today_date whenever we ping (note that we also set it when we first initialize)
					if (state.site_data.frozen_today_date) state.today_date = state.site_data.frozen_today_date
					else state.today_date = date.format(new Date(), 'MM-DD')

					// ping again in x minutes
					// setTimeout(()=>vapp.$store.dispatch('ping'), 5000)
					setTimeout(()=>vapp.$store.dispatch('ping'), 60000)
					resolve()
				});
			})
		},

		save_site_data({state, commit, dispatch}) {
			let payload = {site_data: $.extend(true, {}, state.site_data), site_version: state.site_version}
			return new Promise((resolve, reject)=>{
				U.loading_start()
				U.ajax('flvh/save_site_data', payload, result=>{
					U.loading_stop()
					if (result.status.search(/version_mismatch-(.+)/) > -1) {
						let last_edited_by_email = RegExp.$1
						vapp.$alert(sr('Site data has been updated by someone else ($1) prior to submitting your changes. Please make note of the changes you were trying to make, then reload and try again.', last_edited_by_email))
						return
					}
					if (result.status != 'ok') {
						console.log('Error in save_site_data', result.status)
						reject(result.status)
						return
					}

					state.site_version = result.site_data.site_version
					commit('set', ['site_data', new Site_Data(result.site_data)])

					resolve()
				});
			})
		},

		save_page({state, commit, dispatch}, payload) {
			// add site_version to payload
			payload.site_version = state.site_version

			return new Promise((resolve, reject)=>{
				U.loading_start()
				U.ajax('flvh/save_page', payload, result=>{
					U.loading_stop()
					if (result.status.search(/version_mismatch-(.+)/) > -1) {
						let last_edited_by_email = RegExp.$1
						vapp.$alert(sr('Site data has been updated by someone else ($1) prior to submitting your changes. Please make note of the changes you were trying to make, then reload and try again.', last_edited_by_email))
						return
					}
					if (result.status != 'ok') {
						console.log('Error in save_page', result.status)
						reject(result.status)
						return
					}

					state.site_version = result.site_version
					commit('set', [state.page_data, result.page_data.label, new Page_Data(result.page_data, result.page_data.label)])

					resolve()
				});
			})
		},

		save_faq({state, commit, dispatch}, payload) {
			// add site_version to payload
			payload.site_version = state.site_version

			// payload must include site_faq; stringify it
			payload.site_faq = JSON.stringify(payload.site_faq)

			return new Promise((resolve, reject)=>{
				U.loading_start()
				U.ajax('flvh/save_faq', payload, result=>{
					U.loading_stop()
					if (result.status.search(/version_mismatch-(.+)/) > -1) {
						let last_edited_by_email = RegExp.$1
						vapp.$alert(sr('Site data has been updated by someone else ($1) prior to submitting your changes. Please make note of the changes you were trying to make, then reload and try again.', last_edited_by_email))
						return
					}
					if (result.status != 'ok') {
						console.log('Error in save_faq', result.status)
						reject(result.status)
						return
					}

					state.site_version = result.site_version
					// the faq editor takes care of updating the site_faq

					resolve()
				});
			})
		},

		save_resource({state, commit, dispatch}, args) {
			let payload = {resource_data: $.extend(true, {}, args.resource)}

			let override_options = null
			if (!empty(args.uploaded_file)) {
				// file upload
				if (payload.resource_data.type == 'upload') {
					let fd = new FormData()
					fd.append('file', args.uploaded_file)
					fd.append('resource_data', JSON.stringify(payload.resource_data))
					payload = fd
					override_options = {contentType: false, processData: false}
				} else {
					// html, entered in the resource editor directly
					payload.html = args.uploaded_file
				}
			}

			return new Promise((resolve, reject)=>{
				U.loading_start()
				U.ajax('flvh/save_resource', payload, result=>{
					U.loading_stop()
					if (result.status != 'ok') {
						console.log('Error saving resource')
						reject(result.status)
						return
					}

					// we get back resource_data; create a new object and add/replace in site_resources
					let resource = new Site_Resource(result.resource_data)

					let index = state.site_resources.find(x=>x.vh_resource_id == resource.vh_resource_id)
					if (index > -1) {
						commit('set', [state.site_resources, 'SPLICE', index, resource])
					} else {
						commit('set', [state.site_resources, 'PUSH', resource])
					}

					// resolve with the resource
					resolve(resource)
				}, override_options);
			})
		},

		get_shifts({state, commit, dispatch}) {
			return new Promise((resolve, reject)=>{
				U.loading_start()
				U.ajax('flvh/get_shifts', {}, result=>{
					U.loading_stop()
					if (result.status != 'ok') {
						vapp.$alert('Error retrieving shifts.')
						reject()
						return
					}
					state.got_shifts = true
					state.shifts = []
					for (let shift_data of result.shifts) {
						state.shifts.push(new Shift(shift_data))
					}
					resolve()
				});
			})
		},

		save_shift({state, commit, dispatch}, payload) {
			// payload must include shift or shifts; stringify it
			if (payload.shift) payload.shift = JSON.stringify(payload.shift)
			if (payload.shifts) payload.shifts = JSON.stringify(payload.shifts)

			return new Promise((resolve, reject)=>{
				U.loading_start()
				// note that the save_shifts service will take care of the cases where vh_location_id is an array, or 0
				U.ajax('flvh/save_shifts', payload, result=>{
					U.loading_stop()
					if (result.status != 'ok') {
						console.log('Error in save_shifts', result.status)
						reject(result.status)
						return
					}

					// replace or add returned shift(s) -- we may have processed more than one
					for (let shift_data of result.shift_data) {
						let shift = new Shift(shift_data)
						// unless we haven't actually loaded state.shifts (e.g. this is a volunteer signing up), push/splice into state.shifts
						if (state.got_shifts) {
							let index = state.shifts.findIndex(x=>x.vh_shift_id == shift.vh_shift_id)
							if (index == -1) {
								state.shifts.push(shift)
							} else {
								state.shifts.splice(index, 1, shift)
							}
						}

						// if this shift was one of my_shifts and now isn't remove it
						let index2 = state.my_shifts.findIndex(x=>x.vh_shift_id == shift.vh_shift_id)
						if (index2 != -1) {
							if (shift.volunteer_email != state.user_info.email) {
								state.my_shifts.splice(index2, 1)
							} else {
								// if we're not removing it, replace it, so we get the updated values
								state.my_shifts.splice(index2, 1, new Shift(shift_data))
							}

						} else {
							// else if the shift now *is* one of my_shifts, add it
							if (shift.volunteer_email == state.user_info.email) {
								state.my_shifts.push(new Shift(shift_data))
							}
						}
					}

					// return the number of shifts created/updated
					resolve(result.shift_data.length)
				});
			})
		},

		batch_update_shifts({state, commit, dispatch}, payload) {
			// payload must include shifts; stringify it
			payload.shifts = JSON.stringify(payload.shifts)

			return new Promise((resolve, reject)=>{
				U.loading_start()
				U.ajax('flvh/batch_update_shifts', payload, result=>{
					U.loading_stop()
					if (result.status != 'ok') {
						vapp.$alert('Error in batch_update_shifts')
						reject()
						return
					}

					// replace or add returned shift
					for (let shift_data of result.shift_data) {
						let shift = new Shift(shift_data)
						let index = state.shifts.findIndex(x=>x.vh_shift_id == shift.vh_shift_id)
						if (index == -1) {
							// we shouldn't be adding shifts here, but this line doesn't do any harm
							state.shifts.push(shift)
						} else {
							state.shifts.splice(index, 1, shift)
						}

						// if this shift was one of my_shifts and now isn't remove it
						index = state.my_shifts.findIndex(x=>x.vh_shift_id == shift.vh_shift_id)
						if (index != -1) {
							if (shift.volunteer_email != state.user_info.email) {
								state.my_shifts.splice(index, 1)
							} else {
								// if we're not removing it, replace it, so we get the updated values
								state.my_shifts.splice(index, 1, new Shift(shift_data))
							}

						} else {
							// else if the shift now *is* one of my_shifts, add it
							if (shift.volunteer_email == state.user_info.email) {
								state.my_shifts.push(new Shift(shift_data))
							}
						}
					}

					resolve()
				});
			})
		},

		delete_shifts({state, commit, dispatch}, payload) {
			return new Promise((resolve, reject)=>{
				U.loading_start()
				U.ajax('flvh/delete_shifts', payload, result=>{
					U.loading_stop()
					if (result.status != 'ok') {
						vapp.$alert('Error in delete_shifts')
						reject()
						return
					}

					for (let vh_shift_id of payload.vh_shift_ids) {
						let index = state.shifts.findIndex(x=>x.vh_shift_id == vh_shift_id)
						if (index > -1) {
							state.shifts.splice(index, 1)
						}
					}

					resolve(result)
				});
			})
		},

		get_users({state, commit, dispatch}, payload) {
			if (empty(payload)) payload = {}

			return new Promise((resolve, reject)=>{
				U.loading_start()
				U.ajax('flvh/get_users', payload, result=>{
					U.loading_stop()
					if (result.status != 'ok') {
						vapp.$alert('Error in get_users')
						reject()
						return
					}
					// reset state.users unless we're loading a single user
					if (!payload.user_id_to_get) state.users = []
					for (let user_data of result.users) {
						let i = state.users.findIndex(x=>x.vh_user_id == user_data.vh_user_id)
						if (i == -1) state.users.push(new User(user_data))
						else state.users.splice(i, 1, new User(user_data))
					}
					resolve()
				});
			})
		},

		save_users({state, commit, dispatch}, payload) {
			return new Promise((resolve, reject)=>{
				U.loading_start()
				U.ajax('flvh/save_users', payload, result=>{
					U.loading_stop()
					if (result.status != 'ok') {
						vapp.$alert('Error in save_users')
						reject()
						return
					}

					// replace or add returned users
					for (let user_data of result.user_data) {
						let user = new User(user_data)
						let index = state.users.findIndex(x=>x.vh_user_id == user.vh_user_id)
						if (index == -1) {
							state.users.push(user)
						} else {
							state.users.splice(index, 1, user)
						}

						// if we updated the signed-in user, update user_info
						if (state.user_info.email == user_data.email) {
							// use a different copy of the User class
							state.user_info = new User(user_data)
						}
					}

					// we may also get updated shift info; if so save it
					if (result.updated_shift_data) {
						for (let vh_shift_id in result.updated_shift_data) {
							let shift = state.shifts.find(x=>x.vh_shift_id == vh_shift_id)
							if (shift) {
								shift.lbj_status = result.updated_shift_data[vh_shift_id].lbj_status
								shift.lbj_shift_database_id = result.updated_shift_data[vh_shift_id].lbj_shift_database_id
							}
						}
					}

					resolve(result)
				});
			})
		},

		add_users({state, commit, dispatch}, payload) {
			// payload must include new_users; stringify it
			payload.new_users = JSON.stringify(payload.new_users)

			return new Promise((resolve, reject)=>{
				U.loading_start()
				U.ajax('flvh/add_users', payload, result=>{
					U.loading_stop()
					if (result.status != 'ok') {
						vapp.$alert('Error in add_users')
						reject()
						return
					}

					resolve(result)
				});
			})
		},

		delete_users({state, commit, dispatch}, payload) {
			return new Promise((resolve, reject)=>{
				U.loading_start()
				U.ajax('flvh/delete_users', payload, result=>{
					U.loading_stop()
					if (result.status != 'ok') {
						vapp.$alert('Error in delete_users')
						reject()
						return
					}

					for (let vh_user_id of payload.vh_user_ids) {
						let index = state.users.findIndex(x=>x.vh_user_id == vh_user_id)
						if (index > -1) {
							state.users.splice(index, 1)
						}
					}

					resolve(result)
				});
			})
		},

		get_locations({state, commit, dispatch}, payload) {
			if (empty(payload)) payload = {}

			return new Promise((resolve, reject)=>{
				U.loading_start()
				U.ajax('flvh/get_locations', payload, result=>{
					U.loading_stop()
					if (result.status != 'ok') {
						vapp.$alert('Error in get_locations')
						reject()
						return
					}
					state.locations = []
					for (let location_data of result.locations) {
						state.locations.push(new Location(location_data))
					}
					resolve()
				});
			})
		},

		save_locations({state, commit, dispatch}, payload) {
			// payload must include locations; stringify it
			payload.locations = JSON.stringify(payload.locations)

			return new Promise((resolve, reject)=>{
				U.loading_start()
				U.ajax('flvh/save_locations', payload, result=>{
					U.loading_stop()
					if (result.status != 'ok') {
						vapp.$alert('Error in save_locationss')
						reject()
						return
					}

					// replace or add returned locations
					for (let location_data of result.location_data) {
						let location = new Location(location_data)
						let index = state.locations.findIndex(x=>x.vh_location_id == location.vh_location_id)
						if (index == -1) {
							state.locations.push(location)
						} else {
							state.locations.splice(index, 1, location)
						}
					}

					resolve()
				});
			})
		},

		add_locations({state, commit, dispatch}, payload) {
			// payload must include new_locations; stringify it
			payload.new_locations = JSON.stringify(payload.new_locations)

			return new Promise((resolve, reject)=>{
				U.loading_start()
				U.ajax('flvh/add_locations', payload, result=>{
					U.loading_stop()
					if (result.status != 'ok') {
						vapp.$alert('Error in add_locations')
						reject()
						return
					}

					resolve(result)
				});
			})
		},

		delete_locations({state, commit, dispatch}, payload) {
			return new Promise((resolve, reject)=>{
				U.loading_start()
				U.ajax('flvh/delete_locations', payload, result=>{
					U.loading_stop()
					if (result.status != 'ok') {
						vapp.$alert('Error in delete_locations')
						reject()
						return
					}

					for (let vh_location_id of payload.vh_location_ids) {
						let index = state.locations.findIndex(x=>x.vh_location_id == vh_location_id)
						if (index > -1) {
							state.locations.splice(index, 1)
						}
					}

					resolve(result)
				});
			})
		},

		save_content({state, commit, dispatch}, payload) {
			return new Promise((resolve, reject)=>{
				U.loading_start()
				U.ajax('flvh/save_content', payload, result=>{
					U.loading_stop()
					if (result.status != 'ok') {
						vapp.$alert('Error in save_content')
						reject()
						return
					}

					resolve(result)
				});
			})
		},

		simulate_user({state, commit, dispatch}, simulated_email) {
			return new Promise((resolve, reject)=>{
				U.ajax('flvh/simulate_user', {simulated_email: simulated_email}, result=>{
					if (result.status == 'unknown_user') {
						vapp.$alert(sr('Could not find a user record for the email address you entered ($1) for this organization ($2).', simulated_email, state.vh_org_id))
						return
					}
					if (result.status != 'ok') {
						vapp.$alert('Error simulating user')
						reject()
						return
					}

					// on success, reload if we're not on an admin page, or reload
					if (vapp.$route.name.indexOf('admin') > -1) {
						document.location = '/vp/home'
					} else {
						document.location.reload()
					}
				});
			})
		},

		simulate_user_cancel({state, commit, dispatch}) {
			return new Promise((resolve, reject)=>{
				U.ajax('flvh/simulate_user_cancel', {}, result=>{
					if (result.status != 'ok') {
						vapp.$alert('Error simulating user')
						reject()
						return
					}

					// on success, reload
					document.location.reload()
				});
			})
		},

		// this.$store.dispatch('sign_out')
		sign_out({state, commit, dispatch}) {
			return new Promise((resolve, reject)=>{
				U.ajax('flvh/sign_out', {}, result=>{
					if (result.status != 'ok') {
						vapp.$alert('Error signing out!')
						reject()
						return
					}

					// on success, reload the page, which should show the google login button
					document.location.reload()
				});
			})
		},

	}
})
