<template><div>
	<!-- <TemplateComponent /> -->
	<v-card class="elevation-3 my-3 mx-2 green lighten-4">
		<v-card-title>
			<b>Shift Sign-Up</b>
			<v-spacer/>
			<v-btn v-if="show_rules_btn" class="k-tight-btn" small color="secondary" @click="show_dialog=true">Sign-Up “Rules” (Admin Only)</v-btn>
		</v-card-title>
		<v-card-text class="grey--text text--darken-4">
			<div v-if="!eligible_for_shifts">
				You are not currently eligible to sign up for shifts. This may be because you have not been registered for a county.
			</div>
			<div v-else>
				<div class="d-flex align-center">
					<div style="max-width:320px"><v-select ref="available_date_select" v-model="date" :items="available_dates" label="Shift Date(s)" dense outlined hide-details append-icon="fas fa-calendar-day" background-color="#fff"></v-select></div>
					<v-text-field class="ml-4" v-model="search" prepend-inner-icon="fa fa-search" clearable clear-icon="fa fa-times-circle" label="Search" single-line hide-details outlined dense background-color="#fff"></v-text-field>
				</div>
				<div class="d-flex align-start">
					<div style="width:320px;" class="d-flex">
						<v-spacer/>
						<div class="mt-3"><v-btn-toggle dense active-class="k-toggle-btn-active-class" class="k-toggle-btn" v-model="ev_ed" mandatory>
							<v-btn small light :value="'ev'">Early Voting</v-btn>
							<v-btn small light :value="'ed'" class="k-toggle-btn-active-class-off">Election Day</v-btn>
						</v-btn-toggle></div>
						<v-spacer/>
					</div>
					<v-spacer/>
					<div style="background-color:#eee; border-radius:5px; margin-top:6px; margin-left:16px; padding:4px 8px; font-size:14px; line-height:18px; text-align:left;">
						<div v-if="!user_info.address" class="d-flex"><v-btn small color="primary" class="k-tight-btn" @click="set_location">Enter your address</v-btn><div class="ml-2">to calculate the distance to each polling location</div></div>
						<div v-if="user_info.address" class="d-flex">
							<div class="mr-2">Your location: <b>{{user_info.address}}</b></div>
							<v-btn small color="primary" class="k-tight-btn" @click="set_location">Update Address</v-btn>
							<v-btn small icon color="#999" class="ml-2" @click="clear_location"><v-icon>fas fa-circle-xmark</v-icon></v-btn>
						</div>
						
					</div>
					<!-- 750 N Apollo Blvd, Melbourne, FL 32935 -->
					<!-- 3960 S Babcock St, Melbourne, FL 32901 -->
				</div>
				
				<div v-if="shifts_showing.length>0" class="mt-3 text-right red--text text--darken-3"><b>NOTE THAT DISTANCES ARE APPROXIMATE!</b></div>

				<div class="mt-3">
					<v-data-table dense
						:headers="headers"
						:items="shifts_showing"
						:custom-filter="table_search_filter"
						:search="search"
						:hide-default-footer="true"
						:items-per-page="-1"
						:no-data-text="no_data_text"
						class="k-my-shifts-chooser-table"
					>
						<template v-slot:item="{ item }"><tr>
							<td style="width:10px" class="text-center">
								<div v-if="shift_available(item.shift)">
									<v-btn x-small color="green darken-2" dark @click="select_shift(item)"><v-icon class="mr-1" x-small>fas fa-check-circle</v-icon><b>Sign Me Up!</b></v-btn>
								</div>
								<div v-else>
									<v-btn x-small color="red lighten-2" dark @click="$alert('This shift conflicts with another shift you’re already signed up for.')"><v-icon class="mr-1" x-small>fas fa-times-circle</v-icon><b>Not Available</b></v-btn>
								</div>
							</td>
							<td><v-tooltip left><template v-slot:activator="{on}"><div v-on="on">{{item.county}}</div></template>Shift ID: {{item.vh_shift_id}}</v-tooltip></td>
							<td>{{item.location}}</td>
							<td v-if="user_info.geocode" class="text-center">{{item.distance}} mi.</td>
							<td class="text-center"><nobr v-html="item.date_display"></nobr></td>
						</tr></template>
					</v-data-table>
				</div>
			</div>
		</v-card-text>
		<v-card-actions class="pa-4 pt-0">
			<div v-if="previous_priority_available!=-1||next_priority_available!=-1" class="d-flex align-center" style="font-size:14px;">
				<div>More Available Shifts:</div>
				<v-btn v-show="previous_priority_available!=-1" fab x-small class="ml-2" color="secondary" @click="show_page('previous')"><v-icon small>fas fa-angle-left</v-icon></v-btn>
				<v-btn v-show="next_priority_available!=-1" fab x-small class="ml-2" color="secondary" @click="show_page('next')"><v-icon small>fas fa-angle-right</v-icon></v-btn>
			</div>
			<v-spacer/>
			<v-btn small color="primary" @click="$emit('cancel')"><v-icon small class="mr-2">fas fa-check</v-icon>Done</v-btn>
		</v-card-actions>
	</v-card>
<MyShiftsSignupRules v-if="show_dialog" @dialog_cancel="show_dialog=false" />
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
import MyShiftsSignupRules from '@/components/MyShiftsSignupRules'

export default {
	components: { MyShiftsSignupRules },
	props: {
		// req: { type: String, required: true },
		view_as_user: { required: false, default() { return null} },
	},
	data() { return {
		footer_options: {
			itemsPerPageOptions: [10,25,100,-1],
		},
		search: '',
		date: '',
		priority_showing: 0,
		previous_priority_available: -1,
		next_priority_available: -1,
		show_dialog:false,
	}},
	computed: {
		...mapState(['user_info', 'shifts', 'locations', 'today_date', 'this_year', 'my_shifts']),
		...mapGetters(['is_system_or_county_admin']),
		headers() {
			let arr = []
			arr.push({ text: '', align: 'left', sortable: false, value:'vh_shift_id' })
			arr.push({ text: 'County', align: 'left', sortable: false, value:'county' })
			arr.push({ text: 'Location', align: 'left', sortable: false, value:'location' })
			if (this.user_info.geocode) arr.push({ text: 'Distance', align: 'center', sortable: true, value:'distance' })
			arr.push({ text: 'Date/Time', align: 'center', sortable: true, value:'date_sort' })

			return arr
		},
		available_dates() {
			// start with a copy of the available_dates menu
			let arr = this.$store.getters.available_dates.concat([])

			// if we have more than one available_date, it means we're still >1 day from ED
			// also, ed_only users can only see ED
			if (arr.length > 1 && !this.user.has_role('ed_only')) {
				// always show "All remaining EV dates", and show this field if we don't switch to the next one below
				arr.splice(1, 0, {value:'all_ev', text:'All remaining early voting dates'})
				this.date = 'all_ev'
				// 8/3/2022: skip the soon_ev option, at least for now
				// if (arr.length > 4) {
				// 	// if there are 3 or more EV days left (today and 2 more), also show an option for 'soon_ev'
				// 	arr.splice(1, 0, {value:'soon_ev', text:'Upcoming early voting dates'})
				// 	this.date = 'soon_ev'
				// }
			} else {
				// otherwise it's ED (!), so select ED
				this.date = arr[0].value
			}

			return arr
		},
		election_date() { return this.$store.state.site_data.election_date },
		user() {
			if (!empty(this.view_as_user)) return this.view_as_user
			else return this.user_info
		},
		eligible_for_shifts() {
			return !empty(this.user.counties.length>0)
		},
		no_data_text() {
			if (!this.user.ev_eligible && this.date != this.election_date) return 'You are not eligible for early-voting shifts'
			if (!this.user.ed_eligible && this.date == this.election_date) return 'You are not eligible for election day shifts'
			return 'No shifts available'
		},
		available_shifts() {
			let darr = this.today_date.split('-')
			let today_int = U.day_of_year(new Date(this.this_year, darr[0]*1-1, darr[1]*1))
			// console.log('available_shifts!: ', darr, this.this_year, today_int)
		
			let arr = []
			let lowest_priority = 5
			for (let s of this.shifts) {
				// skip if priority is 0 ("unassigned"), [this shouldn't happen though]
				if (s.priority == 0) continue

				// skip shift if it doesn't match the user's counties
				if (!this.user.matches_county(s.county)) continue

				// skip if its status isn't 'open'
				if (s.status != 'open') continue

				// skip if no date set (shouldn't happen)
				if (empty(s.date)) continue

				let sda = s.date.split('-')
				let shift_date_int = U.day_of_year(new Date(this.this_year, sda[0]*1-1, sda[1]*1))

				// skip if shift is in the past
				if (today_int > shift_date_int) continue

				// OLD: if user has role ed_only, skip if it isn't an ed shift
				// TODO: we may want to make it so that ed_only users can't sign up for shifts at all, because these are controlled by the county admins...
				if (this.user.has_role('ed_only') && s.date != this.election_date) {
					continue
				}

				// NEW: skip if this is an ev shift and the user isn't ev-eligible
				if (!this.user.ev_eligible && s.date != this.election_date) continue
				// skip if this is an ed shift and the user isn't ed-eligible
				if (!this.user.ed_eligible && s.date == this.election_date) continue

				// skip if we're looking only at soon ev days and the date is > 2 days in the future (or it's election day)
				if (this.date == 'soon_ev') {
					if ((shift_date_int - today_int) > 2 || s.date == this.election_date) continue
				} else if (this.date == 'all_ev') {
					if (s.date == this.election_date) continue
				// skip if we're looking at a specific date and this shift isn't on that date
				} else {
					if (s.date != this.date) continue
				}

				// *for EV shifts only*, skip if this shift's priority isn't appropriate/allowed for this user's proficiency_level:
				// 1-star vols can only see priority 4 shifts
				// (for ED, we ignore the priority)
				if (s.date != this.election_date) {
					if (this.user.proficiency_level == 1) {
						if (s.priority != 4) continue

					// 3-star vols can see everything *except* priority 4
					} else if (this.user.proficiency_level == 3) {
						if (s.priority == 4) continue

					// 2-star vols can see priority 2 and 3
					} else {
						if (s.priority == 1 || s.priority == 4) continue
					}
				}

				// skip if the user is already signed up for this shift
				if (this.my_shifts.find(x=>x.vh_shift_id==s.vh_shift_id)) continue

				// if we get to here, show the shift

				let date_display = sr('<b>$1</b> ', s.date.replace(/^20\d+-/, ''))
				if (s.start_time) date_display += '&nbsp;&nbsp;' + s.start_time
				if (s.end_time) date_display += '-' + s.end_time
				let date_sort = s.date + ' ' + s.start_time

				// show an indicator of the priority level (customize the conditional to do this only for admins)
				if (true) date_display += sr('<i class="pl-1 fas fa-circle-dot $1"></i>', U.shift_priority_to_color_text(s.priority))

				let location_record = this.locations.find(x=>x.vh_location_id==s.vh_location_id)
				let location = (!location_record) ? '???' : location_record.location

				// let distance = this.user_info.geocode ? U.get_distance_from_geocodes(this.user_info.geocode, {lat: 28.361161, lon: -80.677408}) : ''
				let distance = (this.user_info.geocode && location_record.geocode) ? U.get_distance_from_geocodes(this.user_info.geocode, location_record.geocode, 1) : ''

				let o = {
					vh_shift_id: s.vh_shift_id,
					priority: s.priority,
					county: s.county,
					location: location,
					date_display: date_display,
					date_sort: date_sort,
					distance: distance,

					shift: s,
				}

				o.location_lc = o.location.toLowerCase()

				arr.push(o)

				// keep track of lowest_priority
				if (s.priority < lowest_priority) lowest_priority = s.priority
			}

			// set prority_showing to lowest_priority
			this.priority_showing = lowest_priority

			// sort available shifts
			arr.sort((a,b)=>{
				// first sort by ed_priority_sort_val (only relevant for ed shifts, but won't do anything for ev)
				if (a.ed_priority_sort_val != b.ed_priority_sort_val) return a.ed_priority_sort_val - b.ed_priority_sort_val

				// first sort by priority -- lowest first
				if (a.priority != b.priority) return a.priority - b.priority

				// then date
				if (a.shift.date < b.shift.date) return -1
				if (a.shift.date > b.shift.date) return 1

				// then distance if we have it, or location otherwise
				if (a.distance && b.distance) {
					if (a.distance*1 < b.distance*1) return -1
					if (a.distance*1 > b.distance*1) return 1
				} else {
					let ls = U.natural_sort(a.location, b.location)
					if (ls != 0) return ls
				}

				// finally by start_time
				if (a.shift.start_time < b.shift.start_time) return -1
				if (a.shift.start_time > b.shift.start_time) return 1

				return 0
			})

			return arr
		},

		shifts_showing() {
			// show the available_shifts that are at priority_showing; also note if there is a previous and/or next priority to show
			this.previous_priority_available = -1
			this.next_priority_available = -1
			let arr = []

			for (let o of this.available_shifts) {
				if (o.priority == this.priority_showing) {
					arr.push(o)
				} else if (o.priority < this.priority_showing) {
					// we want previous_priority_available to be the highest available priority that's lower than the current one
					if (this.previous_priority_available == -1 || o.priority > this.previous_priority_available) {
						this.previous_priority_available = o.priority
					}
				} else if (o.priority > this.priority_showing) {
					// we want next_priority_available to be the lowest available priority that's higher than the current one
					if (this.next_priority_available == -1 || o.priority < this.next_priority_available) {
						this.next_priority_available = o.priority
					}
				}
			}

			console.log(sr('shifts_showing: priority_showing=$1; previous_priority_available=$2; next_priority_available=$3', this.priority_showing, this.previous_priority_available, this.next_priority_available))

			return arr
		},
		show_rules_btn() {
			return this.is_system_or_county_admin || this.user_info.admin_email_doing_simulation
		},
		ev_ed: {
			get() {
				if (this.available_dates[0].value == this.date) return 'ed'
				else return 'ev'
			},
			set(val) {
				if (val == 'ed') {
					this.date = this.available_dates[0].value
				} else {
					this.date = this.available_dates[1].value
				}
			}
		},
	},
	watch: {
	},
	created() {
		if (this.shifts.length == 0) this.$store.dispatch('get_shifts')
		if (this.locations.length == 0) this.$store.dispatch('get_locations')
	},
	mounted() {
	},
	methods: {
		show_page(flag, confirmed) {
			if (flag == 'next' && confirmed !== true) {
				// if they're trying to go to the next page, try to keep them on this page...
				this.$confirm({
				    text: 'The next page of available shifts are lower priority than the shifts you’re currently viewing. If possible, please pick a shift from this page; but if you can’t make any of these shifts, go ahead and look on the next page. Thanks!',
				    cancelText: 'Show Next Page',
				    acceptText: 'Stay on This Page',
					dialogMaxWidth: 600,
				}).then(y => {
				}).catch(n=>{
					this.show_page(flag, true)
				}).finally(f=>{})

				return
			}

			if (flag == 'next') this.priority_showing = this.next_priority_available
			else this.priority_showing = this.previous_priority_available
		},

		table_search_filter(value, search, item) {
			// value is the value of the column (we can ignore this); search is the search string (could be empty)
			// RETURN FALSE TO HIDE THE ITEM

			// if search is empty, always return true, so the row will SHOW
			if (empty(search)) return true

			search = search.toLowerCase()
			let re = new RegExp(search, 'i')

			// check _lc fields
			if (item.location_lc.search(re) > -1) return true

			// if we get to here return false
			return false
		},

		shift_available(shift) {
			// make sure this shift doesn't overlap with a shift the user's already signed up for
			for (let ms of this.my_shifts) {
				if (ms.date == shift.date) {
					// if the start or end times are the same, or if the end time or the start time of ms is between the end time and start time of shift, they overlap
					if ((ms.start_time == shift.start_time || ms.end_time == shift.end_time) || (ms.start_time > shift.start_time && ms.start_time < shift.end_time) || (ms.end_time > shift.start_time && ms.end_time < shift.end_time)) {
						return false
					}
				}
			}
			return true
		},

		select_shift(item) {
			if (!this.shift_available(item.shift)) {
				this.$alert('The selected shift overlaps with a shift you’re already signed up with. Please choose another shift.')
				return
			}

			let html = `<ul><li>Location: ${item.location}</li><li>Date/Time: ${item.date_display}</li>`
			this.$confirm({
				title: 'Confirm Shift Sign-Up',
				text: html,
				acceptText: 'Sign Up for Shift',
				dialogMaxWidth: 700
			}).then(y => {
				let edited_shift = new Shift(item.shift)
				edited_shift.status = 'filled'
				edited_shift.volunteer_email = this.user.email
				this.$store.dispatch('save_shift', {shift: edited_shift}).then((result)=>{
					// this.$emit('cancel')
				}).catch((result)=>{
					// TODO: if we couldn't sign up because someone beat them to it, tell them...
				})
			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		set_location() {
			// 2627 NE 11th Ave, Portland OR 97212
			this.$prompt({
				title: 'My Location',
				text: 'Enter an address to calculate distance (“as the crow flies”) from your location to each available polling location.',
				promptType: 'textarea',		// default is 'text'
				initialValue: this.user_info.address,
				acceptText: 'Save Location',
				acceptIconAfter: 'fas fa-check',
			}).then(address => {
				address = $.trim(address)
				if (empty(address)) return

				U.ajax('flvh/address_to_coordinates', {addresses: [address]}, result=>{
					if ($.isArray(result.location_data) && result.location_data[0]?.data[0]) {
						let data = result.location_data[0]?.data[0]
						console.log(data)
						this.$confirm({
							title: 'Is this correct?',
							text: `Does this location label match your address?<div class="mt-2"><b>${data.label}</b></div>`,
							acceptText: '  Yes  ',
							cancelText: '  No  ',
							dialogMaxWidth: 600,
						}).then(y => {
							let geocode = {lat: data.latitude, lon: data.longitude}
							// and save to db; note that the save_users dispatch will update user_info
							this.$store.dispatch('save_users', {users: [{vh_user_id: this.user_info.vh_user_id, address: address, geocode: geocode}]}).then((result)=>{
							})

						}).catch(n=>{console.log(n)}).finally(f=>{})
					} else {
						this.$alert('Your location could not be calculated from the address you entered.')
					}
				})
			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		clear_location() {
			this.$confirm({
				text: 'Click the button below to clear your address. You can always re-enter an address later.',
				acceptText: 'Clear Address',
				acceptIconAfter: 'fas fa-xmark',
			}).then(y => {
				this.$store.dispatch('save_users', {users: [{vh_user_id: this.user_info.vh_user_id, address: '*CLEAR*', geocode: '*CLEAR*'}]}).then((result)=>{
					this.$store.commit('set', [this.user_info, 'address', ''])
					this.$store.commit('set', [this.user_info, 'geocode', null])
				})
				
			}).catch(n=>{console.log(n)}).finally(f=>{})
		},
	}
}
</script>

<style lang="scss">
.k-my-shifts-chooser-table {
	th {
		white-space: nowrap;
	}
	td {
		font-size:12px!important;
	}
}
</style>
