<template><v-dialog v-model="dialog_open" persistent max-width="900px"><v-card v-if="edited_user">
	<v-card-title>
		<b>Edit User Record</b> <span v-if="edited_user.vh_user_id>0" class="ml-2" style="font-size:16px">(User ID {{edited_user.vh_user_id}})</span>
		<v-spacer/>
		<div style="font-size:14px">Search LBJ by:</div>
		<v-btn class="ml-2" :disabled="!lbj_name_search" small color="primary" @click="search_lbj(lbj_name_search)">Name</v-btn>
		<v-btn class="ml-2" :disabled="!edited_user.phone_number" small color="primary" @click="search_lbj(`${edited_user.phone_number.replace(/[^\d]/g, '')}`)">Phone</v-btn>
	</v-card-title>
	<v-card-text>
		<div class="d-flex">
			<v-text-field class="mr-1" outlined dense hide-details label="Last Name" v-model="edited_user.name_last" placeholder="" autocomplete="new-password"></v-text-field>
			<v-text-field class="mx-1" outlined dense hide-details label="First Name" v-model="edited_user.name_first" placeholder="" autocomplete="new-password"></v-text-field>
			<div style="width:200px" class="ml-1"><v-text-field outlined dense hide-details label="Phone Number" v-model="edited_user.phone_number" placeholder="" autocomplete="new-password"></v-text-field></div>
		</div>
		<div class="d-flex mt-4">
			<v-text-field class="mr-1" outlined dense hide-details label="Main Email" v-model="edited_user.email" placeholder="" autocomplete="new-password"></v-text-field>
			<v-text-field class="ml-1" outlined dense hide-details label="Alt. Email (optional; usually gmail)" v-model="edited_user.other_email" placeholder="" autocomplete="new-password"></v-text-field>
		</div>
		<div class="d-flex mt-4">
			<v-text-field class="mr-1" outlined dense hide-details label="LBJ Email (optional; needed if not same as Main Email)" v-model="edited_user.lbj_email" placeholder="" autocomplete="new-password"></v-text-field>
			<v-text-field class="ml-1" outlined dense hide-details label="LBJ ID (normally looked up by the system)" v-model="edited_user.lbj_user_id" placeholder="" autocomplete="new-password"></v-text-field>
		</div>
		<v-select class="mt-4" v-model="edited_roles" :items="role_options" label="Role(s)" outlined hide-details multiple chips small-chips deletable-chips></v-select>

		<div class="mt-4 d-flex align-center">
			<div class="mr-2">County:</div>
			<div><v-autocomplete outlined dense hide-details v-model="edited_user.counties" multiple small-chips deletable-chips :items="counties" @input="autocomplete_input=null" :search-input.sync="autocomplete_input"></v-autocomplete></div>
			<v-spacer/>
			<v-checkbox class="mt-0 pt-0" :label="'EV-eligible'" v-model="edited_user.ev_eligible" hide-details off-icon="far fa-square" on-icon="fas fa-check-square"></v-checkbox>
			<v-checkbox class="mt-0 pt-0 ml-6" :label="'ED-eligible'" v-model="edited_user.ed_eligible" hide-details off-icon="far fa-square" on-icon="fas fa-check-square"></v-checkbox>
		</div>
		<div class="mt-4 d-flex align-center">
			<div class="mr-2">Training Level (optional):</div>
			<div style="width:180px"><v-select outlined dense hide-details v-model="edited_user.training_level" :items="training_levels"></v-select></div>
			<div class="ml-6 mr-2">Proficiency Level (optional):</div>
			<div>
				<v-icon :color="(edited_user.proficiency_level>=1)?'amber darken-2':'#ddd'" @click="edited_user.proficiency_level=(edited_user.proficiency_level==1)?0:1">fas fa-star</v-icon>
				<v-icon :color="(edited_user.proficiency_level>=2)?'amber darken-2':'#ddd'" @click="edited_user.proficiency_level=2">fas fa-star</v-icon>
				<v-icon :color="(edited_user.proficiency_level>=3)?'amber darken-2':'#ddd'" @click="edited_user.proficiency_level=3">fas fa-star</v-icon>
			</div>
			<v-spacer/>
		</div>
		<v-text-field class="mt-4" outlined dense hide-details label="Languages (e.g. “spanish”, “hatian creole”; comma-separated if multiple; “english” is assumed)" v-model="edited_user.languages" placeholder="" autocomplete="new-password"></v-text-field>
	</v-card-text>
	<v-card-actions class="pl-4 pr-4 pb-4">
		<v-btn color="secondary" @click="$emit('close', '')" class="mr-1"><v-icon small class="mr-2">fas fa-times</v-icon> Cancel</v-btn>
		<v-spacer></v-spacer>
		<v-btn v-if="user_being_edited.vh_user_id" color="red" dark @click="delete_user" class="mr-1"><v-icon small class="mr-2">fas fa-trash-alt</v-icon> Delete User</v-btn>
		<v-btn v-if="is_system_admin&&edited_user.vh_user_id>0" color="primary" @click="lbj_synch" class="mr-1"><v-icon small class="mr-2">fas fa-arrow-right-arrow-left</v-icon> LBJ Synch</v-btn>
		<v-btn color="primary" @click="save_edits"><v-icon small class="mr-2">fas fa-check</v-icon> {{user_being_edited.vh_user_id==0?'Save User':'Save Edits'}}</v-btn>
	</v-card-actions>
</v-card></v-dialog></template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
	components: { },
	props: {
		user_being_edited: { type: Object, required: true },
	},
	data() { return {
		dialog_open: true,
		training_levels: [
			{ value: 0, text: '– (No Training)' },
			{ value: 10, text: 'Basic Training' },
			{ value: 20, text: 'Advanced Training' },
		],
		edited_user: null,
		autocomplete_input: '',
	}},
	computed: {
		...mapState(['site_data', 'user_info']),
		...mapGetters(['is_system_admin', 'is_county_admin']),
		counties() {
			let arr = []
			for (let c of this.$store.state.site_data.counties) {
				// for non-system admins, only show counties the current user is authorized for
				if (this.is_system_admin || this.user_info.matches_county(c)) arr.push(c)
			}
			return arr
		},
		role_options() {
			let arr = []
			// start with default roles (see user.js)
			for (let o of U.default_roles) {
				// non-system admins only get a limited number of roles to choose from
				if (!this.is_system_admin && !['poll_watcher', 'hotline_vol'].includes(o.value)) continue
				arr.push(o)
			}

			// add any custom roles in site_data for system admins
			if (this.is_system_admin) for (let role of this.site_data.custom_roles) {
				arr.push({value: role, text: role})
			}

			return arr
		},
		edited_roles: {
			get() {
				let arr1 = this.edited_user.roles.split(/\s*,\s*/)
				let arr2 = []
				for (let s of arr1) {
					if (!this.role_options.find(x=>x.value == s)) continue
					arr2.push(s)
				}
				return arr2
			},
			set(val) {
				let s = ''
				for (let role of val) {
					if (empty(val)) continue
					if (s) s += ', '
					s += role
				}
				this.edited_user.roles = s
			}
		},
		lbj_name_search() {
			if (empty(this.edited_user.name_first) && empty(this.edited_user.name_last)) return ''
			if (empty(this.edited_user.name_first)) return this.edited_user.name_last
			if (empty(this.edited_user.name_last)) return this.edited_user.name_first
			return `${this.edited_user.name_first}%20${this.edited_user.name_last}`
		},
	},
	created() {
		// when we open the form to edit an existing user, refresh the user data for this user, because we might possibly have updated the user’s lbj_user_id (and address?) since the user list was last loaded
		if (this.user_being_edited.vh_user_id) {
			this.$store.dispatch('get_users', {user_id_to_get: this.user_being_edited.vh_user_id}).then(()=>{
				this.edited_user = new User(this.user_being_edited)
				// a non-system admin can only edit users who have roles 'poll_watcher' and/or 'hotline'
				if (!this.is_system_admin) {
					let arr = this.edited_user.roles_arr.concat([])
					let i = arr.findIndex(x=>x=='poll_watcher')
					if (i > -1) arr.splice(i, 1)
					i = arr.findIndex(x=>x=='hotline')
					if (i > -1) arr.splice(i, 1)
					if (arr.length > 0) {
						this.$alert('You cannot edit this user. Please ask a system admin to edit the user for you.').then(x=>{
						})
						this.$emit('close', '')
						return
					}
				}
			})
		} else {
			this.edited_user = new User(this.user_being_edited)
		}
	},
	mounted() {
		// DEBUG
		vapp.admin_user_edit = this
	},
	methods: {
		lbj_synch() {
			this.save_edits('no_close')
		},

		save_edits(flag) {
			if (this.edited_user.email) this.edited_user.email = $.trim(this.edited_user.email.toLowerCase())
			if (this.edited_user.other_email) this.edited_user.other_email = $.trim(this.edited_user.other_email.toLowerCase())
			if (this.edited_user.lbj_email) this.edited_user.lbj_email = $.trim(this.edited_user.lbj_email.toLowerCase())
			// if (this.edited_user.roles) this.edited_user.roles = $.trim(this.edited_user.roles.toLowerCase())

			// if the user isn't a system admin, require specification of a county
			if (!this.is_system_admin && this.edited_user.counties.length == 0) {
				this.$alert('You must specify a county.')
				return
			}

			if (this.edited_user.lbj_user_id) {
				this.edited_user.lbj_user_id = $.trim(this.edited_user.lbj_user_id) * 1
				if (isNaN(this.edited_user.lbj_user_id)) {
					this.edited_user.lbj_user_id = ''
					this.$alert('LBJ ID must be an integer.')
					return
				}
			}

			// only let full admins set other users to 'admin' roles
			// let roles = this.edited_user.roles.split(/\s*,\s*/)
			if (!this.is_system_admin) {
				if (this.edited_roles.includes('admin')) {
					this.$alert('Non-site-admins cannot give admin rights to other users.')
					return
				}
			}

			if (empty(this.edited_user.email)) {
				this.$alert('You must enter a Main Email for the user.')
				return
			}

			if (this.edited_user.phone_number) {
				// strip non-numbers from phone number
				let pn = this.edited_user.phone_number.replace(/\D/g, '')

				if (pn.length != 10) {
					this.$alert('Please enter a full phone number with area code (10 digits). (Or leave the phone number field blank.)')
					return
				}

				// now format number nicely
				this.edited_user.phone_number = pn.replace(/(\d\d\d)(\d\d\d)(\d\d\d\d)/, '($1)$2-$3')
			}

			let original_lbj_user_id = this.user_being_edited.lbj_user_id
			this.$store.dispatch('save_users', {users: [this.edited_user]}).then((result)=>{
				if (flag == 'no_close') {
					if (result.user_data) {
						if (result.user_data[0].lbj_user_id != original_lbj_user_id) {
							this.$inform({text: 'LBJ User ID UPDATED', color: 'green darken-3'})
						} else {
							this.$inform({text: 'LBJ User ID NOT UPDATED', color: 'red darken-3'})
						}
					}
				} else {
					this.$emit('close', result)
				}
			})
		},

		delete_user() {
			this.$confirm({
			    title: 'Are you sure?',
			    text: 'Are you sure you want to delete this user?',
			    acceptText: 'Delete',
				acceptColor: 'red',
				// dialogMaxWidth: 800
			}).then(y => {
				this.$store.dispatch('delete_users', {vh_user_ids: [this.edited_user.vh_user_id]}).then((result)=>{
					this.$emit('close', 'delete')
				})
			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		search_lbj(search_string) {
			let url =`https://lbj.voting/invite?assignment_state=${this.$store.getters.org_state}&search=${search_string}`
			window.open(url, 'lbj_name_search')
		},
	}
}
</script>

<style lang="scss">
</style>
