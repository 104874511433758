<template><v-dialog v-model="dialog_open" persistent max-width="98vw"><v-card>
	<v-card-title>
		<b>Process LBJ Locations</b>
		<v-spacer></v-spacer>
		<v-btn v-if="updated_locations.length>0" color="secondary" @click="clear_all_checkboxes" class="mr-2">Clear all checkboxes</v-btn>
		<v-btn v-if="updated_locations.length>0" class="mr-5" color="primary" @click="save_locations"><v-icon small class="mr-2">fas fa-check</v-icon> Save</v-btn>
		<v-btn color="secondary" @click="$emit('close')"><v-icon small class="mr-2">fas fa-times</v-icon> Cancel</v-btn>
	</v-card-title>
	<v-card-text class="black--text" style="font-size:14px; line-height:17px;">
		<ul>
			<li>LBJ lines: {{ lbj_lines.length }}<ul>
				<!-- <li>first line: <pre>{{ JSON.stringify(lbj_lines[0], null, 2) }}</pre></li> -->
				<!-- <li>last line: {{ JSON.stringify(lbj_lines[lbj_lines.length-1]) }}</li> -->
			</ul></li>
			<li class="mt-2">Processed locations: {{ current_locations.length }}<ul>
				<!-- <li>first line: <pre>{{ JSON.stringify(current_locations[0], null, 2) }}</pre></li> -->
				<!-- <li>last line: {{ JSON.stringify(current_locations[current_locations.length-1]) }}</li> -->
			</ul></li>
			<li v-if="no_county_matches.length>0" class="mt-2">No county matches: {{ no_county_matches.length }} <ul>
				<li v-for="(item, index) in no_county_matches" :key="item.vh_location_id"><pre>{{JSON.stringify(item, null, 2)}}</pre></li>
			</ul></li>
			<li class="mt-2">Perfect matches: {{perfect_matches}} / {{locations_needing_matches}}</li>
			
			<li v-for="(county_record, county, index) in county_records" :key="county" class="mt-4">
					<b>{{county}}</b> 
					<v-btn x-small class="k-tight-btn k-nocaps-btn" color="#444" text @click="select_all_for_county(county_record)"><v-icon small class="mr-1">far fa-check-square</v-icon> Select/Deselect All</v-btn>
					<v-btn v-if="county_record.prev_match_count" x-small text class="k-tight-btn k-nocaps-btn" color="green darken-3" @click="county_record.show_prev=!county_record.show_prev">Show already-matched locations ({{county_record.prev_match_count}})</v-btn>
				<div v-for="(loc, index) in county_record.locations" v-show="(!loc.prev_match)||county_record.show_prev"><div class="d-flex mt-1" :class="match_rating_class(loc)">
					<v-checkbox class="shrink mt-0 pt-0 d-inline-block" hide-details v-model="loc.to_save"></v-checkbox>
					<div style="margin-top:3px"><code>{{loc.lbj_location_id}}</code></div>
					<div style="margin-top:3px"><b><pre class="d-inline px-2">{{loc.match_rating<100?' ':''}}{{loc.match_rating}}</pre></b></div>
					<div style="margin-top:3px">
						{{loc.location}} <span v-if="loc.prev_match"><b>[already matched]</b></span>
						<div v-if="loc.match_rating < 100">{{loc.lbj_location}} <b>[LBJ]</b></div>
						<div v-if="loc.prev_mismatch"><b>MISMATCH:</b> previous lbj_location_id = <code>{{loc.prev_lbj_location_id}}</code></div>
					</div>
				</div></div>
				<div v-if="county_record.lbj_dups.length>0" class="amber lighten-4 pa-2 mt-2"><b>LBJ duplicates for county:</b>
					<ul>
						<li v-for="(item, index) in county_record.lbj_dups" :key="item.lbj_location_id"><code class="mr-2">{{item.lbj_location_id}}</code> {{item.lbj_location}}</li>
					</ul>
				</div>
				<div v-if="county_record.lbj_misses.length>0" class="amber lighten-4 pa-2 mt-2"><b>LBJ unmatched locations for county:</b>
				<ul>
					<li v-for="(item, index) in county_record.lbj_misses" :key="item.lbj_location_id"><code class="mr-2">{{item.lbj_location_id}}</code> {{item.lbj_location}}</li>
				</ul>
			</div>
			</li>
		</ul>
	</v-card-text>
</v-card></v-dialog></template>

<script>
import { mapState, mapGetters } from 'vuex'
import './lbj_locations.js'

export default {
	components: { },
	props: {
		// admin_user: { type: Object, required: true },
	},
	data() { return {
		dialog_open: true,
		current_locations: [],
		lbj_lines: [],
		no_county_matches: [],
		perfect_matches: 0,
		locations_needing_matches: 0,
		county_records: {},
	}},
	computed: {
		...mapState(['state', 'site_data', 'locations']),
		updated_locations() {
			let arr = []
			for (let loc of this.current_locations) {
				if (loc.to_save) arr.push(loc)
			}
			return arr
		},
	},
	created() {
		vapp.plbjl = this
	},
	mounted() {
		this.process_lines()
	},
	methods: {
		process_lines() {
			this.current_locations = []
			this.lbj_lines = []
			this.no_county_matches = []
			this.perfect_matches = 0
			this.locations_needing_matches = 0
			this.county_records = {}

			// process lbj lines
			let raw_lbj_lines = window.raw_lbj_lines.split(/\n/)
			for (let line of raw_lbj_lines) {
				line = $.trim(line)
				if (!line) continue
				let arr = line.split(/\t/)
				let o = {
					lbj_location_id: arr[0],
					county: arr[1],
					name: arr[2],
					address: arr[3],
					address_2: arr[4],
					city: arr[5],
					zip	: arr[6],

					matches_found: 0,
				}

				o.county_lc = $.trim(o.county.toLowerCase().replace(/[^\w\s]/g, '').replace(/\s+/g, ' '))
				o.name_lc = $.trim(o.name.toLowerCase().replace(/[^\w\s]/g, '').replace(/\s+/g, ' '))
				o.address_lc = $.trim((o.address + ' ' + o.address_2).toLowerCase().replace(/[^\w\s]/g, '').replace(/\s+/g, ' '))
				o.city_lc = $.trim(o.city.toLowerCase().replace(/[^\w\s]/g, '').replace(/\s+/g, ' '))
				o.location_lc = o.name_lc + ' ' + o.address_lc + ' ' + o.city_lc + ' ' + o.zip
				o.lbj_location = o.name + ' ' + o.address + ' ' + o.city + ' ' + o.zip

				this.lbj_lines.push(o)
			}
			this.lbj_lines.sort((a,b)=>{
				if (a.county_lc < b.county_lc) return -1
				if (b.county_lc < a.county_lc) return 1
				if (a.name_lc < b.name_lc) return -1
				if (b.name_lc < a.name_lc) return 1
				if (a.address_lc < b.address_lc) return -1
				if (b.address_lc < a.address_lc) return 1
				return 0
			})

			// process our locations
			for (let loc of this.locations) {
				// for now we're only processing early voting locations
				if (loc.location_type != 'ev') continue

				// if a county is selected, only process that county
				if (this.$store.state.lst.default_county != 'ALL' && this.$store.state.lst.default_county != loc.county) continue

				let o = {
					vh_location_id: loc.vh_location_id,
					lbj_location_id: '------',
					prev_lbj_location_id: loc.lbj_location_id,
					prev_match: false,
					prev_mismatch: false,
					county: loc.county,
					location: loc.location,

					lbj_line: null,
					lbj_location: '',
					match_rating: '---',
					matches_found: 0,

					to_save: false,
				}
				o.county_lc = $.trim(o.county.toLowerCase().replace(/[^\w\s]/g, '').replace(/\s+/g, ' '))
				o.location_lc = $.trim(o.location.toLowerCase().replace(/[^\w\s]/g, '').replace(/\s+/g, ' '))

				this.current_locations.push(o)
			}
			this.current_locations.sort((a,b)=>{
				if (a.county_lc < b.county_lc) return -1
				if (b.county_lc < a.county_lc) return 1
				if (a.location_lc < b.location_lc) return -1
				if (b.location_lc < a.location_lc) return 1
				return 0
			})

			for (let loc of this.current_locations) {
				if (!this.county_records[loc.county]) this.$set(this.county_records, loc.county, {
					locations: [], 
					lbj_dups: [],
					lbj_misses: [],
					prev_match_count: 0,
					show_prev: false,
				})
				this.county_records[loc.county].locations.push(loc)
			}

			// try to match!
			let register_match = (loc, lbj_line, match_rating) => {
				// note if we find an lbj_line with more than one match
				lbj_line.matches_found += 1

				// if we previously had an lbj_line, reduce matches_found for it, since we're replacing
				if (loc.lbj_line) {
					loc.lbj_line.matches_found -= 1
				}

				loc.lbj_line = lbj_line

				loc.lbj_location_id = lbj_line.lbj_location_id
				loc.lbj_location = lbj_line.lbj_location

				loc.match_rating = match_rating
			}

			// go through each county
			for (let county in this.county_records) {
				let county_record = this.county_records[county]
				let county_lc
				// go through each location in this county
				for (let loc of county_record.locations) {
					county_lc = loc.county_lc

					// skip hotline and test counties
					if (county_lc == 'hotline' || county_lc == 'test') continue

					++this.locations_needing_matches

					let county_matches = 0
					for (let lbj_line of this.lbj_lines) {
						// skip if counties don't match
						if (county_lc != lbj_line.county_lc) continue
						++county_matches

						// look for perfect match
						if (loc.location_lc == lbj_line.location_lc) {
							loc.match_rating = 100
							register_match(loc, lbj_line, 100)
							++this.perfect_matches

							// if we find a perfect match, continue
							continue
						}

						// look for imperfect match...
						let match_rating = 0
						if (loc.location_lc.indexOf(lbj_line.name_lc) > -1) match_rating += 30
						if (loc.location_lc.indexOf(lbj_line.address_lc) > -1) match_rating += 30
						if (loc.location_lc.indexOf(lbj_line.zip) > -1) match_rating += 20
						if (loc.location_lc.indexOf(lbj_line.city_lc) > -1) match_rating += 10

						if (loc.match_rating == '---' || match_rating > loc.match_rating) {
							register_match(loc, lbj_line, match_rating)
							// DON'T continue; consider all other locations in the county (unless we subsequently find a perfect match)
						}
					}

					// note locations where we didn't find any matching lbj counties (hopefully there won't be any)
					if (county_matches == 0) {
						loc.match_rating = 'NCM'
						this.no_county_matches.push(loc)
					}
				}
			
				// we've gone through all locations for the county; now go through them again...
				for (let loc of county_record.locations) {
					// note previous matches
					if (loc.lbj_location_id != '------' && loc.lbj_location_id == loc.prev_lbj_location_id) {
						loc.prev_match = true
						++county_record.prev_match_count
					// note mismatches
					} else if (loc.lbj_location_id != '------' && loc.prev_lbj_location_id && loc.lbj_location_id != loc.prev_lbj_location_id) {
						loc.prev_mismatch = true

					} else {
						// for not-already-matched locations, set to_save to true if match_rating is >= 40
						if (loc.match_rating >= 40) loc.to_save = true
					}
				}

				// look through any lbj_lines for the county and note ones where we found no match or > match
				for (let lbj_line of this.lbj_lines) {
					if (county_lc != lbj_line.county_lc) continue
					if (lbj_line.matches_found == 0) county_record.lbj_misses.push(lbj_line)
					if (lbj_line.matches_found > 1) county_record.lbj_dups.push(lbj_line)
				}
			}
		},
		
		match_rating_class(loc) {
			if (loc.prev_mismatch) return 'orange--text text--darken-4'
			if (loc.prev_match) return 'green--text text--darken-3'
			if (isNaN(loc.match_rating*1) || loc.match_rating <= 20) return 'red--text text--darken-3'
			if (loc.match_rating < 40) return 'purple--text text--darken-3'
			if (loc.match_rating < 100) return 'blue--text text--darken-3'
		},

		clear_all_checkboxes() {
			for (let loc of this.current_locations) {
				loc.to_save = false
			}
		},

		select_all_for_county(county_record) {
			let ct = 0
			for (let loc of county_record.locations) if (loc.to_save) ++ct

			let val = (ct == 0)
			for (let loc of county_record.locations) {
				// don't automatically change values of items already matched
				if (!loc.prev_match) loc.to_save = val
			}
		},

		save_locations() {
			let arr = []
			for (let loc of this.updated_locations) {
				arr.push({
					vh_location_id: loc.vh_location_id,
					lbj_location_id: loc.lbj_location_id,
				})
			}

			this.$confirm({
				title: 'Are you sure?',
				text: sr('Are you sure you want to set the lbj_location_ids for the $1 selected location(s)?', arr.length),
				acceptText: 'Update',
			}).then(y => {
				this.$store.dispatch('save_locations', {locations: arr}).then((result)=>{
					this.process_lines()
				}).catch((result)=>{
					// shouldn't happen
				})
			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

	}
}
</script>

<style lang="scss">
</style>
