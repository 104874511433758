<template><div class="k-shift-user-editor-popup elevation-3 brown lighten-4" @click.stop="">
	<v-btn icon color="#666" @click.stop="$emit('close')" style="position:absolute;right:-12px;top:-12px;"><v-icon>fas fa-times-circle</v-icon></v-btn>
	<v-autocomplete background-color="#fff" outlined dense hide-details :label="'Select '+va_label" v-model="user_email" :items="user_emails"></v-autocomplete>
	<div class="d-flex mt-2">
		<v-btn v-if="shift[field]" small color="red" dark @click.stop="$emit('save',shift,field,'')"><v-icon class="mr-2" small>fas fa-times</v-icon>Clear {{va_label}}</v-btn>
		<v-spacer/>
		<v-btn small color="primary" @click.stop="$emit('save',shift,field,user_email)"><v-icon class="mr-2" small>fas fa-check</v-icon>Save Changes</v-btn>
	</div>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
// import TemplateComponent from '@/components/TemplateComponent'

export default {
	// components: { TemplateComponent },
	props: {
		shift: { type: Object, required: true },
		field: { type: String, required: true },
	},
	data() { return {
		user_email: this.shift[this.field],
	}},
	computed: {
		...mapState(['user_info', 'users']),
		...mapGetters([]),
		user_emails() {
			let arr = []
			for (let u of this.users) {
				// only show people from this shift's county
				if (!u.matches_county(this.shift.county)) continue
				// if (u.county != this.shift.county) continue

				let o = {value: u.email}
				if (!empty(u.name_last)) {
					o.text = sr('$1, $2 ($3)', u.name_last, u.name_first, u.email)
				} else {
					o.text = u.email
				}
				arr.push(o)
			}

			// order volunteers by name
			arr.sort((a,b)=>{
				if (a.text < b.text) return -1
				if (b.text < a.text) return 1				
				return 0
			})
			return arr
		},
		va_label() { return (this.field == 'volunteer_email') ? 'volunteer' : 'shift leader' },
	},
	watch: {
	},
	created() {
	},
	mounted() {
	},
	methods: {
	}
}
</script>

<style lang="scss">
.k-shift-user-editor-popup {
	position:absolute;
	// right:20px;
	width:400px;
	z-index:10;
	// background-color:#f8f8f8;
	// border:1px solid #666;
	white-space:nowrap;
	font-size: 14px;
	// margin-top:30px;
	border-radius:8px;
	padding:12px 16px;
}
</style>
