import Vue from 'vue'
// Require Froala Editor js file.
import 'froala-editor/js/froala_editor.pkgd.min.js'

// require packages
import 'froala-editor/js/plugins/align.min.js'
import 'froala-editor/js/plugins/code_beautifier.min.js'
import 'froala-editor/js/plugins/code_view.min.js'
import 'froala-editor/js/plugins/colors.min.js'
import 'froala-editor/js/plugins/draggable.min.js'
import 'froala-editor/js/plugins/emoticons.min.js'
import 'froala-editor/js/plugins/entities.min.js'
import 'froala-editor/js/plugins/file.min.js'
import 'froala-editor/js/plugins/font_family.min.js'
import 'froala-editor/js/plugins/font_size.min.js'
import 'froala-editor/js/plugins/fullscreen.min.js'
import 'froala-editor/js/plugins/help.min.js'
import 'froala-editor/js/plugins/image.min.js'
import 'froala-editor/js/plugins/image_manager.min.js'
import 'froala-editor/js/plugins/inline_class.min.js'
import 'froala-editor/js/plugins/inline_style.min.js'
import 'froala-editor/js/plugins/line_breaker.min.js'
import 'froala-editor/js/plugins/link.min.js'
import 'froala-editor/js/plugins/lists.min.js'
import 'froala-editor/js/plugins/paragraph_format.min.js'
import 'froala-editor/js/plugins/paragraph_style.min.js'
import 'froala-editor/js/plugins/quick_insert.min.js'
import 'froala-editor/js/plugins/quote.min.js'
import 'froala-editor/js/plugins/special_characters.min.js'
import 'froala-editor/js/plugins/table.min.js'
import 'froala-editor/js/plugins/video.min.js'
import 'froala-editor/js/plugins/word_paste.min.js'

// Require Froala Editor css files.
import 'froala-editor/css/froala_editor.pkgd.min.css'
import 'froala-editor/css/froala_style.min.css'
import 'froala-editor/css/themes/dark.min.css'

// require package css files
import 'froala-editor/css/plugins/code_view.min.css'
import 'froala-editor/css/plugins/colors.min.css'
import 'froala-editor/css/plugins/draggable.min.css'
import 'froala-editor/css/plugins/emoticons.min.css'
import 'froala-editor/css/plugins/file.min.css'
import 'froala-editor/css/plugins/fullscreen.min.css'
import 'froala-editor/css/plugins/help.min.css'
import 'froala-editor/css/plugins/image.min.css'
import 'froala-editor/css/plugins/image_manager.min.css'
import 'froala-editor/css/plugins/line_breaker.min.css'
import 'froala-editor/css/plugins/quick_insert.min.css'
import 'froala-editor/css/plugins/special_characters.min.css'
import 'froala-editor/css/plugins/table.min.css'
import 'froala-editor/css/plugins/video.min.css'

// Import and use Vue Froala lib
import VueFroala from 'vue-froala-wysiwyg'
Vue.use(VueFroala)

import FroalaEditor from 'froala-editor';
window.FroalaEditor = FroalaEditor

FroalaEditor.DefineIconTemplate('important_template', '<span class="k-important" style="font-size: 24px; line-height: 24px; height:24px; font-family:monospace; text-align:center; padding:0 4px">I</span>');
FroalaEditor.DefineIcon('important', {NAME: 'important', template:'important_template'});
FroalaEditor.RegisterCommand('important', {
	title: 'Important!',
	focus: true,
	undo: true,
	refreshAfterCallback: true,
	callback: function() {
		let html = sr('<span class="k-important">$1</span>', this.selection.text())
		this.html.insert(html)
	}
});

FroalaEditor.DefineIconTemplate('insert_resource_template', '<span style="display:flex; line-height: 24px; border-radius: 8px; background-color: #795548; color: #fff; padding: 2px 8px; height: 28px; margin: 0 6px;"><span style="font-size: 24px; line-height: 24px; font-weight:normal; font-family:Arial; min-width: 0; margin-right: 2px; padding: 0; float: none; display: inline-block;">+</span><b style="font-family:Roboto; font-size:12px; margin-left:2px;">RESOURCE</b></span>');
FroalaEditor.DefineIcon('insert_resource', {NAME: 'insert_resource', template:'insert_resource_template'});
FroalaEditor.RegisterCommand('insert_resource', {
	title: 'Insert Resource',
	focus: true,
	undo: true,
	refreshAfterCallback: true,
	callback: function() {
		let fco = U.get_froala_component(this)
		if (fco.parent_component) fco.parent_component.froala_insert_resource_callback(fco)
		// else c.froala_insert_resource_callback(froala_modeled_parameter, this)
	}
});

///////////////////////////////////////////////////////
// The following pattern allows us to better manipulate the froala editor functionality. to implement, we use the <froala-wrapper> tag like so:

// <froala-wrapper v-model="description" />
// <froala-wrapper :config="{zIndex: 2501}" parameter="description" />
// <froala-wrapper :config="editor_config()" :parameter_object="course" parameter="description" />

// then we can reference the froala component and its surrounding component; one important thing this allows us to do is the following,
// which ensures that the modeled value will be updated after we've manipulated things in the editor
// (note that updateModel is a fn supplied by the official froala vue component)
// let fco = U.get_froala_component(editor)
// if (fco.froala_component) fco.froala_component.updateModel()

// this <froala-wrapper> method MUST be used for the image-insertion and paste "helpers" below to work properly

U.get_froala_component = function(source) {
	// source can either be a) the froala editor dom object (usually retrieved, directly or indirectly, from a froala event callback fn; see below)
	// or b) a selector referencing a dom object, or the dom object itself, that is a part of the froala-edited html (e.g. a link in the edited html)

	// the returned object will include:
	// froala_editor:  the froala editor dom object
	// froala_component: the <froala> vue component that wraps the froala_editor
	// froala_wrapper_component: the FroalaWrapper component (which in turn contains the <froala> component)
	// parent_component: the component that contains the FroalaWrapper component

	let o = {}
	if (typeof(source) == 'object' && !empty(source.$el)) {
		o.froala_wrapper_id = $(source.$el).closest('[data-froala_wrapper_id]').attr('data-froala_wrapper_id')
	} else {
		o.froala_wrapper_id = $(source).closest('[data-froala_wrapper_id]').attr('data-froala_wrapper_id')
	}

	// the FroalaWrapper component registers the froala_wrapper_id in the store (but if we haven't initialized a froala editor at all, froala_wrapper_components may not even exist)
	if (vapp.$store.state.froala_wrapper_components) {
		o.froala_wrapper_component = vapp.$store.state.froala_wrapper_components[o.froala_wrapper_id]
		if (empty(o.froala_wrapper_component)) {
			console.log('couldn’t find editor', o.froala_wrapper_id)
		} else {
			o.froala_component = o.froala_wrapper_component.$refs.froala_component
			o.parent_component = o.froala_wrapper_component.get_parent()
			o.froala_editor = o.froala_component.getEditor()
		}
	}
	// console.log(o)
	return o
}

// pass the froala editor into this fn to ensure that the <froala> vue component immediately updates the modeled parameter to the current value specified by the froala editor
// (it may seem like this shouldn't be necessary, but sometimes it is.)
U.update_froala_model = function(editor) {
	let fco = U.get_froala_component(editor)
	if (fco.froala_component) fco.froala_component.updateModel()
	else console.log('couldn’t update model...')
}
///////////////////////////////////////////////////////


U.get_froala_config = function(params) {
	// params could include, e.g.:
	// placeholderText
	// heightMin
	// heightMax
	// toolbarInline: true,
	if (empty(params)) params = {}

	let config = {
		key: vapp.$store.state.froala_key,
		placeholderText: '',
		// charCounterCount: true,

		// immediateVueModelUpdate: true,		// https://froala.com/wysiwyg-editor/docs/framework-plugins/vue/ -- doesn't seem to help anything

		attribution: false,
		quickInsertEnabled: false,
		theme: 'light',
		paragraphFormat: {
			N: 'Normal',
		    // H1: 'Heading 1',
		    // H2: 'Heading 2',
		    // H3: 'Heading 3',
			BLOCKQUOTE: 'Block Quote',
		    PRE: 'Code',
		},
		paragraphFormatSelection: true,
		htmlAllowedAttrs: ['accept', 'accept-charset', 'accesskey', 'action', 'align', 'allowfullscreen', 'allowtransparency', 'alt', 'aria-.*', 'async', 'autocomplete', 'autofocus', 'autoplay', 'autosave', 'background', 'bgcolor', 'border', 'charset', 'cellpadding', 'cellspacing', 'checked', 'cite', 'class', 'color', 'cols', 'colspan', 'content', 'contenteditable', 'contextmenu', 'controls', 'coords', 'data', 'data-.*', 'datetime', 'default', 'defer', 'dir', 'dirname', 'disabled', 'download', 'draggable', 'dropzone', 'enctype', 'for', 'form', 'formaction', 'frameborder', 'headers', 'height', 'hidden', 'high', 'href', 'hreflang', 'http-equiv', 'icon', 'id', 'ismap', 'itemprop', 'keytype', 'kind', 'label', 'lang', 'language', 'list', 'loop', 'low', 'max', 'maxlength', 'media', 'method', 'min', 'mozallowfullscreen', 'multiple', 'muted', 'name', 'novalidate', 'open', 'optimum', 'pattern', 'ping', 'placeholder', 'playsinline', 'poster', 'preload', 'pubdate', 'radiogroup', 'readonly', 'rel', 'required', 'reversed', 'rows', 'rowspan', 'sandbox', 'scope', 'scoped', 'scrolling', 'seamless', 'selected', 'shape', 'size', 'sizes', 'span', 'src', 'srcdoc', 'srclang', 'srcset', 'start', 'step', 'summary', 'spellcheck', 'style', 'tabindex', 'target', 'title', 'type', 'translate', 'usemap', 'value', 'valign', 'webkitallowfullscreen', 'width', 'wrap',
			'onclick',
		],
		toolbarButtons: {
			// moreRich: {buttons: ['bold', 'italic', 'formatUL', 'insertLink', 'insertImage', 'insertVideo', 'emoticons', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontSize', 'textColor', 'backgroundColor', 'align', 'formatOL', 'outdent', 'indent'], buttonsVisible: 7},	// , 'quote', 'outdent', 'indent'
			// moreMisc: {buttons: ['insertTable', 'clearFormatting', 'html', 'fullscreen'], buttonsVisible: 1, align:'right'}	// , 'paragraphFormat', 'fullscreen'
			moreRich: {buttons: ['bold', 'italic', 'formatUL', 'formatOL', 'insertLink', 'insertImage', 'insertVideo', 'emoticons', 'insertTable', 'clearFormatting', 'html', 'fullscreen'], buttonsVisible: 100},
			moreMisc: {buttons: ['underline', 'strikeThrough', 'subscript', 'superscript', 'fontSize', 'textColor', 'backgroundColor', 'align', 'outdent', 'indent'], buttonsVisible: 0},
		},
		imageEditButtons: ['imageDisplay', 'imageAlign', 'imageStyle', 'imageLink', 'linkOpen', 'linkEdit', 'linkRemove', '-', 'imageAlt', 'imageSize', 'imageReplace', 'imageRemove'],	// , 'imageCaption' doesn't seem to work consistently
		imageInsertButtons: ['imageBack', '|', 'imageUpload', 'imageByURL'],
		linkInsertButtons: ['linkBack'],
		videoInsertButtons: ['videoBack', '|', 'videoByURL', 'videoEmbed'],
		videoEditButtons: ['videoDisplay', 'videoAlign', 'videoSize', 'videoReplace', 'videoRemove'],

		imageDefaultWidth: 0,			// when first inserted, don't explicitly set the image's size
		imageResizeWithPercent: true,	// when you resize, specify size with percent instead of pixels
		imageRoundPercent: true,		// round to integer when resizing
		imageDefaultDisplay: 'block',
		imageDefaultAlign: 'center',

		// zIndex: 1000,	// may be needed to make image popup (and other popups) show up properly

		// image uploading -- not used; we convert images to inline dataURLs (see below)
		// imageUploadURL: '/src/ajax.php',
	    // imageUploadMethod: 'POST',
	    // imageMaxSize: 1 * 1024 * 1024,	// 1 MB
	    // imageUploadParams: {
		// 	service_name: 'froala_file_upload',
	    //     user_id: vapp.user_info.user_id,
	    // },
		// imageUploadMethod: 'POST',
		// imageAllowedTypes: ['jpeg', 'jpg', 'png', 'gif', 'svg+xml', 'svg'],

		events: {
			// store images as data-urls
			'image.beforeUpload': function (images) {
				// note that images is analogous to the file object returned by the onChange handler of a file input
				let image_file = images[0]
				let editor = this

				if (!image_file.type.startsWith('image/')) {
					vapp.$alert('The file you specified does not appear to be an image.')
					return
				}

				// for some reason this seems to get called for already-existing images as well as newly-pasted images
				let ei = editor.image.get()
				if (ei && (ei.attr('data-fr-image-pasted') != 'true' || ei.attr('data-cglt') == 'true')) {
					console.log('skipping already-processed image')
					return
				}
				console.log('processing image')

				vapp.$prompt({
					title: 'Choose Image Size',
					text: 'Choose a size for your pasted image. (Smaller-sized images take up less storage and load faster, so please choose a smaller size if possible.)',
					promptType: 'select',
					selectOptions: [{value:'360', text: 'Small'}, {value:'500', text: 'Medium'}, {value:'640', text: 'Large'}, {value:'800', text: 'X-Large'}, {value:'full', text: 'Full-Size'}],
					initialValue: vapp.$store.state.lst.froala_image_size,
					acceptText: 'Select',
					focusBtn: true,		// focus on the accept btn when dialog is rendered
				}).then(size => {
					vapp.$store.commit('lst_set', ['froala_image_size', size])

					// use the create_image_data_url utility fn to convert the image file to a dataURL
					// typical file sizes for given max_width's: 500 (56815) - 600 (77939) - 668 (93107)
					U.create_image_data_url(image_file, {image_format: 'webp', max_width: size, compression_level:0.9, callback_fn: o=>{
						// use the returned img_url (dataURL) as the src for the img tag
						vapp.$inform('Pasted image size: ' + U.format_bytes(o.img_url.length))

						// console.log('callback', ei, o.img_url)
						// if ei is empty, insert a new img tag
						if (empty(ei) || ei.length == 0) {
							let img_tag = sr('<img src="$1" class="fr-fic fr-dib" data-cglt="true">', o.img_url)
							editor.html.insert(img_tag)

						} else {
							// else insert the dataURL as the src tag
							ei.attr('src', o.img_url)
							ei.attr('data-cglt', 'true')
							ei.removeAttr('data-fr-image-pasted', '')
						}

						// hide the img popup/uploading popup if open
						editor.popups.hideAll()
						setTimeout(x=>editor.popups.hideAll(), 10)
						setTimeout(x=>editor.popups.hideAll(), 100)

						U.update_froala_model(editor)
					}})
				}).catch(n=>{
					// if user cancels, remove the image
					ei.remove()
				}).finally(f=>{})

				// hide the img popup/uploading popup if open
				editor.popups.hideAll()
				setTimeout(x=>editor.popups.hideAll(), 10)
				setTimeout(x=>editor.popups.hideAll(), 100)

				return false
			},

			'paste.afterCleanup': function (clipboard_html) {
				let plain_html = U.html_to_text(clipboard_html)
				let equivalent = (clipboard_html == plain_html)
				let editor = this
				// console.log('froala_before_cleanup_html', window.froala_before_cleanup_html)
				// console.log('after', clipboard_html)
				// console.log('equivalent: ' + equivalent)

				let fn = function(clipboard_html) {
					if (vapp.$store.state.lst.froala_paste_mode == 'normal') {
						clipboard_html = window.clean_froala_pasted_text(clipboard_html)
					} else if (vapp.$store.state.lst.froala_paste_mode == 'plain') {
						// console.log(1, clipboard_html)
						// make sure there is a \n after all block tags and <br> tags
						clipboard_html = clipboard_html.replace(/(<\/(p|h1|h2|h3|h4|h5|h6|li|pre|blockquote|td|button)>)(\n)?/g, '$1\n')
						clipboard_html = clipboard_html.replace(/(<br>)(\n)?/g, '\n')

						// console.log(2, clipboard_html)
						// replace \n's with a string we can find again below
						clipboard_html = clipboard_html.replace(/\n/g, 'XXXRETURNXXX')

						// console.log(3, clipboard_html)
						// convert to plain text, then replace that string with <br>'s
						clipboard_html = U.html_to_text(clipboard_html).replace(/XXXRETURNXXX/g, '<br>')

						// for spaces immediately following br's, use nbsp's
						clipboard_html = clipboard_html.replace(/(<br>)(\s+)/g, ($0, $1, $2) => {
							let s = '<br>'
							for (let i = 0; i < $2.length; ++i) s += '&nbsp;'
							return s
						})
						// console.log(4, clipboard_html)
					}

					let html = editor.html.get().replace(/<span[^>]*?class="k-pasted-text"[^>]*?>xxx<\/span>/, clipboard_html)
					editor.html.set(html)
				}

				// if text has no formatting, just paste
				if (clipboard_html == U.html_to_text(clipboard_html) || clipboard_html.search(/(\bstyle=)|(\bclass=)/) == -1) {
					return clipboard_html

				// look for tell-tale signature of pasting from froala to froala; if this is found, do our standard cleanup
				} else if (clipboard_html.search(/<span style="color: rgb\(\d+.*?\); [^>]*font-variant-ligatures/) > -1) {
					return window.clean_froala_pasted_text(clipboard_html)

				} else {
					vapp.$prompt({
						title: 'Paste Text',
						text: 'Choose an option for formatting the pasted text:',
						promptType: 'select',
						selectOptions: [{value:'asis', text: 'Preserve all html formatting (not recommended)'}, {value:'normal', text: 'Preserve basic formatting (e.g. bold and italics)'}, {value:'plain', text: 'Discard all formatting (paste as plain text)'}],
						initialValue: vapp.$store.state.lst.froala_paste_mode,
						acceptText: 'Paste',
						// hideCancel: true,
						focusBtn: true,
					}).then(mode => {
						// clear the dummy `k-pasted-text` stage out of the undo stack, so that if the user undo-s, they won't go back to having the dummy div in the editor
						editor.undo_stack.pop()

						vapp.$store.commit('lst_set', ['froala_paste_mode', mode])
						fn(clipboard_html)
						U.update_froala_model(editor)

					}).catch(n=>{
						editor.undo_stack.pop()

						let html = editor.html.get().replace(/<span[^>]*?class="k-pasted-text"[^>]*?>xxx<\/span>/g, '')
						editor.html.set(html)
						U.update_froala_model(editor)
					}).finally(f=>{})

					return '<span class="k-pasted-text" style="display:none">xxx</span>'
				}
			},
		}
	}

	return $.extend(config, params)
}

window.clean_froala_pasted_text = function(clipboard_html) {
	// try to preserve bold and italics
	let x = $(sr('<div>$1</div>', clipboard_html))
	x.find('*').each(function() {
		let el = $(this)

		// add strong tags for font-weight bold and > 400
		let fw = el.css('font-weight')
		if (fw == 'bold' || fw*1 > 400) {
			if (this.tagName != 'STRONG') {
				// console.log('found bold: ' + el.html())
				el.wrapInner('<strong></strong>')
			}
		}

		// add em tags for font-style italic
		let fs = el.css('font-style')
		if (fs == 'italic') {
			if (this.tagName != 'EM') {
				// console.log('found italic: ' + el.html())
				el.wrapInner('<em></em>')
			}
		}

		if (this.tagName == 'B') {
			el.replaceWith('<strong>' + el.html() + '</strong>')
		}
		if (this.tagName == 'I') {
			el.replaceWith('<em>' + el.html() + '</em>')
		}

		// remove style and class attributes
		el.removeAttr('style')
		el.removeAttr('class')
	})

	// remove these tags altogether
	x.find('base,head,link,meta,style,title,area,map,script,canvas,noscript,del,ins').remove()

	// these too
	x.find('option,datalist,fieldset,meter,optgroup,option,output,progress,select,textarea').remove()

	// these too
	x.find('iframe,video,audio,track,embed,object,param,picture,source').remove()

	// remove all inputs (remember that if this is an "internal paste" with queries, we won't be here)
	x.find('input').remove()

	// for these tags, extract everything in them and put them directly in the dom
	x.find('body,address,article,aside,footer,header,main,nav,section').replaceWith(function() {
		return $(this).html()
	})

	// these too
	x.find('button,label,legend').replaceWith(function() {
		return $(this).html()
	})

	let s = x.html()

	// preserve just the tags we want to save, and remove any attributes from non-images
	s = s.replace(/<((\/)?(em|strong|sub|sup))\b.*?>/gi, 'ZZZLTZZZ$1ZZZGTZZZ')
	s = s.replace(/<((\/)?img\b.*?)>/gi, 'ZZZLTZZZ$1ZZZGTZZZ')
	// console.log(s)

	// insert p tags at the ends of block-level things; this results in line breaks where we want them
	s = s.replace(/(\s*<\/(p|div|li|tr|figcaption|figure|pre|blockquote).*?>\s*)+/ig, 'ZZZLTZZZpZZZGTZZZ')

	// strip all other tags
	s = s.replace(/<(\/?)[a-z].*?>/ig, '')

	// put back the <'s we preserved
	s = s.replace(/ZZZLTZZZ/g, '<')
	s = s.replace(/ZZZGTZZZ/g, '>')

	// consolidate multiple ps
	s = s.replace(/(<p>)+/g, '<p>')

	return s
}

///////////////////////////////////////////////////////////
// fn for clearing extra empty paragraphs or breaks at the ends of froala-entered text
window.trim_froala_text = function(html) {
	// console.log('trim_froala_text (start)', html)

	// clear ` id="isPasted"`
	html = html.replace(/\s+id="isPasted"/g, '')

	// clear 'undefined' tags (this shouldn't be necessary once the froala 3.2.7 ENTER_BR bug is fixed)
	html = html.replace(/<\s*(\/?)undefined\s*>/g, '')

	html = html.replace(/^((\s|\&nbsp;)*<br>(\s|\&nbsp;)*)*([\s\S]*?)((\s|\&nbsp;)*<br>(\s|\&nbsp;)*)*$/, '$4')

	// replace singleton p's both before and after replacing empty p's with closing tags
	html = html.replace(/^((\s|\&nbsp;)*<p>(\s|\&nbsp;)*)+/, '<p>')
	html = html.replace(/((\s|\&nbsp;)*<p>(\s|\&nbsp;)*)+$/, '')

	html = html.replace(/^((\s|\&nbsp;|<br>)*<p>(\s|\&nbsp;|<br>)*<\/p>(\s|\&nbsp;|<br>)*)*([\s\S]*?)((\s|\&nbsp;|<br>)*<p>(\s|\&nbsp;|<br>)*<\/p>(\s|\&nbsp;|<br>)*)*$/, '$5')

	html = html.replace(/^((\s|\&nbsp;)*<p>(\s|\&nbsp;)*)+/, '<p>')
	html = html.replace(/((\s|\&nbsp;)*<p>(\s|\&nbsp;)*)+$/, '')

	// console.log('trim_froala_text (end)', html)

	return $.trim(html)
}
