<template><div>
	<div class="py-4 d-flex">
		<v-text-field
			v-model="search"
			prepend-inner-icon="fa fa-search" clearable clear-icon="fa fa-times-circle"
			label="Search" single-line hide-details outlined dense background-color="#fff" style="flex:0 1 350px"
		></v-text-field>
		<div v-if="is_system_admin" class="ml-3" style="width:200px"><v-autocomplete outlined dense hide-details v-model="county_filter" label="County" :items="counties"></v-autocomplete></div>
		<div class="ml-3" style="width:120px"><v-select outlined dense hide-details v-model="proficiency_filter" label="Proficiency" :items="proficiencies"></v-select></div>
		<v-spacer/>
		<v-btn v-if="is_system_or_county_admin" class="ml-3" color="primary" @click="new_user_clicked" style="letter-spacing:0;text-transform:none;"><v-icon small class="mr-2">fas fa-plus</v-icon> Add New Person</v-btn>
		<v-btn v-if="is_system_admin" class="ml-3" color="primary" @click="bulk_add_clicked" style="letter-spacing:0;text-transform:none;"><v-icon small class="mr-2">fas fa-plus</v-icon> Batch Add New People</v-btn>
		<v-btn class="ml-3" fab small color="primary" @click="get_users"><v-icon>fas fa-sync-alt</v-icon></v-btn>
	</div>

	<v-data-table dense
		:headers="headers"
		:items="rows"
		:sort-by="['last_login_val']"
		:sort-desc="[true]"
		:must-sort="true"
		:custom-filter="table_search_filter"
		:search="search"
		:footer-props="footer_options"
		:items-per-page="25"
		class="k-admin-users-table"
	>
		<template v-slot:item="{ item }"><tr :class="(last_user_id_edited==item.vh_user_id)?'amber lighten-4':''">
			<td style="max-width:10px"><v-checkbox class="shrink mt-0 pt-0 d-inline-block" hide-details v-model="batch_select[item.vh_user_id]"></v-checkbox></td>
			<td @click="user_showing=item.user" style="cursor:pointer;">
				<UserInfo v-if="user_showing&&user_showing==item.user" :user="user_showing" @close="user_showing=null" style="margin-left:24px;margin-top:20px;"></UserInfo>
				<nobr>
					<v-btn icon x-small class="mr-2" @click.stop="edit_user(item.user)"><v-icon small>fas fa-edit</v-icon></v-btn>
					<v-icon x-small color="rgba(128,128,128,0.5)" style="margin-top:-2px">fas fa-info-circle</v-icon>
					{{item.name_display}}
				</nobr>
			</td>
			<!-- <td><v-btn icon x-small class="mr-2" @click="edit_user(item.user)"><v-icon small>fas fa-edit</v-icon></v-btn><span v-html="item.name_display"></span></td> -->
			<td v-html="item.role_descriptions"></td>
			<td>{{item.user.counties.join(', ')}}</td>
			<!-- <td class="text-center">{{item.training_display}}</td> -->
			<td>
				<v-menu bottom left><template v-slot:activator="{on}"><v-btn v-on="on" x-small color="#fff" @click="">{{item.training_display}}</v-btn></template>
					<v-list dense>
						<v-list-item v-for="(titem) in training_levels" :key="titem.value" @click="update_user(item.user, 'training_level', titem.value)"><v-list-item-title><v-icon small color="#333" class="mr-2" v-visible="titem.value==item.training_level">fas fa-circle</v-icon>{{titem.text}}</v-list-item-title></v-list-item>
					</v-list>
				</v-menu>
			</td>
			<td class="text-center">
				<v-icon :color="(item.proficiency_level>=1)?'amber darken-2':'#ddd'" small @click="update_user(item.user,'proficiency_level',1)">fas fa-star</v-icon>
				<v-icon :color="(item.proficiency_level>=2)?'amber darken-2':'#ddd'" small @click="update_user(item.user,'proficiency_level',2)">fas fa-star</v-icon>
				<v-icon :color="(item.proficiency_level>=3)?'amber darken-2':'#ddd'" small @click="update_user(item.user,'proficiency_level',3)">fas fa-star</v-icon>
			</td>
			<td>{{item.languages}}</td>
			<td class="px-0 text-center"><v-icon small>fas fa-{{item.ev_eligible?'check':'xmark'}}</v-icon></td>
			<td>{{item.lbj_user_id}}</td>
			<td class="text-center">{{item.login_count}}</td>
			<td class="text-center" style="white-space:nowrap">{{item.last_login_display}}</td>
		</tr></template>
	</v-data-table>

	<div>
		<v-btn small color="#ccc" @click="select_all" class="k-tight-btn mr-2">Select All</v-btn>
		<v-btn v-if="batch_checkbox_selected" small color="#ccc" @click="deselect_all" class="k-tight-btn mr-2">Deselect All</v-btn>
		<v-btn v-if="is_system_admin&&batch_checkbox_selected" small color="red" dark @click="delete_selected" class="k-tight-btn mr-2"><v-icon small class="mr-2">fas fa-trash-alt</v-icon>Delete Selected Users</v-btn>
		<v-btn v-if="is_system_admin&&batch_checkbox_selected" small color="primary" @click="batch_assign_property('roles')" class="k-tight-btn mr-2"><v-icon small class="mr-2">fas fa-bolt</v-icon>Batch Assign role</v-btn>
		<v-btn v-if="is_system_admin&&batch_checkbox_selected" small color="primary" @click="batch_assign_property('languages')" class="k-tight-btn mr-2"><v-icon small class="mr-2">fas fa-bolt</v-icon>Batch Assign language</v-btn>
		<v-btn v-if="batch_checkbox_selected" small color="primary" @click="lbj_synch" class="k-tight-btn mr-2"><v-icon small class="mr-2">fas fa-arrow-right-arrow-left</v-icon> LBJ Synch</v-btn>
	</div>

	<AdminNewUsers v-if="add_new_users" @close="add_new_users=false" @refresh="get_users" />
	<AdminEditUser v-if="user_being_edited" :user_being_edited="user_being_edited" @close="edit_user_closed"></AdminEditUser>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
import AdminNewUsers from './AdminNewUsers'
import AdminEditUser from './AdminEditUser'
import UserInfo from '../users/UserInfo'

export default {
	components: { AdminNewUsers, AdminEditUser, UserInfo },
	props: {
		// req: { type: String, required: true },
		// nreq: { type: String, required: false, default() { return ''} },
	},
	data() { return {
		headers: [
			{ text: '', align: 'left', sortable: false, value:'vh_user_id' },
			{ text: 'Name/Email', align: 'left', sortable: true, value:'name_display' },
			{ text: 'Roles', align: 'left', sortable: true, value:'role_descriptions' },
			{ text: 'County', align: 'left', sortable: true, value:'county_lc' },
			{ text: 'Training', align: 'center', sortable: true, value:'training_display' },
			{ text: 'Proficiency', align: 'center', sortable: true, value:'proficiency_level' },
			{ text: 'Languages', align: 'left', sortable: true, value:'languages' },
			{ text: 'EV Elig', align: 'left', sortable: true, value:'ev_eligible' },
			{ text: 'LBJ ID', align: 'left', sortable: true, value:'lbj_user_id' },
			{ text: 'Logins', align: 'center', sortable: true, value:'login_count' },
			{ text: 'Last Login', align: 'center', sortable: true, value:'last_login_val' },
		],
		footer_options: {
			itemsPerPageOptions: [10,25,100,-1],
		},
		proficiencies: [ 'ALL', '3 Stars', '2 Stars', '1 Star' ],
		proficiency_filter: 'ALL',
		county_filter: 'ALL',
		search: '',
		user_being_edited: null,
		last_user_id_edited: '',
		add_new_users: false,
		user_showing: null,
		batch_select: {},
		training_levels: [
			{ value: 0, text: '– (No Training)' },
			{ value: 10, text: 'Basic Training' },
			{ value: 20, text: 'Advanced Training' },
		],
	}},
	computed: {
		...mapState(['user_info', 'users', 'time_zone_correction']),
		...mapGetters(['is_system_admin', 'is_county_admin', 'is_system_or_county_admin']),
		counties() {
			let arr = ['ALL']
			for (let c of this.$store.state.site_data.counties) {
				// for non-system admins, only show counties the current user is authorized for
				if (this.is_system_admin || this.user_info.matches_county(c)) arr.push(c)
			}
			return arr
		},
		rows() {
			let arr = []

			for (let u of this.users) {
				// if user isn't a system admin, only show them people from their county(s)
				if (!this.is_system_admin) {
					if (!this.user_info.matches_county(u.counties)) continue
				}

				// if county_filter is set, check county
				if (this.county_filter != 'ALL') {
					if (!u.matches_county(this.county_filter)) continue
				}

				// if proficiency_filter is set, check proficiency
				if (this.proficiency_filter != 'ALL') {
					if (this.proficiency_filter == '1 Star' && u.proficiency_level != 1) continue
					if (this.proficiency_filter == '2 Stars' && u.proficiency_level != 2) continue
					if (this.proficiency_filter == '3 Stars' && u.proficiency_level != 3) continue
				}

				let name_display = sr('$1, $2 ($3)', u.name_last, u.name_first, u.email)
				// if (u.lbj_email) name_display += sr(' [LBJ: $1]', u.lbj_email)
				// if (u.other_email) name_display += sr(' [Alt: $1]', u.other_email)
				let name_search = u.name_last + ' ' + u.name_first + ' ' + u.email

				if (!empty(u.lbj_email)) name_search += ' ' + u.lbj_email
				if (!empty(u.cal_email)) name_search += ' ' + u.cal_email
				if (!empty(u.other_email)) name_search += ' ' + u.other_email
				if (!empty(u.phone_number)) name_search += ' ' + u.phone_number
				name_search = name_search.toLowerCase()

				let training_display = '—'
				if (u.training_level == 10) training_display = 'Basic'
				if (u.training_level == 20) training_display = 'Advanced'

				let last_login_display = ''
				let last_login_val = 0
				if (!empty(u.last_login)) {
					last_login_val = date.parse(u.last_login, 'YYYY-MM-DD HH:mm:ss').getTime() - this.time_zone_correction
					last_login_display = date.format(new Date(last_login_val), 'M-DD h:mm A')
				} else {
					last_login_val = -1 * date.parse(u.created_at, 'YYYY-MM-DD HH:mm:ss').getTime() - this.time_zone_correction
				}

				let o = {
					vh_user_id: u.vh_user_id,

					name_display: name_display,
					name_search: name_search,
					role_descriptions: u.role_descriptions(),
					proficiency_level: u.proficiency_level,
					training_display: training_display,
					training_display_lc: training_display.toLowerCase(),
					languages: u.languages,
					ev_eligible: u.ev_eligible,
					lbj_user_id: u.lbj_user_id,
					county_lc: u.counties.join(',').toLowerCase(),
					residence_state_lc: u.residence_state.toLowerCase(),

					login_count: u.login_count,
					last_login_val: last_login_val,
					last_login_display: last_login_display,

					user: u,
				}

				arr.push(o)
			}

			return arr
		},
		n_checkboxes_selected() {
			let i = 0
			for (let index in this.batch_select) {
				if (this.batch_select[index]) ++i
			}
			return i
		},
		batch_checkbox_selected() {
			return this.n_checkboxes_selected > 0
		},
		default_county: {
			get() { return this.$store.state.lst.default_county },
			set(val) { this.$store.commit('lst_set', ['default_county', val]) }
		},
	},
	watch: {
		county_filter(val) {
			this.default_county = val
		},
		'$store.state.lst.default_county'(val) {
			this.county_filter = val
		}
	},
	created() {
		vapp.admin_users = this
		// if user isn't a system admin, use 'ALL' (users will still be limited to their counties); otherwise if default_county is set, use it
		if (!this.is_system_admin) this.county_filter = 'ALL'
		else if (this.$store.state.lst.default_county) this.county_filter = this.$store.state.lst.default_county
	},
	mounted() {
		this.get_users()
	},
	methods: {
		get_users() {
			this.$store.dispatch('get_users').then(()=>{})
		},

		table_search_filter(value, search, item) {
			// value is the value of the column (we can ignore this); search is the search string (could be empty)
			// RETURN FALSE TO HIDE THE ITEM

			// if search is empty, always return true, so the row will SHOW
			if (empty(search)) return true

			search = search.toLowerCase()
			let re = new RegExp(search, 'i')

			// check _lc fields
			if (item.name_search.search(re) > -1) return true
			if (item.training_display_lc.search(re) > -1) return true
			if (item.county_lc.search(re) > -1) return true

			// also check role_descriptions
			if (item.role_descriptions.search(re) > -1) return true

			// if we get to here return false
			return false
		},

		update_user(user, key, val) {
			// if this user is selected, batch update all selected records
			if (this.batch_select[user.vh_user_id]) {
				this.batch_update_users(key, val)
				return
			}

			let edited_user = new User(user)
			edited_user[key] = val

			this.$store.dispatch('save_users', {users: [edited_user]}).then((result)=>{
				// this.$emit('cancel')
			}).catch((result)=>{
				// shouldn't happen
			})
		},

		deselect_all() {
			this.batch_select = {}
		},

		select_all() {
			let o = {}
			for (let item of this.rows) {
				if (this.table_search_filter(null, this.search, item)) {
					o[item.vh_user_id] = true
				}
			}
			this.batch_select = o
		},

		batch_update_users(key, val) {
			let arr = []
			for (let vh_user_id in this.batch_select) {
				let edited_user = new User(this.users.find(x=>x.vh_user_id == vh_user_id))
				edited_user[key] = val
				arr.push(edited_user)
			}

			this.$confirm({
			    title: 'Are you sure?',
			    text: sr('Are you sure you want to update the $1 selected user(s)?', arr.length),
			    acceptText: 'Update',
			}).then(y => {
				this.$store.dispatch('save_users', {users: arr}).then((result)=>{
					// this.$emit('cancel')
				}).catch((result)=>{
					// shouldn't happen
				})
			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		batch_assign_property(key) {
			let key_string = key.replace(/s$/, '')
			this.$prompt({
				title: 'Batch assign ' + key_string,
				text: sr('Enter the $1 to set for these users. (Note that when using this batch tool, each selected user will be assigned the specified $1 <b>only</b>; any previous $1(s) that might have been assigned to these users will be removed.)<br><br>Enter “none” to clear the $1 values.', key_string),
			}).then(val => {
				val = $.trim(val)
				if (empty(val)) return
				if (val == 'none') {
					val = ''
				} else {
					if (key == 'roles') {
						let final_val = ''
						if (this.$store.state.site_data.custom_roles.includes(val)) {
							final_val = val
						} else {
							// translate entered role to its value...
							let o = U.default_roles.find(x=>x.text.toLowerCase() == val.toLowerCase())
							if (o) final_val = o.value
						}
						if (empty(final_val)) {
							this.$alert('Invalid role: ' + val)
							return
						}
					}
				}

				this.batch_update_users(key, val)
			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		delete_selected() {
			let vh_user_ids = []
			for (let vh_user_id in this.batch_select) {
				if (this.batch_select[vh_user_id]) vh_user_ids.push(vh_user_id)
			}

			let payload = {vh_user_ids: vh_user_ids}
			let text = `Are you sure you want to delete these ${vh_user_ids.length} user(s)?`
			if (vh_user_ids.length > 100) {
				text += '<br><br>Note: LBJ shift/assignment records for these users will NOT be deleted. If you need to have LBJ records deleted, please delete users in batches of 100 or fewer.'
				payload.synch_users_with_lbj = 'no'
				payload.synch_shifts_with_lbj = 'no'
			}

			this.$confirm({
			    title: 'Are you sure?',
			    text: text,
			    acceptText: 'Delete',
				acceptColor: 'red',
			}).then(y => {
				this.$store.dispatch('delete_users', {vh_user_ids: vh_user_ids}).then((result)=>{
					this.batch_select = {}
					// get shifts -- some shifts might have been updated
					this.$store.dispatch('get_shifts')

				}).catch((result)=>{
					// shouldn't happen
				})
			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		edit_user(user) {
			this.user_being_edited = user
			this.last_user_id_edited = user.vh_user_id
		},

		new_user_clicked() {
			let data = {roles: 'poll_watcher'}
			this.user_being_edited = new User(data)
			this.last_user_id_edited = ''
		},

		edit_user_closed($evt) {
			console.log($evt)
			if ($evt == 'delete') this.last_user_id_edited = ''
			else if ($evt?.user_data) this.last_user_id_edited = $evt.user_data[0].vh_user_id
			this.user_being_edited = null
		},

		bulk_add_clicked() {
			this.add_new_users = true
		},

		lbj_synch() {
			let max_synchs = 25

			// try to synch first X users in the currently-selected rows that don't have lbj_user_id values; tell the user what we're doing first
			// all we need to do to synch is send the vh_user_id to save_users
			let users_to_synch = []
			let n_selected = 0
			let n_to_synch = 0
			for (let vh_user_id in this.batch_select) {
				++n_selected
				let user = this.users.find(x=>x.vh_user_id == vh_user_id)
				if (empty(user.lbj_user_id) && user.synch_attempted != true) {
					++n_to_synch
					if (users_to_synch.length < max_synchs) users_to_synch.push({vh_user_id: vh_user_id})
				}
			}

			if (users_to_synch.length == 0) {
				this.$alert('All currently-selected users have been synched (or have been attempted to synch) with LBJ.')
				return
			}

			let text = `${n_to_synch} of the ${n_selected} currently-selected users need to be synched with LBJ. `
			if (users_to_synch.length < n_to_synch) {
				text += `Click SYNCH to attempt to synch ${users_to_synch.length} of these users, based on the email address(es) entered in this site’s database. (You can continue to synch ${max_synchs} users at a time after this synch attempt completes.)`
			} else {
				text += `Click SYNCH to attempt to synch these users, based on the email address(es) entered in this site’s database.`
			}
			text += '<br><br>NOTE: The synch operation may take up to one minute to complete.'
			this.$confirm({
				title: 'Synch Users from LBJ',
				text: text,
				acceptText: 'Synch',
				dialogMaxWidth: 600,
			}).then(y => {
				this.$store.dispatch('save_users', {users: users_to_synch}).then((result)=>{
					let n_updated = 0
					for (let u of users_to_synch) {
						let user = this.users.find(x=>x.vh_user_id == u.vh_user_id)
						// set synch_attempted to true for all the users we tried to synch (synch_attempted is temporary; it will be cleared if the user reloads, or probably also if they leave the People tab then come back)
						if (user) {
							user.synch_attempted = true
							if (!empty(user.lbj_user_id)) ++n_updated
						}
					}
					this.$alert(`${n_updated} user(s) updated.`)
					// this.$emit('cancel')
				}).catch((result)=>{
					// shouldn't happen
				})
			}).catch(n=>{console.log(n)}).finally(f=>{})
		},
	}
}
</script>

<style lang="scss">
.k-admin-users-table {
	td {
		font-size:12px!important;
	}
}
</style>
