<template><div class="k-page-holder">
	<PageTitle :tab_id="tab_id" />
	<PageSections :tab_id="tab_id" />
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
import PageTitle from '@/components/PageTitle'
import PageSections from '@/components/PageSections'

export default {
	components: { PageTitle, PageSections },
	props: {
		// tab_id: { type: String, required: true },
		// nreq: { type: String, required: false, default() { return ''} },
	},
	data() { return {
	}},
	computed: {
		...mapState([]),
		...mapGetters([]),
		tab_id() {
			return this.$route.name
		},
	},
	watch: {
	},
	created() {
	},
	mounted() {
	},
	methods: {
	}
}
</script>

<style lang="scss">
</style>
