import '@/js/utilities.js'	// do this first so router et al have access to U functions
import Vue from 'vue'
import vuetify from './plugins/vuetify';
import App from './App.vue'
import store from '@/store'
import router from '@/router'
import DialogPromise from '@/components/vuetify-dialog-promise-pwet'
Vue.use(DialogPromise)

import VueVisible from 'vue-visible'
Vue.use(VueVisible)

// import froala component, custom froala fns, and FroalaWrapper component; to use: <froala-wrapper />
import froala from '@/components/FroalaWrapper/froala-plugin'
import FroalaWrapper from '@/components/FroalaWrapper/FroalaWrapper'
Vue.component('froala-wrapper', FroalaWrapper)

import date from 'date-and-time'
import 'date-and-time/plugin/meridiem';
date.plugin('meridiem')
window.date = date

// jquery
window.$ = require('jquery')
window.jQuery = window.$

import VueYoutube from 'vue-youtube'
Vue.use(VueYoutube)

import './js/site_data.js'
import './js/user.js'
import './js/shift.js'
import './js/location.js'
import './js/dialpad_user.js'

Vue.config.productionTip = false

new Vue({
	vuetify,
	store,
	router,
	render: h => h(App)
}).$mount('#app')
