class Location {
	constructor(data) {
		if (empty(data)) data = {}

		sdp(this, data, 'vh_location_id', 0)
		sdp(this, data, 'vh_org_id', vapp.$store.state.vh_org_id)
		sdp(this, data, 'lbj_location_id', '')
		sdp(this, data, 'county', '')
		sdp(this, data, 'location', '')
		sdp(this, data, 'geocode', null)
		sdp(this, data, 'ed_priority', 0)
		this.ed_priority_sort_val = this.ed_priority ? this.ed_priority : 99999999

		// 1 is highest priority; 4 is lowest priority
		sdp(this, data, 'default_priority', 4)
		// but we initially allowed for 5 and 6; switch those to 4; also use 4 if <= 0
		if (this.default_priority > 4 || this.default_priority <= 0) this.default_priority = 4

		sdp(this, data, 'location_type', '', ['', 'ev', 'ed'])
		sdp(this, data, 'location_status', 'active', ['active', 'inactive'])
	}
}
window.Location = Location
