import '@fortawesome/fontawesome-free/css/all.css' // Ensure you are using css-loader
import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify);

export default new Vuetify({
	icons: {
		iconfont: 'fa',
	},
	theme: {
		dark: false,
		themes: {
			light: {
				primary: '#4b67f2',
				// secondary: '#b0bec5',
			}
		}
	}
});
