var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"k-page-holder"},[_c('PageTitle',{attrs:{"tab_id":"shifts"}}),_vm._v(" "),_c('div',{staticClass:"mt-3 k-my-shifts-table-wrapper"},[_c('div',{staticClass:"d-flex"},[_c('v-spacer'),_c('div',{staticClass:"text-center green lighten-4 px-2",staticStyle:{"border-radius":"6px"}},[_vm._v("The current date is "),_c('b',[_vm._v(_vm._s(_vm.today_date))])]),_c('v-spacer')],1),_vm._v(" "),(_vm.my_shifts.length==0)?_c('div',{staticClass:"mt-3"},[_vm._v("You are not signed up for any upcoming shifts.")]):_vm._e(),_vm._v(" "),(_vm.my_shifts.length>0)?_c('div',{staticClass:"mt-3"},[_c('v-data-table',{staticClass:"k-my-shifts-table",attrs:{"dense":"","headers":_vm.headers,"items":_vm.my_shift_rows,"must-sort":false,"hide-default-footer":true,"items-per-page":-1},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',{staticClass:"text-center",staticStyle:{"width":"10px","white-space":"nowrap"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(item.status=='filled')?_c('span',_vm._g({},on),[_c('b',[_vm._v("Scheduled")]),_vm._v(" "),_c('v-btn',{staticClass:"ml-1",style:(_vm.shift_confirmable(item.shift)?'color:#555!important':''),attrs:{"disabled":!_vm.shift_confirmable(item.shift),"x-small":"","color":"orange darken-3","light":""},on:{"click":function($event){return _vm.change_shift_status(item.shift,'confirmed')}}},[_c('b',[_vm._v("Confirm")])])],1):_vm._e()]}}],null,true)},[_c('div',{staticClass:"text-center",domProps:{"innerHTML":_vm._s(_vm.confirm_btn_tooltip(item.shift))}})]),_vm._v(" "),(['confirmed','checked_in','completed'].includes(item.status))?_c('span',{staticStyle:{"margin-right":"50px"}},[_c('v-icon',{attrs:{"small":"","color":"green darken-1"}},[_vm._v("fas fa-circle-check")]),_vm._v(" "),_c('b',{staticClass:"green--text text--darken-1"},[_vm._v("CONFIRMED")])],1):_vm._e(),_vm._v(" "),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(item.status!='completed')?_c('v-btn',_vm._g({staticClass:"ml-0",attrs:{"small":"","icon":"","color":"red"},on:{"click":function($event){return _vm.change_shift_status(item.shift,'open')}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-times-circle")])],1):_vm._e()]}}],null,true)},[_vm._v("I can’t make this shift")])],1),_vm._v(" "),_c('td',[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_vm._v(_vm._s(item.county))])]}}],null,true)},[_vm._v("Shift ID: "+_vm._s(item.vh_shift_id))])],1),_vm._v(" "),_c('td',[_vm._v(_vm._s(item.location))]),_vm._v(" "),_c('td',{staticClass:"text-center"},[_c('nobr',{domProps:{"innerHTML":_vm._s(item.date_display)}})],1)])]}}],null,false,1883783740)})],1):_vm._e()]),_vm._v(" "),(!_vm.signing_up)?_c('div',{staticClass:"k-new-shift-signup-wrapper text-center mt-5 mb-3"},[_c('v-btn',{attrs:{"large":"","color":"green darken-2","dark":""},on:{"click":function($event){_vm.signing_up=true}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("fas fa-plus")]),_vm._v("Sign up for a new shift")],1)],1):_vm._e(),_vm._v(" "),(_vm.signing_up)?_c('MyShiftsSignup',{on:{"cancel":function($event){_vm.signing_up=false}}}):_vm._e(),_vm._v(" "),_c('PageSections',{attrs:{"tab_id":"shifts"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }