<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div>
	<froala :config="config_x" :data-froala_wrapper_id="froala_wrapper_id" ref="froala_component" v-model="model_value"></froala>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
// import TemplateComponent from '@/components/TemplateComponent'

export default {
	name : "FroalaWrapper",
	// components: { TemplateComponent },
	props: {
		value: { required: false, default() { return null } },
		parameter: { required: false, default() { return '' } },
		parameter_object: { required: false, default() { return null } },
		config: { required: false, default() { return null } },
		parent_component: { required: false, default() { return null } },
	},
	data() { return {
		config_x: {},
		froala_wrapper_id: U.new_uuid(),
	}},
	computed: {
		...mapState([]),
		...mapGetters([]),
		// we provide multiple options for mapping the model_value returned by the froala editor onto the parent's parameter...
		model_value: {
			get() {
				// if a parameter_object is passed in, we directly get/set parameter_object[this.parameter]
				if (this.parameter_object) {
					return this.parameter_object[this.parameter]

				} else if (this.parameter) {
					// if we get a parameter (but not a parameter_object), set this.$parent.parameter
					// note that this requires the froala-wrapper component to be a *direct child* of the component it's in
					return this.$parent[this.parameter]
					// (we might want to use this if we need access to the parameter name for some other reason...)

				} else {
					// if parameter/parameter_object not supplied, use the standard vue v-model method
					// https://v2.vuejs.org/v2/guide/components-custom-events.html
					// https://www.digitalocean.com/community/tutorials/how-to-add-v-model-support-to-custom-vue-js-components
					return this.value
				}
			},
			set(val) {
				if (this.parameter_object) {
					this.parameter_object[this.parameter] = val

				} else if (this.parameter) {
					this.$parent[this.parameter] = val

				} else {
					this.$emit('input', val)
					// this.$emit('update:value', val)	// for vue 3, we will need to switch to this, and use "modelValue" instead of "value"
					// https://vuejs.org/guide/components/events.html#usage-with-v-model
				}
			}
		},
	},
	watch: {
	},
	created() {
		// if we're passed an explicit froala config object, use it; otherwise use the default supplied by U.get_froala_config
		if (!this.config) this.config_x = U.get_froala_config({})
		else if (this.config.key) this.config_x = this.config
		else this.config_x = U.get_froala_config(this.config)

		// create the froala_wrapper_components object in the store if not already there
		if (!this.$store.state.froala_wrapper_components) {
			this.$store.commit('set', ['froala_wrapper_components', {}])
		}

		// then "register" this wrapper component
		this.$store.commit('set', ['froala_wrapper_components', this.froala_wrapper_id, this])
	},
	mounted() {
	},
	methods: {
		get_parent() {
			if (this.parent_component) return this.parent_component
			else return this.$parent
		},
	}
}
</script>

<style lang="scss">
</style>
