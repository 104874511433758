<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template>
	<v-dialog v-model="dialog_open" max-width="900" persistent scrollable>
		<v-card>
			<v-card-title style="border-bottom:1px solid #999"><b>Shift Sign-Up “Rules” (shown to admins only)</b></v-card-title>
			<v-card-text class="mt-3" style="font-size:16px; color:#222;"><ol>

				<li>A volunteer will not see shifts they are <b>already signed up for</b> in the “Shift Sign-Up” area (they will, of course, see these shifts above the sign-up area, in the list of their current shifts)</li>
				<li>Shifts whose date/time is in the <b>past</b> are not shown</li>
				<li>Shifts whose status is anything other than “<b>open</b>” are not shown</li>
				<li>Shifts whose county does not match the volunteer’s <b>county</b> are not shown</li>

				<li class="mt-3">A 3-star (“gold”) volunteer will see priority 1 (red), 2 (orange), and 3 (yellow) shifts</li>
				<li>A 2-star (“silver”) volunteer will see priority 2 (orange) and 3 (yellow) shifts</li>
				<li>A 1-star (“bronze”) volunteer will see only priority 4 (green) shifts</li>
				<li>A volunteer that has not been given a proficiency level (i.e. they have “<b>no stars</b>”) will not see any available shifts</li>

				<li class="mt-3">If an upcoming shift has a date and time that <b>overlaps with a shift the volunteer is already signed up for</b>, that shift will be shown, but the button for the shift will be labeled “Not Available”, and if the volunteer clicks the button, they will see an alert saying “This shift conflicts with another shift you’re already signed up for.”</li>

				<li class="mt-3">The <b>first page</b> of shifts shown in the “Shift Sign-Up” area will always be the <b>set of shifts available to the volunteer whose priority is highest</b> (remember that “1” is the highest priority, and “4” is the lowest piority). If the volunteer clicks the button to see “More Available Shifts”, the set of available shifts with the next-lowest priority will be shown (after first showing the volunteer a dialog that says “The next page of available shifts are lower priority than the shifts you’re currently viewing. If possible, please pick a shift from this page; but if you can’t make any of these shifts, go ahead and look on the next page. Thanks!”).</li>

				<li class="mt-3"><b>Example:</b><ul>
					<li>A 3-star volunteer is requesting to sign up for a shift tomorrow.</li>
					<li>For this volunteer’s county and that day, there are:<ul>
						<li>3 open priority 1 (red) shifts, </li>
						<li>0 open priority 2 (orange) shifts, </li>
						<li>8 open priority 3 (yellow) shifts,</li>
						<li>4 open priority 4 (green) shifts, </li>
					</ul></li>
					<li>The volunteer will first see the 3 red shifts.</li>
					<li>Then if she clicks “More”, she will see the 8 yellow shifts (because there aren't any orange shifts to show);</li>
					<li>Then she will no longer see the “More” button, because we don’t show priority 4 shifts to 3-star volunteers.</li>
				</ul></li>

			</ol></v-card-text>
			<v-card-actions class="pa-3" style="border-top:1px solid #999">
				<v-spacer></v-spacer>
				<v-btn color="primary" @click="$emit('dialog_cancel')">Done</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
	// components: { MyShiftsSignupRules },
	props: {
		// req: { type: String, required: true },
		// nreq: { type: String, required: false, default() { return ''} },
	},
	data() { return {
		dialog_open: true
	}},
	computed: {
		...mapState([]),
		...mapGetters([]),
	},
	watch: {
	},
	created() {
	},
	mounted() {
	},
	methods: {
	}
}
</script>

<style lang="scss">
</style>
