// Various utility functions, some created by PW and some brought in from the intertubes (all open source)
// Part of the SPARKL educational activity system, Copyright 2019 by Pepper Williams

/** Checks whether a value is empty, defined as null or undefined or "".
 *  Note that 0 is defined as not empty.
 *  @param {*} val - value to check
 *  @returns {boolean}
 */
window.empty = function(val) {
	// you can also call this fn as empty(o, 'foo', 'bar'), which will return true if:
	// - o is empty OR o is not an object
	// - o.foo is empty OR o.foo is not an object
	// - o.foo.bar is empty OR o.foo.bar is not an object
	// this simplifies an if clause like `if (empty(o) || empty(o.foo) || empty(o.foo.bar))` to `if (empty(o, 'foo', 'bar'))`
	if (arguments.length > 1) {
		if (empty(val) || typeof(val) != 'object') {
			return true
		}

		// if we get to here, arguments[1] might be an array or the first of a series of strings; handle both cases
		let args
		if ($.isArray(arguments[1])) {
			args = arguments[1]
		} else {
			// copy arguments, then use splice to get everything from index 1 on
			args = $.merge([], arguments).splice(1)
		}

		// if we're here, we know that val is itself an object
		if (empty(val[args[0]])) {
			return true
		} else if (args.length > 1) {
			// shift the first value out of args and recurse
			val = val[args[0]]
			args.shift()
			return empty(val, args)
		}
		return false
	}

	// note that we need === because (0 == "") evaluates to true
	return (val === null || val === "" || val === undefined)
}

// look for a nested property of an object; if any property in the list is empty, return null
// obj = {alpha: {bravo:'charlie'}}
// oprop(obj, 'alpha', 'bravo') // == 'charlie'
window.oprop = function(obj) {
	if (empty(obj)) return null
	if (arguments.length == 1 || typeof(obj) != 'object') return obj

	// if we get to here, arguments[1] might be an array or the first of a series of strings; handle both cases
	let args
	if ($.isArray(arguments[1])) {
		args = arguments[1]
	} else {
		// copy arguments, then use splice to get everything from index 1 on
		args = $.merge([], arguments).splice(1)
	}

	// if args[0] isn't a property of obj, return null
	if (empty(obj[args[0]])) {
		return null

	// if we still have more than 1 arg, shift the first value out of args and recurse
	} else if (args.length > 1) {
		obj = obj[args[0]]
		args.shift()
		return oprop(obj, args)

	} else {
		return obj[args[0]]
	}
}



/** Return val or default_val, depending on whether val isn't or is empty (as defined by window.empty above)
 *  SHORTCUT: dv()
 */
window.default_value = function(val, default_val) {
	if (!empty(val)) return val
	return default_val
}
window.dv = window.default_value

/** Set a property of an object to a default value if the property is currently empty (as defined by window.empty above)
 *  SHORTCUT: sdp()
 *  Two versions:
 *      1. sdp(o, 'foo', 'bar')
 *          - sets o.foo to 'bar', unless o.foo is already set
 *      2. sdp(o, p, 'foo', 'bar')
 *          - if p.foo is set, then set o.foo to p.foo; otherwise set o.foo to 'bar'
 *
 *  Also do some limited type checking: if default_val is a Boolean or a number, make sure the value we ultimately set
 *      is a Boolean or number; if converting it to the correct type fails, throw an error
 *  Version 2 can also include a "legal_vals" array, for enumeration checking
 */
window.set_default_property = function(o) {
	var prop, default_val, val, legal_vals
	if (arguments.length >= 4) {
		legal_vals = arguments[4]
		default_val = arguments[3]
		prop = arguments[2]
		var p = arguments[1]
		if (!empty(p) && !empty(p[prop])) val = p[prop]
		else val = default_val
	} else {
		prop = arguments[1]
		default_val = arguments[2]
		if (!empty(o[prop])) val = o[prop]
		else val = default_val
	}

	// if default_val is true or false, make sure the value we set is also a boolean
	if (default_val === true || default_val === false) {
		if (val === 'true') val = true
		if (val === 'false') val = false
		if (val != true && val != false) {
			if (typeof(prop) == 'object') prop = '[object]'
			throw new Error(sr('Boolean argument expected for property $1; $2 received', prop, val))
		}
		// convert 1/0 to true/false
		val = (val == true)

	// if default_val is a number, make sure the value we set is also a number
	} else if (typeof(default_val) == 'number') {
		let new_val = val * 1
		if (isNaN(new_val)) {
			throw new Error(sr('Numeric argument expected for property $1; $2 received', prop, val))
		}
		val = new_val
	}

	// if we got legal_vals (which must be an array), check to make sure the value is one of those; use default_val otherwise
	if (!empty(legal_vals)) {
		if (legal_vals.find(x => x == val) == null) {
			console.log(sr('illegal value found for prop “$1”: “$2”', prop, val))
			val = default_val
		}
	}

	o[prop] = val
}
window.sdp = window.set_default_property

/** Replace variables in a string, a la PHP
 * e.g.:
 * str_replace("replace value $bar.", {bar: "foo"})
 *    =>
 * "replace value foo."
 *
 * o.bar can be either a scalar value or a function that returns a scalar value
 *
 * Or, you can pass variables in directly, and specify them with $1, $2, $3, etc.
 * str_replace("replace value $1.", "foo")
 *    =>
 * "replace value foo."
 *
 * SHORTCUT: sr()
 *
 *  @param {string} s
 *  @param {object|array} [o] - if this is an array, it will be assumed to be an array of objects. if this is not an array or an object, it will treat the arguments array as a list of scalars
 *  @returns {*}
 */
window.str_replace = function(s, o) {
	// if o is an array, recursively process each object in the array
	if ($.isArray(o)) {
		for (var i = 0; i < o.length; ++i) {
			s = str_replace(s, o[i])
		}

	} if (typeof(o) == "object") {
		// find all instances of $xxx
		var matches = s.match(/\$(\w+)\b/g)
		if (!empty(matches)) {
			for (var i = 0; i < matches.length; ++i) {
				var key = matches[i].substr(1)
				var val = null
				// scalars
				if (typeof(o[key]) == "string" || typeof(o[key]) == "number") {
					val = o[key]
				} else if (typeof(o[key]) == "function") {
					val = o[key]()
				}
				if (val !== null) {
					var re = new RegExp("\\$" + key + "\\b", "g")
					s = s.replace(re, val)
				}
			}
		}
	} else {
		for (var i = 1; i < arguments.length; ++i) {
			var re = new RegExp("\\$" + i + "\\b", "g")
			s = s.replace(re, arguments[i])
		}
	}
	return s
}
// shortcut
window.sr = str_replace

window.U = {}

// try to get PHPSESSID out of get string, then from local storage
U.session_id = ''
if (window.location.search.search(/.*PHPSESSID=(\w+).*/) > -1) {
	U.session_id = RegExp.$1
// if not found in get string, look in localstorage
} else if (window.location.pathname.search(/.*\/(\d+)\b/) > -1) {
	let link_id = RegExp.$1
	U.session_id = window.localStorage.getItem('sparkl_session_id-' + link_id)
	if (empty(U.session_id)) U.session_id = ''
}

U.ajax = function(service_name, data, callback_fn, override_options) {
	var url
	// local development with 'npm run serve'
	if (window.location.host.indexOf('localhost') > -1) {
	 	url = "/src/ajax.php"
	// server, or local testing with 'npm run build'
	} else {
		url = "/src/ajax.php"
	}
	if (!empty(U.session_id)) url += "?PHPSESSID=" + U.session_id

	if (data instanceof FormData) {
		data.append('service_name', service_name)
	} else {
		data.service_name = service_name
	}

	var options = {
		type: "POST",
		url: url,
		cache: false,
		data: data,
		dataType: "text",
		success: function(str, text_status) {
			var result
			if (empty(str)) {
				result = {"status": "Ajax returned with no status"}
			} else {
				try {
					result = JSON.parse(str)
				} catch(e) {
					result = {"status": str}
				}
			}

			if (empty(result.status)) {
				result.status = "Ajax returned with no status"
			}
			if (result.status != "ok") {
				// error
				console.log("ajax success but not 'ok'", result)
			}

			if (!empty(callback_fn)) {
				callback_fn(result)
			}

		},
		error: function(jqXHR, textStatus, errorThrown) {
			// let caller handle expired session notices
			// if (jqXHR.responseText == 'Session expired - please re-launch') {
			// 	alert('Your session has expired.')
			// }

			var result = {
				"status": "Ajax server error",
				"ajax_name": service_name,
				"textStatus": textStatus,
				"errorThrown": errorThrown,
				"responseText": jqXHR.responseText
			}

			if (!empty(callback_fn)) {
				callback_fn(result)
			}
		}
	}

	// override any options coming in
	if (!empty(override_options)) {
		for (var key in override_options) {
			options[key] = override_options[key]
		}
	}

	$.ajax(options)
}

U.loading_start = function(msg) {
	$('#spinner-wrapper').show()
	if (!empty(msg)) {
		$('#spinner-wrapper').append(sr('<div id="spinner-wrapper-msg" style="text-align:center;margin:20% 30px 0 30px;font-size:24px;font-weight:bold;font-family:sans-serif;color:#fff;">$1</div>', msg))
	}
}

U.loading_stop = function() {
	$('#spinner-wrapper').hide()
	$('#spinner-wrapper-msg').remove()
}

// clear the location search string without reloading the page or generating a new history entry
U.clear_location_search = function() {
	window.history.replaceState(null, '', window.location.pathname)
}

/**
 * Randomize array element order in-place.
 * Using Durstenfeld shuffle algorithm.
 * https://stackoverflow.com/questions/2450954/how-to-randomize-shuffle-a-javascript-array
 */
U.shuffle_array = function(array) {
	for (var i = array.length - 1; i > 0; i--) {
		var j = Math.floor(Math.random() * (i + 1));
		var temp = array[i];
		array[i] = array[j];
		array[j] = temp;
	}
}

// if one argument is specified, it's max and we return >= 0  and < max (use for an array index, e.g.)
// if two arguments are specified, they're min and max, and we return >= min and <= max
// if the first argument is a function, assume it's a random number generator to use instead of Math.random()
U.random_int = function() {
	let rng = Math.random
    let args
	if (arguments.length > 0 && typeof(arguments[0]) == "function") {
		rng = arguments[0]
        args = [arguments[1], arguments[2]]
	} else {
        args = arguments
    }

	if (empty(args[0])) return 0

	let min, max
	if (empty(args[1])) {
		min = 0
		max = args[0] * 1
	} else {
		min = args[0] * 1
		max = args[1] * 1 + 1
	}
	if (isNaN(min) || isNaN(max)) {
		return 0
	}
	return min + Math.floor(rng() * (max-min))
}

U.word_count = function(text) {
	if (empty(text)) return 0
	text = '' + text

	// remove entities
	text = text.replace(/\&[#\w]+/g, ' ')

	// insert spaces at breaks
	text = text.replace(/\b/g, ' ')

	// remove tags
	text = text.replace(/<.*?>/g, ' ')

	// remove non-letters
	text = text.replace(/[^\w\s]/g, '')

	// trim
	text = $.trim(text)

	// split on spaces and return count
	let arr = text.split(/\s+/)
	return arr.length;
}

U.html_to_text = function(html) {
	return $.trim($('<div>' + html + '</div>').text())
}

// U.copy_to_clipboard = function(s) {
// 	$('body').append('<textarea class="k-copy-to-clipboard-input-textarea"></textarea>')
// 	let jq = $('body').find('.k-copy-to-clipboard-input-textarea')
// 	jq.val(s)
// 	jq[0].select()
// 	document.execCommand("copy")
// 	jq.remove()
// }

U.copy_to_clipboard = function(s) {
	// https://stackoverflow.com/questions/400212/how-do-i-copy-to-the-clipboard-in-javascript
	// https://developer.mozilla.org/en-US/docs/Web/API/Clipboard_API
	let fallback = (s) => {
		$('body').append('<textarea class="k-copy-to-clipboard-input-textarea"></textarea>')
		let jq = $('body').find('.k-copy-to-clipboard-input-textarea')
		jq.val(s)
		jq[0].select()
		document.execCommand("copy")
		jq.remove()
	}

	if (!navigator.clipboard) {
		fallback(s)
	} else {
		navigator.clipboard.writeText(s).then(function() {
			console.log('Async: Copy to clipboard was successful')
		}, function(err) {
			console.error('Async: Could not copy text; trying fallback', err)
			fallback(s)
		})
	}
}

// plural string / plural string with number
// ps('word', 1) => 'word'
// ps('word', 2) => 'words'
// psn('word', 2) => '2 words'
// ps('a word', 2, 'the words') => 'the words'
U.ps = function(s, val, plural_s) {
	if (val*1 == 1) return s
	if (!empty(plural_s)) return plural_s
	return s + 's'
}
U.psn = function(s, val, plural_s) {
	s = U.ps(s, val, plural_s)
	return sr('$1 $2', val, s)
}

U.capitalize_word = function(s) {
	if (empty(s) || typeof(s) != 'string') return ''
	return s[0].toUpperCase() + s.substr(1)
}

U.remove_accents = function(str) {
	const accents     = 'ÀÁÂÃÄÅàáâãäåßÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž'
	const accents_out = 'AAAAAAaaaaaaBOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz'
	str = str.split('')
	let sl = str.length
	let i, x
	for (i = 0; i < sl; i++) {
		if ((x = accents.indexOf(str[i])) != -1) {
			str[i] = accents_out[x]
		}
	}
	return str.join('')
}

// parse and return unique characters in a string
// note that the last copy of each repeated letter will be returned
// note that this is case sensitive -- U.unique_chars('fFoobbar') returns 'fFobar'; use U.unique_chars(s.toLowerCase) to convert to lc first
U.unique_chars = function(s) {
	return s.replace(/(.)(?=.*\1)/g, "")
}

// return a sortable numeric value for grade labels, which can be "g-K", "g-1", etc.
U.grade_val = function(g) {
	if (g == 'g-K') return 0
	return g.substr(2) * 1
}

// easy natural sort algorithm that actually seems to work!
// https://fuzzytolerance.info/blog/2019/07/19/The-better-way-to-do-natural-sort-in-JavaScript/
U.natural_sort = function(a, b) {
	return a.localeCompare(b, navigator.languages[0] || navigator.language, {numeric: true, ignorePunctuation: true})
}

// formats seconds into "hh:mm:ss", with leading zeros inserted as appropriate and hours optional
U.time_string = function(seconds) {
	seconds = Math.round(seconds)
	let hours = Math.floor(seconds / 3600)
	let minutes = Math.floor((seconds - hours*3600) / 60)
	seconds = seconds % 60
	if (seconds < 10) seconds = '0' + seconds
	let ts = sr('$1:$2', minutes, seconds)
	if (hours > 0) {
		if (minutes < 10) ts = '0' + ts
		ts = sr('$1:$2', hours, ts)
	}

	return ts
}

// generates RFC-compliant GUIDs
// https://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript
// this is "e7" of Jeff Ward's answer
U.guid_lut = []; for (var i=0; i<256; i++) { U.guid_lut[i] = (i<16?'0':'')+(i).toString(16); }
U.new_uuid = function() {
	var d0 = Math.random()*0xffffffff|0;
	var d1 = Math.random()*0xffffffff|0;
	var d2 = Math.random()*0xffffffff|0;
	var d3 = Math.random()*0xffffffff|0;
	return U.guid_lut[d0&0xff]+U.guid_lut[d0>>8&0xff]+U.guid_lut[d0>>16&0xff]+U.guid_lut[d0>>24&0xff]+'-'+
		U.guid_lut[d1&0xff]+U.guid_lut[d1>>8&0xff]+'-'+U.guid_lut[d1>>16&0x0f|0x40]+U.guid_lut[d1>>24&0xff]+'-'+
		U.guid_lut[d2&0x3f|0x80]+U.guid_lut[d2>>8&0xff]+'-'+U.guid_lut[d2>>16&0xff]+U.guid_lut[d2>>24&0xff]+
		U.guid_lut[d3&0xff]+U.guid_lut[d3>>8&0xff]+U.guid_lut[d3>>16&0xff]+U.guid_lut[d3>>24&0xff];
}
U.is_uuid = function(s) {
	return s.search(/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/) == 0
}

U.is_letter = function(char) {
	return ('ABCDEFGHIJKLMNOPQRSTUVWXYZ'.indexOf(char) > -1)
}

// utility fn to convert a "coded" numeric query answer to its numeric value (e.g. '1/4' => .25)
U.decode_numeric_query_answer = function(s) {
	let arr = s.split('/')
	if (arr.length == 1) {
		return s * 1
	}

	return arr[0] / arr[1]
}

// utility fn to "encode" a number into the format used for numeric queries (?)
U.encode_numeric_query_answer = function(n) {
	// TODO: deal with symbols...
	let s = n + ''

	return s
}

// utility fn to determine if a string is a valid correct_answer for a numeric query
U.query_answer_is_numeric = function(s) {
	return !isNaN(this.decode_numeric_query_answer(s))
}

U.numeric_display_html = function(s) {
	s = s + ''

	// convert hyphen surrounded by spaces to n-dash
	s = s.replace(/ - /g, ' – ')

	// convert * to times
	s = s.replace(/\*/g, '×')

	// italicize letters
	s = s.replace(/([a-zA-Z])/g, '<i>$1<xi>')

	// convert fractions
	let arr = s.split('/')
	if (arr.length == 2) {
		s = sr('<span class="k-fraction-wrapper"><span class="k-fraction-numerator">$1</span><span class="k-fraction-denominator">$2</span></span>', arr[0], arr[1])
	}

	s = s.replace(/xi/g, '/i')

	s = sr('<span class="k-math">$1</span>', s)

	return s
}

// utility fn to determine the number of places for a numeric value
U.num_places = function(n) {
	if (isNaN(n*1)) return 0

	n = '' + n
	if (n.search(/\.(\d+)$/) > -1) {
		return RegExp.$1.length
	}
	return 0
}

// utilities for using local storage to save and retrieve settings
U.local_storage_set = function(key, val) {
	// use a consistent prefix for every key
	key = 'pwet__' + key

	// can't set to localStorage in safari incognito mode
	try {
		window.localStorage.setItem(key, JSON.stringify(val));
	} catch(e) {
		console.log('error in local_storage_set', e)
	}
}

U.local_storage_clear = function(key) {
	// use a consistent prefix for every key
	key = 'pwet__' + key

	window.localStorage.removeItem(key);
}

U.local_storage_get = function(key, default_val) {
	// use a consistent prefix for every key
	key = 'pwet__' + key

	let val = window.localStorage.getItem(key)
	if (!empty(val)) {
		try {
			val = JSON.parse(val);
		} catch(e) {
			console.log('error parsing JSON in local_storage_get: ' + val)
			val = ''
		}
	}

	if (empty(val)) {
		return default_val
	} else {
		// do some type checking
		if (default_val === true || default_val === false) {
			if (val === 'true') val = true
			if (val === 'false') val = false
			if (val != true && val != false) {
				throw new Error(sr('Boolean argument expected for key $1; $2 received', key, val))
			}

		} else if (typeof(default_val) == 'number') {
			if (isNaN(val*1)) {
				throw new Error(sr('Numeric argument expected for key $1; $2 received', key, val))
			}
			val = val * 1

		} else if (typeof(default_val) == 'string') {
			if (typeof(val) != 'string') {
				throw new Error(sr('String argument expected for key $1; $2 received', key, val))
			}
			val = val + ''
		}
		return val
	}
}

U.cookie_set = function(key, val) {
	// use a consistent prefix for every key
	key = 'pwet__' + key

	document.cookie = sr('$1=$2', key, val)
}

U.cookie_clear = function(key) {
	// use a consistent prefix for every key
	key = 'pwet__' + key

	document.cookie = key + "=; expires=Thu, 01 Jan 1970 00:00:00 GMT";
}

U.cookie_get = function(key, default_val) {
	// use a consistent prefix for every key
	key = 'pwet__' + key

	let cookies = document.cookie.split(';')
	for (let c of cookies) {
		let arr = c.split('=')
		if ($.trim(arr[0]) == key) {
			let val = arr[1]
			// do some type checking
			if (default_val === true || default_val === false) {
				if (val === 'true') val = true
				if (val === 'false') val = false
				if (val != true && val != false) {
					throw new Error(sr('Boolean argument expected for key $1; $2 received', key, val))
				}

			} else if (typeof(default_val) == 'number') {
				if (isNaN(val*1)) {
					throw new Error(sr('Numeric argument expected for key $1; $2 received', key, val))
				}
				val = val * 1

			} else if (typeof(default_val) == 'string') {
				if (typeof(val) != 'string') {
					throw new Error(sr('String argument expected for key $1; $2 received', key, val))
				}
				val = val + ''
			}
			return val
		}
	}
}

// Credit David Walsh (https://davidwalsh.name/javascript-debounce-function)
// Returns a function, that, as long as it continues to be invoked, will not be triggered.
// The function will be called after it stops being called for N milliseconds.
// If `immediate` is passed, trigger the function on the leading edge, instead of the trailing.
// To call:
	// // establish the debounce fn if necessary
	// if (empty(this.fn_debounced)) {
	// 	this.fn_debounced = U.debounce(function(x) {
	// 		...
	// 	}, 1000)
	// }
	// // call the debounce fn
	// this.fn_debounced(x)
U.debounce = function(func, wait, immediate) {
  	var timeout

  	// This is the function that is actually executed when the DOM event is triggered.
  	return function executedFunction() {
    	// Store the context of this and any parameters passed to executedFunction
    	var context = this
    	var args = arguments

    	// The function to be called after the debounce time has elapsed
    	var later = function() {
      		// null timeout to indicate the debounce ended
      		timeout = null

      		// Call function now if you did not on the leading end
      		if (!immediate) func.apply(context, args)
    	}

    	// Determine if you should call the function on the leading or trail end
    	var callNow = immediate && !timeout

    	// This will reset the waiting every function execution. This is the step that prevents the function
    	// from being executed because it will never reach the inside of the previous setTimeout
    	clearTimeout(timeout)

    	// Restart the debounce waiting period. setTimeout returns a truthy value (it differs in web vs node)
    	timeout = setTimeout(later, wait)

    	// Call immediately if you're dong a leading end execution
    	if (callNow) func.apply(context, args)
  	}
}


// https://stackoverflow.com/questions/1293147/javascript-code-to-parse-csv-data
window.CSV = {
	parse: function(csv, reviver) {
	    reviver = reviver || function(r, c, v) { return v; };
	    var chars = csv.split(''), c = 0, cc = chars.length, start, end, table = [], row;
	    while (c < cc) {
	        table.push(row = []);
	        while (c < cc && '\r' !== chars[c] && '\n' !== chars[c]) {
	            start = end = c;
	            if ('"' === chars[c]){
	                start = end = ++c;
	                while (c < cc) {
	                    if ('"' === chars[c]) {
	                        if ('"' !== chars[c+1]) { break; }
	                        else { chars[++c] = ''; } // unescape ""
	                    }
	                    end = ++c;
	                }
	                if ('"' === chars[c]) { ++c; }
	                while (c < cc && '\r' !== chars[c] && '\n' !== chars[c] && ',' !== chars[c]) { ++c; }
	            } else {
	                while (c < cc && '\r' !== chars[c] && '\n' !== chars[c] && ',' !== chars[c]) { end = ++c; }
	            }
	            row.push(reviver(table.length-1, row.length, chars.slice(start, end).join('')));
	            if (',' === chars[c]) { ++c; }
	        }
	        if ('\r' === chars[c]) { ++c; }
	        if ('\n' === chars[c]) { ++c; }
	    }
	    return table;
	},

	stringify: function(table, replacer) {
	    replacer = replacer || function(r, c, v) { return v; };
	    var csv = '', c, cc, r, rr = table.length, cell;
	    for (r = 0; r < rr; ++r) {
	        if (r) { csv += '\r\n'; }
	        for (c = 0, cc = table[r].length; c < cc; ++c) {
	            if (c) { csv += ','; }
	            cell = replacer(r, c, table[r][c]);
	            if (/[,\r\n"]/.test(cell)) { cell = '"' + cell.replace(/"/g, '""') + '"'; }
	            csv += (cell || 0 === cell) ? cell : '';
	        }
	    }
	    return csv;
	}
};

// simple TSV equivalent to CSV fns above
window.TSV = {
	parse: function(tsv) {
		let lines = tsv.split('\n')
		for (let i = 0; i < lines.length; ++i) {
			let line = $.trim(lines[i])

			if (empty(line)) {
				lines[i] = []
			} else {
				lines[i] = line.split('\t')
			}
		}
		return lines
	},

	stringify:function(lines) {
		let tsv = ''
		for (let i = 0; i < lines.length; ++i) {
			tsv += lines[i].join('\t') + '\n'
		}
		return tsv
	}
};

U.download_file = function(data, filename) {
	// this works for a csv or a tsv; probably also for other data types
	// slightly hacky solution from here: https://stackoverflow.com/questions/19721439/download-json-object-as-a-file-from-browser
	var data_str = 'data:text/json;charset=utf-8,' + encodeURIComponent(data)
	var download_anchor_node = document.createElement('a')
	download_anchor_node.setAttribute('href', data_str)
	// set filename of downloaded file
	download_anchor_node.setAttribute('download', filename)
	document.body.appendChild(download_anchor_node) // required for firefox
	download_anchor_node.click()
	download_anchor_node.remove()
}

// compress an image selected by the user from the filesystem (or a picture taken with a phone camera) client-side, and return the dataURL that represents the image
U.create_image_data_url = function(image_file, params) {
	console.log('create_image_data_url: ' + params.max_width)
	// params: max_width is required; max_height is optional
	let max_width = params.max_width
	let max_height = params.max_height
	// required callback fn to receive the image data_url and placeholder
	let callback_fn = params.callback_fn

	// 0.9 = slightly compressed jpg
	let compression_level = dv(params.compression_level, 0.9)

	let image_format = dv(params.image_format, 'jpeg')	// jpeg, webp, or png; png is the only one that's required for browsers to support...

	// create a canvas element to do the conversion
	let canvas = document.createElement("canvas")

	// load the image into memory using a FileReader to get the image size
	let reader = new FileReader()
	reader.onload = e => {
		let image = new Image()
		image.onload = e => {
			let natural_image_width = (image.naturalWidth) ? image.naturalWidth : image.width
			let natural_image_height = (image.naturalHeight) ? image.naturalHeight : image.height

			let scaled_image_width, scaled_image_height
			// if image is smaller than width/height, or if max_width is 'full', return as is
			if (max_width == 'full' || natural_image_width < max_width*1) {
				scaled_image_width = natural_image_width
				scaled_image_height = natural_image_height
			} else {
				// else start by scaling to make the image width fit in the max_height
				scaled_image_width = max_width
				scaled_image_height = natural_image_height / (natural_image_width / scaled_image_width)
			}

			// if we have max_height and this makes the height taller than max_height, scale to make the image height fit in the vertical space
			if (max_height && max_height != 'full' && scaled_image_height > max_height) {
				scaled_image_height = max_height
				scaled_image_width = natural_image_width / (natural_image_height / scaled_image_height)
			}

			// set the canvas width, then draw the image to the canvas
			canvas.width = scaled_image_width
			canvas.height = scaled_image_height
			canvas.getContext('2d').drawImage(image, 0, 0, scaled_image_width, scaled_image_height)

			// extract the image dataURL
			let img_url = canvas.toDataURL('image/' + image_format, compression_level)
			console.log('img_url size:' + img_url.length)

			callback_fn({
				img_url: img_url,
				width: scaled_image_width,
				height: scaled_image_height,
			})
		}
		image.src = e.target.result
	}
	// trigger the FileReader to load the image file
	reader.readAsDataURL(image_file)
}

// https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
U.format_bytes = function(bytes, decimals) {
    if (bytes === 0) return '0 Bytes'

    const k = 1024
    const dm = (!decimals || decimals < 0) ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

// This function takes in latitude and longitude of two location and returns the distance between them as the crow flies (in km)
// https://stackoverflow.com/a/18883819
U.get_distance_from_geocodes = function(geocode1, geocode2, places) {
    // Converts numeric degrees to radians
    function toRad(Value) { return Value * Math.PI / 180 }

	// var R = 6371 // km
	var R = 3959 // mi
	var dLat = toRad(geocode2.lat-geocode1.lat)
	var dLon = toRad(geocode2.lon-geocode1.lon)
	var lat1 = toRad(geocode1.lat)
	var lat2 = toRad(geocode2.lat)

	var a = Math.sin(dLat/2) * Math.sin(dLat/2) + Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2) 
	var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)) 
	var d = R * c

	if (empty(places)) return d
	return d.toFixed(places)
}

// Jan. 1 is 1, Jan 2 is 2,..., Feb 1 is 32, etc.; takes into account leap years and timezones
// https://stackoverflow.com/a/40975730
U.day_of_year = function(date){
	return (Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()) - Date.UTC(date.getFullYear(), 0, 0)) / 24 / 60 / 60 / 1000;
}

/*
date and time functions:
npm i date-and-time
https://github.com/knowledgecode/date-and-time

in main.js:
import date from 'date-and-time'
import 'date-and-time/plugin/meridiem';
date.plugin('meridiem')
window.date = date

to convert from timestamp:
date.format(new Date(this.entry.date_added*1000), 'MMMM D, YYYY h:mm A')	// January 1, 2019 3:12 PM
date.format(new Date(this.post.date_created*1000), 'MMM D, YYYY h:mm A')	// Jan 1, 2019 3:12 PM

to convert from mysql date to js Date, then to an alternate format:
let d = date.parse(data.results.created_at, 'YYYY-MM-DD HH:mm:ss')
date.format(d, 'MMM D, YYYY h:mm A')	// Jan 3, 2020 3:04 PM

token   meaning	  example
YYYY    year      0999, 2015
YY      year      05, 99
Y       year      2, 44, 888, 2015
MMMM    month     January, December
MMM     month     Jan, Dec
MM      month     01, 12
M       month     1, 12
DDD (*) day       1st, 2nd, 3rd
DD      day       02, 31
D       day       2, 31
dddd    day/week  Friday, Sunday
ddd     day/week  Fri, Sun
dd      day/week  Fr, Su
HH      24-hour   23, 08
H       24-hour   23, 8
A       meridiem  AM, PM
a (*)   meridiem  am, pm
AA (*)  meridiem  A.M., P.M.
aa (*)  meridiem  a.m., p.m.
hh      12-hour   11, 08
h       12-hour   11, 8
mm      minute    14, 07
m       minute    14, 7
ss      second    05, 10
s       second    5, 10
SSS     msec      753, 022
SS      msec      75, 02
S       msec      7, 0
Z       timezone  +0100, -0800
*/
