export default {
	data() { return {
		email_autocomplete_search: '',
		email_autocomplete_items: [],
	}},
	watch: {
		email_autocomplete_search(val) {
			console.log(this.email_autocomplete_search, val)
			// don't search until we have three characters; then if the value drops back below three characters, clear autocomplete_items so we'll search again when we get back to 3
			if (!val || val.length < 3) {
				this.email_autocomplete_items = []
				return
			} else if (this.email_autocomplete_items.length > 0 && val.length > 3) {
				// if we don't have any items, the search service may have overloaded, so keep trying if the user keeps typing
				return
			}

			// note that users must be in state prior to the fn running
			let users = vapp.$store.state.users
			this.email_autocomplete_items = []
			val = val.toLowerCase()
			for (let u of users) {
				if (u.email.indexOf(val) > -1 || u.name_first_lc.indexOf(val) > -1 || u.name_last_lc.indexOf(val) > -1) {
					let o = {value: u.email}
					if (!empty(u.name_last)) {
						o.text = sr('$1 ($2, $3)', u.email, u.name_last, u.name_first)
					} else {
						o.text = u.email
					}
					this.email_autocomplete_items.push(o)
				}
			}
		},
	}
}
