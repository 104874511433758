class Shift {
	constructor(data) {
		if (empty(data)) data = {}

		sdp(this, data, 'vh_shift_id', 0)
		sdp(this, data, 'lbj_shift_database_id', '')	// could be empty or a number
		sdp(this, data, 'lbj_status', '')
		sdp(this, data, 'vh_org_id', vapp.$store.state.vh_org_id)
		sdp(this, data, 'county', '')
		sdp(this, data, 'vh_location_id', 0)
		sdp(this, data, 'date', '')
		sdp(this, data, 'start_time', '09:00')
		sdp(this, data, 'end_time', '19:00')
		// 1 is highest priority; 4 is lowest priority
		sdp(this, data, 'priority', 2)
		// but we initially allowed for 5 and 6; switch those to 4
		if (this.priority > 4) this.priority = 4

		sdp(this, data, 'admin_email', '')
		sdp(this, data, 'volunteer_email', '')
		sdp(this, data, 'status', 'not_open', ['not_open', 'open', 'filled', 'confirmed', 'checked_in', 'completed'])
		sdp(this, data, 'updated_at', '')
	}

	filled_for_lbj() {
		return (this.status == 'filled' || this.status == 'confirmed' || this.status == 'checked_in' || this.status == 'completed')
	}

	lbj_status_info() {
		// calculate lbj status info to show
		let lbj_icon, lbj_icon_color, lbj_status_display, lbj_status_tooltip, lbj_sortorder

		// NOTE: if lbj_sortorder comes out as 0, it means we need to synch; we check for this in, e.g., AdminShifts

		// if lbj_status starts with 'todo-failed', this is a general-purpose failure of the API...
		if (this.lbj_status.indexOf('todo-failed') == 0) {
			lbj_icon = 'fas fa-xmark'
			lbj_icon_color = 'red darken-3'
			lbj_status_display = 'API failure'
			lbj_status_tooltip = `Status code: ${this.lbj_status}`
			lbj_sortorder = 9

		// if we don't have a lbj_shift_database_id...
		} else if (empty(this.lbj_shift_database_id)) {
			// if shift is filled with a volunteer...
			if (!(this.status == 'not_open' || this.status == 'open')) {
				// this is an error, because lbj needs to know this shift is scheduled and filled
				lbj_icon = 'fas fa-xmark'
				lbj_icon_color = 'red darken-3'

				// this should be the most common error state; say something about the cause...
				if (empty(this.lbj_status) || this.lbj_status == 'synched') {
					// synch was never tried, or if lbj_status is 'synched', the assignment was explicitly cleared from LBJ
					lbj_icon_color = '#666'
					lbj_status_display = 'Synch needed'
					lbj_status_tooltip = `Synch needed`
					lbj_sortorder = 1

				} else if (this.lbj_status == 'todo-election_id') {
					// need election id
					lbj_status_display = 'Need LBJ election_id'
					lbj_status_tooltip = `Status code: ${this.lbj_status}`
					lbj_sortorder = 5

				} else if (this.lbj_status == 'todo-location') {
					// need location id
					lbj_status_display = 'Need LBJ location_id'
					lbj_status_tooltip = `Status code: ${this.lbj_status}`
					lbj_sortorder = 6

				} else if (this.lbj_status == 'todo-user') {
					// need user id
					lbj_status_display = 'Need LBJ user_id'
					lbj_status_tooltip = `Status code: ${this.lbj_status}`
					lbj_sortorder = 7

				} else {
					lbj_status_display = 'Synch error'
					lbj_status_tooltip = `Status code: ${this.lbj_status}`
					lbj_sortorder = 8
				}

			// else shift isn't filled...
			} else {
				// show the minus icon, but color it green, because this is OK
				lbj_icon = 'fas fa-minus'
				lbj_icon_color = 'green darken-3'

				// if lbj_status is 'synched', tell them this
				if (this.lbj_status == 'synched') {
					lbj_status_display = 'Deleted'
					lbj_status_tooltip = 'Deleted from LBJ'
					lbj_sortorder = 0
				} else {
					lbj_status_display = 'Unfilled'
					lbj_status_tooltip = 'Never been synched with LBJ'
					lbj_sortorder = 0
				}
			}
		
		// else we do have an lbj_shift_database_id. 
		} else {
			// if shift isn't filled with a volunteer...
			if (this.status == 'not_open' || this.status == 'open') {
				// this is an error, because the shift should have been deleted from LBJ
				lbj_icon = 'fas fa-xmark'
				lbj_icon_color = 'red darken-3'
				lbj_status_display = 'Delete failed'
				lbj_status_tooltip = `${this.lbj_status}: ${this.lbj_shift_database_id}`
				lbj_sortorder = 3
			
			// if lbj_status is 'synched', that's good...
			} else if (this.lbj_status == 'synched') {
				lbj_icon = 'fas fa-check'
				lbj_icon_color = 'green darken-3'
				lbj_status_display = 'Synched'
				lbj_status_tooltip = `Synched with LBJ: ${this.lbj_shift_database_id}`
				lbj_sortorder = 0

			// else something else is wrong...
			} else {
				lbj_icon = 'fas fa-xmark'
				lbj_icon_color = 'red darken-3'
				if (this.lbj_status == 'todo-election_id') {
					// need election id
					lbj_status_display = 'Need LBJ election_id'
					lbj_status_tooltip = `${this.lbj_status}: ${this.lbj_shift_database_id}`
					lbj_sortorder = 5

				} else if (this.lbj_status == 'todo-location') {
					// need location id
					lbj_status_display = 'Need LBJ location_id'
					lbj_status_tooltip = `${this.lbj_status}: ${this.lbj_shift_database_id}`
					lbj_sortorder = 6

				} else if (this.lbj_status == 'todo-user') {
					// need user id
					lbj_status_display = 'Need LBJ user_id'
					lbj_status_tooltip = `${this.lbj_status}: ${this.lbj_shift_database_id}`
					lbj_sortorder = 7
					
				} else {
					lbj_status_display = 'Synch error'
					lbj_status_tooltip = `${this.lbj_status}: ${this.lbj_shift_database_id}`
					lbj_sortorder = 8
				}
			}
		}

		return {
			lbj_icon: lbj_icon,
			lbj_icon_color: lbj_icon_color,
			lbj_status_display: lbj_status_display,
			lbj_status_tooltip: lbj_status_tooltip,
			lbj_sortorder: lbj_sortorder,
		}
	}
}
window.Shift = Shift

U.shift_priority_to_color = function(priority) {
	if (priority == 0) return ''
	if (priority == 1) return 'red lighten-3'
	if (priority == 2) return 'orange lighten-3'
	if (priority == 3) return 'yellow lighten-3'
	if (priority == 4) return 'green lighten-3'
}

U.shift_priority_to_color_light = function(priority) {
	if (priority == 0) return ''
	if (priority == 1) return 'red lighten-4'
	if (priority == 2) return 'orange lighten-4'
	if (priority == 3) return 'yellow lighten-4'
	if (priority == 4) return 'green lighten-4'
}

U.shift_priority_to_color_dark = function(priority) {
	if (priority == 0) return ''
	if (priority == 1) return 'red darken-2'
	if (priority == 2) return 'orange darken-2'
	if (priority == 3) return 'yellow darken-2'
	if (priority == 4) return 'green darken-2'
}

U.shift_priority_to_color_text = function(priority) {
	if (priority == 0) return ''
	if (priority == 1) return 'red--text text--darken-2'
	if (priority == 2) return 'orange--text text--darken-2'
	if (priority == 3) return 'yellow--text text--darken-2'
	if (priority == 4) return 'green--text text--darken-2'
}

U.shift_priority_select_items = function() {
	return [
		// { value: 0, text: '0 (unset)' },
		{ value: 1, text: '1 (red - highest)' },
		{ value: 2, text: '2 (orange)' },
		{ value: 3, text: '3 (yellow)' },
		{ value: 4, text: '4 (green)' },
	]
}

U.shift_status_description = function(status) {
	if (status == 'not_open') return 'Not Open'
	if (status == 'checked_in') return 'Checked In'
	return U.capitalize_word(status)
}

U.shift_status_select_items = function() {
	let arr = []
	for (let status of ['not_open', 'open', 'filled', 'confirmed', 'checked_in', 'completed']) {
		arr.push({
			value: status,
			text: U.shift_status_description(status)
		})
	}
	return arr
}

U.shift_status_to_icon = function(status) {
	if (status == 'not_open') return 'fas fa-circle-minus'
	if (status == 'open') return 'fas fa-circle'
	if (status == 'filled') return 'fas fa-circle-dot'
	// for confirmed, checked_in, completed return the same thing
	return 'fas fa-circle-check'
}

U.check_for_shift_overlap = function(shifts, locations, updated_shift) {
	// updated_shift.vh_location_id could be an array, if we're coming from AdminEditShifts; so if it's not an array, make it an array, then process once per location
	let vh_location_ids = updated_shift.vh_location_id
	if (!$.isArray(vh_location_ids)) vh_location_ids = [vh_location_ids]

	for (let vh_location_id of vh_location_ids) {
		let location_record = locations.find(x=>x.vh_location_id==vh_location_id)
		let location = (location_record) ? location_record.location : '???'
		let updated_shift_description = sr('$1 County: $2 $3-$4 / $5', updated_shift.county, updated_shift.date, updated_shift.start_time, updated_shift.end_time, location)

		// for every other shift...
		for (let os of shifts) {
			// skip updated_shift itself (if this isn't a new shift, it will be in the shifts list)
			if (updated_shift.vh_shift_id == os.vh_shift_id) continue

			// if the date/times overlap...
			if (os.date == updated_shift.date) {
				if ((os.start_time == updated_shift.start_time || os.end_time == updated_shift.end_time) || (os.start_time > updated_shift.start_time && os.start_time < updated_shift.end_time) || (os.end_time > updated_shift.start_time && os.end_time < updated_shift.end_time)) {

					// if the locations are identical, these are identical shifts, so don't allow
					if (vh_location_id == os.vh_location_id) {
						vapp.$alert(sr('The new shift ($1) overlaps with a shift that’s already been created.', updated_shift_description))
						return true
					}

					// if the volunteer_emails are identical, we're trying to sign the same person up for two overlapping shifts, so don't allow
					if (updated_shift.volunteer_email && (updated_shift.volunteer_email == os.volunteer_email)) {
						vapp.$alert(sr('The volunteer being assigned to this shift ($1) is already signed up for another shift at the same date/time.', updated_shift_description))
						return true
					}
				}
			}
		}
	}

	// if we get to here, return false, meaning that it's OK for new_shift to be saved
	return false
}
