<template><v-dialog v-model="dialog_open" persistent max-width="800px"><v-card>
	<v-card-title><b>Add/Update People Records</b></v-card-title>
	<v-card-text class="black--text">
		<div class="mb-2">
			<v-select v-model="fields" :items="field_options" label="Fields" outlined hide-details multiple chips small-chips deletable-chips></v-select>
		</div>
		<div class="d-flex my-4">
			<v-checkbox class="mt-0 pt-0" :label="'EV-eligible'" v-model="ev_eligible" hide-details off-icon="far fa-square" on-icon="fas fa-check-square"></v-checkbox>
			<v-checkbox class="mt-0 pt-0 ml-6" :label="'ED-eligible'" v-model="ed_eligible" hide-details off-icon="far fa-square" on-icon="fas fa-check-square"></v-checkbox>
			<v-spacer/>
			<div v-if="fields.includes('roles')"><v-checkbox class="mt-0 pt-0" :label="'Add/update role(s) ONLY for NEW people'" v-model="role_new_only" hide-details></v-checkbox></div>
		</div>

		<p style="line-height:20px;">Specify above the field(s) you wish to enter for each person, then type or paste in one line per person below, separating fields with a comma or tab (to enter multiple roles for users, you must separate values with a tab). The email address is required; other fields are optional. For example, if you use the fields [Main Email, First Name, Last Name] and enter the following two lines, two users will be imported, with “Joe” having no last name entered. (You can always go back and edit individual people records later.)</p>
		
<pre class="mb-4 ml-8" style="line-height:20px;">joeb@gmail.com	Joe
peteyb@hotmail.com	Benson	Peter</pre>
		<p style="line-height:20px;">Note: The EV-eligible/ED-eligible flags you set here will only apply to new users; existing users will not have their EV/ED-eligible status updated.</p>

		<v-textarea outlined hide-details auto-grow v-model="new_users" rows="10" class="k-new-user-textarea"></v-textarea>

	</v-card-text>
	<v-card-actions class="pl-4 pr-4 pb-4">
		<v-btn color="secondary" @click="$emit('close')" class="mr-2"><v-icon small class="mr-2">fas fa-times</v-icon> Cancel</v-btn>
		<v-btn v-if="is_system_admin" color="secondary" @click="check_lbj_errors">Check LBJ Errors</v-btn>
		<v-spacer></v-spacer>
		<v-btn color="primary" @click="save_users"><v-icon small class="mr-2">fas fa-check</v-icon> Process Lines</v-btn>
	</v-card-actions>
</v-card></v-dialog></template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
	components: { },
	props: {
		// admin_user: { type: Object, required: true },
	},
	data() { return {
		dialog_open: true,
		new_users: '',
		field_options: [
			{value: 'email', text: 'Main Email'},
			{value: 'name_first', text: 'First Name'},
			{value: 'name_last', text: 'Last Name'},
			{value: 'lbj_email', text: 'LBJ Email'},
			{value: 'other_email', text: 'Alt. Email'},
			{value: 'roles', text: 'Role'},
			{value: 'languages', text: 'Language'},
			{value: 'training_level', text: 'Training Level'},
			{value: 'proficiency_level', text: 'Proficiency Level (1-3)'},
			{value: 'residence_state', text: 'State'},
			// Note: we upload a single "county", but the add_users service converts this to counties, and allows for multiple counties
			{value: 'county', text: 'County'},
			{value: 'phone_number', text: 'Phone Number'},
		],
		role_new_only: true,
		ev_eligible: true,
		ed_eligible: true,
	}},
	computed: {
		...mapState(['users', 'counties']),
		...mapGetters(['is_system_admin', 'user_hash']),
		fields: {
			get() { return this.$store.state.lst.batch_user_import_fields },
			set(val) { this.$store.commit('lst_set', ['batch_user_import_fields', val]) }
		},
	},
	watch: {
		fields() {
			// you must at least import the email address
			if (this.fields.length == 0) this.fields = ['email']
		},
	},
	created() {
	},
	mounted() {
	},
	methods: {
		check_lbj_errors() {
			console.log('check_lbj_errors')
			// this fn helps with LBJ import of users whose emails in the vp website don't match LBJ
			let lines = this.new_users.split(/\n/)
			let users_to_fix = {}
			for (let line of lines) {
				let m = line.match(/volunteer_id (\S+) does not exist/)
				if (m && !users_to_fix[m]) {
					let used_email = m[1]
					// note that we're not currently suggesting the suggested_lbj_email to the user, because it seems too confusing; just let them look it up
					let suggested_lbj_email = null

					// first try to look up user by main email
					let u = this.users.find(x=>x.email == used_email)
					if (u) {
						// if lbj_email is already there, add 'XXX' to the existing lbj_email
						// (but this shouldn't happen, because if the user has an lbj_email that email should have been used
						if (u.lbj_email) suggested_lbj_email = 'XXX' + u.lbj_email
						// if we have an alt email, suggest that as the lbj_email
						else if (u.other_email) suggested_lbj_email = '???' + u.other_email
						// otherwise we will use '???'
						else suggested_lbj_email = '???'

					} else {
						// try to look up by lbj_email
						u = this.users.find(x=>x.lbj_email == used_email)
						// if found here, then the lbj_email we have must not match their actual lbj email, so add 'XXX' to the existing lbj_email
						if (u) suggested_lbj_email = 'XXX' + u.lbj_email
					}

					if (u) {
						users_to_fix[used_email] = {
							email: u.email,
							county: u.counties[0] ?? '',
							name_first: u.name_first,
							name_last: u.name_last,
							lbj_email: suggested_lbj_email,
						}
					} else {
						// this really shouldn't happen...
						users_to_fix[used_email] = {
							email: used_email,
							county: '',
							name_first: '???',
							name_last: '???',
							lbj_email: '???',
						}
					}
				}
			}

			// set new_users to the list of updates generated above
			let unknown_user_lines = []
			for (let key in users_to_fix) {
				let utf = users_to_fix[key]
				unknown_user_lines.push([utf.email, utf.county, utf.name_first, utf.name_last])
			}
			// sort lines by county, then email
			unknown_user_lines.sort((a,b)=>{
				if (a[1] < b[1]) return -1
				if (b[1] < a[1]) return 1
				if (a[0] < b[0]) return -1
				if (b[0] < a[0]) return 1
				return 0
			})

			this.new_users = ''
			for (let uul of unknown_user_lines) {
				this.new_users += uul.join(', ') + ', \n'
			}

			// set the fields to import
			this.fields = ['email', 'county', 'name_first', 'name_last', 'lbj_email']

			if (unknown_user_lines.length == 0) this.$alert('No updates to suggest.')
			else this.$alert(sr('$1 user updates found and pasted into the text box. Look up each user’s LBJ email in LBJ and enter it at the end of each line. Lines with names listed as “???” mean that the email could not be found in our system.', unknown_user_lines.length))
		},

		save_users() {
			let lines = this.new_users.split(/\n/)

			let records = []
			let line_number = 1
			let updated_users = 0
			let corrected_records = []
			for (let line of lines) {
				if (empty($.trim(line))) continue

				let arr
				// if line starts with non-spaces followed by a comma, assume they want to split by commas; otherwise use tabs
				if (line.search(/^\S+,/) > -1) arr = line.split(/\s*,\s*/)
				else arr = line.split(/\t/)

				if (arr.length > this.fields.length) {
					this.$alert(sr('Error on line $1: too many fields specified ($2)', line_number, line))
					return
				}

				let o = {}
				let i = 0
				for (let field of this.fields) {
					let val = $.trim(arr[i])

					if (field == 'training_level') {
						if (val.toLowerCase() == 'trained') val = 10
						val *= 1
						if (isNaN(val)) {
							this.$alert(sr('Error on line $1: invalid value for trained ($2)', line_number, arr[i]))
							return
						}
					}
					if (field == 'languages' && !empty(val)) {
						val = val.toLowerCase()
					}
					if (field == 'email' && !empty(val) && val.indexOf('@') == -1) {
						this.$alert(sr('Error on line $1: invalid value for main email ($2)', line_number, val))
						return
					}
					if (field == 'lbj_email' && !empty(val) && val.indexOf('@') == -1) {
						this.$alert(sr('Error on line $1: invalid value for LBJ email ($2)', line_number, val))
						return
					}
					if (field == 'proficiency_level' && !empty(val) && val.search(/^[123]$/) == -1) {
						this.$alert(sr('Error on line $1: invalid value for proficiency ($2)', line_number, val))
						return
					}
					if (field == 'county' && !empty(val)) {
						// county may come in as e.g. 'fl-indian-river'; correct
						val = val.replace(/^\w\w-/, '')
						val = val.replace(/-/g, ' ')
						val = val.toLowerCase()
						let fixed_county = this.$store.state.site_data.counties.find(x=>x.toLowerCase() == val)
						if (!fixed_county) {
							this.$alert(sr('Error on line $1: invalid value for county ($2)', line_number, val))
							return
						}
						val = fixed_county
					}
					if (!empty(val)) o[field] = val

					++i
				}

				if (empty(o.email)) {
					this.$alert(sr('Error on line $1: Main email not specified ($2)', line_number, line))
					return
				}

				if (o.email.search(/^.*?\@.*?\.\w+$/) == -1) {
					this.$alert(sr('Error on line $1: incorrectly formatted email ($2)', line_number, o.email))
					return
				}
				if (o.lbj_email && o.lbj_email.search(/^.*?\@.*?\.\w+$/) == -1) {
					this.$alert(sr('Error on line $1: incorrectly formatted LBJ email ($2)', line_number, o.lbj_email))
					return
				}

				// make all emails lowercase
				o.email = o.email.toLowerCase()
				if (o.lbj_email) o.lbj_email = o.lbj_email.toLowerCase()

				// see if the user already exists, by email, lbj_email, or other_email
				let existing_user = this.users.find(x=>x.email == o.email)
				if (!existing_user) existing_user = this.users.find(x=>x.lbj_email == o.email)
				if (!existing_user) existing_user = this.users.find(x=>x.other_email == o.email)

				// if we still don't have an existing_user...
				if (!existing_user && this.fields.includes('name_first') && this.fields.includes('name_last') && this.fields.includes('county')) {
					// if we have a county, first name, and last name, see if we already have a user with those values
					existing_user = this.users.find(x=>(x.name_first == o.name_first && x.name_last == o.name_last && x.matches_county(o.county)))
					// if we found it...
					if (existing_user) {
						console.log('there')
						// unless the user explicitly suggested a *different* email as the lbj_email...
						if (!o.lbj_email) {
							// suggest that we use this as the lbj_email. 
							// Note that we'll suggest inserting the lbj_email even if they aren't importing lbj_email
							o.lbj_email = o.email
						}
						// then set o.email to the existing user's email
						o.email = existing_user.email

						// and add o to corrected_records
						corrected_records.push(o)
					}
				}

				if (existing_user) {
					o.vh_user_id = existing_user.vh_user_id
					// we never want to change their main email address with this service; so *delete* email from o
					if (o.email != existing_user.email) delete o.email

					++updated_users

				} else {
					// for new users, apply the ev_eligible and ed_eligible flags
					o.ev_eligible = this.ev_eligible
					o.ed_eligible = this.ed_eligible
				}

				// for existing users, update roles with this tool, but don't clear existing roles
				if (o.roles != undefined) {
					o.roles = o.roles.toLowerCase()
					if (existing_user && existing_user.roles) {
						// if role_new_only is ON, we ONLY add roles to NEW users, so delete o.roles in this case
						if (this.role_new_only) {
							delete o.roles
						} else {
							let existing_roles = existing_user.roles.toLowerCase().split(/\s*,\s*/)
							let new_roles = o.roles.split(/\s*,\s*/)
							for (let role of new_roles) {
								if (existing_roles.indexOf(role) == -1) {
									existing_roles.push(role)
								}
							}
							o.roles = existing_roles.join(', ')
						}
					}
				}

				records.push(o)

				++line_number
			}

			if (records.length == 0) {
				this.$emit('close')
				return
			}

			console.log(records, corrected_records)

			let msg = sr('This will insert $1 new user record(s) and update $2 existing records. Proceed?', (records.length - updated_users), updated_users)

			if (corrected_records.length > 0) {
				msg += sr('<div class="my-2"><b class="red--text">Important:</b> for $1 line(s), we found a name/county match where the email you provided <b>does not</b> match the email we have on file. These lines will be corrected as follows, where the email you provided will be added as the user’s LBJ email:</div>', corrected_records.length)
				msg += '<ul>'
				for (let r of corrected_records) {
					msg += sr('<li class="my-1"><b class="grey--text">Name:</b> $1, $2   <b class="grey--text">main email:</b> $3   <b class="grey--text">LBJ email:</b> $4</li>', r.name_first, r.name_last, r.email, r.lbj_email)
				}
				msg += '</ul>'
				msg += '<p class="my-2">If you don’t wish to make these corrections, cancel and try again.</p>'
			}

			this.$confirm({
			    title: 'Are you sure?',
			    text: msg,
			    acceptText: 'Add/Update Users',
				dialogMaxWidth: 900
			}).then(y => {
				this.$store.dispatch('add_users', {new_users: records}).then((result)=>{
					let msg = sr('$1 record(s) created<br>', result.records_created)
					if (result.records_created < records.length) {
						msg += sr('$1 record(s) updated', records.length - result.records_created)
					}

					msg += '<br><br>Note: you will need to use the “LBJ Synch” tool to synch new or updated user records with LBJ.'
					this.$alert(msg).then(y=>{
						this.$emit('refresh')
						this.$emit('close')
					})
				})

			}).catch(n=>{console.log(n)}).finally(f=>{});
		},

	}
}
</script>

<style lang="scss">
.k-new-user-textarea {
	textarea {
		font-size:12px;
		line-height:15px!important;
	}
}
</style>
