import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

// 1. Define route components. These can be imported from other files
// import BadRoute from './components/main/BadRoute'
import GenericTabView from './components/GenericTabView'
import VoterFAQView from './components/VoterFAQView'
import MyShiftsView from './components/MyShiftsView'
import AdminView from './components/AdminView'
import AdminUsers from './components/users/AdminUsers'
import AdminShifts from './components/shifts/AdminShifts'
import AdminShiftGrid from './components/shifts/AdminShiftGrid'
import AdminLocations from './components/locations/AdminLocations'

// 2. Define some routes
const routes = [
	// { path: '*', component: BadRoute },
	{ path: '', redirect: '/home' },
	{ path: '/voterfaq', name: 'voterfaq', component: VoterFAQView },
	{ path: '/shifts', name: 'shifts', component: MyShiftsView },

	{ path: '/home', name: 'home', component: GenericTabView },
	{ path: '/techfaq', name: 'techfaq', component: GenericTabView },
	{ path: '/resources', name: 'resources', component: GenericTabView },
	{ path: '/custom1', name: 'custom1', component: GenericTabView },
	{ path: '/custom2', name: 'custom2', component: GenericTabView },
	{ path: '/custom3', name: 'custom3', component: GenericTabView },
	{ path: '/custom4', name: 'custom4', component: GenericTabView },
	{ path: '/custom5', name: 'custom5', component: GenericTabView },

	{ path: '/admin', name: 'admin', component: AdminView, children: [
		{ path: '/admin/users', name: 'admin_users', component: AdminUsers },
		{ path: '/admin/locations', name: 'admin_locations', component: AdminLocations },
		{ path: '/admin/shifts', name: 'admin_shifts', component: AdminShifts },
		{ path: '/admin/shiftgrid', name: 'admin_shift_grid', component: AdminShiftGrid },
	] },
]

// 3. Create the router instance and pass the `routes` option
const router = new VueRouter({
	// for local development, base is ''; on the server, it's '/vp'
	base: (document.location.href.indexOf('localhost') > -1) ? '' : '/vp',
	mode: 'history',
	routes // short for `routes: routes`
})

// 4. export router object for inclusion in main.js
export default router
