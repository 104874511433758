<template><div class="k-page-holder">
	<PageTitle tab_id="voterfaq" />
	<PageSections tab_id="voterfaq" />

	<div class="k-faq-top mt-4">
		<v-text-field light background-color="blue lighten-5" solo hide-details clearable
			placeholder="Enter terms and hit return to search"
			v-model="search_terms"
			prepend-inner-icon="fas fa-search" @click:prepend-inner="execute_search_start"
			@click:clear="execute_search_clear"
			@keyup="search_field_keyup"
			autocomplete="new-password"
		></v-text-field>
	</div>
	<div class="k-faq-main" :class="moving?'k-faq-main-moving':''">
		<div class="k-faq-inner-wrapper">
			<div class="text-right" style="margin-top:-2px">
				<v-btn :style="open_items.length>0?'visibility:visible':'visibility:hidden'" @click="collapse_all" x-small text color="light-blue darken-2"><b>Collapse all</b></v-btn>
			</div>
			<v-treeview dense light
				:open="open_items"
				:items="site_faq"
				active-class="k-faq-item--active"
				item-key="faq_id"
				item-children="children"
				@update:open="open_updated"
			>
				<template v-slot:label="{ item }"><draggable :list="site_faq" group="node" :animation="100" dragClass="k-faq-item-drag-class" handle=".k-faq-move-handle" :id="item.faq_id" :data-parent="item.parent_faq_id" @start="move_start" @end="move_end">
					<v-hover v-slot:default="{hover}"><div :data-faq_id="item.faq_id" class="k-faq-item" :class="item_css(item,hover)">
						<div v-visible="!moving" class="float-left" v-if="item.type=='node'||item.type=='question'"><v-btn :color="hover?'#777':'#ccc'" x-small icon class="ml-1 mr-2" @click.stop="copy_faq_link(item)"><v-icon>fas fa-link</v-icon></v-btn></div>
						<div v-visible="!moving&&hover" class="float-right" v-if="is_system_admin"><v-btn x-small color="primary" icon class="ml-2" @click="edit_item(item)"><v-icon small>fas fa-edit</v-icon></v-btn></div>
						<div v-visible="!moving&&hover" class="float-right" v-if="is_system_admin&&item.type=='node'">
							<v-menu bottom left><template v-slot:activator="{on}"><v-btn v-on="on" x-small color="primary" icon class="ml-2"><v-icon small>fas fa-circle-plus</v-icon></v-btn></template>
								<v-list>
									<v-list-item @click="add_new_item('node',item)"><v-list-item-title>Add new Category</v-list-item-title></v-list-item>
									<v-list-item @click="add_new_item('question',item)"><v-list-item-title>Add new Question</v-list-item-title></v-list-item>
								</v-list>
							</v-menu>
						</div>
						<v-icon v-show="hover" v-if="is_system_admin&&item.type!='answer'" small color="#999" class="float-right mr-1 k-faq-move-handle" style="margin-top:6px;">fas fa-arrows-alt</v-icon>
						<div v-if="item.type=='node'" class="k-faq-tree-node fr-view" :class="node_css(item.faq_id)" v-html="highlight_search_terms(item.text)" @click.stop="toggle_item(item.faq_id)"></div>
						<div v-if="item.type=='question'" class="k-faq-tree-question fr-view" :class="node_css(item.faq_id)" v-html="highlight_search_terms(item.text)" @click.stop="toggle_item(item.faq_id)"></div>
						<div v-if="item.type=='answer'" class="k-faq-tree-answer fr-view" :class="node_css(item.faq_id)" v-html="faq_text(item.text)"></div>
					</div></v-hover>
				</draggable></template>
			</v-treeview>
			<div v-if="is_system_admin" class="text-left mt-3">
				<v-menu bottom left><template v-slot:activator="{on}"><v-btn v-on="on" small color="primary" class="ml-2"><v-icon small class="mr-2">fas fa-circle-plus</v-icon>Add New…</v-btn></template>
					<v-list>
						<v-list-item @click="add_new_item('node',null)"><v-list-item-title>Add new Category</v-list-item-title></v-list-item>
						<v-list-item @click="add_new_item('question',null)"><v-list-item-title>Add new Question</v-list-item-title></v-list-item>
					</v-list>
				</v-menu>
				<v-btn v-if="!import_items_processed" small color="primary" class="ml-4" @click="importer_showing=true">Batch Import FAQ Items</v-btn>
				<v-btn v-if="import_items_processed" small color="green" class="ml-4" @click="save_imported_items"><v-icon small class="mr-2">fas fa-save</v-icon>Save Imported Items</v-btn>
			</div>
		</div>
	</div>

	<v-dialog v-if="editor_showing" v-model="editor_showing" persistent max-width="900px">
		<v-card>
			<v-card-title>{{edited_faq_id?'Edit':'New'}} {{edited_type_label}}</v-card-title>
			<v-card-text>
				<div class="d-flex">
					<b class="mr-3">{{edited_type_label}} visibility:</b>
					<v-checkbox class="mt-0 pt-0" :label="'Visible'" v-model="edited_visible" hide-details></v-checkbox>
				</div>
				<div class="mt-4">
					<div class="mb-1"><b>{{edited_type_label}}:</b></div>
					<froala-wrapper :config="editor_config(50)" :parent_component="this" v-model="edited_text" />
				</div>
				<div class="mt-3" v-if="edited_type=='question'">
					<div class="mb-1"><b>Answer:</b></div>
					<froala-wrapper :config="editor_config(150)" :parent_component="this" v-model="edited_answer" />
				</div>
			</v-card-text>
			<v-card-actions class="px-4 pb-4">
				<v-btn color="secondary" @click="close_editor">Cancel</v-btn>
				<v-spacer/>
				<v-btn v-if="edited_faq_id" color="red" @click="delete_item">Delete {{edited_type_label}}</v-btn>
				<v-spacer/>
				<v-btn color="primary" @click="save_changes">Save and Close</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>

	<v-dialog v-if="importer_showing" v-model="importer_showing" persistent max-width="900px">
		<v-card>
			<v-card-title>Batch Import FAQ Items</v-card-title>
			<v-card-text>
				<pre style="border:1px solid #999; border-radius:8px; padding:8px; font-size:12px; line-height:16px;">
$item: General Questions

	$item: How can I vote in Florida?
By mail, in-person during the early voting period, and in person on election day

	$item: Can I vote by mail?
Any registered voter can request a VBM ballot, no excuse is needed. ##See Fla. Stat. § 101.62##

	$item: Sample Ballot
The voter’s sample ballot can be found here: https://registration.elections.myflorida.com/CheckVoterStatus
				</pre>
				<div class="mt-4">
					<v-textarea class="k-faq-batch-import-textarea" outlined hide-details clearable auto-grow label="" placeholder="" v-model="import_lines" rows="12"></v-textarea>
				</div>
			</v-card-text>
			<v-card-actions class="px-4 pb-4">
				<v-btn color="secondary" @click="importer_showing=false">Cancel</v-btn>
				<v-spacer/>
				<v-btn color="primary" @click="process_import_lines">Process Lines…</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>

	<ResourceEditor v-if="resource_being_edited"
		:original_resource="resource_being_edited"
		@edit_resource_cancel="create_resource_cancel"
		@edit_resource_saved="edit_resource_saved"
	/>

	<input style="display:none" class="k-copy-hidden-input" type="text">

</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
import PageTitle from '@/components/PageTitle'
import PageSections from '@/components/PageSections'
import goTo from 'vuetify/lib/services/goto'
import ResourceMixin from '@/components/mixins/ResourceMixin'
import ResourceEditor from '@/components/ResourceEditor'
import draggable from 'vuedraggable'

export default {
	mixins: [ResourceMixin],
	components: { PageTitle, PageSections, ResourceEditor, draggable },
	props: {
		// req: { type: String, required: true },
		// nreq: { type: String, required: false, default() { return ''} },
	},
	data() { return {
		open_items: [],
		search_terms: '',
		search_term_res: [],
		stop_words: [],
		search_results: [],
		show_search_help: false,
		show_search_help: false,
		linked_question: null,
		linked_answer: null,

		editor_showing: false,
		edited_faq_id: '',
		edited_item_parent_faq_id: '',
		edited_type: '', // node, question
		edited_type_label: '',	// Category, Question
		edited_text: '',
		edited_answer: '',
		edited_visible: true,

		editor_component_id: U.new_uuid(),

		moving: false,
		moved_item: null,

		importer_showing: false,
		import_lines: '',
		import_items_processed: false,
	}},
	computed: {
		...mapState(['site_faq', 'site_faq_hash']),
		...mapGetters(['is_system_admin', ]),
	},
	watch: {
	},
	created() {
		this.show_initial_faq()
	},
	mounted() {
		// store a reference to the component in vapp so we can target it via javascript links (see below)
		vapp.faq_tree = this
	},
	methods: {
		item_css(item, hover) {
			let s = ''
			if (!item.visible) s += ' k-faq-item-hidden'
			if (hover && !this.moving) s += ' k-faq-item-hovered'
			return s
		},

		open_updated(arr) {
			this.open_items = arr
		},

		// sample searches:
		//    energy ask questions: string contains "energy" AND "ask" AND "questions"
		//    energy "ask questions": string contains "energy" AND "ask questions"
		//    energy OR "ask questions": string contains "energy" OR "ask questions"
		//    energy OR ask questions: string contains "energy" OR ("ask" AND "questions")
		//    energ*: string contains "energy" or "energize" or "energetic", etc.
		create_search_re() {
			this.search_term_res = []
			this.stop_words = []

			if (empty(this.search_terms)) {
				this.search_terms = ''
				return
			}

			// remove all punctuation except a few things, to make sure regexps work
			this.search_terms = this.search_terms.replace(/[^0-9a-zA-Z+\-,;'" .*]/g, '')

			// replace . with \.
			let st = this.search_terms.replace(/\./g, '\\.')

			// wildcards: replace * with .*? (eliminating any surrounding spaces)
			st = st.replace(/\s*\*\s*/g, '\.*?')

			// pull out stop words
			st = st.replace(/-\s*"([^"]+)"/g, ($0, $1) => {
				this.stop_words.push(new RegExp('\\b' + $1 + '\\b', 'gi'))
				return ''
			})
			st = st.replace(/-\s*(\w+)/g, ($0, $1) => {
				this.stop_words.push(new RegExp('\\b' + $1 + '\\b', 'gi'))
				return ''
			})
			st = $.trim(st)

			if (!empty(st)) {
				// first split by ' OR 's into or_re_strings: “energy OR ask questions” => ['energy', 'ask questions']
				let or_arr = st.split(/\s+OR\s+/)
				for (let or_re_string of or_arr) {
					// trim each or_re_string
					or_re_string = $.trim(or_re_string)
					if (empty(or_re_string)) continue

					// "hard-code" spaces within double-quotes: "foo bar" > foo\sbar
					or_re_string = or_re_string.replace(/"([^"]+)"/g, function($0, $1) {
						return "\\b" + $1.replace(/ +/g, "\\s") + "\\b";
					})
					or_re_string = $.trim(or_re_string)
					if (empty(or_re_string)) continue

					// then split the or_re_string into individual and_re_strings on spaces: “ask questions” => ['ask', 'questions']
					let or_res = []
					let and_re_strings = or_re_string.split(/\s+/)
					for (let and_re_string of and_re_strings) {
						and_re_string = $.trim(and_re_string)
						if (!empty(and_re_string)) {
							// create a regexp for this and_re_string
							or_res.push(new RegExp('(' + and_re_string + ')', 'gi'))
						}
					}

					// if we actually created an regexps, push onto search_term_re
					if (or_res.length > 0) {
						this.search_term_res.push(or_res)
					}
				}
			}
		},

		strings_match_search_term_res(string_arr) {
			// at least one of the top-level search_term_res arrays must match at least one of the strings of string_arr
			// (note that usually, the user won't use the 'OR' keyword, so there will be only one sub-array of search_term_res)

			// go through each set of "and_res" in the search_term_res array
			for (let and_res of this.search_term_res) {
				// now go through each string
				for (let string of string_arr) {
					string = $.trim(string)
					if (empty(string)) continue

					// now go through each "re" in the "and_res" array; each re must be in the string for it to match
					let match = true
					for (let re of and_res) {
						// if this re isn't in the string, it's not a match!
						if (string.search(re) == -1) {
							match = false
							break
						}
					}

					// if match is true, this string includes all the re's from this set of and_res, so we can return true overall
					if (match == true) return true

					// otherwise we keep looking for matches in other strings and/or with other sets of and_res
				}
			}

			// if we haven't returned true somewhere above, return false -- no match
			return false
		},

		string_includes_stop_word(s) {
			if (empty(s)) return false
			for (let sw of this.stop_words) {
				if (s.search(sw) > -1) return true
			}
		},

		execute_search(node) {
			if (empty(node)) return false

			// by default return false (item doesn't meet criteria)
			let rv = false

			// if the node has children, search the children
			if (!empty(node.children) && node.children.length > 0) {
				for (let child of node.children) {
					if (this.execute_search(child)) {
						if (!this.open_items.find(x=>x==node.faq_id)) this.open_items.push(node.faq_id)
						rv = true
					}
				}
			}

			// assuming the node has a text value, determine if it should be highlighted as a search result
			if (!empty(node.text)) {
				// if the description includes a stop word, no
				if (!this.string_includes_stop_word(node.text)) {
					let arr = [node.text]

					if (this.strings_match_search_term_res(arr)) {
						this.search_results.push(node.faq_id)
						if (!this.open_items.find(x=>x==node.faq_id)) this.open_items.push(node.faq_id)
						rv = true
					}
				}
			}

			return rv
		},

		execute_search_start() {
			this.collapse_all()
			U.loading_start()
			setTimeout(()=>U.loading_stop(), 100)
			this.execute_search_clear()
			this.create_search_re()
			for (let node of this.site_faq) {
				this.execute_search(node)
			}
			if (this.search_results.length == 0) {
				this.$inform('No resources or resource categories matched your search terms.')
			}
		},

		execute_search_clear() {
			// uncomment this to close everything when search is cleared
			// this.open_items = []
			this.search_results = []
			this.search_term_res = []
		},

		search_field_keyup(evt) {
			if (evt.key == 'Enter' || evt.keyCode == 13) {
				this.execute_search_start()
			}
		},

		node_found_by_search(tree_key) {
			return (!empty(this.search_terms) && !empty(this.search_results.find(o=>o==tree_key)))
		},

		node_css(tree_key) {
			let s = ''
			if (this.open_items.find(x=>x==tree_key)) s += ' k-faq-item-open'
			if (this.node_found_by_search(tree_key)) s += ' k-faq-search-match'
			if (this.linked_question == tree_key) s += ' k-faq-linked-question'
			if (this.linked_answer == tree_key) s += ' k-faq-linked-answer'
			return s
		},

		highlight_search_terms(s) {
			// protect items within tags
			let tags = []
			s = s.replace(/(<\w.*?>)/g, ($0, $1) => {
				tags.push($1)
				return 'XTAGT'
			})

			// highlight search terms if we have them
			if (!empty(this.search_term_res)) {
				for (let res of this.search_term_res) {
					for (let re of res) {
						s = s.replace(re, '<span class="k-faq-searched-term">$1</span>')
						// fix replaces that break references
						s = s.replace(/(\$\S+)<span class="k-faq-searched-term">(.*?)<\/span>/g, '$1$2')
					}
				}
			}

			s = s.replace(/XTAGT/g, ($0, $1) => {
				return tags.shift()
			})

			return s
		},

		faq_text(text) {
			text = this.highlight_search_terms(text)

			text = text.replace(/<a /g, '<a target="_blank" ')

			text = text.replace(/\[(.*?)\]\(\$(\S+)\)/g, '<a class="k-faq-question-link" onclick="vapp.faq_tree.open_question(\'$2\')">$1</a>')
			text = text.replace(/\#\#(.*?)\#\#/g, '<a onclick="vapp.faq_tree.show_note(\'$1\')">*</a>')

			return text
		},

		collapse_all() {
			this.open_items = []
		},

		toggle_item(faq_id) {
			let index = this.open_items.findIndex(x=>x==faq_id)
			if (index > -1) this.open_items.splice(index, 1)
			else this.open_items.push(faq_id)
		},

		open_question(faq_id) {
			console.log('open_question: ' + faq_id)
			let item = this.site_faq_hash[faq_id]
			while (item != null) {
				this.open_items.push(item.faq_id)
				item = this.site_faq_hash[item.parent_faq_id]
			}

			this.linked_question = faq_id
			this.linked_answer = faq_id + '_ANSWER'

			setTimeout(()=>{
				this.linked_question = null
				this.linked_answer = null
			}, 5000)

			setTimeout(()=>{
				let target = $(sr('[data-faq_id=$1]', faq_id))[0]
				goTo(target, {offset:40})
				// let container = $('.k-faq-inner-wrapper')[0]
				// goTo(target, {container:container, offset:40})
			}, 250)
		},

		show_note(note) {
			note = note.replace(/(http\S*)/g, '<a target="_blank" href="$1">$1</a>')
			this.$alert(note)
		},

		show_initial_faq() {
			let hash = document.location.hash
			if (empty(hash)) return

			hash = hash.replace(/^#/, '')
			console.log('hash: ' + hash)
			setTimeout(()=>{
				this.open_question(hash)
			}, 500)
		},

		copy_faq_link(node) {
			console.log('copy link: ' + node.faq_id)
			// document.location.host = fl22.demvpro.org
			let url = sr('https://$1/vp/voterfaq#$2', document.location.host, node.faq_id)

			let jq = $(this.$el).find('.k-copy-hidden-input')
			jq.val(url)
			jq.show()

			// select the input value and copy to clipboard
			jq[0].select()
			document.execCommand("copy")

			// re-hide the input
			jq.hide()

			this.$inform({
				text: sr('Link to FAQ ( <b>$1</b> ) copied to clipboard.', url)
			})
		},

		go_to_route(route_name) {
			vapp.go_to_route(route_name)
		},

		//////////////////////////////////////////
		// editor
		editor_config(height) {
			let config = U.get_froala_config({
				// initOnClick: true,
				toolbarInline: false,
				minHeight: height,
				editorClass: 'k-faq-tree-answer'
			})

			// add the insert resource btn at the front of the moreRich buttons
			config.toolbarButtons.moreRich.buttons.unshift('insert_resource')

			// add the important btn at the front of the moreRich buttons
			config.toolbarButtons.moreRich.buttons.unshift('important')
			return config
		},

		edit_item(item) {
			// if edit is clicked on an answer, edit its parent (the question)
			if (item.type == 'answer') {
				this.edit_item(this.site_faq_hash[item.parent_faq_id])
				return
			}

			this.edited_text = item.text
			this.edited_type = item.type
			this.edited_type_label = (this.edited_type == 'node') ? 'Category' : 'Question'
			this.edited_item_parent_faq_id = item.parent_faq_id

			// for a question, get the answer from item.children
			if (item.type == 'question') {
				this.edited_answer = item.children[0].text
			} else {
				this.edited_answer = ''
			}
			this.edited_visible = item.visible

			this.edited_faq_id = item.faq_id
			this.editor_showing = true

			// have to do this for the resource editor to work properly
			this.$store.commit('set', ['editor_components', this.editor_component_id, this])
		},

		add_new_item(type, parent_item) {
			this.edited_text = ''
			this.edited_answer = ''
			this.edited_visible = true
			this.edited_faq_id = ''
			this.edited_type = type
			this.edited_type_label = (this.edited_type == 'node') ? 'Category' : 'Question'

			// if we received a parent_item, set edited_item_parent_faq_id to the parent_item's faq_id
			if (parent_item) {
				this.edited_item_parent_faq_id = parent_item.faq_id
			} else {
				// else edited_item_parent_faq_id is empty, indicating that the new item goes at the end of the top-level of the tree
				this.edited_item_parent_faq_id = ''
			}

			this.editor_showing = true
		},

		close_editor() {
			this.edited_faq_id = ''
			this.editor_showing = false
		},

		save_changes() {
			let item
			let original_answer = ''
			if (this.edited_faq_id) {
				// make a new version of the edited item *and all its descendents*
				item = new FAQ_Item(this.site_faq_hash[this.edited_faq_id], this.edited_item_parent_faq_id)
				if (item.type == 'question') {
					original_answer = item.children[0].text
				}
			} else {
				item = new FAQ_Item({type: this.edited_type}, this.edited_item_parent_faq_id)
			}

			// trim edited text
			this.edited_answer = window.trim_froala_text(this.edited_answer)
			this.edited_text = window.trim_froala_text(this.edited_text)

			if (this.edited_visible == item.visible && this.edited_text == item.title && this.edited_answer == item.html) {
				this.$inform('No changes were made.')
				this.close_editor()
				return
			}

			// every item must at least text
			let text_text = U.html_to_text(this.edited_text)
			if (text_text == '') {
				this.close_editor()
				return
			}

			// questions must have an answer
			if (this.edited_type == 'question') {
				let answer_text = U.html_to_text(this.edited_answer)
				if (answer_text == '') {
					this.$alert('You must enter an answer to the question.')
					return
				}

				// set the child item of item to the answer; create a new child if this is a new item
				if (!this.edited_faq_id) {
					item.children[0] = new FAQ_Item({type:'answer'}, item.faq_id)
				}
				item.children[0].text = this.edited_answer
			}

			////////////////////////////
			// if we get to here (or the statements above, for a question), we know we're going to update the item

			item.text = this.edited_text
			item.visible = this.edited_visible

			// replace existing item or unshift new one
			let parent_array
			if (!this.edited_item_parent_faq_id) parent_array = this.site_faq
			else parent_array = this.site_faq_hash[this.edited_item_parent_faq_id].children

			// if we're replacing an existing item, find its index in the parent array and splice it in
			if (this.edited_faq_id) {
				let index = parent_array.findIndex(x=>x.faq_id == item.faq_id)
				if (index == -1) {
					// shouldn't happen...
					console.log(parent_array, this.edited_item_parent_faq_id)
					this.$alert('Error 678 in FAQ editor')
					return
				}
				this.$store.commit('set', [parent_array, 'SPLICE', index, item])

			} else {
				// else push the new item to the end of the parent array
				this.$store.commit('set', [parent_array, 'PUSH', item])
			}

			// add or replace to hash
			this.$store.commit('set', [this.site_faq_hash, item.faq_id, item])

			// dispatch save_faq with a deep copy of site_faq
			this.$store.dispatch('save_faq', {site_faq: $.extend(true, {}, this.site_faq)})

			this.close_editor()
		},

		delete_item() {
			let text = 'Are you sure you want to delete this ' + this.edited_type_label + '?'
			if (this.edited_type == 'node' && this.site_faq_hash[this.edited_faq_id].children.length > 0) {
				text += ' This will also delete the Category’s descendent item(s).'
			}
			text += ' (If you might want to show it again later, you can hide it instead.)'
			this.$confirm({
			    title: 'Are you a sure?',
			    text: text,
			    acceptText: 'Delete ' + this.edited_type_label,
				acceptColor: 'red',
				dialogMaxWidth: 600,
			}).then(y => {
				// remove the item (and any descendents), then save the faq
				let parent_array
				if (!this.edited_item_parent_faq_id) parent_array = this.site_faq
				else parent_array = this.site_faq_hash[this.edited_item_parent_faq_id].children

				let index = parent_array.findIndex(x=>x.faq_id == this.edited_faq_id)
				if (index == -1) {
					// shouldn't happen...
					this.$alert('Error 679 in FAQ editor')
					return
				}
				this.$store.commit('set', [parent_array, 'SPLICE', index])
				this.$store.dispatch('save_faq', {site_faq: $.extend(true, {}, this.site_faq)})
				this.close_editor()
			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		move_start(evt) {
			// console.log('start', evt)
			this.moved_item = this.site_faq_hash[evt.from.id]
			this.moving = true
		},

		clean_up_move() {
			this.moving = false
			this.moved_item = null
			return true
		},

		move_end(evt) {
			// console.log('end ' + evt.newIndex, evt)
			let moved_beside_item = this.site_faq_hash[evt.to.id]
			if (moved_beside_item == this.moved_item) {
				// console.log('tried to move something beside itself; returning')
				return this.clean_up_move()
			}
			// if evt.newIndex is 0, we moved before
			let side = evt.newIndex ? 'after' : 'before'
			// console.log(sr('moved `$1` $2 `$3`', this.moved_item.text, side, moved_beside_item.text))

			// note that we need to splice the moved_item out of its original_parent's children before we calculate the index of where it needs to go
			let splice_moved_item = () => {
				let original_parent = this.site_faq_hash[this.moved_item.parent_faq_id]
				let original_parent_children = original_parent ? original_parent.children : this.site_faq
				let original_index = original_parent_children.findIndex(x=>x==this.moved_item)
				// console.log(sr('splicing `$1` child $2', (!original_parent)?'site_faq':original_parent.text, original_index))
				original_parent_children.splice(original_index, 1)
				// this.$store.commit('set', [original_parent_children, 'SPLICE', original_index])
			}

			let new_parent, new_parent_children, new_index

			// if side is 'before', move before moved_beside_item in moved_beside_item's new_parent's children
			if (side == 'before') {
				new_parent = this.site_faq_hash[moved_beside_item.parent_faq_id]
				if (new_parent == this.moved_item) {
					// console.log('tried to move something inside itself; returning')
					return this.clean_up_move()
				}
				if (!new_parent) new_parent_children = this.site_faq
				else new_parent_children = new_parent.children
				splice_moved_item()
				new_index = new_parent_children.findIndex(x=>x==moved_beside_item)

			// else if side is 'after'...
			} else {
				// if moved_beside_item is an open node, move to the start of moved_beside_item's children
				if (moved_beside_item.type == 'node' && this.open_items.find(x=>x==moved_beside_item.faq_id)) {
					new_parent = moved_beside_item
					if (new_parent == this.moved_item) {
						// console.log('tried to move something inside itself; returning')
						return this.clean_up_move()
					}
					new_parent_children = moved_beside_item.children
					splice_moved_item()
					new_index = 0

				// else move after moved_beside_item in moved_beside_item's new_parent's children
				} else {
					new_parent = this.site_faq_hash[moved_beside_item.parent_faq_id]
					if (new_parent == this.moved_item) {
						// console.log('tried to move something inside itself; returning')
						return this.clean_up_move()
					}
					if (!new_parent) new_parent_children = this.site_faq
					else new_parent_children = new_parent.children
					splice_moved_item()
					new_index = new_parent_children.findIndex(x=>x==moved_beside_item) + 1
				}
			}

			// console.log(sr('moving `$1` to child $2 in `$3`', this.moved_item.text, new_index, (!new_parent)?'site_faq':new_parent.text))
			// splice this.moved_item into new_parent_children and update parent_faq_id on this.moved_item
			new_parent_children.splice(new_index, 0, this.moved_item)
			this.$store.commit('set', [this.moved_item, 'parent_faq_id', (!new_parent)?null:new_parent.faq_id])

			// save the new order
			this.$store.dispatch('save_faq', {site_faq: $.extend(true, {}, this.site_faq)})

			// not sure if returning true or false does anything different...
			return this.clean_up_move()
		},

		process_import_lines() {
			let lines = this.import_lines.split(/\n/)
			let items = []
			let item_hash = {}
			let last_items = []
			let most_recent_item
			let question_count = 0
			let node_count = 0
			for (let line of lines) {
				// console.log(line)
				// skip empty lines and lines that start with any number of spaces + //
				if (empty(line) || line.search(/^\s*\/\//) > -1) continue

				// item
				if (line.search(/^(\t*)\$(.*?):\s*(.*)/) > -1) {
					let level = RegExp.$1.length
					let item = {
						// faq_id: RegExp.$2,
						type: 'node',
						text: RegExp.$3,
						visible: true,
						children: [],
						parent_faq_id: (level == 0) ? null : last_items[level - 1].faq_id,
					}
					// use uuids for faq_ids
					item.faq_id = U.new_uuid()

					++node_count

					item_hash[item.faq_id] = item
					// console.log(sr('$1 ($2): $3', level, item.faq_id, item.text))

					if (level == 0) {
						items.push(item)
					} else {
						last_items[level - 1].children.push(item)
					}
					last_items[level] = item
					most_recent_item = item

				// text for the most-recently-created item
				} else {
					if (most_recent_item.children.length == 0) {
						most_recent_item.type = 'question'
						--node_count
						++question_count

						let item = {
							faq_id: most_recent_item.faq_id + '_ANSWER',
							type: 'answer',
							visible: true,
							text: '',
							parent_faq_id: most_recent_item.faq_id,
						}

						most_recent_item.children.push(item)
						item_hash[item.faq_id] = item
					}
					most_recent_item.children[0].text += line + '\n'
				}
			}

			// process the imported text for each item
			for (let faq_id in item_hash) {
				item_hash[faq_id].text = this.process_imported_text(item_hash[faq_id].text)
			}

			this.$confirm({
			    // title: 'Are you a witch?',
			    text: sr('This will create $1 categories and $2 questions. Are you sure you wish to proceed?', node_count, question_count),
			    acceptText: 'Create Items',
				dialogMaxWidth: 500,
			}).then(y => {
				// merge imported items into site_faq and site_faq_hash
				for (let item of items) {
					this.$store.commit('set', [this.site_faq, 'PUSH', item])
				}
				for (let faq_id in item_hash) {
					this.$store.commit('set', [this.site_faq_hash, faq_id, item_hash[faq_id]])
				}

				// set import_items_processed to true so the button to save shows
				this.import_items_processed = true

				this.$alert('Click SAVE IMPORTED ITEMS to save the imported categories and/or questions.')

			}).catch(n=>{console.log(n)}).finally(f=>{
				this.importer_showing = false
			})

		},

		process_imported_text(text) {
			let html = ''
			let cur_level = 0
			let lines = text.split('\n')
			for (let line of lines) {
				if (empty($.trim(line))) continue

				let text = line

				let level = 0
				if (line.search(/^(\t*)-\s+(.*)/) > -1) {
					text = RegExp.$2
					level = 1 + RegExp.$1.length
				}

				text = $.trim(text)

				// links that aren't already in <a> tags
				text = text.replace(/ (http(s)?\S+)/, '<a href="$1">$1</a>')

				// some markup stuff
				text = text.replace(/\*\*\*(\S.*?)\*\*\*/g, '<b><i>$1</i></b>')
				text = text.replace(/\*\*(\S.*?)\*\*/g, '<b>$1</b>')
				text = text.replace(/\*(\S.*?)\*/g, '<i>$1</i>')
				text = text.replace(/!(\S.*?)!/g, '<i class="fas fa-$1"></i>')

				// close open lists if necessary
				if (level < cur_level) {
					for (let i = cur_level; i > level; --i) {
						html += '</ul>'
					}

				// open lists if necessary
				} else if (level > cur_level) {
					for (let i = cur_level; i < level; ++i) {
						html += '<ul>'
					}
				}

				if (level > 0) {
					html += '<li>' + text + '</li>'
				} else if (text.indexOf('<') != 0) {
					html += '<p>' + text + '</p>'
				} else {
					html += text + '\n'
				}

				cur_level = level
			}

			for (let i = 0; i < cur_level; ++i) {
				html += '</ul>'
			}

			return html
		},

		save_imported_items() {
			// save to the db
			this.$store.dispatch('save_faq', {site_faq: $.extend(true, {}, this.site_faq)})
			this.import_items_processed = false
			this.import_lines = ''
		}
	}
}
</script>

<style lang="scss">
.v-treeview-node__root:hover::before {
	// display:none!important;
	opacity:0!important;
	// this kills the light grey background when you hover over tree items
}

.k-faq-top {
	padding-bottom:5px;
}

.k-faq-main-moving {
	.v-treeview-node__toggle {
		visibility:hidden!important;
	}
}

.k-faq-main {
	// height:calc(100% - 90px);
	.k-faq-inner-wrapper {
		height:100%;
		overflow:auto;
	}

	.v-treeview-node__root {
		min-height:36px;
		align-items:flex-start;
	}

	.v-treeview-node--leaf > .v-treeview-node__root {
		padding-left:0px;
	}

	.v-treeview-node__label {
		overflow: visible!important;
		white-space: normal!important;
	}

	.v-treeview-node__content {
		margin-left:0;
	}

	.k-faq-tree-answer {
		margin-top:-8px;
		margin-bottom:16px;
		background-color:#eee;
		padding:1px 8px 1px 8px!important;
		border-radius:6px;
		cursor:default;
	}
}

.k-faq-tree-node, .k-faq-tree-question {
	font-size:16px;
	cursor:pointer;
}
.k-faq-tree-question.k-faq-item-open, .k-faq-tree-node.k-faq-item-open {
	font-weight:bold;
}
.k-faq-tree-answer {
	font-size:14px;
	p {
		margin:8px 0;
	}

	.k-faq-question-link {
		font-weight:bold;
	}

	.k-faq-image-link {
		text-decoration:none;
		color:inherit;
	}
}

.k-faq-item--active .v-treeview-node__label {
	color:$v-light-blue-darken-2!important;
	font-weight:bold;
}


.k-faq-item {
	background-color:#fff;
	border-top:1px solid transparent;
}
.k-faq-item-hidden {
	background-color:$v-red-lighten-4!important;
}
.k-faq-item-hovered {
	border-top:1px solid #ccc;
}

.k-faq-tree-question.k-faq-search-match {
	background-color:$v-light-blue-lighten-3;
	border-radius:4px;
	padding:0 4px;
}

.k-faq-tree-answer.k-faq-search-match {
	background-color:$v-light-blue-lighten-4;
}

.k-faq-searched-term {
	background-color:$v-light-blue-lighten-2;
	border-radius:4px;
	padding:0 4px;
	// text-decoration:underline;
}

.k-faq-linked-question {
	background-color:$v-green-lighten-3;
	border-radius:4px;
	padding:0 4px;
}

.k-faq-linked-answer {
	background-color:$v-green-lighten-5;
}

.k-faq-item-drag-class {
	background-color:#ff9!important;
	border-radius:8px;
	button {
		visibility:hidden!important;
	}
}

.k-faq-batch-import-textarea {
	textarea {
		font-size:12px;
		line-height:16px;
		font-family:monospace;
	}
}

.k-quick-facts {
	border-collapse:collapse;
	margin-bottom:16px;
	td, th {
		padding:4px 8px;
		border:1px solid #999;
	}
	td {
		font-size:12px;
	}
}
</style>
