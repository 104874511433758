// Copyright 2022, Common Good Learning Tools LLC
import { mapState, mapGetters } from 'vuex'

export default {

	data() { return {
		fco: null,
		resource_being_edited: false,
	}},
	computed: {
		...mapState(['site_resources']),
		...mapGetters([]),
	},
	watch: {
	},
	methods: {
		froala_insert_resource_callback(fco) {
			this.fco = fco
			this.fco.froala_editor.html.insert('<link class="k-lesson-component-resource-link-placeholder fas fa-ellipsis-h">')
			this.resource_being_edited = 'new'
		},

		insert_resource_cancel() {
			if (this.fco) {
				let html = this.fco.froala_editor.html.get()
				html = html.replace(/<link class="k-lesson-component-resource-link-placeholder.*?>/g, '')
				this.fco.froala_editor.html.set(html)
				this.fco.froala_component.updateModel()
			}
			this.fco = null
		},

		insert_resource_selected(resource) {
			// let title = resource.title.replace(/"/g, '\\"')
			// let link = sr('<link class="k-resource-link fas $1" title="$2" data-resource-link-id="$3" onclick="vapp.open_resource_link(\'$4\',this)">', resource.icon(), title, U.new_uuid(), resource.vh_resource_id)

			let link = sr('&nbsp;<span class="k-resource-link" data-resource-link-id="$3" onclick="vapp.open_resource_link(\'$4\',this)"><i class="fas $1">&nbsp;</i>$2</span>&nbsp;', resource.icon(), resource.title, U.new_uuid(), resource.vh_resource_id)

			let html = this.fco.froala_editor.html.get()
			html = html.replace(/<link class="k-lesson-component-resource-link-placeholder.*?>/, link)
			this.fco.froala_editor.html.set(html)
			this.fco.froala_component.updateModel()

			this.fco = null
		},

		// froala_inserted_resource_clicked(froala_modeled_parameter, vh_resource_id, froala_resource_link_id) {
		froala_inserted_resource_clicked(fco, vh_resource_id, froala_resource_link_id) {
			// this gets called when the user clicks a previously-inserted resource from the froala editor.
			// - froala_modeled_parameter tells us which parameter the froala editor is modeling
			// - vh_resource_id is the vh_resource_id of the resource (duh)
			// - froala_resource_link_id is a uuid we assign to the link, so that we can easily remove it here if needed

			let resource = this.site_resources.find(x=>x.vh_resource_id == vh_resource_id)
			if (resource) {
				// show a confirm dialog with a button to preview the resource, along with its title
				let btn = sr('<button type="button" class="v-btn v-btn--is-elevated v-btn--has-bg theme--dark v-size--small primary" onclick="vapp.open_resource_link(\'$1\')"><span class="v-btn__content"><i aria-hidden="true" class="v-icon notranslate mr-2 fas fa-arrow-up-right-from-square theme--dark" style="font-size: 16px;"></i>Preview</span></button>', resource.vh_resource_id)
				let text = sr('<div class="d-flex justify-center align-center"><div>$1</div><div class="ml-2">$2</div></div>', resource.title, btn)

				this.$confirm({
					title: 'Resource Link',
					text: text,
					acceptText: 'Remove Resource Link',
					acceptColor: 'red darken-2',
					dialogMaxWidth: 600
				}).then(y => {
					// if we're here, the user said to remove the resource link
					let html = fco.froala_editor.html.get()
					let re = new RegExp(sr('<span[^>]*?data-resource-link-id="$1".*?</span>', froala_resource_link_id))
					html = html.replace(re, '')
					fco.froala_editor.html.set(html)
					fco.froala_component.updateModel()

				}).catch(n=>{console.log(n)}).finally(f=>{})
			} else {
				console.log('couldn’t find resource:', vh_resource_id)
			}
		},

		froala_inserted_resource_clicked_NEW(fco, resource_id, resource_description, froala_resource_link_id) {
			// this gets called when the user clicks a previously-inserted resource from the froala editor.
			// - fco is the get_froala_component return value
			// - resource_id is the resource_id of the resource (duh)
			// - froala_resource_link_id is a uuid we assign to the link, so that we can easily remove it here if needed

			// show a confirm dialog with a button to preview the resource, along with its description
			let btn = sr('<button type="button" class="v-btn v-btn--is-elevated v-btn--has-bg theme--dark v-size--small primary" onclick="vapp.open_resource_link(\'$1\')"><span class="v-btn__content"><i aria-hidden="true" class="v-icon notranslate mr-2 fas fa-arrow-up-right-from-square theme--dark" style="font-size: 16px;"></i>Preview</span></button>', resource_id)
			let text = sr('<div class="d-flex justify-center align-center"><div>$1</div><div class="ml-2">$2</div></div>', resource_description, btn)

			this.$confirm({
			    title: 'Resource Link',
			    text: text,
			    acceptText: 'Remove Resource Link',
				acceptColor: 'red darken-2',
				dialogMaxWidth: 600
			}).then(y => {
				// if we're here, the user said to remove the resource link
				let html = fco.froala_editor.html.get()
				let re = new RegExp(sr('<link.*?data-resource-link-id="$1".*?>', froala_resource_link_id))
				html = html.replace(re, '')
				fco.froala_editor.html.set(html)
				fco.froala_component.updateModel()

			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		edit_resource(resource) {
			this.resource_being_edited = resource
		},

		create_resource_start() {
			this.resource_being_edited = 'new'
		},

		create_resource_cancel() {
			this.resource_being_edited = null

			// call insert_resource_cancel to remove the placeholder from the froala editor
			this.insert_resource_cancel()
		},

		edit_resource_saved(updated_resource) {
			// the resource editor will have saved the resource to the db if necessary and pushed it to the store; updated_resource will be the Site_Resource object

			// if we were adding to a froala editor, call insert_resource_selected
			if (this.fco) {
				this.insert_resource_selected(updated_resource)
			}

			this.resource_being_edited = null
		},
	}
}
