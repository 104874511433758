window.raw_lbj_lines = `
587993	Palm Beach	Acerage Branch Library	15801 Orange Blvd		Loxahatchee	33470
585356	Broward	African American Research Library	2650 Sistrunk Boulevard		Ft. Lauderdale	33311
585191	Alachua	Alachua County Agriculture and Equestrian Center	23100 W Newberry Rd		Newberry	32669
587656	Orange	Alafaya Library	12000 E. Colonial Drive		Orlando	32826
588347	Pasco	Alice Hall Community Center	38116 5th Ave		Zephyrhills	33542
589073	Sumter	Allamanda Recreation Center	1515 Saint Charles Place		The Villages	32162
586326	Hillsborough	Apollo Beach Recreation Center	664 Golf And Sea Blvd		Apollo Beach	33572
587680	Orange	Apopka Community Center	519 S. Central Avenue		Apopka	32703
587179	Miami-Dade	Arcola Lakes Branch Library	8240 Nw 7th Avenue		Miami	33150
585887	Duval	Argyle Branch Library	7973 Old Middleburg Rd S		Jacksonville	32222
585131	Nassau	Atlantic Avenue Recreation Center	2500 Atlantic Ave		Fernandina Beach	32034
586200	Highlands	AVON PARK CITY HALL COUNCIL CHAMBERS	123 E Pine St		Avon Park	33825
585900	Duval	BEACHES BRANCH LIBRARY	600 3rd Street		Neptune Beach	32266
588931	Sarasota	Bee Ridge Park	4430 S Lockwood Ridge Road		Sarasota	34231
588250	Palm Beach	Belle Glade Library/Civic Ctr	725 Nw 4th St		Belle Glade	33430
586931	Marion	Belleview Public Library	13145 Se Hwy 484		Belleview	34420
607448	Escambia	Bellview Branch Library	6425 Mobile Highway		Pensacola	32526
587569	Monroe	Big Pine Key Community Park, End of Sands Road	31009 Atlantis Dr.		Big Pine Key	33043
586090	Escambia	Billy G. Ward Courthouse	7500 N Century Blvd.		Century	32535
586406	Hillsborough	Bloomingdale Regional Public Library	1906 Bloomingdale Ave		Valrico	33596
587963	Palm Beach	Boca Raton Downtown Library	400 Nw 2nd Ave		Boca Raton	33432
585019	Bradford	Bradford County Courthouse	945 North Temple Avenue		Starke	32091
585899	Duval	BRADHAM & BROOKS BRANCH LIBRARY	1755 Edgewood Avenue West		Jacksonville	32208
607440	Liberty	Bristol Courthouse	10818 N.w. State Road 20		Bristol	32321
586072	Escambia	Brownsville Community Center	3200 W De Soto St.		Pensacola	32505
586278	Hillsborough	Bruton Memorial Library	302 W Mclendon St		Plant City	33563
589054	Sumter	Bushnell Elections Annex	316 East Anderson Avenue		Bushnell	33513
586546	Lake	Cagan Crossings Library	16729 Cagan Oaks		Clermont	34714
585804	Collier	Cambier Park Norris Center	755 8th Ave S		Naples	34102
586652	Lee	CAPE CORAL - LEE COUNTY LIBRARY	921 Sw 39th Ter		Cape Coral	33914
607443	Broward	Carver Ranch Branch Library	4735 Sw 18th Street		West Park	33023
589001	Seminole	Casselberry Library	215 N Oxford Rd		Casselberry	32707
586317	Hillsborough	C. Blythe Andrews, Jr. Public Library	2607 E Dr. Mlk, Jr. Blvd		Tampa	33610
587873	Osceola	Celebration Library	305 Campus St		Kissimmee	34747
589159	Volusia	Center at Deltona	1640 Dr. Mlk Blvd.		Delton	32725
585689	Citrus	CENTRAL RIDGE COMMUNITY CENTER	77 Civic Cir		Beverly Hills	34465
585680	Citrus	CENTRAL RIDGE LIBRARY	425 W Roosevelt Blvd		Beverly Hills	34465
607444	Charlotte	Charlotte Administration Building	18500 Murdock Cir., B- 106		Port Charlotte	33943
607449	Gadsden	Chattahoochee Library	300 Maple Street		Chattahoochee	322324
587784	Orange	Chickasaw Library	870 N. Chickasaw Trail		Orlando	32825
589148	Volusia	City Island Day Library	105 Jackie Robinson Pkwy		Daytona Beach	32114
586530	Lake	Clermont Arts and Recreation Center	3700 S Hwy 27		Clermont	34711
585758	Collier	Collier County Government Complex	3311 Tamiami Trl E		Naples	34112
586534	Lake	Cooper Memorial Library	2525 Oakley Seaver Dr.		Clermont	34711
587049	Miami-Dade	Coral Gables War Memorial Youth Center	405 University Dr		Coral Gables	33134
587454	Miami-Dade	Coral Reef Branch Library	9211 Sw 152 St		Miami	33157
588438	Pinellas	Countryside Recreation Center	2640 Sabal Springs Dr		Clearwater	33761
585516	Broward	Dania Beach-Paul DeMaio Library	1 Park Ave E		Dania Beach	33004
585309	Brevard	David R. Schechter Community Center	1089 South Patrick Dr		Satellite Beach	32937
585340	Broward	Davie Cooper City Branch Library	4600 S.w. 82nd Avenue		Davie	33328
587942	Palm Beach	Delray Beach Community Center	50 Nw 1st Ave		Delray Beach	33444
586922	Marion	Deputy Brian Litz Building	9048 Sw Hwy 200		Ocala	34481
587616	Okaloosa	Destin Community Center	101 Stahlman Ave		Destin	32541
584902	Dixie	Dixie County Supervisor of Eelctions	229 Ne 351 Hwy	Suite A	Cross City	32628
586763	Leon	Dr. B.L. Perry Branch Library	2817 S. Adams St.		Tallahassee	32301
586639	Lee	Dr. Carrie D. Robinson Center	2990 Edison Ave		Fort Myers	33916
585311	Brevard	Dr. Joe Lee Smith Community Center	415 Stone St		Cocoa	32922
586916	Marion	Dunnellon Public Library	20351 Robinson Rd		Dunnellon	34431
586657	Lee	East County Regional Library	881 Gunnery Rd N		Lehigh Acres	33971
586171	Hernando	East Hernando Branch Library	6457 Windmere Road		Brooksville	34602
588287	Pasco	EAST PASCO GOVERNMENT CENTER	14236 6th St		Dade City	33523
586678	Leon	Eastside Branch Library	1583 Pedrick Rd		Tallahassee	32317
586955	Martin	Elections Center	135 Se Martin Luther King, Jr. Blvd		Stuart	34994
587418	Miami-Dade	Elections Department (Main Office)	2700 Nw 87th Avenue		Doral	33172
584890	Gulf	Elections Office	401 Long Ave		Port St. Joe	32456
588730	Putnam	ELECTIONS OFFICE	2509 Crill Ave, Suite 900		Palatka	32177
586956	Martin	Elisabeth Lahti Library	15200 Sw Adams Ave		Indiantown	34956
585380	Broward	Emma Lou Olson Civic Center	1801 N.e. 6th Street		Pompano Beach	33060
586091	Escambia	Escambia Extension Services	3740 Stefani Rd.		Cantonment	32533
586631	Lee	Estero Recreation Center	9200 Corkscrew Palms Blvd		Estero	33928
586532	Lake	Eustis Service Center	301 W. Ward Ave.		Eustis	32726
585766	Collier	Everglades City Hall	102 Copeland Ave N		Everglades City	34139
589057	Sumter	Everglades Recreation Center	5497 Marsh Bend Trail		The Villages	34785
587930	Palm Beach	Ezell Hester Community Center	1901 N Seacrest Blvd		Boynton Beach	33435
587797	Orange	FAIRVIEW SHORES LIBRARY	902 Lee Rd Suite 26		Orlando	32810
589191	Walton	Faith Assembly Church	306 S Geronimo St		Miramar Beach	32550
586737	Leon	FAMU Student Union	1628 S Martin Luther King Jr. Blvd		Tallahassee	32307
588175	Palm Beach	FAU Jupiter Campus	5353 Parkside Dr		Jupiter	33458
586101	Flagler	Flagler Beach United Methodist Church	1520 S. Daytona Avenue		Flagler Beach	32136
586094	Flagler	FLAGLER COUNTY PUBLIC LIBRARY	2500 Palm Coast Pkwy Nw		Palm Coast	32137
586095	Flagler	Flagler County Supervisor of Elections Office	1769 E. Moody Boulevard	Building 2, Suite 101	Bunnell	32110
585746	Clay	Fleming Island Library	1895 Town Center Blvd		Fleming Island	32003
586988	Miami-Dade	Florida International University (Student Academic Success Center)	11200 Sw 8th Street		Miami	33199
585823	Duval	Florida State College at Jacksonville	9911 Old Baymeadows Rd.		Jacksonville	32256
586163	Hernando	Forest Oaks Supervisor of Elections Office	7443 Forest Oaks Blvd		Spring Hill	34606
586920	Marion	Forest Public Library	905 S Hwy 314a		Ocklawaha	32179
586713	Leon	Fort Braden Community Center	16387 Blountstown Hwy		Tallahassee	32310
586669	Lee	FORT MYERS REGIONAL LIBRARY - VOTING ROOM ADDRESS	1651 Lee St		Fort Myers	33901
584934	Columbia	Fort White Community Center	17579 Sw State Road 47		Fort White	32038
585224	Bay	Frank Brown Park Community Center	16200 Panama City Beach Pkwy		Panama City Beach	32413
584912	Franklin	Franklin County Courthouse Annex	912 Nw Ave A		Carrabelle	32322
585242	Bay	Frank Nelson Building	4201 W. 22nd Street		Panama City	32405
586290	Hillsborough	Fred B. Karl County Center	601 E Kennedy Blvd		Tampa	33602
586902	Marion	Freedom Public Library	5870 Sw 95th St		Ocala	34476
589201	Walton	Freeport City Hall	112 St Hwy 20 W		Freeport	32439
588953	Sarasota	Fruitville Public Library	100 Apex Road		Sarasota	34240
586745	Leon	FSU Student Services Building	874 Traditions Way		Tallahssee	32306
585407	Broward	Ft. Lauderdale Branch Library/Art Serve	1350 E. Sunrise Boulevard		Ft. Lauderdale	33304
587977	Palm Beach	Gardens Branch Library	11303 Campus Dr		Palm Beach Gardens	33410
607445	Escambia	Genealogy Ranch Library	5740 N 9th Ave		Pensacola	32504
584982	Gilchrist	Gilchrist Courthouse	112 South Main Street	Room 137	Trenton	32693
588683	Polk	Gil Jones NE Polk County Government Center	3425 Lake Alfred Rd		Winter Haven	33881
585008	Glades	Glades County Supervisor of Elections	998 Us Hwy 27 South		Moore Haven	33471
585229	Bay	Glenwood Community Center	722 E 9th Ct		Panama City	32401
585777	Collier	Golden Gate Community Center	4701 Golden Gate Pkwy		Naples	34116
584840	Jackson	Graceville City Hall	5348 Cliff St.		Graceville	32440
587941	Palm Beach	Greenacres Community Center	501 Swain Blvd		Greenacres	33463
584983	Madison	Greenville American Legion	133 Sw Broad Ave		Greenville	32331
588459	Pinellas	Gulfport Neighborhood Center	1617 49 St. S		Gulfport	33707
587868	Osceola	GYM AND AQUATIC CENTER	625 Country Club Dr		Kissimmee	34759
587929	Palm Beach	Hagen Ranch Road Library	14350 Hagen Ranch Rd		Delray Beach	33446
588614	Polk	Haines City Library	111 N 6th St		Haines City	33844
585548	Broward	Hallandale Beach Cultural Community Center	410 S.e. 3rd Street		Hallandale Beach	33009
584851	Hamilton	Hamilton County Supervisor of Elections Office - Courthouse Annex	1153 Us Hwy. 41 Nw, Suite 1		Jasper	32052
585061	Hardee	Hardee County Library Public	315 N. 6th Ave		Wauchula	33873
588761	St. Johns	Hastings Branch Library	6195 S Main St		Hastings	32145
607452	Gadsden	Havana Library	203 E Fifth Avenue		Havana	32333
585786	Collier	Heritage Bay Government Services Center	15450 Collier Blvd		Naples	34120
586173	Hernando	Hernando County Utilities Department	15365 Cortez Blvd		Brooksville	34613
587749	Orange	Hiawassee Library	7391 W. Colonial Drive		Orlando	32818
585874	Duval	Highlands Regional Library	1826 Dunn Ave		Jacksonville	32218
584969	Nassau	Hilliard Community Center	37177 Pecan St		Hilliard	32046
585663	Charlotte	Historic Courthouse	226 Taylor Street		Punta Gorda	33950
587294	Miami-Dade	Historic Garage	3250 S Miami Avenue		Miami	33129
586968	Martin	Hobe Sound Library	10595 Se Federal Hwy		Hobe Sound	33455
586959	Martin	Hoke Library	1150 Ne Jack Williams Way		Jensen Beach	34957
585512	Broward	Hollywood Branch Library	2600 Hollywood Boulevard		Hollywood	33020
587184	Miami-Dade	Homestead Community Center (William F. Dickinson Community Center)	1601 N Krome Avenue		Homestead	33030
585687	Citrus	Homosassa Public Library	4100 S Grandmarch Ave		Homosassa	34446
584975	Liberty	Hosford Fire Department	17081 Ne State Rd 65		Hosford	32334
587956	Palm Beach	Howard Park Community Center	1302 Parker Ave		West Palm Beach	33401
588333	Pasco	HUDSON LIBRARY	8012 Library Rd		Hudson	34667
585797	Collier	Immokalee Community Park	321 N 1st St		Immokalee	34142
586464	Indian River	Indian River County Main Library	1600 21st St		Vero Beach	32960
588839	St. Lucie	Indian River State College Veterans Center of Excellence	500 Nw California Blvd.		Port St. Lucie	34986
588728	Putnam	Interlachen Community Center	135 S County Rd 315		Interlachen	32148
587204	Miami-Dade	International Mall Branch Library	10315 Nw 12th Street		Doral	33172
585694	Citrus	INVERNESS CITY HALL	212 W Main St		Inverness	34450
587555	Monroe	Islamorada Branch Library	81830 Overseas Hwy., Mm. 81.7		Islamorada	33036
585027	Nassau	James S. Page Governmental Complex	96135 Nassau Plc		Yulee	32097
586344	Hillsborough	Jan Kaminis Platt Regional Library	3910 S Manhattan Ave		Tampa	33611
588285	Pasco	J. BEN HARRILL RECREATION COMPLEX	2830 Gulf Trace Blvd		Holiday	34691
586252	Hillsborough	Jimmie B. Keel Regional Library	2902 West Bearss Ave		Tampa	33618
587262	Miami-Dade	John F. Kennedy Library	190 W 49 St		Hialeah	33012
585130	Suwannee	JO KENNON PUBLIC LIBRARY	10655 Dowling Park Dr		Dowling Park	32060
587526	Miami-Dade	Joseph Caleb Center Community Meeting Room	5400 Nw 22nd Avenue - Building A		Miami	33142
585913	Duval	Joseph Lee Community Center	5120 Perry St		Jacksonville	32208
585053	Suwannee	JUDICIAL ANNEX Early Voting	218 Parshley St		Live Oak	32064
588774	St. Johns	Julington Creek Annex (St. Johns County Service Center)	725 Flora Branch Blvd		St. Johns	32259
588158	Palm Beach	Jupiter Community Center	200 Military Trl		Jupiter	33458
585212	Alachua	J. Wayne Reitz Union	655 Reitz Union Dr		Gainesville	32611
588494	Pinellas	J.W. Cate Recreation Center	5801 22nd Ave. N.		St. Petersburg	33710
587331	Miami-Dade	*Kendale Lakes Branch Library	15205 Sw 88th Street		Miami	33196
587234	Miami-Dade	Kendall Branch Library	9101 Sw 97 Ave		Miami	33176
607453	Highlands	Kenilworth Operations Center	4500 Kenilworth Blvd		Sebring	33870
585731	Clay	Keystone Hghts City Hall	555 S Lawrence Blvd.		Keystone Heights	32656
586305	Hillsborough	Keystone Recreation Center	17928 Gunn Hwy		Odessa	33556
587843	Osceola	KISSIMMEE CIVIC CENTER	201 E Dakin Ave		Kissimmee	34741
585332	Brevard	Kiwanis Island Park Gymnasium	950 Kiwanis Island Park Rd		Merritt Island	32952
586519	Lake	Lady Lake Community Building	237 W. Guava St.		Lady Lake	32159
586573	Lake	Lake County Agricultural Center	1951 Woodlea Rd		Tavares	32778
586724	Leon	Lake Jackson Community Center	3840 N Monroe St		Tallahassee	32303
589010	Seminole	Lake Mary Library	580 Green Way Blvd		Lake Mary	32746
586194	Highlands	Lake Placid Town Hall Council Chambers	1069 U.s. Hwy. 27 North		Lake Placid	33852
586620	Lee	LAKES REGIONAL LIBRARY	15290 Bass Rd		Fort Myers	33919
586828	Manatee	Lakewood Ranch Town Hall	8175 Lakewood Ranch Blvd		Lakewood Ranch	34202
588370	Pasco	Land O' Lakes Recreation Complex	3032 Collier Pkwy		Land O' Lakes	34639
587997	Palm Beach	Lantana Road Branch Library	4020 Lantana Rd		Lake Worth	33462
589063	Sumter	Laurel Manor Recreation Center	1985 Laurel Manor Drive		The Villages	32162
584885	Madison	Lee City Hall	286 Ne Cr 255		Lee	32059
586616	Lee	LEE COUNTY ELECTIONS - BONITA SPRINGS BRANCH OFFICE	25987 S Tamiami Trail #105		Bonita Springs	34134
586584	Lee	LEE COUNTY ELECTIONS - CAPE CORAL BRANCH OFFICE	1039 Se 9th Ave		Cape Coral	33990
586656	Lee	Lee County Elections Center	13180 S Cleveland Ave		Fort Myers	33907
585177	Alachua	Legacy Park Multipurpose Center	15400 Peggy Rd		Alachua	32615
585821	Duval	Legends Community Center	5130 Soutel Dr		Jacksonville	32208
587508	Miami-Dade	Lemon City Branch Library	430 Ne 61st Street		Miami	33137
586758	Leon	Leon County Courthouse	301 S. Monroe St		Tallahassee	32301
586679	Leon	Leon County Elections Center	2990-1 Apalachee Pkwy		Tallahassee	32301
585815	Collier	Library Headquarters	2385 Orange Blossom Dr		Naples	34109
585239	Bay	Lyndale Conference Center	423 Lyndalle Lane		Lyndale	32407
585221	Bay	Lynn Haven Senior Center	905 Pennslyvania Ave		Lynn Haven	32444
585646	Charlotte	Mac V. Horton West County Annex	6868 San Casa Dr		Englewood	34224
588849	Santa Rosa	Main Elections Office	6495 Caroline St., Suite "F"		Milton	32570
586017	Escambia	Main Library	239 N Spring St.		Pensacola	32502
588014	Palm Beach	Main Library	3650 Summit Blvd		West Palm Beach	33406
586768	Manatee	Manatee County Utilities Admin Office	4410 66th St W		Bradenton	34210
585927	Duval	Mandarin Branch Library	3330 Kori Rd		Jacksonville	32257
585753	Collier	Marco Island Library	210 S Heathwood Dr		Marco Island	34145
586849	Marion	Marion County Election Center	981 Ne 16th St		Ocala	34470
587798	Orange	Marks Street Senior Recreation Complex	99 E. Marks Street		Orlando	32803
586254	Hillsborough	Maureen B. Gauzza Public Library	11211 Countryway Blvd		Tampa	33626
585323	Brevard	Max K. Rodes Park Community Center	3410 Flanagan Ave		West Melbourne	32904
584916	Lafayette	Mayo Community Center	150 Sw Community Cir.		Mayo	32066
587727	Orange	MEADOW WOODS RECREATION CENTER	1751 Rhode Island Woods Cir		Orlando	32824
587257	Miami-Dade	Miami Beach City Hall	1700 Convention Center Dr		Miami Beach	33139
587382	Miami-Dade	Miami Dade College Kendall Campus (The Fascell Conference Center)	11011 Sw 104th Street - Building K		Miami	33176
587090	Miami-Dade	Miami Dade College North Campus (Library)	11380 Nw 27th Avenue - Building 2		Miami	33167
587288	Miami-Dade	Miami Lakes Community Center (Mary Collins Community Center)	15151 Nw 82nd Avenue		Miami Lakes	33016
585739	Clay	MIDDLEBURG CIVIC CENTER	2102 Palmetto St		Middleburg	32068
588809	St. Lucie	MIDFLORIDA Credit Union Event Center	9221 S.e. Event Center Place		Port St. Lucie	34952
589103	Volusia	Midtown Cultural Center	925 George W Engram Blvd		Daytona Beach	32114
607450	Gadsden	Midway City Hall	60 Mlk Blvd		Midway	32343
585159	Alachua	Millhopper Branch Library	3145 Nw 43rd St		Gainesville	32606
586526	Lake	Minneola City Hall	800 N Us Hwy 27		Minneola	34715
585385	Broward	Miramar Branch Library	2050 Civic Center Place		Miramar	33025
607446	Escambia	Molino Community Center	6450-a Highway 95a		Molino	32577
586882	Marion	Mulberry Center (Liberty Rm)	8445 Se 165th Mulberry Ln		The Villages	32162
588591	Polk	Mulberry Civic Center	901 Ne 5th St		Mulberry	33860
585862	Duval	MURRAY HILL UNITED METHODIST CHURCH	4101 College St		Jacksonville	32205
587009	Miami-Dade	*Naranja Branch Library	14850 Sw 280th Street		Homestead	33032
587872	Osceola	NARCOOSSEE COMMUNITY CENTER	5354 Rambling Rd		St. Cloud	34771
588851	Santa Rosa	Navarre Community Gym	8840 High School Blvd		Navarre	32566
588355	Pasco	NEW RIVER LIBRARY	34043 State Road 54		Wesley Chapel	33543
589165	Volusia	New Smyrna Library	1001 S. Dixie Freeway		New Smyrna Beach	32168
586324	Hillsborough	New Tampa Regional Library	10001 Cross Creek Blvd		Tampa	33647
607454	Okaloosa	Niceville City Hall	208 Partin Dr.		Niceville	32578
585751	Collier	North Collier Reigonal Park	15000 Livingston Rd		Naples	34109
587509	Miami-Dade	North Dade Regional Library	2455 Nw 183 St		Miami Gardens	33056
586390	Hillsborough	Northdale Recreation Center	15550 Spring Pine Dr		Tampa	33624
586757	Leon	Northeast Branch Library	5513 Thomasville Rd		Tallahassee	32312
587112	Miami-Dade	Northeast Dade-Aventura Branch Library	2930 Aventura Boulevard		Aventura	33180
586578	Lee	North Fort Myers Recreation Center	2000 N Recreation Park Way		North Fort Myers	33903
585622	Broward	North Lauderdale Saraniero Library	6901 Kimberly Boulevard		North Lauderdale	33068
587263	Miami-Dade	North Miami Public Library	835 Ne 132 St		North Miami	33161
588946	Sarasota	North Port Elections Office - Biscayne Plaza	13640 Tamiami Trail		North Port	34287
585391	Broward	North Regional Library/BC	1100 Coconut Creek Boulevard		Coconut Creek	33066
588913	Sarasota	NORTH SARASOTA PUBLIC LIBRARY	2801 Newtown Blvd		Sarasota	34234
587219	Miami-Dade	North Shore Branch Library	7501 Collins Ave		Miami Beach	33141
586439	Hillsborough	North Tampa Branch Library	8916 North Blvd		Tampa	33604
585471	Broward	Northwest Regional Library	3151 University Drive		Coral Springs	33065
586626	Lee	Northwest Regional Library	519 Chiquita Blvd N		Cape Coral	33993
586372	Hillsborough	Northwest Regional Office	4575 Gunn Hwy		Tampa	33624
585496	Broward	Nova Southeastern University (Alvin Sherman Library)	3300 Ray Ferrero Jr. Boulevard		Davie	33314
585749	Clay	Oakleaf Residence Club	3975 Eagle Landing Pkwy		Orange Park	32065
585928	Duval	Oceanway Community Center	12215 Sago Ave W		Jacksonville	32218
588377	Pasco	Odessa Community Center	1627 Chesapeake Dr		Odessa	33556
587609	Okaloosa	Okaloosa County Administration Bldg.	1250 Eglin Pkwy N		Shalimar	32579
587594	Okaloosa	Okaloosa Supervisor of Elections Office	302 N Wilson St, Ste 102		Crestview	32536
587783	Orange	Orange County National Golf Center	16301 Phil Ritson Way		Winter Garden	34787
587750	Orange	Orange County Supervisor of Elections	119 W. Kaley Street		Orlando	32806
585162	Alachua	Orange Heights Baptist Church	16700 Ne State Road 26		Hawthorne	32640
585708	Clay	ORANGE PARK LIBRARY	2054 Plainfield Ave		Orange Park	30273
589156	Volusia	Ormand Beach Library	30 S. Beach St.		Daytrona Beach	32104
587876	Osceola	OSCEOLA COUNTY WELCOME CENTER	4155 W. Vine St		Kissimmee	34741
585435	Broward	Oveta McKeithen Recreational Complex	445 S.w. 2nd Street		Deerfield Beach	33441
588997	Seminole	Oviedo Aquatic Center	148 Oviedo Boulevard		Oviedo	32765
589009	Seminole	Oviedo Library	310 Division St		Oviedo	32765
585859	Duval	PABLO CREEK REGIONAL LIBRARY	13295 Beach Boulevard		Jacksonville	32246
588853	Santa Rosa	Pace Community Center	5976 Chumuckla Hwy.		Pace	32571
585247	Bay	Pakrer Community Center	935 W. Park St.		Parker	32404
586097	Flagler	PALM COAST COMMUNITY CENTER	305 Palm Coast Pkwy Ne		Palm Coast	32137
586822	Manatee	Palmetto Library	923 6th St W		Palmetto	34221
585243	Bay	Palo Alto Church of Christ	3119 N Highway 231		Panama City	32405
588352	Pasco	PASCO COUNTY UTILITIES ADMINISTRATION BLDG	19420 Central Blvd		Land O' Lakes	34637
588834	St. Lucie	Paula A. Lewis Library	2950 Sw Rosser Blvd.		Port St. Lucie	34953
586960	Martin	Peter & Julie Cummings Library	2551 Sw Martin Hwy		Palm City	34990
585555	Broward	Pine Trails Ampitheater	10555 Trails End		Parkland	33076
584837	Madison	Pinetta Vol. Fire Dept.	509 Ne Persimmon Dr		Pinetta	32350
588569	Polk	Poincianna Community Fitness Center	397 Marigold Ave		Kissimmee	34759
588648	Polk	Polk County Government Center	930 E Parker St		Lakeland	33801
588609	Polk	Polk County Sherrifs NE District Office	1100 Dunson Rd		Davenport	33896
588612	Polk	Polk Street Community Center	1255 Polk St		Bartow	33830
588742	St. Johns	Ponte Vedra Branch Library	101 Library Blvd		Ponte Vedra Beach	32082
607456	St. Johns	Ponte Vedra Concert Hall	1050 A1a North		Ponte Vedra Beach	32082
589141	Volusia	Port Orange Library	1001 City Center Circle		Port Orange	32129
588778	St. Lucie	Port St. Lucie Community Center	2195 Se Airoso Blvd.		Port St. Lucie	34984
586298	Hillsborough	Port Tampa Community Center	4702 W Mccoy St		Tampa	33616
585976	Duval	PRIME OSBORN CONVENTION CENTER	1000 Water Street		Jacksonville	32204
586409	Hillsborough	Providence West Community Center	5405 Providence Rd		Riverview	33578
586876	Marion	Reddick Community Center	4345 Nw 152nd St		Reddick	32686
588380	Pasco	Regency Park Library	9701 Little Road		New Port Richey	34654
585880	Duval	REGENCY SQUARE BRANCH LIBRARY	9900 Regency Square Boulevard		Jacksonville	32225
588823	St. Lucie	Renaissance Business Park	4132 Okeechobee Rd.		Fort Pierce	34947
587807	Orange	RENAISSANCE SENIOR CENTER	3800 S Econlockhatchee Trl		Orlando	32829
585076	Wakulla	Riversink Volunteer Fire Department	491 Crawfordville Hwy		Crawfordville	32327
586393	Hillsborough	Riverview Branch Library	9951 Balm Riverview Rd		Riverview	33569
587853	Osceola	ROBERT GUEVARA COMMUNITY CENTER	501 Florida Pkwy		Kissimmee	34743
585021	Suwannee	ROBERT LEONARD PUBLIC LIBRARY	703 Nw Suwannee Ave		Branford	32008
587585	Okaloosa	Robert L.F. Sikes Library	1445 Commerce Dr		Crestview	32539
586442	Hillsborough	Robert L. Gilder Elections Service Center	2514 N Falkenburg Rd		Tampa	33619
586965	Martin	Robert Morgade Library	5851 Se Community Dr		Stuart	34997
586417	Hillsborough	Robert W Saunders, Sr. Public Library	1505 N Nebraska Ave		Tampa	33602
586825	Manatee	Rocky Bluff Library	6750 Us 301 N		Ellenton	34222
589062	Sumter	Rohan Recreation Center	850 Kristine Way		The Villages	32163
589037	Seminole	Sanford Library	150 N Palmetto Ave		Sanford	32771
585918	Duval	SAN MARCO BRANCH LIBRARY	1513 Lasalle Street		Jacksonville	32207
588875	Santa Rosa	Santa Rosa County Auditorium	4530 Spike's Way		Milton	32583
588923	Sarasota	Sarasota Elections Office - Terrace Building	2001 Adams Lane		Sarasota	34237
586471	Indian River	Sebastian City Hall Council Chambers	1225 Main Street		Sebastian	32958
588942	Sarasota	Shannon Staub Public Library	4675 Career Lane		North Port	34289
584865	Calhoun	Shelton Park Library	24726 Highway 73		Altha	32421
587527	Miami-Dade	*Shenandoah Branch Library	2111 Sw 19th Street		Miami	33145
607457	St. Johns	SilverLeaf Amenity Center	218 Silver Forest Drive		St Augustine	32092
586926	Marion	Silver Springs Shores Community Ctr	590 Silver Rd		Ocala	34472
588696	Polk	Simpson Park Community Center	1725 Martin L King Jr Ave		Lakeland	33805
585117	Jackson	Sneads City Hall	2028 Third Ave.		Sneads	32460
585379	Broward	SOE at E. Pat Larkins Community Center	520 Martin Luther King Boulevard		Pompano Beach	33060
585431	Broward	SOE at Lauderhill Mall	1519 N.w. 40th Avenue		Lauderhill	33313
585020	Franklin	SOE Main Office	47 Ave Office F		Apalachicola	32320
589102	Volusia	SOE Office	1750 S. Woodland Blvd.		Delton	32720
585223	Bay	SOE Office	830 W. 11th Street		Panama City	32401
585137	Levy	SOE Office	421 S Court St		Bronson	32621
584854	Madison	SOE Office (Back of Office)	239 Sw Pinckney St.		Madison	32340
588044	Palm Beach	SOE West County Branch Office	2976 Sr-15		Belle Glade	33430
586482	Lake	Sorrento Early Voting Sire	31340 County Road 437		Sorrento	32776
586155	Hernando	South Brooksville Community Center	601 E Dr. Martin Luther King Blvd		Brooksville	34601
588125	Palm Beach	South County Civic Center	16700 Jog Rd		Delray Beach	33446
587693	Orange	South Creek Library	1702 Deerfield Boulevard		Orlando	32837
587308	Miami-Dade	South Dade Regional Library	10750 Sw 211 St		Cutler Bay	33189
588740	St. Johns	Southeast Branch Library	6670 Us 1 S		St Augustine	32086
587766	Orange	Southeast Library	5575 S. Semoran Boulevard		Orlando	32822
585936	Duval	Southeast Regional Library	10599 Deerwood Park Blvd		Jacksonville	32256
586275	Hillsborough	Southeast Regional Office	10020 S Us Hwy 301		Riverview	33578
588222	Palm Beach	South Florida Fairgrounds	9067 Southern Blvd		West Palm Beach	33411
585952	Duval	SOUTH MANDARIN BRANCH LIBRARY	12125 San Jose Boulevard		Jacksonville	32223
588723	Putnam	SOUTH PUTNAM COUNTY GOVERNMENT COMPLEX	115 N Summit St		Crescent City	32112
585760	Collier	South Regional Library	8065 Lely Cultural Pkwy		Naples	34113
585536	Broward	South Regional Library/BC	7300 Pines Boulevard		Pembroke Pines	33024
588862	Santa Rosa	South Service Center	5841 Gulf Breeze Pkwy		Gulf Breeze	32563
586309	Hillsborough	SouthShore Regional Library	15816 Beth Shields Way		Ruskin	33573
586433	Hillsborough	SouthShore Regional Service Center	410 30th St Se		Ruskin	33570
586566	Lake	Southside Umatilla Community Center	17107 Ball Park Rd.		Umatilla	32784
587755	Orange	Southwest Library	7255 Della Drive		Orlando	32819
585434	Broward	Southwest Regional Library	16835 Sheridan Street		Pembroke Pines	33331
588224	Palm Beach	Spanish River Library	1501 Spanish River Blvd		Boca Raton	33431
586144	Hernando	Spring Hill Library	9220 Spring Hill Dr		Spring Hill	34608
588769	St. Johns	St. Augustine Beach City Hall	2200 A1a S		St Augustine	32080
587889	Osceola	St. Cloud Civic Center	3001 17th St		St Cloud	34769
587270	Miami-Dade	Stephen P. Clark Government Center (Elections Branch Office)	111 Nw 1st Street (Lobby)		Miami	33128
588243	Palm Beach	Sugar Sand Park Community Center	300 S Military Trl		Boca Raton	33486
585593	Broward	Sunrise Senior Center	10650 W. Oakland Park Boulevard		Sunrise	33351
584961	Union	Supervisor of Elections	175-w Main St		Lake Butler	32054
607441	Lafayette	Supervisor of Elections	120 W Main St	Room 207	Mayo	32066
589027	Seminole	Supervisor of Elections	1500 East Airport Boulevard		Sanford	32773
607451	Gadsden	Supervisor of Elections	16 S Madison Street		Quincy	32351
584980	Columbia	Supervisor of Elections - Main Office	971 W. Duval Street	Suite 102	Lake City	32055
587881	Osceola	Supervisor of Elections Main Office	2509 E Irlo Bronson Mem Hwy		Kissimmee	34744
585922	Duval	SUPERVISOR OF ELECTIONS MAIN OFFICE	105 East Monroe Street		Jacksonville	32202
586472	Indian River	Supervisor of Elections Office	4375 43rd Ave		Vero Beach	32967
585183	Alachua	Supervisor of Elections Office	515 N. Main Street		Gainesville	32601
588760	St. Johns	Supervisor of Elections Office	4455 Avenue A, #101		St. Augustine	32095
586771	Manatee	Supervisor of Elections Office	600 301 Blvd W	Suite 118	Bradenton	34205
585685	Citrus	Supervisor of Elections Office	1500 N Meadowcrest Blvd		Crystal River	34428
585736	Clay	Supervisor of Elections Office	500 N Orange Ave		Green Cove Springs	32043
584938	Calhoun	Supervisor of Elections Office	20859 Central Avenue East, Rm 117		Blountstown	32424
585103	Taylor	Supervisor of Elections Office	433 U S 19 North		Perry	32347
585810	Collier	Supervisor of Elections Office	3750 Enterprise Ave		Naples	34104
585094	Okeechobee	Supervisor of Elections Office	304 Nw 2nd St		Okeechobee	34972
586054	Escambia	Supervisor of Elections Office	213 Palafox Place (2nd Floor)		Pensacola	32502
585054	Washington	Supervisor of Elections Office	1424 Jackson Ave., Suite C		Chipley	32428
585083	Holmes	Supervisor of Elections Office	201 N Oklahoma Street, Suite 102		Bonifay	32425
584855	Jackson	Supervisor of Elections Office	2851 Jefferson St.		Marianna	32448
585092	DeSoto	Supervisor of Elections Office	201 E. Oak Street, Room 104		Arcadia	34266
584834	Baker	Supervisor of Elections Office	32 N 5th St	Suite A	Macclenny	32063
584878	Jefferson	Supervisor of Elections Office	1175 W Washington Street		Monticello	32344
586483	Lake	Supervisor of Elections Office	1898 E Burleigh Blvd		Tavares	32778
585072	Hendry	Supervisor of Elections Office Clewiston Office	1100 S. Olympia Street		Clewiston	33440
588524	Pinellas	Supervisor of Elections Office - County Building	501 First Ave. N.		St. Petersburg	33701
588504	Pinellas	Supervisor of Elections Office - Election Service Center	13001 Starkey Rd.		Largo	33773
587546	Monroe	Supervisor of Elections Office - Key Largo Branch	102050 Overseas Hwy		Key Largo	33037
585145	Hendry	Supervisor of Elections Office LaBelle Office	25 E. Hickpochee Avenue		Labelle	33935
587559	Monroe	Supervisor of Elections Office - Main Office	530 Whitehead St #101		Key West	33040
587557	Monroe	Supervisor of Elections Office - Marathon Branch	10015 Overseas Hwy		Marathon	33050
588518	Pinellas	Supervisor of Elections Office - Pinellas County Courthouse	315 Court St., Room 117		Clearwater	33756
586052	Escambia	SW Branch Library	12248 Gulf Beach Hwy		Pensacola	32507
585522	Broward	Tamarac Branch Library	8701 W. Commercial Boulevard		Tamarac	33321
587861	Osceola	TAX COLLECTOR (CAMPBELL CITY)	4730 S Orange Blossom Trail		Kissimmee	34746
585278	Brevard	Ted Whitlock Community Center	370 Championship Cir Nw		Palm Bay	32907
586374	Hillsborough	Temple Terrace Public Library	202 Bullard Pkwy		Temple Terrace	33617
588464	Pinellas	The Centre of Palm Harbor	1500 16th St		Palm Harbor	34683
588879	Sarasota	The Devyn Event Center	7113 South Tamiami Trail		Sarasota	34231
585745	Clay	THRASHER-HORNE CONF CNTR	283 College Dr		Orange Park	32065
587688	Orange	Tibet-Butler Preserve	8777 County Road 535		Orlando	32836
589051	Sumter	Tierra Del Sol Recreation Center	808 San Marino Drive		The Villages	32162
588858	Santa Rosa	Tiger Point Community Center	1370 Tiger Park		Gulf Breeze	32563
585289	Brevard	Titusville Public Library	2121 S Hopkins Ave		Titusville	32780
585265	Brevard	Tony Rosa Palm Bay Community Center	1502 Port Malabar Blvd Ne		Palm Bay	32905
588594	Polk	Tourist Club	205 N 5th St		Lake Wales	33853
585207	Alachua	Tower Road Branch Library	3020 Sw 75th St		Gainesville	32608
586304	Hillsborough	Town N Country Regional Public Library	7606 Paula Dr		Tampa	33615
587673	Orange	UCF - Live Oak Event Center	4115 Pyxis Lane		Orlando	32816
586446	Hillsborough	University Area Community Center	14013 N 22nd St		Tampa	33613
585860	Duval	UNIVERSITY PARK BRANCH LIBRARY	3435 University Boulevard North		Jacksonville	32277
586369	Hillsborough	USF Family Center (MHF-Activities Room)	3125 Usf Banyan Cir		Tampa	33612
607447	Escambia	UWF International Center	11000 University Parkway	Building 71	Pensacola	32514
587812	Orange	Valencia College - Lake Nona Campus	12350 Narcoossee Road		Orlando	32832
586564	Lake	Venetian Center	1 Dozier Cir		Leesburg	34748
588940	Sarasota	Venice Elections Office - R.L. Anderson Admin Building	4000 Tamiami Trail S, Rm 114		Venice	34293
588881	Sarasota	Venice Public Library	300 Nokomis Ave S		Venice	34285
584843	Washington	Vernon City Hall- Council Meeting Room	2808 Yellow Jacker Dr.		Vernon	32462
586649	Lee	Veterans Park Recreation Center	55 Homestead Rd S		Lehigh Acres	33936
585287	Brevard	Viera Regional Community Center	2300 Judge Fran Jamieson Way		Melbourne	32940
589066	Sumter	Villages Sumter County Service Center	7375 Powell Road, Room 102		Wildwood	34785
585068	Wakulla	Wakulla Elections Office	3115-b Crawfordville Hwy		Crawfordville	32327
607442	Brevard	Walter Butler Community Center	4201 N Cocoa Blvd		Cocoa	32927
584900	Nassau	Walter Junior Boatright County Building	45401 N Mickler St		Callahan	32011
589181	Walton	Walton County Courthouse Annex - SOE Satellite Office	31 Coastal Centre Blvd		Santa Rosa Beach	32459
589193	Walton	Walton County Courthouse SOE Office	571 Us Hwy 90 E	Suite 102	Defuniak Springs	32433
587765	Orange	Washington Park Library	5151 Raleigh Street, Suite A		Orlando	32811
585980	Duval	Webb Wesconnett Regional Library	6887 103rd St		Jacksonville	32210
588982	Seminole	Wekiva Library	245 N Hunt Club Blvd		Longwood	32779
588013	Palm Beach	Wellington Branch Library	1951 Royal Fern Dr		Wellington	33414
588238	Palm Beach	Wells Rec Community Center	2409 Avenue H West		Riviera Beach	33404
588282	Pasco	Wesley Chapel District Park	7727 Boyette Road		Wesley Chapel	33545
588016	Palm Beach	West Boca Branch Library	18685 State Road 7		Boca Raton	33498
585944	Duval	WEST BRANCH LIBRARY	1425 Chaffee Road South		Jacksonville	32221
587452	Miami-Dade	Westchester Regional Library	9445 Sw 24th Street		Miami	33165
587405	Miami-Dade	West Kendall Regional Library	10201 Hammocks Blvd		Miami	33196
587089	Miami-Dade	West Miami Community Center	901 Sw 62nd Avenue		West Miami	33144
588986	Seminole	Westmonte Recreation Center	624 Bills Ln		Altamonte Springs	32714
587716	Orange	West Oaks Library	1821 E. Silver Star Road		Ocoee	34761
585529	Broward	Weston Branch Library	4205 Bonaventure Blvd		Weston	33332
588280	Pasco	WEST PASCO GOVERNMENT CENTER	8731 Citizens Dr		New Port Richey	34654
585504	Broward	West Regional Library	8601 W. Broward Boulevard		Plantation	33324
586347	Hillsborough	West Tampa Branch Library	2312 W Union St		Tampa	33607
584894	Gulf	Wewahitchka Branch Library	314 N. Second Street		Wewahitchka	32465
585294	Brevard	Wickham Park Community Center	2815 Leisure Way		Melbourne	32935
587676	Orange	Winter Garden Library	805 E. Plant Street		Winter Garden	34787
587790	Orange	Winter Park Library	1052 W. Morse Boulevard		Winter Park	32789
607455	Pasco	WIREGRASS RANCH SPORTS CAMPUS	3211 Lajuana Blvd		Wesley Chapel	33543
585409	Broward	Women's Club of Wilton Manors	600 N.e. 21st Court		Wilton Manors	33305
586688	Leon	Woodville Community Center	8000 Old Woodville Rd		Tallahassee	32305
586543	Lake	W.T. Bland Public Library	1995 N Donnelly St		Mount Dora	32757
585066	Nassau	Yulee Sports Complex	86142 Goodbread Rd		Yulee	32097
588812	St. Lucie	Zora Neale Hurston Library	3008 Avenue D		Fort Pierce	34947
`