<template>
  <v-dialog light v-if="shown" content-class="simple-dialog" eager v-model="shown" :max-width="message.dialogMaxWidth || 500" scrollable persistent overlay-opacity="0.9">
    <v-card>
      <!-- <v-responsive> -->
        <v-card-title v-if="message.title" class="pa-3">
          <h3 v-html="message.title"></h3>
        </v-card-title>
        <v-card-text class="px-4 py-6">
          <div v-html="message.text"></div>
		  <v-text-field ref="passwordInput" outlined hide-details class="simple-dialog-text-field" v-if="type === 'prompt' && message.promptType === 'password'" v-model="text_input" @keyup="checkSubmit" autocomplete="new-password"  :type="show_password ? 'text' : 'password'" :append-icon="show_password ? 'fas fa-eye' : 'fas fa-eye-slash'" @click:append="show_password = !show_password"></v-text-field>
          <v-text-field ref="textInput" outlined hide-details class="simple-dialog-text-field" v-if="type === 'prompt' && message.promptType === 'text'" :type="message.type || 'text'" v-model="text_input" @keyup="checkSubmit"></v-text-field>
		  <v-textarea ref="textareaInput" outlined hide-details auto-grow class="simple-dialog-textarea" v-if="type === 'prompt' && message.promptType === 'textarea'" v-model="text_input"></v-textarea>
		  <v-file-input ref="fileInput" outlined hide-details placeholder="Select a file…" class="simple-dialog-file-input" v-if="type === 'prompt' && message.promptType === 'file'" v-model="file_input"></v-file-input>
		  <v-select ref="selectInput" outlined hide-details dense class="simple-dialog-select-input" v-if="type === 'prompt' && message.promptType === 'select'" v-model="text_input" :items="message.selectOptions" label=""></v-select>
        </v-card-text>
        <v-card-actions class="pa-3">
			<v-btn v-on:click="cancel" class="simple-dialog-dismiss-btn" dark :color="message.cancelColor" v-if="type !== 'alert'" :style="message.hideCancel==true?'visibility:hidden;height:0px;':''" v-html="message.cancelText"></v-btn>
			<v-spacer></v-spacer>
			<v-btn v-if="message.extraBtnText" v-on:click="extra_btn_clicked" dark :color="message.extraBtnColor" ref="extraBtn" class="mr-2"><span v-html="message.extraBtnText"></span></v-btn>
			<v-btn v-on:click="accept" :class="type=='alert'?'simple-dialog-dismiss-btn':''" dark :color="message.acceptColor" :style="message.hideAccept==true?'visibility:hidden;height:0px;':''" v-html="message.acceptText" ref="acceptBtn"></v-btn>
        </v-card-actions>
      <!-- </v-responsive> -->
    </v-card>
  </v-dialog>
</template>

<script>
    export default {
        name : "SimpleDialog",
        props : {
            type : String,
            message : Object,
            resolve : Function
        },
        data() { return {
            shown: false,
            text_input: '',
			file_input: [],
			show_password: false,
        }},
		created() {
			if (this.message.initialValue != null) {
				this.text_input = this.message.initialValue;
			}
		},
        methods : {
            cancel()
            {
                this.shown = false;
                this.resolve( false );
            },
            accept()
            {
                this.shown = false;
				if (this.type == 'prompt') {
					// note that although file_input is defined as an array, since we only currently allow for one file to be selected, only a single file object is returned.
					if (this.message.promptType == 'file') this.resolve(this.file_input)
					else this.resolve(this.text_input)
				} else {
					this.resolve(true)
				}
            },
			extra_btn_clicked() {
				// extra btn option -- allows for a choice of two things with an additional button option
				this.shown = false;
				let rv = 'extra'
				if (this.type == 'prompt') rv = ['extra', this.text_input]
				this.resolve(rv);
				// caller should look for `x === 'extra'` as the return value
			},
            checkSubmit( evt )
            {
                if( evt.key === "Enter" )
                {
                    this.accept();
                }

				// if allCaps is true, transform input to upper case
				if (this.message.allCaps) {
					this.text_input = this.text_input.toUpperCase()
				}
            }
        },
        mounted()
        {
            this.shown = true;
            if (this.type === "prompt"){
                setTimeout(function() {
					if (this.message.promptType == 'text') {
						$(this.$refs.textInput.$el).find('input').focus()
						$(this.$refs.textInput.$el).find('input').select()
					} else if (this.message.promptType == 'password') {
						$(this.$refs.passwordInput.$el).find('input').focus()
						$(this.$refs.passwordInput.$el).find('input').select()
					} else if (this.message.promptType == 'textarea') {
						$(this.$refs.textareaInput.$el).find('textarea').focus()
						$(this.$refs.textareaInput.$el).find('textarea').select()
					}
				}.bind(this), 300 );
            }

			if (this.message.focusBtn) {
				setTimeout(function() {
					$(this.$refs.acceptBtn.$el).focus()
				}.bind(this), 300 );
			}
        }
    }
</script>

<style>
.simple-dialog-textarea textarea {
	margin-top:0!important;
	max-height:50vh;
	font-size:16px;
	line-height:20px;
}

.simple-dialog-text-field {
	margin-top:10px!important;
}

.simple-dialog-file-input {
	margin-top:15px!important;
}

.simple-dialog .v-card__text {
	font-size:16px;
	line-height:24px;
	color:#111!important;
}

.simple-dialog .v-card__actions {
	background-color:#f8f8f8;
	border-top:1px solid #ddd;
}

.simple-dialog .v-card__title {
	background-color:#f8f8f8;
	border-bottom:1px solid #ddd;
}
.simple-dialog .v-card__title h3 {
	font-size:24px;
	margin:12px;
	margin:0;
	padding:0;
	color:#111!important;
}

@media (max-width: 600px) {
	.simple-dialog {
		margin: 0;
		border-radius:0;
		/* height:100vh; */
	}

	.simple-dialog .v-card {
		border-radius:0;
		/* height:100vh; */
	}

	.simple-dialog .v-card__actions {
		flex-wrap:wrap;
	}

	.simple-dialog .v-card__actions .v-btn {
		width:100%;
		margin-top:5px;
	}
}
</style>
