class Dialpad_User {
	constructor(data) {
		if (empty(data)) data = {}

		sdp(this, data, 'username', '')
		sdp(this, data, 'email', '')
		sdp(this, data, 'password', '')
		sdp(this, data, 'enabled', '')
		sdp(this, data, 'language', 'english')
		sdp(this, data, 'backup', false)
		sdp(this, data, 'priority', '')
		sdp(this, data, 'note', '')
	}
}
window.Dialpad_User = Dialpad_User
