<template>
<v-app><div v-show="app_mode!='uninitialized'">
	<v-toolbar :color="toolbar_color" dark height="48" extension-height="40">
		<v-icon large class="mr-3" :color="toolbar_icon_color">fas {{toolbar_icon_code}}</v-icon>
		<v-toolbar-title><b v-html="site_data.title"></b></v-toolbar-title>
		<v-spacer/>
		<div v-if="app_mode!='closed'" class="k-phone-number" :class="phone_number_class"><b style="font-size:20px" v-html="site_data.phone_number"></b></div>
		<v-spacer/>
		<div class="k-display-name" v-if="app_mode=='main'&&user_info.name_first">{{user_info.name_first + ' ' + user_info.name_last}}</div>
		<v-menu bottom left><template v-slot:activator="{on}"><v-btn v-on="on" icon text v-show="app_mode=='main'" class="k-burger-menu ml-2"><v-icon>fas fa-bars</v-icon></v-btn></template>
			<v-list min-width="200" dense>
				<v-list-item><v-list-item-icon style="min-width:10px;width:10px;"><v-icon small>fas fa-user</v-icon></v-list-item-icon><v-list-item-title>{{user_info.email}}</v-list-item-title></v-list-item>
				<v-list-item v-if="is_system_admin" class="red--text" @click="simulate_user"><v-list-item-icon style="min-width:10px;width:10px;"><v-icon small color="red">fas fa-gear</v-icon></v-list-item-icon><v-list-item-title>Simulate another user</v-list-item-title></v-list-item>
				<v-list-item v-if="user_info.admin_email_doing_simulation" class="red--text" @click="simulate_user_cancel"><v-list-item-icon style="min-width:10px;width:10px;"><v-icon small color="red">fas fa-gear</v-icon></v-list-item-icon><v-list-item-title>Cancel user simulation</v-list-item-title></v-list-item>
				<v-list-item><v-list-item-title class="text-center"><i>Site Version {{$store.state.app_version}}–{{site_version}}</i></v-list-item-title></v-list-item>
				<v-list-item @click="sign_out"><v-list-item-icon style="min-width:10px;width:10px;"><v-icon small>fas fa-sign-out-alt</v-icon></v-list-item-icon><v-list-item-title>Sign Out</v-list-item-title></v-list-item>
			</v-list>
		</v-menu>
		<template v-slot:extension v-if="app_mode=='main'">
			<v-tabs class="k-site-tabs">
				<v-tab v-for="(o, label) in page_data" v-if="show_tab(o)" :to="'/'+label" :key="label"><v-icon small class="mr-1">fas {{o.icon}}</v-icon><span class="k-tab-label">{{o.tab_title}}</span></v-tab>
				<v-tab to="/admin" v-if="show_admin_tab"><v-icon small class="mr-1">fas fa-cog</v-icon><span class="k-tab-label">ADMIN</span></v-tab>
			</v-tabs>
		</template>
	</v-toolbar>
	<LoginView2 ref="login_view" v-if="app_mode=='login'"/>
	<div v-if="app_mode=='main'">
		<keep-alive><router-view :key="router_key"></router-view></keep-alive>
	</div>

	<ResourceViewer v-if="resource_showing" :resource="resource_showing" @dialog_cancel="resource_showing=false" />
</div></v-app>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import LoginView from './components/LoginView'
import LoginView2 from './components/LoginView2'
import ResourceViewer from '@/components/ResourceViewer'

export default {
	name: 'App',
	components: { LoginView, LoginView2, ResourceViewer },
	data() { return {
		app_mode: 'uninitialized',
		resource_showing: false,
	}},
	computed: {
		...mapState(['initialized', 'user_info', 'site_version', 'site_data', 'page_data' ]),
		...mapGetters(['is_system_or_county_admin', 'is_system_admin']),
		toolbar_color() {
			if (this.app_theme == 'halloween') return 'orange darken-3'
			if (this.app_theme == 'earth') return 'green darken-1'
			return 'blue'
		},
		toolbar_icon_code() {
			return 'fa-check-to-slot'
		},
		toolbar_icon_color() {
			return 'white'
		},
		phone_number_class() {
			if (this.app_theme == 'halloween') return 'yellow--text text--lighten-3'
			return 'white--text'
		},
		app_theme() {
			// halloween, gojoe, earth empty
			let ss = document.location.search
			if (ss.search(/theme=(\w+)/) > -1) {
				return RegExp.$1
			}
			return ''
		},
		router_key() {
			let path = this.$route.fullPath
			return path
		},
		show_admin_tab() {
			if (this.is_system_or_county_admin) return true
			return false
		},
	},
	watch: {
	},
	created() {
		window.vapp = this
		this.initialize_app()

		setTimeout(x=>{
			// if we received a token= param in the get string...
			let tm = window.location.search.match(/\?token=(.*)\b/)
			if (tm) {
				let msg = tm[1]
				// if we got an error (probably bad_token), inform the user; we should be looking at the login screen again
				if (!['ok', 'already_signed_in'].includes(msg)) {
					if (msg == 'bad_token') msg = 'The login link token was invalid or expired.'
					if (msg == 'no_token') msg = 'No token was present in the link.'	// this shouldn't happen
					if (msg == 'email_not_found') msg = 'Email not found.'				// this shouldn't happen
					this.$inform({text: msg, color: 'red darken-3', snackbarTimeout:-1})
				} else {
					// we should have stored where the user was when they clicked the send login link btn; go back there
					let path = U.local_storage_get('flvh_login_link_path')
					if (path) {
						this.$router.replace(path)
						U.local_storage_clear('flvh_login_link_path')
					}
				}
				// clear search string
				U.clear_location_search()
			}
		}, 50)
	},
	mounted() {
	},
	methods: {
		initialize_app(payload) {
			this.$store.dispatch('initialize_app', payload).then((mode)=>{
				if (mode == 'login') {
					// this code block closes the site
					// // if user isn't already logged in and "backdoor" isn't in the search string, don't show the login button
					// if (document.location.search.indexOf('backdoor') == -1) {
					// 	this.app_mode = 'closed'
					// 	return
					// }

					// after a tick for everything to get set up, set app_mode to show the app
					this.$nextTick(()=>{
						$('body').removeClass('k-body-uninitialized')
						this.app_mode = mode
					})

				} else {
					// start pinging, which also retrieves my_shifts
					this.$store.dispatch('ping')

					// for admins, get shifts and users?
					if (false && this.is_system_admin) {
						this.$store.dispatch('get_shifts').then(()=>{
							this.$store.dispatch('get_users').then(()=>{
								// after a tick for everything to get set up, set app_mode to show the app
								this.$nextTick(()=>{
									$('body').removeClass('k-body-uninitialized')
									this.app_mode = mode
								})

							})
						})
					} else {
						// after a tick for everything to get set up, set app_mode to show the app
						this.$nextTick(()=>{
							$('body').removeClass('k-body-uninitialized')
							this.app_mode = mode
						})
					}
				}
			})
		},

		show_tab(page_data) {
			if (!page_data.tab_enabled) return false

			// system admins can see any tab
			if (this.is_system_admin) return true

			// if enabled_roles is empty anyone can see it
			if (!page_data.enabled_roles) return true

			// else check roles
			if (page_data.enabled_roles) {
				let enabled_roles = page_data.enabled_roles.split(/\s*,\s*/)
				for (let enabled_role of enabled_roles) {
					if (this.user_info.has_role(enabled_role)) return true
				}
			}

			// if we get to here you can't see it
		},

		go_to_route(route_name) {
			if (this.$route.name == route_name) return
			this.$router.push(route_name)
		},

		open_resource_link(resource, el) {
			// if we're in the context of a froala editor, `resource` will be the resource_id, and we will have received the dom object for the link in the editor
			if (el) {
				// send a message to the editor's parent_component to show the ResourceEditor dialog
				let fco = U.get_froala_component(el)
				if (fco.parent_component) {
					let froala_resource_link_id = $(el).attr('data-resource-link-id')
					fco.parent_component.froala_inserted_resource_clicked(fco, resource, froala_resource_link_id)
					return
				}
			}

			// if we didn't receive a resource object, look up from site_resources
			if (typeof(resource) != 'object') {
				// note that if we copied a resource from one org to another, we may find the resource in copied_from_resource_id
				resource = this.$store.state.site_resources.find(x=>x.vh_resource_id == resource || x.copied_from_resource_id == resource)
				if (empty(resource)) {
					this.$alert('Can’t find a resource to open')
					return
				}
			}

			if (resource.type == 'website') {
				window.open(resource.full_url(), '_blank')
			} else {
				this.resource_showing = resource
			}
		},

		simulate_user() {
			this.$prompt({
				title: 'Simulate another user',
				text: 'Enter the email address of the user you would like to simulate. To cancel the simulation later, return to this menu.',
				acceptText: 'Start Simulation',
			}).then(email => {
				email = $.trim(email)
				if (empty(email)) return
				this.$store.dispatch('simulate_user', email)
			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		simulate_user_cancel() {
			this.$store.dispatch('simulate_user_cancel')
		},

		sign_out() {
			this.$store.dispatch('sign_out')
		},
	}
}
</script>

<style lang="scss">

html {
	font-size:18px;
}

.v-application {
	font-family: $sans-serif-font;
}

.k-page-holder {
	max-width:920px;
	margin:0px auto;
	padding:20px;
	position:relative;
	font-size:16px;

	h2 {
		margin-top:16px;
	}
}

.k-important {
	background-color:#ff0;
	padding:0 5px;
	// margin-right:8px;
	font-weight:bold;
}

a {
	color:$v-blue;
}

.k-site-tabs {
	a {
		text-transform:none!important;
		letter-spacing:0px!important;
	}
	.v-tab {
		min-width:50px;
	}
	.v-tab--active {
		font-weight:bold;
	}
}

.k-disabled-tab {
	background-color:$v-red-darken-2;
	// border-bottom:0px;
	// color:$v-red-darken-4!important;
	// .fas { color:$v-red-darken-4!important }
	// border-radius:8px 8px 0 0;
}

.k-display-name {
	font-size:14px;
}

@media (max-width: 500px) {
	.k-volunteer-info { display:none; }
	.k-display-name { display:none; }
	.k-tab-label { display:none; }
	.k-site-tabs .v-slide-group__content { justify-content:center!important; }
}

@keyframes kGhostShake {
  25% { transform: translate(0, 3px); }
  75% { transform: translate(0,-3px); }
}

.k-halloween-ghost {
	animation: kGhostShake 3s linear infinite;
    transform: translate(0, 0);
}


@keyframes kCatFade {
	0%,25%,40%,100% { opacity: 0 }
	30%,35% { opacity: 1 }
}

.k-halloween-cat-sound {
	font-size:12px;
	margin-left:4px;
	margin-top:-10px;
	color:#000;
	// opacity: 1;
    animation: kCatFade 10s linear infinite;
}

@keyframes kSpiderCrawl {
	0% { transform: translate(0, 0px) rotate(90deg); }
	48% { transform: translate(300px, 0px) rotate(90deg); }
	50% { transform: translate(300px, 0px) rotate(-90deg); }
	98% { transform: translate(0, 0px) rotate(-90deg); }
	100% { transform: translate(0, 0px) rotate(90deg); }
}

.k-halloween-spider {
	max-width:300px;
	margin:0 auto;
	.v-icon {
		animation: kSpiderCrawl 69s linear infinite;
		// transform: translate(0,0);
	}
}

.k-go-letter {
	font-size:40px;
	font-weight:bold;
	color:$v-red-accent-1;
	text-shadow: 1px 1px 2px #666;
	// margin:0 4px;
}

@keyframes kGoFlicker1 {
	0%,       25%,55%,       100% { opacity: 0 }
	   2%,23%,        57%,97% { opacity: 1 }
}

.k-go-letter-1 {
	animation: kGoFlicker1 10s linear infinite;
}

@keyframes kGoFlicker2 {
	0%,25%,        50%,55%,       100% { opacity: 0 }
	       27%,48%,        57%,97% { opacity: 1 }
}

.k-go-letter-2 {
	animation: kGoFlicker2 10s linear infinite;
}

@media print {
	.v-toolbar__content {
		color:#333!important;
	}

	.k-site-tabs, .k-burger-menu, .k-phone-number, .k-new-shift-signup-wrapper {
		display:none;
	}

	.k-display-name,  {
		white-space:nowrap;
	}
}
</style>
