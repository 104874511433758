class Site_Data {
	constructor(data) {
		if (empty(data)) data = {}

		sdp(this, data, 'vh_org_id', '')	// required
		sdp(this, data, 'title', '')	// required
		sdp(this, data, 'election_date', '')	// required
		sdp(this, data, 'n_shift_dates', 14)
		sdp(this, data, 'n_shift_dates_hotline', 42)
		sdp(this, data, 'tech_help_email', 'flvhtech@gmail.com')
		sdp(this, data, 'lbj_election_id', '')
		sdp(this, data, 'shift_grid_email_watcher_links', 'LBJ Training Video: https://vimeo.com/738070262')
		sdp(this, data, 'shift_grid_email_hotline_links', 'LBJ Training Video: https://vimeo.com/738070262')
		sdp(this, data, 'counties', [])	// required
		sdp(this, data, 'phone_number', '800-555-5555')
		sdp(this, data, 'tab_order', [])
		sdp(this, data, 'custom_roles', [])

		sdp(this, data, 'checked_out_by', 0)
		sdp(this, data, 'last_edited_by', 0)
		sdp(this, data, 'created_at', '')
		sdp(this, data, 'updated_at', '')

		// note that we don't save site_version in Site_Data; it's kept track of separately in the store
	}
}
window.Site_Data = Site_Data

class Page_Data {
	constructor(data, tab_label) {
		if (empty(data)) data = {}

		sdp(this, data, 'vh_tab_id', 0)
		sdp(this, data, 'vh_org_id', vapp.$store.state.vh_org_id)
		this.label = tab_label

		let default_data = {
			home: {tab_enabled:true, tab_title:'Home', icon:'fa-home'},
			shifts: {tab_enabled:true, tab_title:'My Shifts', icon:'fa-calendar-day'},
			resources: {tab_enabled:true, tab_title:'Resources', icon:'fa-file-alt'},
			voterfaq: {tab_enabled:true, tab_title:'Manual & FAQ', icon:'fa-book'},
			techfaq: {tab_enabled:true, tab_title:'Tech Help', icon:'fa-wrench'},
			custom: {tab_enabled:false, tab_title:'Custom', icon:'fa-user'},
		}

		default_data.home.page_title = 'Volunteer Homepage'
		default_data.shifts.page_title = 'My Shifts'
		default_data.resources.page_title = 'Key Resources'
		default_data.voterfaq.page_title = 'Voter Hotline Manual and FAQ'
		default_data.techfaq.page_title = 'Tech Help: Accessing the Voter Protection Hotline'
		default_data.custom.page_title = 'Custom Tab'

		default_data.home.page_description = '<p><b>Thank you</b> for being a hotline volunteer!</p>'
		default_data.shifts.page_description = '<p><span class="k-important">PLEASE!</span> Remember to <b>confirm</b> your readiness <nobr>the day before your shift,</nobr> and <b>check in</b> when you’re at the location to start your shift.</p>'
		default_data.resources.page_description = '<p>You must know these!!!  We update them regularly.</p>'
		default_data.voterfaq.page_description = '<i>“Thank you for calling the Democratic Party Voter Assistance Hotline! May I get your phone number in case we get disconnected?”</i>'
		default_data.techfaq.page_description = ''
		default_data.custom.page_description = ''

		if (default_data[tab_label]) {
			default_data = default_data[tab_label]
		} else {
			default_data = default_data.custom
			let n = tab_label[6]
			default_data.page_title += ' ' + n
			default_data.tab_title += ' ' + n
		}

		sdp(this, data, 'tab_enabled', default_data.tab_enabled)
		sdp(this, data, 'enabled_roles', '')
		sdp(this, data, 'tab_title', default_data.tab_title)
		sdp(this, data, 'page_title', default_data.page_title)
		sdp(this, data, 'page_description', default_data.page_description)
		sdp(this, data, 'icon', default_data.icon)
		sdp(this, data, 'checked_out_by', 0)
		sdp(this, data, 'last_edited_by', 0)
		sdp(this, data, 'created_at', '')
		sdp(this, data, 'updated_at', '')

		this.sections = []
		if (data.sections) {
			for (let section of data.sections) {
				this.sections.push(new Page_Section_Data(section))
			}
		}
	}
}
window.Page_Data = Page_Data

class Page_Section_Data {
	constructor(data) {
		if (empty(data)) data = {}

		// use a new uuid for the id if one isn't supplied
		if (empty(data.id)) this.id = U.new_uuid()
		else this.id = data.id

		sdp(this, data, 'title', '')
		sdp(this, data, 'mode', 'hidden', ['no_collapse', 'collapsible', 'start_collapsed', 'hidden'])
		sdp(this, data, 'html', '')
	}
}
window.Page_Section_Data = Page_Section_Data

class Site_Resource {
	constructor(data) {
		if (empty(data)) data = {}

		// use a new uuid for the id if one isn't supplied
		if (empty(data.vh_resource_id)) this.vh_resource_id = U.new_uuid()
		else this.vh_resource_id = data.vh_resource_id

		sdp(this, data, 'vh_org_id', vapp.$store.state.vh_org_id)
		sdp(this, data, 'title', '')
		sdp(this, data, 'type', '', ['', 'website', 'upload', 'video', 'html'])
		sdp(this, data, 'url', '')
		sdp(this, data, 'copied_from_org_id', '')
		sdp(this, data, 'copied_from_resource_id', '')
	}

	// return the fontawesome icon class that should go with this resource type. this should be paired with class `fas`
	icon() {
		if (this.type == 'website') return 'fa-link'
		if (this.type == 'video') return 'fa-video'
		if (this.type == 'upload') {
			let ext = this.url.replace(/.*?\.(\w+)$/, '$1')
			if (ext == 'pdf') return 'fa-file-pdf'
			if (ext == 'doc') return 'fa-file-word'
			if (ext == 'jpg' || ext == 'jpeg' || ext == 'gif' || ext == 'pdf') return 'fa-file-image'
		}
		if (this.type == 'html') return 'fa-file-alt'
		return 'fa-file'
	}

	type_label() {
		if (this.type == 'upload') return 'Uploaded File'
		if (this.type == 'html') return 'Text'
		if (this.type == 'website') return 'Website'
		if (this.type == 'video') return 'Video'
		return ('unknown type')
	}

	full_url() {
		if (this.type == 'upload' || this.type == 'html') {
			return '/src/upload.php?' + this.url

		} else {
			return this.url
		}
	}
}
window.Site_Resource = Site_Resource

class FAQ_Item {
	constructor(data, parent_faq_id) {
		if (empty(data)) data = {}

		// questions and nodes have uuid's; answers have the question's uuid + '_ANSWER'
		// use a new uuid for the id if one isn't supplied
		if (empty(data.faq_id)) this.faq_id = U.new_uuid()
		else this.faq_id = data.faq_id

		sdp(this, data, 'type', '', ['', 'node', 'question', 'answer'])
		sdp(this, data, 'text', '')
		sdp(this, data, 'visible', true)

		// set parent_faq_id
		this.parent_faq_id = parent_faq_id

		// recursively add children
		this.children = []
		if (data.children) {
			for (let child of data.children) {
				if (child.visible || vapp.$store.getters.is_system_admin) {
					this.children.push(new FAQ_Item(child, this.faq_id))
				}
			}
		}
	}
}
window.FAQ_Item = FAQ_Item
