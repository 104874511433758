<!-- Part of the SPARKL educational activity system, Copyright 2019 by Pepper Williams -->
<template>
	<v-container fill-height>
		<div class="k-login_inner">

			<div class="grey lighten-3 elevation-3 pa-3" style="border-radius:8px;">
				<div class="k-login-activity-header">Click the button below to <b>Sign in with Google</b><!-- using the email address you used when signing up to be a voter protection volunteer.-->*</div>

				<img class="k-google_signin_btn" ref="google_signin_btn" src="./../images/btn_google_signin_dark_normal_web@2x.png">

				<div class="mt-4" style="font-size:16px; line-height:21px">
					<div class="text-center">* Note that you can Sign in with Google with non-Gmail accounts<!-- —that is, your email does <i>not</i> need to have the form “xxx@gmail.com”.--> <v-btn class="mt-3 mb-2" small color="#bbb" @click="more_help=!more_help">click here for additional information</v-btn></div>

					<div v-show="more_help" class="text-left mt-4">
						<p>To access the voter protection website you will need to log in using a Google-linked email account (we do this so we can protect ourselves from trolls but don’t have to store any passwords in our system).</p>
						<p>If the email address you’ve been using for voter protection correspondence is a Gmail account—e.g. if it has the form “xxx@gmail.com”, you should be able to sign in by clicking the “Sign in with Google” button above (you’ve probably done the same thing with many other sites).</p>
						<p><b>If you haven’t been using a Gmail account, you have a few different options for signing into the voter protection website.</b> Note that for all these options, you only have to do something special once; after that, you just Sign in with Google, enter your email address and password, and you’re in.</p>
						<ol>
							<li>First, if you <b>already have a Gmail account</b> (but haven’t been using this Gmail address for other voter protection correspondence), you can follow this process:
									<ul>
										<li>Click the “Sign in with Google” button and sign in with your Gmail account in the pop-up window.</li>
										<li>After signing in you should see a dialog on the voter protection website that says “We don’t recognize that email in our system. If you used a different email when you signed up to volunteer, please enter it below and click “TRY THIS EMAIL”.</li>
										<li>Enter the email address you use for other voter protection correspondence, click “TRY THIS EMAIL”, and the system should link the two emails, so that when you come back later, you can sign in using your Google-linked account and get right in.</li>
									</ul>
								</li>
							<li class="mt-3">Alternatively, you can <b>create a Google Account</b> and link your non-Gmail email address with that account, using the following steps:
								<ul>
									<li>Click the “Sign in with Google” button.</li>
									<li>You should then see the Google pop-up window. On that window you should see a “Create account” or “Use another account” button. Click that button.</li>
									<li>On the subsequent screen, you will see the “Create your Google Account” form. Enter your first and last name, then either:<ol type="a">
										<li><b>Create a new gmail email address</b> right from here. After completing the Google account creation process, you’ll then link the new gmail address to the email you used with you registered to volunteer, as described above.</li>
										<li>Click the button that says <b>“Use my current email address instead”</b> and enter the email address you used with you registered to volunteer. This will allow you create a Google account that is linked to your other email address. After completing the Google account creation process, you’ll be sent right in to the voter protection site.</li>
									</ol></li>
								</ul>
							</li>
						</ol>
						<p class="mt-3"><b>If you don’t see the “Sign in with Google” button at all,</b> or the sign in process doesn’t seem to work, an ad blocker may be fouling things up; try to turn the ad blocker off for the site’s domain (demvpro.org).  If you don’t have an ad blocker on or you can’t disable it, you might try using an alternate browser to access the site.</p>
					</div>
				</div>
			</div>

			<div class="grey lighten-3 elevation-3 pa-3 mt-6" style="border-radius:8px;">
				<div class="k-login-activity-header">Or enter your email address and click the button below to <nobr><b>send a “Login Link”</b></nobr> to your email**</div>
				<div class="mt-2 d-flex align-center">
					<v-text-field ref="email_field" outlined hide-details dense label="Your email address" v-model="login_link_email" background-color="#fff" placeholder="joe@example.com" autocomplete="new-password"></v-text-field>
					<v-btn small color="primary" class=" ml-2 k-tight-btn" @disabled="login_link_email==''" @click="send_login_link">Send Login Link</v-btn>
				</div>

				<div class="mt-4" style="font-size:16px; line-height:21px; border-radius:8px;">
					<div class="text-center">** This process is similar to how many people sign in to LBJ. <b>Note</b> that the email you will be sent may end up in your Spam, Promotions, or Junk folder.<!-- <v-btn class="mt-3" small color="primary" @click="more_help=!more_help">click here for additional information</v-btn>--></div>

					<!-- <div v-show="more_help" class="text-left mt-6"></div> -->
				</div>
			</div>

		</div>
	</v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
export default {
	components: {  },
	data() { return {
		more_help: false,
		login_link_email: '',
	}},
	computed: {
		...mapState(['google_client_id']),
	},
	created() {
		this.$nextTick(()=>{
			this.login_link_email = U.local_storage_get('demvopro_login_link_email', '')
			$(this.$refs.email_field.$el).find('input').focus()
		})
	},
	mounted() {
		this.initialize()
	},
	methods: {
		initialize() {
			U.loading_start()
			// window.gapi should be loaded via https://apis.google.com/js/platform.js in index.html
			// if it isn't loaded yet, try again in 100 ms
			if (window.gapi == null) {
				setTimeout(()=>this.initialize(), 100);
				return;
			}
			U.loading_stop()

			console.log('id', this.google_client_id)

			window.gapi.load('auth2', () => {
				var auth2 = window.gapi.auth2.init({
					client_id: this.google_client_id,
					scope: 'profile email ',
					fetch_basic_profile: true,
					approval_prompt: 'force',
					prompt: 'select_account consent',
					access_type: 'offline'
					// cookiepolicy: 'single_host_origin'
				})

				let jq = $('.k-google_signin_btn')
				console.log(jq.length)
				for (let i = 0; i < jq.length; ++i) {
					// auth2.attachClickHandler(this.$refs.google_signin_btn, {}, googleUser => {
					auth2.attachClickHandler(jq[i], {}, googleUser => {
						// The ID token you need to pass to your backend:
						let id_token = googleUser.getAuthResponse().id_token
						// console.log('attachClickHandler fn called', googleUser.getAuthResponse())

						if (id_token) {
							// pass id_token back to initialize_portal service, which will sign the user in
							vapp.initialize_app({id_token: id_token})
						} else {
							// TODO: what do we do here?
							alert('Google getAuthResponse failed')
						}
					}, error => console.error(error))
				}
			})
		},

		send_login_link() {
			this.login_link_email = $.trim(this.login_link_email)
			if (empty(this.login_link_email)) {
				this.$alert('You must first enter your email address.')
				return
			}

			U.ajax('flvh/send_password_reset', {email: this.login_link_email}, result=>{
				console.log(result)
				if (result.status == 'ok') {
					this.$alert('Login link sent. Check your <b>Spam, Promotions, or Junk folder</b> for an email from “noreply@demvpro.org” if you don’t see it in your inbox within one minute.<br><br><b>The login link will expire in one hour</b>')

					// store current route so we can go back there after the user clicks the login link
					let path = vapp.$route.fullPath
					U.local_storage_set('flvh_login_link_path', path)

				} else if (result.status == 'invalid_email') {
					this.$alert(sr('The email address you entered ($1) didn’t work for a login link. If you might have used a different email when registering as a voter protection volunteer, try that other email. (Usually, the email address for this site will be the same email you use to sign in to LBJ.)', this.login_link_email))
				} else {
					this.$alert('An error occurred trying to send your login link. You can try signing in with Google instead.')
				}
			})
		},

	},
}
</script>

<style lang="scss">
.k-login_inner {
	flex:1 1 100%;
	margin:0 auto 100px auto;
	max-width:600px;
	text-align:center;
	img {
		cursor:pointer;
		width:350px;
		max-width:calc(100vw - 60px);
	}

	.k-login-activity-header {
		margin-bottom:20px;
	}
}
</style>
