<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template>
	<v-dialog v-model="dialog_open" max-width="1000" persistent scrollable content-class="k-resource-collection-item-lesson-card-dialog">
		<v-card class="k-resource-collection-item-lesson-card">
			<div class="d-flex">
				<v-icon class="mr-2">fas {{icon}}</v-icon>
				<div class="k-lesson-title" style="font-weight:bold" v-html="resource.title"></div>
				<v-spacer/>
			</div>

			<div v-show="show_inline_document" class="my-2 k-aspect-ratio-box--10-8 k-resource-link-iframe-outer"><div class="k-aspect-ratio-box-inside">
				<iframe id="inline_resource_iframe_doc" :src="url" class="k-resource-link-iframe"></iframe>
			</div></div>

			<div v-if="show_youtube_player" id="k-youtube-player-wrapper" class="my-2 k-aspect-ratio-box--10-8 k-resource-link-iframe-outer"><div class="k-aspect-ratio-box-inside-video" :style="video_wrapper_style">
				<div v-if="video_wrapper_ready>1"><youtube
					:video-id="youtube_id"
					:player-vars="youtube_player_vars"
					fitParent
					resize
					ref="youtube"
				></youtube></div>
			</div></div>

			<v-card-actions class="ma-0 px-1 pt-3 pb-1">
				<v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-on="on" class="mr-1" fab x-small dark color="#333" @click="open_resource_in_new_window"><v-icon small>fas fa-arrow-up-right-from-square</v-icon></v-btn></template>Open resource in new window</v-tooltip>
				<v-spacer/>
				<v-btn color="secondary" dark small @click="$emit('dialog_cancel')"><v-icon small class="mr-2">fas fa-times</v-icon>Done</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
	// components: { ResourceViewer },
	props: {
		resource: { type: Object, required: true },
		// nreq: { type: String, required: false, default() { return ''} },
	},
	data() { return {
		dialog_open: true,
		youtube_player_vars: {
			autoplay: 0,
			controls: 1,	// show controls
			disablekb: 0,	// disable keyboard
			enablejsapi: 1,
			fs: 1,
			modestbranding: 1,
			rel: 0,
			playsinline: 1,	// allow the video to play without automatically going to full screen mode on iOS
		},
		video_wrapper_ready: 0,
	}},
	computed: {
		...mapState([]),
		...mapGetters([]),
		icon() {
			return this.resource.icon()
		},
		url() {
			if (this.resource.type == 'video') return 'https://youtu.be/' + this.youtube_id
			else if (this.resource.type == 'html') return this.resource.full_url() + '&' + encodeURIComponent(this.resource.title)
			else return this.resource.full_url()
		},
		youtube_id() {
			// https://www.youtube.com/watch?v=jofNR_WkoCE&foo
			let video_id = this.resource.url.replace(/.*\bv=([A-Za-z0-9_-]+).*/, '$1')
			// https://youtu.be/BtN-goy9VOY
			if (video_id == this.resource.url) video_id = this.resource.url.replace(/.*youtu\.be\/([A-Za-z0-9_-]+).*/, '$1')

			if (video_id.indexOf('/') > -1) {
				this.$inform(sr('The video id may be malformed ($1)', this.resource.url))
			}

			return video_id
		},
		show_youtube_player() {
			return this.resource.type == 'video'
		},
		show_inline_document() {
			return !this.show_youtube_player
		},
		video_wrapper_style() {
			if (this.video_wrapper_ready == 0) return ''

			// the area available for the video needs to have the aspect ratio 600 x 340
			let height = Math.round($('#k-youtube-player-wrapper').parent().height() - 90)
			let width = Math.round(600 / 338 * height)
			let left = Math.round(($('#k-youtube-player-wrapper').parent().width() - width) / 2)
			let top = 0;

			if (left < 0) {
				width = Math.round($('#k-youtube-player-wrapper').parent().width())
				height = (338 / 600 * width)
				left = 0
				top = Math.round((($('#k-youtube-player-wrapper').parent().height() - 90) - height) / 2)
			}

			let s = sr('width:$1;height:$2;left:$3;top:$4;border:1px solid #000;background-color:#000;', width+'px', height+'px', left+'px', top+'px')
			return s
		},
	},
	watch: {
		resource: {
			immediate: true, handler(val) {
				// we do this to make the aspect ratio of videos work properly
				this.video_wrapper_ready = 0
				setTimeout(x=>this.video_wrapper_ready=1, 100)
				setTimeout(x=>this.video_wrapper_ready=2, 200)
			}
		}
	},
	created() {
	},
	mounted() {
	},
	methods: {
		open_resource_in_new_window() {
			window.open(this.url)
		},
	}
}
</script>

<style lang="scss">
.k-resource-collection-item-lesson-card-dialog {
	border-radius:12px!important;

	.k-resource-link-inline-html {
		max-width:760px;
		margin-left:auto;
		margin-right:auto;
	}
}

.k-resource-collection-item-lesson-card {
	border-radius:12px!important;
	padding:12px;
}

.k-lesson-title {
	font-size:16px;
	line-height:20px;
	// margin-bottom:16px;
}

.k-aspect-ratio-box--10-8 {
	height: 0;
	overflow: hidden;
	padding-top: 10px / 8px * 100%;
	background: white;
	position: relative;
}
.k-aspect-ratio-box-inside {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.k-aspect-ratio-box-inside-video {
	position: absolute;
	top: 0;
	left: 0;
	width: 600px;
	height: 400px;
}

.k-resource-link-iframe-outer.k-aspect-ratio-box--10-8 {
	// use this so the iframe won't be too tall when it's wide
	padding-top: min(70vh, 125%);
}

.k-resource-link-iframe {
	width:100%;
	height:100%;
	border:1px solid #000;
	background-color:#fff;
}

.k-resource-link-inline-html {
	background-color:#fff;
	border-radius:8px;
	padding:5px 15px 15px 15px;
	font-size:14px;
}

</style>
