<template><div class="k-page-holder k-admin-page-holder">
	<h2 class="mt-0">System Admin</h2>
	<v-tabs class="k-admin-tabs">
		<v-tab to="/admin"><span class="k-tab-label"><v-icon small>fas fa-cog</v-icon></span></v-tab>
		<v-tab to="/admin/users"><span class="k-tab-label">People</span></v-tab>
		<v-tab to="/admin/locations"><span class="k-tab-label">Locations</span></v-tab>
		<v-tab to="/admin/shifts"><span class="k-tab-label">Shifts</span></v-tab>
		<v-tab to="/admin/shiftgrid"><span class="k-tab-label">Shift Grid View</span></v-tab>
	</v-tabs>
	<div v-show="base_view">
		<p>Hi there lovely admin user!</p>
		<div v-if="is_system_admin" class="my-3"><v-btn small color="primary" @click="editor_showing=!editor_showing"><v-icon small class="mr-2">fas fa-edit</v-icon>Edit Site Data</v-btn></div>

		<div v-show="editor_showing" style="margin:8px 0; background-color: #eee; border-radius:8px; width:700px; padding:12px;">
			<div>
				<b>Site Organization Code:</b> <span class="ml-2" style="font-family:monospace; font-size:1.2em;">{{vh_org_id}}</span>
			</div>
			<div class="mt-3">
				<v-text-field dense outlined hide-details background-color="#fff" label="Site title" v-model="edited_title" placeholder="" autocomplete="new-password"></v-text-field>
			</div>
			<div class="mt-3">
				<v-text-field dense outlined hide-details background-color="#fff" label="Site phone number" v-model="edited_phone_number" placeholder="" autocomplete="new-password"></v-text-field>
			</div>
			<div class="mt-3">
				<v-text-field dense outlined hide-details background-color="#fff" label="Tech help email" v-model="edited_tech_help_email" placeholder="" autocomplete="new-password"></v-text-field>
			</div>
			<div class="mt-3">
				<v-text-field dense outlined hide-details background-color="#fff" label="LBJ Election ID" v-model="edited_lbj_election_id" placeholder="" autocomplete="new-password"></v-text-field>
			</div>
			<div class="mt-3">
				<v-text-field dense outlined hide-details background-color="#fff" label="Election Date (MM-DD)" v-model="edited_election_date" placeholder="" autocomplete="new-password"></v-text-field>
			</div>
			<div class="mt-3">
				<v-text-field dense outlined hide-details background-color="#fff" label="Number of days to schedule shifts for POLL WATCHERS (including election day)" v-model="edited_n_shift_dates" placeholder="" autocomplete="new-password"></v-text-field>
			</div>
			<div class="mt-3">
					<v-text-field dense outlined hide-details background-color="#fff" label="Number of days to schedule shifts for HOTLINE (including election day)" v-model="edited_n_shift_dates_hotline" placeholder="" autocomplete="new-password"></v-text-field>
				</div>
			<div class="mt-3">
				<v-textarea class="k-counties-textarea" dense outlined hide-details background-color="#fff" label="Counties (comma-separated list)" v-model="edited_counties" placeholder=""></v-textarea>
			</div>
			<div class="mt-3">
				<v-textarea class="k-counties-textarea" dense outlined hide-details background-color="#fff" label="Review materials for POLL WATCHER emails initiated from shift grid view" v-model="edited_shift_grid_email_watcher_links" placeholder=""></v-textarea>
				<div style="font-size:12px; margin-top:2px; margin-left:8px;font-style:italic;">Enter one line per link; for each line, enter a link description, then a :, then the link url</div>
			</div>
			<div class="mt-3">
				<v-textarea class="k-counties-textarea" dense outlined hide-details background-color="#fff" label="Review materials for HOTLINE emails initiated from shift grid view" v-model="edited_shift_grid_email_hotline_links" placeholder=""></v-textarea>
				<div style="font-size:12px; margin-top:2px; margin-left:8px;font-style:italic;">See note above</div>
			</div>
			<div class="mt-3">
				<v-text-field dense outlined hide-details background-color="#fff" label="Custom roles (separate multiple by commas; no spaces allowed in roles)" v-model="edited_custom_roles" placeholder="" autocomplete="new-password"></v-text-field>
			</div>

			<div class="mt-3">
				<b>Site Pages/Tabs:</b>
				<div class="mt-1" style="font-size:14px"><i>NOTE: if the “Limited to role(s)” field is empty for a tab, the tab is viewable by everyone.</i></div>
				<div class="ml-4">
					<draggable v-bind="drag_options" v-model="edited_tab_order" @change="tab_moved">
						<div v-for="(key) in edited_tab_order" :key="key" class="mt-3 d-flex align-center">
							<v-icon small color="#999" class="mr-1 k-page-move-handle">fas fa-arrows-alt</v-icon>
							<div style="width:180px">{{page_data[key].tab_title}}</div>
							<v-checkbox class="mt-0 pt-0" label="Tab enabled" v-model="tab_enabled[key]" hide-details></v-checkbox>
							<div style="width:340px"><v-select class="ml-3" dense v-model="enabled_roles[key]" :items="role_options" label="Limited to role(s)" outlined hide-details multiple chips small-chips deletable-chips></v-select></div>
							<!-- <v-text-field outlined dense background-color="#fff" class="ml-3" hide-details label="Limited to role(s)" v-model="enabled_roles[key]" autocomplete="new-password"></v-text-field> -->
						</div>
					</draggable>
				</div>
			</div>
			<div class="mt-4 text-center"><v-btn small color="primary" @click="save_changes">Save Changes</v-btn></div>
		</div>
	</div>
	<router-view></router-view>
	<!-- <TemplateComponent /> -->
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
import draggable from 'vuedraggable'
// import TemplateComponent from '@/components/TemplateComponent'

export default {
	components: { draggable },
	props: {
		// req: { type: String, required: true },
		// nreq: { type: String, required: false, default() { return ''} },
	},
	data() { return {
		editor_showing: false,
		edited_title: '',
		edited_phone_number: '',
		edited_tech_help_email: '',
		edited_lbj_election_id: '',
		edited_election_date: '',
		edited_n_shift_dates: 0,
		edited_n_shift_dates_hotline: 0,
		edited_counties: '',
		edited_custom_roles: '',
		edited_shift_grid_email_watcher_links: '',
		edited_shift_grid_email_hotline_links: '',
		tab_enabled: {},
		enabled_roles: {},
		edited_tab_order: [],
		tab_order_changed: false,
		drag_options: {
			group: 'drag_group',
			animation: 200,
			handle: ".k-page-move-handle",
		},
		role_options: [],
	}},
	computed: {
		...mapState(['vh_org_id', 'site_data', 'page_data']),
		...mapGetters(['is_system_admin']),
		base_view() {
			return this.$route.name == 'admin'
		},
	},
	watch: {
	},
	created() {
		vapp.admin_view_editor_component = this
		this.set_editor_vals()
	},
	mounted() {
	},
	methods: {
		go_to_route(route_name) {
			vapp.go_to_route(route_name)
		},

		tab_moved() {
			console.log('tab_moved')
			this.tab_order_changed = true
		},

		set_editor_vals() {
			let arr = []
			// start with default roles (see user.js)
			for (let o of U.default_roles) {
				arr.push(o)
			}

			// add any custom roles that were already in site_data
			for (let role of this.site_data.custom_roles) {
				arr.push({value: role, text: role})
			}
			this.role_options = arr


			// transfer values from state to local editor values; we do this when the component is first loaded, then again when a save occurs
			this.edited_title = this.site_data.title
			this.edited_phone_number = this.site_data.phone_number
			this.edited_tech_help_email = this.site_data.tech_help_email
			this.edited_lbj_election_id = this.site_data.lbj_election_id
			this.edited_election_date = this.site_data.election_date
			this.edited_n_shift_dates = this.site_data.n_shift_dates
			this.edited_n_shift_dates_hotline = this.site_data.n_shift_dates_hotline
			this.edited_counties = this.site_data.counties.join(', ')
			this.edited_custom_roles = this.site_data.custom_roles.join(', ')
			this.edited_shift_grid_email_watcher_links = this.site_data.shift_grid_email_watcher_links
			this.edited_shift_grid_email_hotline_links = this.site_data.shift_grid_email_hotline_links
			this.tab_enabled = {}
			this.enabled_roles = {}
			for (let label in this.page_data) {
				this.tab_enabled[label] = this.page_data[label].tab_enabled
				this.enabled_roles[label] = this.page_data[label].enabled_roles.split(',')
			}
			this.edited_tab_order = this.site_data.tab_order.concat([])

			// if tab_order hasn't yet been explicitly saved, construct
			if (this.edited_tab_order.length == 0) {
				for (let key in this.page_data) this.edited_tab_order.push(key)
			}

			this.tab_order_changed = false
		},

		save_changes() {
			let changed = false

			this.edited_n_shift_dates = this.edited_n_shift_dates*1
			if (isNaN(this.edited_n_shift_dates) || this.edited_n_shift_dates < 1) {
				this.$alert('You must enter a numeric value > 0 for the number of days to schedule shifts for poll watchers')
				return
			}

			this.edited_n_shift_dates_hotline = this.edited_n_shift_dates_hotline*1
			if (isNaN(this.edited_n_shift_dates_hotline) || this.edited_n_shift_dates_hotline < 1) {
				this.$alert('You must enter a numeric value > 0 for the number of days to schedule shifts for the Hotline')
				return
			}

			// update page records if necessary
			for (let label in this.page_data) {
				if (this.tab_enabled[label] != this.page_data[label].tab_enabled || this.enabled_roles[label].join(',') != this.page_data[label].enabled_roles) {
					this.$store.commit('set', [this.page_data[label], 'tab_enabled', this.tab_enabled[label]])
					this.$store.commit('set', [this.page_data[label], 'enabled_roles', this.enabled_roles[label].join(',')])
					let updated_json = $.extend(true, {}, this.page_data[label])

					changed = true
					U.loading_start()
					this.$store.dispatch('save_page', {page_data: updated_json}).then((result)=>{
						U.loading_stop()
					}).catch((result)=>{
						U.loading_stop()
						this.$alert('An error occurred when saving the site changes.')
					})
				}
			}

			// update site_data if necessary
			if (this.edited_title != this.site_data.title
				|| this.edited_phone_number != this.site_data.phone_number
				|| this.edited_tech_help_email != this.site_data.tech_help_email
				|| this.edited_lbj_election_id != this.site_data.lbj_election_id
				|| this.edited_election_date != this.site_data.election_date
				|| this.edited_n_shift_dates != this.site_data.n_shift_dates
				|| this.edited_n_shift_dates_hotline != this.site_data.n_shift_dates_hotline
				|| this.edited_counties != this.site_data.counties.join(', ')
				|| this.edited_custom_roles != this.site_data.custom_roles.join(', ')
				|| this.edited_shift_grid_email_watcher_links != this.site_data.shift_grid_email_watcher_links
				|| this.edited_shift_grid_email_hotline_links != this.site_data.shift_grid_email_hotline_links
				|| this.tab_order_changed
			) {
				this.$store.commit('set', [this.site_data, 'title', this.edited_title])
				this.$store.commit('set', [this.site_data, 'phone_number', this.edited_phone_number])
				this.$store.commit('set', [this.site_data, 'tech_help_email', this.edited_tech_help_email])
				this.$store.commit('set', [this.site_data, 'lbj_election_id', this.edited_lbj_election_id])
				this.$store.commit('set', [this.site_data, 'election_date', this.edited_election_date])
				this.$store.commit('set', [this.site_data, 'n_shift_dates', this.edited_n_shift_dates])
				this.$store.commit('set', [this.site_data, 'n_shift_dates_hotline', this.edited_n_shift_dates_hotline])
				this.$store.commit('set', [this.site_data, 'counties', $.trim(this.edited_counties).split(/\s*,\s*/)])
				this.$store.commit('set', [this.site_data, 'custom_roles', $.trim(this.edited_custom_roles).split(/\s*,\s*/)])
				this.$store.commit('set', [this.site_data, 'shift_grid_email_watcher_links', this.edited_shift_grid_email_watcher_links])
				this.$store.commit('set', [this.site_data, 'shift_grid_email_hotline_links', this.edited_shift_grid_email_hotline_links])
				this.$store.commit('set', [this.site_data, 'tab_order', this.edited_tab_order])

				changed = true
				let tab_order_changed_local = this.tab_order_changed	// have to remember this for the promise callback
				U.loading_start()
				this.$store.dispatch('save_site_data').then((result)=>{
					U.loading_stop()
					console.log('this.tab_order_changed: ' + this.tab_order_changed)
					// if tab_order was changed, update this.page_data to reflect new order
					if (tab_order_changed_local) {
						let o = {}
						for (let key of this.edited_tab_order) {
							o[key] = this.page_data[key]
						}
						this.$store.commit('set', ['page_data', o])
					}
				}).catch((result)=>{
					U.loading_stop()
					this.$alert('An error occurred when saving the site changes.')
				})
			}

			// inform user of what happened
			if (changed) {
				this.$inform({text: 'Changes saved.', color: 'green darken-2', snackbarTimeout:3000})
				this.set_editor_vals()
			} else {
				this.$inform({text: 'Nothing was updated, so no changes have been saved.', color: 'pink darken-2', snackbarTimeout:2000})
			}

		},
	}
}
</script>

<style lang="scss">
.k-admin-page-holder {
	max-width:95%;
}

.k-admin-tabs {
	margin-bottom:20px;
	a {
		text-transform:none!important;
		letter-spacing:0px!important;
	}
	.v-tab {
		min-width:50px;
	}
	.v-tab--active {
		font-weight:bold;
	}
}

.k-counties-textarea {
	textarea {
		font-size:12px;
		line-height:16px;
	}
}
</style>
