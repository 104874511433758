<template><div class="k-page-sections-wrapper">
	<div v-if="is_system_admin" class="text-right"><v-btn small text color="primary" @click="add_new_section"><v-icon small class="mr-2">fas fa-plus</v-icon>Add New Section</v-btn></div>
	<draggable v-bind="drag_options" v-model="page_data.sections" @end="sections_moved">
		<v-hover v-slot:default="{hover}" v-for="(section, index) in page_data.sections" :key="section.id" v-if="is_system_admin||section.mode!='hidden'"><div class="k-page-section" :class="section_css(section,hover)">
			<div v-if="section.mode=='hidden'" class="red--text text--darken-3 text-center mb-2"><b>[THIS SECTION IS HIDDEN]</b></div>
			<h3 v-if="section.title" @click.stop="toggle_section(index)" class="d-flex" :style="section.mode=='no_collapse'?'':'cursor:pointer'">
				<v-btn v-if="section.mode!='no_collapse'" small icon class="mr-2"><v-icon v-if="!show_section[index]">fas fa-caret-right</v-icon><v-icon v-else>fas fa-caret-down</v-icon></v-btn>
				<span v-html="section.title" class="fr-view"></span>
				<v-spacer/>
				<v-icon v-show="hover" v-if="is_system_admin" small color="#999" class="mr-1 k-section-move-handle" style="margin-top:-14px;">fas fa-arrows-alt</v-icon>
				<v-btn v-show="hover" v-if="is_system_admin" icon small color="primary" @click.stop="edit_section(index)"><v-icon small>fas fa-edit</v-icon></v-btn>
			</h3>
			<v-btn v-show="hover" v-if="is_system_admin&&!section.title" class="float-right" icon small color="primary" @click.stop="edit_section(index)"><v-icon small>fas fa-edit</v-icon></v-btn>
			<v-icon v-show="hover" v-if="is_system_admin&&!section.title" small color="#999" class="float-right mr-1 k-section-move-handle" style="margin-top:6px;">fas fa-arrows-alt</v-icon>
			<div v-show="section.mode=='no_collapse'||show_section[index]" class="fr-view" :class="section.title?'ml-9':''" v-html="section.html"></div>
		</div></v-hover>
	</draggable>

	<v-dialog v-if="editor_showing" v-model="editor_showing" persistent max-width="900px">
		<v-card>
			<v-card-title>Edit Section</v-card-title>
			<v-card-text>
				<div class="d-flex">
					<b class="mr-3">Section visibility:</b>
					<v-checkbox class="mt-0 pt-0" :label="'Visible'" v-model="visible" hide-details></v-checkbox>
					<v-checkbox v-if="visible" class="mt-0 pt-0 ml-3" :label="'Collapsible'" v-model="collapsible" hide-details></v-checkbox>
					<v-checkbox v-if="collapsible" class="mt-0 pt-0 ml-3" :label="'Collapsed to start'" v-model="start_collapsed" hide-details></v-checkbox>
					<!-- <v-select dense v-model="edited_mode" :items="modes" label="Section “Mode”" dense outlined hide-details></v-select> -->
				</div>
				<div class="mt-4">
					<div class="mb-1"><b>Section title:</b></div>
					<froala-wrapper :config="title_editor_config()" :parent_component="this" v-model="edited_title" />
				</div>
				<div class="mt-3">
					<div class="mb-1"><b>Section text:</b></div>
					<froala-wrapper :config="html_editor_config()" :parent_component="this" v-model="edited_html" />
				</div>
			</v-card-text>
			<v-card-actions class="px-4 pb-4">
				<v-btn color="secondary" @click="close_editor">Cancel</v-btn>
				<v-spacer/>
				<v-btn color="red" @click="delete_section">Delete Section</v-btn>
				<v-spacer/>
				<v-btn color="primary" @click="save_changes">Save and Close</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>

	<ResourceEditor v-if="resource_being_edited"
		:original_resource="resource_being_edited"
		@edit_resource_cancel="create_resource_cancel"
		@edit_resource_saved="edit_resource_saved"
	/>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
import draggable from 'vuedraggable'
import ResourceMixin from '@/components/mixins/ResourceMixin'
import ResourceEditor from '@/components/ResourceEditor'

export default {
	mixins: [ResourceMixin],
	components: { draggable, ResourceEditor },
	props: {
		tab_id: { type: String, required: true },
		// page_data: { type: Object, required: true },
		// nreq: { type: String, required: false, default() { return ''} },
	},
	data() { return {
		show_section: [],
		edited_section: -1,
		editor_showing: false,
		edited_title: '',
		edited_html: '',
		visible: true,
		collapsible: false,
		start_collapsed: false,
		drag_options: {
			group: 'case_items',
			animation: 200,
			handle: ".k-section-move-handle",
		},

		modes: [
			{value:'no_collapse', text: 'Always visible'},
			{value:'collapsible', text: 'Visible when loaded, but collapsible'},
			{value:'start_collapsed', text: 'Collapsed when loaded'},
			{value:'hidden', text: 'Hidden/disabled'},
		],

		editor_component_id: U.new_uuid(),
	}},
	computed: {
		...mapState([]),
		...mapGetters(['is_system_admin']),
		page_data() {
			if (!this.$store.state.page_data[this.tab_id]) return {}
			return this.$store.state.page_data[this.tab_id]
		},
		edited_mode() {
			// translate from checkbox settings to edited_mode value
			if (!this.visible) return 'hidden'
			if (this.collapsible) {
				if (this.start_collapsed) return 'start_collapsed'
				else return 'collapsible'
			}
			return 'no_collapse'
		}
	},
	watch: {
		page_data: {
			immediate: true, handler(val) {
				if (!this.page_data.sections) return

				// update show_section flags immediately, then when page_data is updated
				let arr = []
				for (let section of this.page_data.sections) {
					if (section.mode == 'start_collapsed') arr.push(false)
					else arr.push(true)
				}
				this.show_section = arr
			}
		},
	},
	created() {
		vapp.page_sections = this
	},
	mounted() {
	},
	methods: {
		section_css(section, hover) {
			if (section.mode == 'hidden') return 'k-page-section-hidden'
			if (hover) return 'k-page-section-hovered'
		},

		toggle_section(index) {
			this.show_section.splice(index, 1, !this.show_section[index])
			console.log(index + ': ' + this.show_section[index])
		},

		title_editor_config() {
			let config = U.get_froala_config({
				placeholderText: 'Section title (optional)',
				// initOnClick: true,
				toolbarInline: false,
				minHeight:50,
				editorClass: 'k-froala-editor-section-title',
			})

			// add the important btn at the front of the moreRich buttons
			config.toolbarButtons.moreRich.buttons.unshift('important')

			return config
		},

		html_editor_config(text) {
			if (empty(text)) text = ''
			let config = U.get_froala_config({
				placeholderText: text,
				// initOnClick: true,
				toolbarInline: false,
				minHeight:200,
				editorClass: 'k-froala-editor-section-html',
			})

			// add the insert resource btn at the front of the moreRich buttons
			config.toolbarButtons.moreRich.buttons.unshift('insert_resource')

			// add the important btn at the front of the moreRich buttons
			config.toolbarButtons.moreRich.buttons.unshift('important')
			return config
		},

		edit_section(index) {
			this.edited_title = this.page_data.sections[index].title
			this.edited_html = this.page_data.sections[index].html

			// translate from mode value to checkbox settings
			if (this.page_data.sections[index].mode == 'hidden') {
				this.visible = false
			} else {
				this.visible = true
				if (this.page_data.sections[index].mode == 'no_collapse') {
					this.collapsible = false
				} else {
					this.collapsible = true
					if (this.page_data.sections[index].mode == 'start_collapsed') {
						this.start_collapsed = true
					} else {
						this.start_collapsed = false
					}
				}
			}

			this.edited_section = index
			this.editor_showing = true

			// have to do this for the resource editor to work properly
			this.$store.commit('set', ['editor_components', this.editor_component_id, this])
		},

		add_new_section() {
			this.edited_title = ''
			this.edited_html = ''
			this.visible = true
			this.collapsible = true
			this.start_collapsed = false
			this.edited_section = -1
			this.editor_showing = true

			// have to do this for the resource editor to work properly
			this.$store.commit('set', ['editor_components', this.editor_component_id, this])
		},

		close_editor() {
			this.edited_section = -1
			this.editor_showing = false
		},

		save_changes() {
			let section
			if (this.edited_section >= 0) {
				section = Object.assign({}, this.page_data.sections[this.edited_section])
			} else {
				section = new Page_Section_Data()
			}

			this.edited_title = window.trim_froala_text(this.edited_title)
			this.edited_html = window.trim_froala_text(this.edited_html)

			if (this.edited_mode == section.mode && this.edited_title == section.title && this.edited_html == section.html) {
				this.$inform('No changes were made.')
				this.close_editor()
				return
			}

			section.mode = this.edited_mode
			section.title = this.edited_title
			section.html = this.edited_html

			// every section must at least have a title or text
			let title_text = U.html_to_text(section.title)
			let html_text = U.html_to_text(section.html)
			if (title_text == '' && html_text == '') {
				this.$alert('Every section must have a section title or section text (or both).')
				return
			}

			// if it only has one or the other, it can't be collapsible
			if (title_text == '' || html_text == '') {
				if (section.mode != 'hidden') section.mode = 'no_collapse'
			}
			// console.log(section)

			// replace existing section or unshift new one
			if (this.edited_section >= 0) {
				this.$store.commit('set', [this.page_data.sections, 'SPLICE', this.edited_section, section])
			} else {
				this.$store.commit('set', [this.page_data.sections, 'UNSHIFT', section])
			}

			// dispatch save_page with a deep copy of page_data
			this.$store.dispatch('save_page', {page_data: $.extend(true, {}, this.page_data)})
			// note that save_page replaces page_data, updating the dates

			this.close_editor()
		},

		delete_section() {
			this.$confirm({
			    title: 'Are you a sure?',
			    text: 'Are you sure you want to delete this section? (If you might want to show it again later, you can hide it instead.)',
			    acceptText: 'Delete Section',
				acceptColor: 'red',
				dialogMaxWidth: 600,
			}).then(y => {
				// remove the section, then save the page data
				this.$store.commit('set', [this.page_data.sections, 'SPLICE', this.edited_section])
				this.$store.dispatch('save_page', {page_data: $.extend(true, {}, this.page_data)})
				this.close_editor()
			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		sections_moved() {
			// just save the page; the sections array will have been reordered by vuedraggable
			this.$store.dispatch('save_page', {page_data: $.extend(true, {}, this.page_data)})
		},

		// :onManualControllerReady="froala_initialized"
		// froala_initialized(initControls) {
		// 	console.log('froala_initialized')
		// 	this.initControls = initControls
		// 	this.feo = initControls.getEditor()
		// },
	}
}
</script>

<style lang="scss">
.k-page-section {
	margin-top:12px;
	padding-top:12px;
	padding-left:8px;
	border:1px solid transparent;
	border-top-color:#ccc;
}

.k-page-section-hovered {
	border-color:#ccc;
	border-radius:8px;
}

.k-page-section-hidden {
	background-color:$v-red-lighten-4;
	border:1px solid $v-red;
}

.k-froala-editor-section-title {
	font-weight:bold;
	font-size:18px;
	color:#000;
}

.k-froala-editor-section-html {
	font-size:16px;
	color:#000;
}
</style>
