<template><v-dialog v-model="dialog_open" persistent max-width="800px"><v-card>
	<v-card-title><b>Add New Locations</b></v-card-title>
	<v-card-text class="black--text">
		<div class="d-flex align-center">
			<div class="mr-2">Specify:</div>
			<div class="mr-2" style="width:240px"><v-autocomplete outlined dense hide-details label="County" v-model="county" :items="counties"></v-autocomplete></div>
			<div class="mr-2" style="width:150px"><v-select outlined dense hide-details v-model="location_type" label="Location Type" :items="location_types"></v-select></div>
			<div class="mr-2" style="width:150px"><v-select outlined dense hide-details v-model="location_status" label="Status" :items="location_statuses"></v-select></div>
			<div class="mr-2" style="width:150px"><v-select outlined dense hide-details v-model="default_priority" label="Default Priority" :items="default_priorities"></v-select></div>
		</div>
		<div v-if="county&&location_type" class="mt-4">
			<p v-if="all_counties">On each line, specify the following, separated by tabs: [priority (number) or vh_location_id], county, LBJ ID (number), location description/address/city/zip.</p>
			<p v-else>Enter new location(s), one per line. If you know the LBJ ID for each location, enter the LBJ ID at the start of the line, followed by a tab stop or a comma and a space, then the location address.</p>
			<v-textarea outlined hide-details auto-grow v-model="new_locations" rows="10" class="k-new-location-textarea"></v-textarea>
		</div>
	</v-card-text>
	<v-card-actions class="pl-4 pr-4 pb-4">
		<v-btn color="secondary" @click="$emit('close')" class="mr-1"><v-icon small class="mr-2">fas fa-times</v-icon> Cancel</v-btn>
		<v-spacer></v-spacer>
		<v-btn v-if="new_locations" color="primary" @click="save_locations"><v-icon small class="mr-2">fas fa-check</v-icon> Save</v-btn>
	</v-card-actions>
</v-card></v-dialog></template>

<script>
import { mapState, mapGetters } from 'vuex'
import '../../js/one_time_check_lbj_location_ids.js'

export default {
	components: { },
	props: {
		// admin_user: { type: Object, required: true },
	},
	data() { return {
		dialog_open: true,
		county: '',
		location_type: '',
		// county: 'Manatee',		// debug
		// location_type: 'ev',		// debug

		location_status: 'active',
		default_priority: 5,
		new_locations: '',
		location_types: [
			{value: 'ev', text: 'Early Voting'},
			{value: 'ed', text: 'Election Day'},
		],
		location_statuses: [
			{value: 'active', text: 'Active'},
			{value: 'inactive', text: 'Inactive'},
		],
	}},
	computed: {
		...mapState(['state', 'site_data', 'locations']),
		default_priorities() { return U.shift_priority_select_items() },
		counties() {
			let arr = this.$store.state.site_data.counties.concat([])
			arr.unshift('ALL (specify vh_location_id as first parameter)')
			arr.unshift('ALL (specify ED Priority as first parameter)')
			return arr
		},
		all_counties() { return this.county.indexOf('ALL') == 0 },
	},
	created() {
	},
	mounted() {
		// use default_county if set
		if (this.$store.state.lst.default_county && this.$store.state.lst.default_county != 'ALL') this.county = this.$store.state.lst.default_county
	},
	methods: {
		save_locations() {
			let lines = this.new_locations.split(/\n/)
			let records = []
			let line_number = 1
			let replacements = 0
			for (let line of lines) {
				line = $.trim(line)

				// extract number at start if there (??)
				line = line.replace(/^\d+\.\s*/, '')

				if (empty($.trim(line))) continue

				// extract LBJ ID if there, plus county if needed
				let address = $.trim(line)
				let lbj_location_id = ''
				let county = this.county
				let ed_priority = ''
				let vh_location_id

				if (this.all_counties) {
					// for all_counties, we always use tabs and require 4 values
					let arr = line.split('\t', arr)
					if (arr.length != 4) {
						this.$alert(sr('Error on line $1: this line does not include 3 tab stops. ($2)', line_number, line))
						return

					}
					// first param can be ed_priority or vh_location_id
					if (this.county == 'ALL (specify vh_location_id as first parameter)') {
						vh_location_id = $.trim(arr[0])
					} else {
						ed_priority = $.trim(arr[0])
					}
						
					county = $.trim(arr[1])
					lbj_location_id = $.trim(arr[2])
					address = $.trim(arr[3])

				} else {
					// first check for tabs
					let arr = line.split('\t', arr)
					if (arr.length > 2) {
						this.$alert(sr('Error on line $1: this line includes more than one tab stop. ($2)', line_number, line))
						return
					} else if (arr.length == 2) {
						lbj_location_id = $.trim(arr[0])
						address = $.trim(arr[1])
					
					// if no tabs, check for commas
					} else if (address.search(/^(\d+), (.*)/) > -1) {
						lbj_location_id = RegExp.$1
						address = RegExp.$2
					}
				}

				// make sure the county is valid
				if (!this.counties.includes(county)) {
					this.$alert(sr('Error on line $1: invalid county: $2 ($3)', line_number, county, line))
					return
				}

				// if we got a vh_location_id, everything is a replacement
				if (vh_location_id) {
					++replacements
				} else {
					// if we didn't get a vh_location_id...
					// if we got an lbj_location_id, see if we already have a location with this lbj_location_id and this location_+type
					if (lbj_location_id) {
						let existing_loc = this.locations.find(x=>x.lbj_location_id == lbj_location_id && x.location_type == this.location_type)
						if (existing_loc) {
							// if we're here, update the existing vh_location_id
							vh_location_id = existing_loc.vh_location_id
							++replacements
						}
					}

					// make sure we don't already have this location, unless we determined above that we're doing a replacement based on lbj_location_id
					let lc = address.toLowerCase()
					if (!vh_location_id && this.locations.find(x=>(x.location.toLowerCase() == lc && x.county == county && x.location_type == this.location_type))) {
						this.$alert(sr('Error on line $1: location already exists ($2)', line_number, line))
						return
					}
				}

				let o = {
					county: county, 
					lbj_location_id: lbj_location_id, 
					location:address, 
				}
				if (ed_priority) o.ed_priority = ed_priority
				if (vh_location_id) o.vh_location_id = vh_location_id
				records.push(o)

				++line_number
			}

			if (records.length == 0) {
				this.$emit('close')
				return
			}

			console.log(records)

			let msg
			if (replacements > 0) {
				msg = sr('This will replace/update $1 location(s) and insert $2 new location(s) in the system. Proceed?', replacements, (records.length - replacements))
			} else {
				msg = sr('This will insert $1 new location(s) into the system. Proceed?', records.length)
			}

			this.$confirm({
			    title: 'Are you sure?',
			    text: msg,
			    acceptText: 'Add Locations',
				dialogMaxWidth: 600
			}).then(y => {
				let payload = {
					state: this.state,
					county: this.all_counties ? 'ALL' : this.county,
					location_type: this.location_type,
					location_status: this.location_status,
					default_priority: this.default_priority,
					new_locations: records
				}

				this.$store.dispatch('add_locations', payload).then((result)=>{
					let msg = sr('$1 record(s) created', result.records_created)
					this.$alert(msg).then(y=>{
						this.$emit('refresh')
						this.$emit('close')
					})
				})

			}).catch(n=>{console.log(n)}).finally(f=>{});
		},

	}
}
</script>

<style lang="scss">
.k-new-location-textarea {
	textarea {
		font-size:12px;
		line-height:15px!important;
		max-height:70vh;
	}
}
</style>
