<!-- Part of the SPARKL educational activity system, Copyright 2019 by Pepper Williams -->
<template>
	<v-container fill-height>
		<div class="k-login_inner">

			<div class="k-login-activity-header">Please click the button below to sign in with Google using the email address you used when signing up to be a hotline volunteer.*</div>

			<!-- <img class="k-google_signin_btn" ref="google_signin_btn" src="/btn_google_signin_dark_normal_web@2x.png"> -->
			<img class="k-google_signin_btn" ref="google_signin_btn" src="./../images/btn_google_signin_dark_normal_web@2x.png">

			<div style="padding-top:20px; font-size:16px; margin:auto; text-align:left;">* (Note that this does <i>not</i> have to be a .gmail account! After clicking the “Sign in” button, you can create a Google Account using any email by clicking the “Create account” or “Use another account” button, then choosing the “Use my current email address instead” option. Alternatively, you can sign in with any gmail account and then follow the subsequent instructions in this site to synch that gmail account with the email address you use for other Hotline correspondence.)</div>

			<div class="mt-4"><v-btn color="#999" dark small @click="more_help=!more_help">Additional Help with the sign-in process</v-btn></div>

			<div v-show="more_help" class="text-left mt-6" style="font-size:16px; border:1px solid #999; padding:10px; border-radius:8px;">
				<p>To access the new website you will need to log in using a Google-linked email account (using the standard “Sign In With Google” method that you’ve probably used with many other sites; we do this so we can protect ourselves from trolls but don’t have to store any passwords in our system).  It does not have to be a gmail account. The vast majority of volunteers today have able to sign in with no trouble.  But here are some tips to follow if you encounter issues.</p>
				<ul>
					<li>If you use a non-gmail account for most Hotline correspondence, but have accessed the prior documentation using a different Google-linked account, just use that same Google-linked account to sign in to the homepage. Or you can use any other Google-linked account to sign in. After signing in you should see a dialog that looks like this:</li>
				</ul>
				<div class="text-center my-2"><img src="/flvhdocs/signin_screenshot.png"></div>
				<ul>
					<li>Enter the email address you use for other hotline correspondence (probably the email where you’ve received this message), click “TRY THIS EMAIL”, and the system should sync the two emails up, so that when you come back later, you can sign in using your Google-linked account and get right in.</li>
					<li>If you don’t see the “Sign in with Google” button at all, or the sign in process doesn’t seem to work, an ad blocker may be fouling things up; try to turn the ad blocker off for the site’s domain (demvpro.org).  If you don’t have an ad blocker on or you can’t disable it, you might try using an alternate browser to access the site.</li>
				</ul>
			</div>
		</div>
	</v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
export default {
	components: {  },
	data() { return {
		more_help: false,
	}},
	computed: {
		...mapState(['google_client_id']),
	},
	created() {

	},
	mounted() {
		this.initialize()
	},
	methods: {
		initialize() {
			U.loading_start()
			// window.gapi should be loaded via https://apis.google.com/js/platform.js in index.html
			// if it isn't loaded yet, try again in 100 ms
			if (window.gapi == null) {
				setTimeout(()=>this.initialize(), 100);
				return;
			}
			U.loading_stop()

			console.log('id', this.google_client_id)

			window.gapi.load('auth2', () => {
				var auth2 = window.gapi.auth2.init({
					client_id: this.google_client_id,
					scope: 'profile email ',
					fetch_basic_profile: true,
					approval_prompt: 'force',
					prompt: 'select_account consent',
					access_type: 'offline'
					// cookiepolicy: 'single_host_origin'
				})

				let jq = $('.k-google_signin_btn')
				console.log(jq.length)
				for (let i = 0; i < jq.length; ++i) {
					// auth2.attachClickHandler(this.$refs.google_signin_btn, {}, googleUser => {
					auth2.attachClickHandler(jq[i], {}, googleUser => {
						// The ID token you need to pass to your backend:
						let id_token = googleUser.getAuthResponse().id_token
						// console.log('attachClickHandler fn called', googleUser.getAuthResponse())

						if (id_token) {
							// pass id_token back to initialize_portal service, which will sign the user in
							vapp.initialize_app({id_token: id_token})
						} else {
							// TODO: what do we do here?
							alert('Google getAuthResponse failed')
						}
					}, error => console.error(error))
				}
			})
		},
	}
}
</script>

<style lang="scss">
.k-login_inner {
	flex:1 1 100%;
	margin:0 auto 100px auto;
	max-width:600px;
	text-align:center;
	img {
		cursor:pointer;
		width:350px;
		max-width:calc(100vw - 60px);
	}

	.k-login-activity-header {
		margin-bottom:20px;
	}
}
</style>
