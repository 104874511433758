<template><div class="k-userinfo-popup elevation-3">
	<v-btn icon color="#666" @click.stop="$emit('close')" style="position:absolute;right:-12px;top:-12px;"><v-icon>fas fa-times-circle</v-icon></v-btn>
	<div v-if="user.name_last">{{user.name_last}}, {{user.name_first}}</div>
	<div v-if="user.email"><a :href="'mailto:' + user.email">{{user.email}}</a></div>
	<div v-if="user.lbj_email">[LBJ: <a :href="'mailto:' + user.lbj_email">{{user.lbj_email}}</a>]</div>
	<div v-if="user.cal_email">[Calendly: <a :href="'mailto:' + user.cal_email">{{user.cal_email}}</a>]</div>
	<div v-if="user.other_email">[Other: <a :href="'mailto:' + user.other_email">{{user.other_email}}</a>]</div>
	<div v-if="user.phone_number">{{user.phone_number}}</div>
	<div v-if="show_edit_btn" class="mt-1 text-center"><v-btn small color="primary" @click.stop="$emit('edit')"><v-icon small class="mr-1">fas fa-edit</v-icon>Edit</v-btn></div>
	<div class="mt-2 text-center"><v-btn x-small color="secondary" @click="simulate_user">Simulate this user…</v-btn></div>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
// import TemplateComponent from '@/components/TemplateComponent'

export default {
	// components: { TemplateComponent },
	props: {
		user: { type: Object, required: true },
		show_edit_btn: { type: Boolean, required: false, default() { return false} },
	},
	data() { return {
	}},
	computed: {
		...mapState([]),
		...mapGetters([]),
	},
	watch: {
	},
	created() {
	},
	mounted() {
	},
	methods: {
		simulate_user() {
			this.$confirm({
				title: 'Simulate another user',
				text: sr('This will simulate this user ($1) so that you can see exactly what the user will see when they sign into the site. To cancel the simulation later, click the “burger” menu in the upper-right corner of the window.', this.user.email),
				acceptText: 'Start Simulation',
			}).then(y => {
				this.$store.dispatch('simulate_user', this.user.email)
			}).catch(n=>{console.log(n)}).finally(f=>{})
		},
	}
}
</script>

<style lang="scss">
.k-userinfo-popup {
	position:absolute;
	z-index:10;
	background-color:#f8f8f8;
	color:#000;
	text-align:left;
	border:1px solid #666;
	white-space:nowrap;
	font-size: 14px;
	border-radius:8px;
	padding:12px 16px;
}
</style>
